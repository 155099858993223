<template>
  <el-row id="content">
    <el-row class="sign-up-title">
      <el-col :span="24">
        <div class="grid-content_title">
          <span class="tips_title">
            <img src="../../../image/wxts1.png" alt /> 温馨提示：
          </span>
          <span
            >所有信息一旦“提交”后不可修改，请认真核对后提交。如出现提交不成功，请注意查看提示，并按提示修改后再提交。</span
          >
        </div>
      </el-col>
    </el-row>
    <el-form
      :model="applicantData"
      :rules="rules"
      label-width="100px"
      ref="applicantData"
    >
      <el-row class="sign-up">
        <el-col :span="24">
          <div class="grid-content">
            <span class="tips_title">
              <img src="../../../image/jgcx.png" />
            </span>
            <span>历史证书</span>
            <span class="tips_content"
              >如下为本身份证号所持有历史证书的信息，对于持有多张证书的，可以点击【证书类别】选择提供换领的证书名称。</span
            >
          </div>
          <div class="trainingInformation">
            <el-col :span="19">
              <el-row class="information">
                <el-col :span="10">
                  <el-form-item label="证书类型： " prop="oldCertType" required>
                    <el-select
                      autocomplete="off"
                      placeholder="请选择证书类型"
                      v-model="applicantData.oldCertType"
                      @change="selectType"
                    >
                      <el-option
                        v-for="(item, index) in trainTypes"
                        :key="index"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="资格类型：" prop="oldExamItem">
                    <el-input
                      autocomplete="off"
                      clearable
                      filterable
                      placeholder=""
                      disabled
                      v-model="applicantData.oldExamItem"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10">
                  <el-form-item label="发证机关：" prop="realName">
                    <el-input
                      clearable
                      :disabled="true"
                      v-model="applicantData.oldAuth"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="10" class="trainingTime">
                  <el-form-item label="发证日期：">
                    <el-input
                      v-model="applicantData.oldAuthTime"
                      :disabled="true"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </div>
        </el-col>
      </el-row>
      <el-row class="sign-up personal">
        <el-col :span="24">
          <div class="grid-content">
            <span class="tips_title">
              <img src="../../../image/bticon_grxx.png" />
            </span>
            <span>申请信息</span>
            <span class="tips_content"
              >请如实填写申请领取考核合格证的个人信息，并上传个人头像照片。</span
            >
          </div>
          <div style="margin-top: 14px" class="trainingInformation">
            <el-row>
              <el-col :span="19">
                <el-col :span="10">
                  <el-form-item label="姓名：" prop="realName">
                    <el-input
                      clearable
                      :disabled="true"
                      placeholder="请输入姓名"
                      v-model="applicantData.realName"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="性别：" prop="sex">
                    <el-select
                      autocomplete="off"
                      clearable
                      placeholder="请选择性别"
                      v-model="applicantData.sex"
                    >
                      <el-option
                        v-for="(item, index) in optionData.sex"
                        :key="index"
                        :value="item.dataCode"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="出生年月：" prop="birthday">
                    <el-date-picker
                      v-model="applicantData.birthday"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="手机号码：" prop="mobile">
                    <el-input
                      clearable
                      placeholder="请输手机号码"
                      maxlength="11"
                      v-model="applicantData.mobile"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="证件类型：" prop="certificateType">
                    <el-select
                      autocomplete="off"
                      clearable
                      placeholder="请选择证件类型"
                      v-model="applicantData.certificateType"
                    >
                      <el-option
                        v-for="(item, index) in optionData.card_type"
                        :key="index"
                        :label="item.dataValue"
                        :value="item.dataCode"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="证件号码：" prop="idCard" required>
                    <el-input
                      placeholder="请输入证件号码"
                      v-model="applicantData.idCard"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4" v-show="applicantData.idCard.length == 15">
                  <div class="modify">
                    <el-radio v-model="radio" label="1">还原</el-radio>
                    <el-radio v-model="radio" label="2">修改</el-radio>
                  </div>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="文化程度：" prop="degreeCulture">
                    <el-select
                      autocomplete="off"
                      clearable
                      placeholder="请选择最高学历"
                      v-model="applicantData.degreeCulture"
                    >
                      <el-option
                        v-for="(item, index) in optionData.degree_culture"
                        :key="index"
                        :label="item.dataValue"
                        :value="item.dataValue"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="单位全称：" prop="workUnit">
                    <el-input
                      clearable
                      placeholder="请输入工作单位全称"
                      v-model="applicantData.workUnit"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="通讯地址：" required>
                    <el-row style="padding: 0">
                      <el-col :span="6">
                        <el-form-item prop="areaCode">
                          <el-select
                            autocomplete="off"
                            clearable
                            placeholder="请选择所在区"
                            v-model="applicantData.area"
                          >
                            <el-option
                              v-for="(item, index) in optionData.area"
                              :key="index"
                              :label="item.dataValue"
                              :value="item.dataValue"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="14">
                        <el-form-item prop="address">
                          <el-input
                            placeholder="请输入详细地址"
                            v-model="applicantData.address"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-col>
              <el-col :span="5" style="position: relative; top: -60px">
                <span class="Photo">证件照片</span>
                <el-upload
                  :before-upload="beforeUpload"
                  :show-file-list="false"
                  action
                  class="avatar-uploader"
                >
                  <img
                    :src="uploadImageUrl"
                    class="avatar"
                    v-if="uploadImageUrl"
                  />
                  <i class="el-icon-plus avatar-uploader-icon" v-else></i>
                </el-upload>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <el-row class="sign-up">
        <el-col :span="24">
          <div class="grid-content">
            <span class="tips_title">
              <img src="../../../image/fzlb.png" />
            </span>
            <span>发证类别</span>
          </div>
          <div class="trainingInformation">
            <el-row>
              <el-col :span="10">
                <el-form-item label="行业类别：" prop="industryType">
                  <el-input
                    clearable
                    :disabled="true"
                    placeholder="行业类别"
                    v-model="applicantData.industryType"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="考核类别：" prop="checkTypeCode">
                  <el-input
                    clearable
                    :disabled="true"
                    placeholder="考核类别"
                    v-model="applicantData.examItem"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="领证方式：" prop="receiveType">
                  <el-input
                    placeholder="请输入领证方式"
                    :disabled="true"
                    v-model="applicantData.receiveType"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="submitBon">
      <el-col :span="24">
        <el-button @click="submit" type="primary">确定</el-button>
        <el-button @click="pre" type="primary">取消</el-button>
      </el-col>
    </el-row>
  </el-row>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    var isMobileNumber = (rule, value, callback) => {
      if (!value) {
        return new Error("请输入电话号码");
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;

        const isPhone = reg.test(value);

        value = Number(value); //转换为数字

        if (typeof value === "number" && !isNaN(value)) {
          //判断是否为数字

          value = value.toString(); //转换成字符串

          if (value.length < 0 || value.length > 12 || !isPhone) {
            //判断是否为11位手机号

            callback(new Error("手机号码格式如:138xxxx8754"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入正确的手机电话号码"));
        }
      }
    };
    return {
      radio: "2",
      trainTypes: [], //证书类型
      trainValue: [],
      applicantData: {
        area: "",
        certificateType: "",
        degreeCulture: "",
        address: "",
        examItem: "",
        idCard: "",
        img: "",
        industryType: "非高危生产经营单位",
        mobile: "",
        oldAuth: "",
        oldCertType: "",
        oldExamItem: "",
        oldIdCard: "",
        phone: "",
        realName: "",
        receiveType: "直接申领（安全培训合格证）",
        sex: "",
        workUnit: "",
        birthday: "",
        oldAuthTime: "",
        CONSUMER_ID: sessionStorage.getItem("Tokens"),
        SERVICE_CODE: "szsti.AdminEnd.RenewAuditManage.addRenewAudit",
      },
      rules: {
        sex: [
          { required: "true", message: "性别不能为空", trigger: "blur,change" },
        ],
        birthday: [
          {
            required: "true",
            message: "出生日期不能为空",
            trigger: "blur,change",
          },
        ],
        idCard: [
          { required: "true", message: "证件号码不能为空", trigger: "blur" },
        ],
        workUnit: [
          { required: "true", message: "工作单位不能为空", trigger: "blur" },
        ],
        area: [
          { required: "true", message: "所在区域不能为空", trigger: "blur" },
        ],
        address: [
          { required: "true", message: "详细地址不能为空", trigger: "blur" },
        ],
        mobile: [
          { required: "true", message: "手机号码不能为空", trigger: "blur" },
          { validator: isMobileNumber, trigger: "blur" }
        ],
        certificateType: [
          { required: "true", message: "证件类型不能为空", trigger: "blur" },
        ],
        degreeCulture: [
          {
            required: "true",
            message: "最高学历不能为空",
            trigger: "blur,change",
          },
        ],
      },
      uploadImageUrl: null,
      orderId: null,
      whiteFlag:{}
    };
  },
  computed: {
    ...mapState({
      optionData: (state) => state.option_data,
    }),
  },
  methods: {
    getOption() {
      this.$store.dispatch("modifyOptionData", this.url);
    },
    submit() {
      // 表单验证
      const whiteList = ['安全主任','生产经营','生产经营管理者','主要负责人']
      if(whiteList.indexOf(this.whiteFlag.zcxm)>-1||whiteList.indexOf(this.whiteFlag.zylb)>-1){
          if (this.applicantData.idCard.length == 15) {
        this.$message({
          type: "error",
          message: "请输入18位身份证号",
        });
        return;
      }
      if (!this.applicantData.img) {
        this.$message({
          type: "error",
          message: "请上传证件照片",
        });
        return;
      }
      if (this.applicantData.id) {
        this.applicantData.SERVICE_CODE =
          "szsti.AdminEnd.RenewAuditManage.updateRenewAudit";
      }
        
      this.$refs["applicantData"].validate((valid) => {
        if (valid) {
          // 提交表单
          console.log(this.applicantData);
          this.common
            .ajax({
              method: "post",
              headers: {
                "content-type": "multipart/form-data",
              },
              url: this.url,
              data: this.applicantData,
            })
            .then((res) => {
              console.log(res, "换证成功");
              if (res.data.BODY.status === 200) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                setTimeout(() => {
                  this.$router.push({
                    path: "/replacementSystem/appointmentLook",
                  });
                }, 1000);
              } else {
                this.$message({
                  type: "error",
                  message: res.data.BODY.message,
                });
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      }else{
        this.$message.error('该证书类型不可换证')
        return
      }
    
    },
    pre() {
      this.$router.go(-1);
    },

    selectType(item) {
      this.applicantData.oldCertType = item;
      const selectDatas = JSON.parse(sessionStorage.getItem("datas")).find(
        (items,index) => (items.zylb+index) == item
      );
      this.init(selectDatas);
    },

    init(item) {
      const datas = JSON.parse(sessionStorage.getItem("datas"));
      const sortDatas = datas.sort(this.sortUpDate);
   
      const res = new Map();
      const dataUnique = sortDatas.filter(
        (item) => !res.has(item.trainType) && res.set(item.trainType, 1)
      );
      let trainFilter = datas.map((i,index) => {
        return { value: i.zylb+`${index}`, label: i.zylb,cutIndex:index };
      });
      this.trainValue = dataUnique;
      const data = item ? item : dataUnique[0];
      this.whiteFlag = data
      sessionStorage.setItem("cardId", data.cardNo);
      this.trainTypes = trainFilter;
      this.applicantData.oldCertType = data.zylb; //证书类型
      this.applicantData.examItem = data.zcxm;
      //   this.applicantData.img = data.heardImg;
      this.applicantData.oldExamItem = data.zcxm;
      this.applicantData.oldAuth = data.issueUnit;
      this.applicantData.oldAuthTime = data.initialDate;
      this.applicantData.realName = data.name;
      this.applicantData.sex =
        data.sex == "男" || data.sex == "女" ? data.sex : "";
      this.applicantData.birthday = ""; //生日
      this.applicantData.mobile = data.contactPhone;
      this.applicantData.phone = data.contactPhone;
      this.applicantData.idCard = data.cardNo;
      this.applicantData.oldIdCard = data.cardNo;
      this.applicantData.certificateType = data.certificateType;
      this.applicantData.workUnit = data.workPlace;
      this.applicantData.examItem = this.case(data).examItem;
      this.applicantData.receiveType = this.case(data).receiveType;
      if (this.$route.query.information) {
        this.informationInit();
      }
      //   this.applicantData.areaCode = "";
      //   this.applicantData.address = "";
      //   this.applicantData.industryType = ""; //行业类别
      //   this.applicantData.receiveType = ""; //领证方式
      //   this.applicantData.img = this.resourceUrl + data.heardImg;
      if (data.heardImg) {
        this.uploadImageUrl = this.resourceUrl + data.heardImg;
      }
    },
    case(data) {
      console.log(data);
      let oldCertType = data.zylb;
      let oldExamItem = data.zcxm;
      let redata = { examItem: "", receiveType: "" };
      redata.receiveType =
        oldCertType.indexOf("主任") > -1 ? "主任换证" : "培训换证";
      if (oldExamItem) {
        if (oldCertType == "安全主任" && oldExamItem == "初级") {
          redata.examItem = "安全生产管理人员";
        } else if (oldCertType == "生产经营" && oldExamItem == "主要负责人") {
          redata.examItem = "主要负责人";
        } else {
          redata.examItem = "安全生产管理人员";
        }
      } else {
        switch (oldCertType) {
          case "初级安全主任":
            redata.examItem = "安全生产管理人员";
            break;
          case "中级安全主任":
            redata.examItem = "安全生产管理人员";
            break;
          case "高级安全主任":
            redata.examItem = "安全生产管理人员";
            break;
          case "生产经营管理者":
            redata.examItem = "主要负责人";
            break;
        }
      }
      return redata;
    },
    beforeUpload(file) {
      let formData = new FormData();
      formData.append("CONSUMER_ID", sessionStorage.getItem("Tokens"));
      formData.append("type", "换证审核");
      formData.append("file", file);
      this.axios({
        method: "post",
        url: this.imageUrl,
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formData,
      })
        .then((res) => {
          console.log(res, "上传图片");
          if (res.data.success) {
            this.applicantData.img = res.data.data;
            this.uploadImageUrl = this.resourceUrl + this.applicantData.img;
          } else {
            this.$message({
              type: "error",
              message: "上传失败",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
      return false;
    },

    sortUpDate(a, b) {
      return Date.parse(b.periodTo) - Date.parse(a.periodTo);
    },
    informationInit() {
      let info = JSON.parse(this.$route.query.information);
      console.log("info", JSON.parse(this.$route.query.information));
      this.applicantData.sex = info.sex;
      this.applicantData.birthday = info.birthday;
      this.applicantData.mobile = info.mobile;
      this.applicantData.certificateType = info.certificateType || "";
      // this.$set(this.applicantData,'zylb',info.zylb)
      this.applicantData.degreeCulture = info.degreeCulture || "";
      this.applicantData.workUnit = info.workUnit;
      this.applicantData.area = info.area;
      this.applicantData.address = info.address;
      this.applicantData.img = info.img;
      this.applicantData.id = info.id;
      this.applicantData.idCard = info.idCard;
      this.uploadImageUrl = this.resourceUrl + info.img;
    },
  },
  mounted() {
    this.init();
    this.getOption();

    console.log(this.trainTypes,'sssdasd');
  },
};
</script>
<style lang="stylus">
#content {
  .el-input.is-disabled {
    color: #333333FF;
  }

  .el-input__inner {
    background-color: #F9FAFC;
  }

  .submitBon {
    height: 60px;
    overflow: hidden;
    font-size: 18px;

    .el-button--primary {
      width: 100px;
      margin-right: 20px;
      font-size: 18px;
    }
  }

  .sign-up {
    margin-bottom: 20px;
    background-color: #ffffff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);

    .trainingInformation {
      .el-input {
        width: 96%;
      }

      margin-top: 14px;
      padding: 10px;

      .trainingTime {
        .el-form-item__content {
          display: flex;
        }
      }

      .information {
        .el-form-item__label {
          font-family: Microsoft YaHei;
          font-weight: 400;
        }
      }
    }
  }

  .personal {
    .el-row {
      padding: 10px;

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 188px;
        height: 188px;
        line-height: 188px;
        text-align: center;
      }

      .Photo {
        font-size: 14px;
        color: #333333;
        display: inline-block;
        padding-bottom: 10px;
      }
    }

    .el-form-item {
      .el-form-item__label {
        color: #333333;
      }

      .el-form-item__content {
        .el-row: {
          padding: 0;
        }

        .el-date-editor.el-input {
          width: 96%;
        }
      }
    }
  }

  .sign-up-title {
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 5px 0px rgba(218, 225, 229, 0.75);
    border-radius: 4px;
  }
}

.grid-content {
  background-color: #3E97DF;
  font-size: 18px;
  font-weight: bold;
  font-family: Source Han Sans CN;
  color: rgba(66, 71, 75, 1);
  padding: 10px;
  min-height: 26px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .tips_content {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }

  img {
    margin-left: 0;
    height: 45%;
  }

  span {
    margin: 0 10px;
    color: #ffffff;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.grid-content_title {
  padding: 10px 6px;
  text-indent: 2em;
  font-size: 18px;
  word-break: break-all;
  text-align: left;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #878d99;

  span {
    font-size: 14px;
  }

  .tips_title {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #44A8F8;
    line-height: 30px;
  }
}

.el-form-item__content {
  text-align: left;
}

.el-select {
  width: 100%;
}

.warning_remind {
  font-family: Source Han Sans CN;
  word-break: break-all;
  background: #f2f2f2;
  padding: 10px;
}

.el-form-item {
  .el-form-item__content {
    .el-input .el-input__inner, .is-disabled .el-input__inner {
      color: #333333FF;
    }
  }
}

.modify {
  display: flex;

  .el-radio {
    line-height: 40px;
    margin-right: 20px;
  }
}
</style>
