<template>
  <el-container class="nav-page">
    <el-header height="80px">
      <div class="let_header_title">特种作业实操考评员系统</div>
      <div class="right_header_login">
        <el-dropdown trigger="hover">
          <div class="el-dropdown-link userinfo-inner">
            <span style="color: #fff;">{{userName}}</span>
            <img class="userImg" :src="trainPic" @error="defaultImg" />
            <span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="back_home">返回首页</el-dropdown-item>
            <el-dropdown-item divided @click.native="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container>
      <el-aside width="260px">
        <el-menu
          :default-active="defaultIndex"
          class="el-menu-vertical-demo"
          background-color="#3E97DF"
          @select="handRouter"
        >
          <el-menu-item
            :index="String(index)"
            v-for="(item,index) in menuData"
            :key="item.name"
            :class="item.id===defaultIndex?'bacTabs':''"
          >
            <div class="nav_tabs">
              <span>
                <img :src="item.icon" />
                <span style="margin-left:11px">{{item.name}}</span>
              </span>
              <span>
                <i class="el-icon-arrow-right"></i>
              </span>
            </div>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>
<script>
export default {
  data() {
    return {
      userName: null,
      trainPic: "",
      menuData: [
        {
          id: "1",
          name: "考评预约",
          icon: require("../../image/kpyxt_menu1.png"),
          iconChange: require("@/image/kpyxt_menu2.png"),
          path: "/examinerSystem/evaluationAppointment"
        },
        {
          id: "2",
          name: "查询记录",
          icon: require("../../image/kpyxt_menu3.png"),
          iconChange: require("@/image/kpyxt_menu4.png"),
          path: "/examinerSystem/queryRecord"
        },
        {
          id: "3",
          name: "个人信息",
          icon: require("../../image/kpyxt_menu5.png"),
          iconChange: require("@/image/kpyxt_menu6.png"),
          path: "/examinerSystem/personalInfo"
        },
        {
          id: "4",
          name: "费用统计",
          icon: require("../../image/kpyxt_menu5.png"),
          iconChange: require("@/image/kpyxt_menu6.png"),
          path: "/examinerSystem/cost"
        }
      ],
      path: "/",
      defaultIndex: '1'
    };
  },
  methods: {
    handRouter(key, keyPath) {
      this.path = this.menuData[Number(key)].path;
      this.defaultIndex = this.menuData[Number(key)].id;
      let name = this.menuData[Number(key)].name;

      this.$router.push(this.path);
    },
    back_home() {
      this.$router.push("/xxfww/home");
    },
    init() {
      this.userName = sessionStorage.getItem('examiner_userName');
    },
    //退出登录
    logout() {
      sessionStorage.removeItem('examiner_token');

      this.$router.push({ path: '/login', query: {type: 'examinerSystem'}});
    },
    defaultImg() {
      this.trainPic = require("@/image/exit.png");
    }
  },
  // computed: {
  //   defaultIndex: {
  //     get() {
  //       if (this.$route.params.playId) {
  //         return "1";
  //       } else {
  //         return this.num;
  //       }
  //     },
  //     set(val) {
  //       this.num = val;
  //     }
  //   }
  // },
  mounted() {
    this.init();
    this.trainPic = this.tzzyUrl + sessionStorage.getItem('examiner_trainPic');
    // this.trainPic = "http://139.9.209.18:8082/" + sessionStorage.getItem('examiner_trainPic');

    for(let menu of this.menuData){
      if (menu.path == this.$route.path) {
        this.defaultIndex = menu.id;
      }
    }
    
  }
};
</script>
<style lang="stylus" scoped>
// * {
//   font-family: Source Han Sans CN;
// }

.nav-page {
  background-color: #EFF0F4;

  .el-header {
    background: linear-gradient(-50deg, rgba(74, 165, 239, 1), rgba(68, 168, 248, 1));
    color: #333;
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    line-height: 80px;
  }

  .nav_tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
      span {
          font-size: 20px;
          font-family:Source Han Sans CN;
            font-weight:bold;
            line-height: 44px;
      }
  }
};

  .let_header_title {
  color: #fff;
  font-weight: bold;
  font-size: 30px;
  font-family: Source Han Sans CN;
  height: 29px;
  line-height: 80px;
}

.right_header_login {
   display flex
   align-items center
  .el-dropdown {
    height: 80px;
    line-height: 80px;
    display: flex;
  }
  .return-button {
    margin-right: 10px;
    font-size 18px
    color #fff
    cursor pointer
  }

  .return-button:link {
    color: #fff;
  }

  .return-button:active {
    color: #fff;
  }
  .return-button {
    margin-right: 10px;
  }
}
  .userinfo-inner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }

    span {
      margin-right: 10px;
      i {
          color #fff
      }
    }


    .userImg:hover {
        cursor: pointer;
    }
  }

  .el-aside {
    background-color: #3E97DF;
    font-weight: bold;
    text-align: center;

    .el-menu-vertical-demo {
      border-right: 0;
    }
    .el-menu-item:hover {
        background url(../../image/xzds.png) no-repeat left top/100% 100%
        background-color transparent !important
    }
  }

  .el-menu-item {
    color: #A5D7FE;
    opacity: 0.6;

    i {
      color: #fff;
    }
  }

  .bacTabs {
    background url(../../image/xzds.png) no-repeat left top/100% 100%
    background-color transparent !important
    color: #FFF;
    opacity: 1;
  }

  .el-menu-item:hover {
    color: #FFF;
    opacity: 1;
  }

  .el-menu-item:visited {
    background-color: rgba(255, 255, 255, 1);
    color: #FFF;
    opacity: 1;
  }

  .el-main {
    color: #333;
    text-align: center;
    height: 100%;
    overflow-y: auto;
    padding: 20px 40px;
  
  }

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside, .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
}
</style>
