<template>
    <el-row id="login-content">
        <img :src="bac" class="login-image"/>
        <div class="tips" v-if="$route.query.type == 'examinationregistration'">2019年考试已结束，现不在报名时间内。</div>
        <div class="login-box">
            <div class="left-login-box">
                <div class="title">安全生产考试服务系统</div>
            </div>
            <div class="right-login-box">
                
                <div
                    v-show="this.$route.query.type !== 'replacementSystem' && this.$route.query.type !== 'examinerSystem'"
                >
                    <el-row class="title long-nav">
                        <el-col>{{title}}</el-col>
                    </el-row>
                    <el-row class="login-select">
                        <el-col :span="24">
                            <div class="login-select-content">
                <span
                    :class="loginIndex == index? 'loginSpan' : ''"
                    :key="index"
                    @click="loginClick(item,index)"
                    class="left"
                    v-for="(item,index)  in loginMode"
                >{{item.text}}登录</span>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row class="login-input">
                        <el-col :span="24">
                            <el-form :model="loginData" :rules="rules" ref="loginData" v-if="loginIndex === 0">
                                <el-form-item prop="realName">
                                    <el-input clearable placeholder="请输入手机号" :disabled="$route.query.type=='examinationregistration'" v-model="loginData.realName">
                                        <img :src="realnameImage" slot="prefix"/>
                                    </el-input>
                                </el-form-item>
                                <el-form-item class="password" prop="password">
                                    <el-input
                                        class="input-border"
                                        clearable
                                        :disabled="$route.query.type=='examinationregistration'"
                                        placeholder="请输入验证码"
                                        v-model="loginData.password"
                                    >
                                        <img :src="verifierImage" slot="prefix"/>
                                        <el-button @click="sendCode" slot="append" v-show="show" >发送验证码</el-button>
                                        <el-button class="count" slot="append" v-show="!show">{{count}} s</el-button>
                                    </el-input>
                                </el-form-item>
                            </el-form>
                            <el-form :model="loginData" :rules="rules" ref="loginData" v-else>
                                <el-form-item prop="realName">
                                    <el-input clearable placeholder="请输入手机号" :disabled="$route.query.type=='examinationregistration'" v-model="loginData.realName">
                                        <img :src="realnameImage" slot="prefix"/>
                                    </el-input>
                                </el-form-item>
                                <el-form-item prop="password">
                                    <el-input
                                        clearable
                                        :disabled="$route.query.type=='examinationregistration'"
                                        placeholder="请输入密码"
                                        @keyup.enter.native="handleSubmit2"
                                        type="password"
                                        v-model="loginData.password"
                                    >
                                        <img :src="passwordImage" slot="prefix"/>
                                    </el-input>
                                </el-form-item>
                                <el-form-item class="password verification" prop="captcha">
                                    <el-input placeholder="请输入验证码" v-model="loginData.captcha" :disabled="$route.query.type=='examinationregistration'">
                                        <img :src="verifierImage" slot="prefix"/>
                                        <div slot="append">
                                            <img
                                                :src="captcha + '&v=' + version + '&type=' + this.captchaType"
                                                @click="refreshCaptcha"
                                                style="width: 70px;height: 35px;cursor: pointer;padding-top: 4px;"
                                            />
                                        </div>
                                    </el-input>
                                </el-form-item>
                            </el-form>
                            <el-row class="submit-log">
                                <el-col :span="24">
                                    <el-button @click="login" class="login-botton" :disabled="$route.query.type=='examinationregistration'" type="primary">登录</el-button>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <div @click="unifyAuth" class="footer-long">
                        <img alt src="~@/image/gdzwfwdl.png"/>
                        <div>广东政务服务登录</div>
                    </div>
                </div>
                <!-- 考评员系统 -->
                <div style="padding-bottom: 1.5rem" v-show="this.$route.query.type === 'examinerSystem'">
                    <el-row class="title long-nav">
                        <el-col>{{title}}</el-col>
                    </el-row>
                    <el-row class="login-select">
                        <el-col :span="24">
                            <div class="login-select-content">
                <span
                    :class="loginIndex == index? 'loginSpan' : ''"
                    :key="index"
                    @click="loginClick(item,index)"
                    class="left"
                    v-for="(item,index)  in loginMode"
                >{{item.text}}登录</span>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row class="login-input">
                        <el-col :span="24">
                            <el-form :model="loginData" :rules="rules" ref="loginData" v-if="loginIndex === 0">
                                <el-form-item prop="realName">
                                    <el-input clearable placeholder="请输入手机号" v-model="loginData.realName">
                                        <img :src="examinerRealnameImage" slot="prefix"/>
                                    </el-input>
                                </el-form-item>
                                <el-form-item class="password" prop="password">
                                    <el-input clearable placeholder="请输入验证码" v-model="loginData.password">
                                        <img :src="examinerVerifierImage" slot="prefix"/>
                                        <el-button @click="sendCode" slot="append" v-show="show">发送验证码</el-button>
                                        <el-button class="count" slot="append" v-show="!show">{{count}} s</el-button>
                                    </el-input>
                                </el-form-item>
                            </el-form>
                            <el-form :model="loginData" :rules="rules" ref="loginData" v-else>
                                <el-form-item prop="realName">
                                    <el-input clearable placeholder="请输入账号" v-model="loginData.realName">
                                        <img :src="examinerRealnameImage" slot="prefix"/>
                                    </el-input>
                                </el-form-item>
                                <el-form-item prop="password">
                                    <el-input
                                        clearable
                                        placeholder="请输入密码"
                                        type="password"
                                        v-model="loginData.password"
                                        
                                    >
                                        <img :src="examinerPasswordImage" slot="prefix"/>
                                    </el-input>
                                    <div class="login-err" v-if="errMessage">{{errMessage}}</div>
                                </el-form-item>

                               <el-form-item class="password verification" prop="captcha">
                                   <el-input placeholder="请输入验证码" v-model="code" :disabled="$route.query.type=='examinationregistration'">
                                       <img :src="verifierImage" slot="prefix"/>
                                        <div slot="append">

                                        </div>
                                    </el-input>
                                </el-form-item>
                                  <img
                                                :src="`${tzzyUrl}DataBus/superviseEngId/captcha.shtml?CONSUMER_ID=system&type=${validRandom}&v=${version}`"
                                                @click="refreshValid"
                                                style="width:135px;height:55px;cursor: pointer;padding-top: 4px;"
                                            />
                            </el-form>
                            <el-row class="submit-log">
                                <el-col :span="24">
                                    <el-button @click="examinerLogin" class="login-botton" type="primary">登录</el-button>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </div>

                <!-- 换证系统登录 -->
                <div class="system" v-show="this.$route.query.type === 'replacementSystem'">
                    <el-row :gutter="20" class="title long-nav">
                        <el-col>{{title}}</el-col>
                    </el-row>
                    <el-row class="login-input">
                        <el-col :span="24">
                            <el-form :model="loginData" :rules="rules" ref="loginData">
                                <el-form-item prop="realId">
                                    <el-input clearable placeholder="请输入身份证" v-model="loginData.realId">
                                        <img :src="systemIdImage" slot="prefix"/>
                                    </el-input>
                                </el-form-item>
                                <el-form-item prop="realName">
                                    <el-input clearable placeholder="请输入姓名" v-model="loginData.realName">
                                        <img :src="systemNameImage" slot="prefix"/>
                                    </el-input>
                                </el-form-item>
                                <el-form-item prop="realPhone">
                                    <el-input clearable placeholder="请输入手机号" v-model="loginData.realPhone">
                                        <img :src="systemPhoneImage" slot="prefix"/>
                                    </el-input>
                                </el-form-item>
                            </el-form>
                            <el-row class="submit-log">
                                <el-col :span="24">
                                    <el-button @click="loginSystem" class="login-botton" type="primary">登录</el-button>
                                </el-col>
                            </el-row>
                            <div class="statement">
                                <img @click="read" alt src="~@/image/cz1.png" v-show="select"/>
                                <img @click="reads" alt src="~@/image/yd.png" v-show="select== false"/>
                                <span @click="centerDialogVisible = true">已阅读温馨提示</span>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="centerDialogVisible" center class="login_title" title="温馨提示">
            <el-row class="login_content">
                <el-col :span="24" class="log_title">
                    <span class="bg_image">登录说明</span>
                    <img alt src="../../image/wxts_bt.png"/>
                </el-col>
                <el-col :span="24">
                    <p>1.请输入原证书上的“身份证号”和“姓名”登录系统进行换证。登录出现“该身份证号无旧证书”请联系23481891进行处理。</p>
                    <p>2.一名从业人员仅担任一个职务，仅能申请领取一张考核合格证。</p>
                </el-col>
                <el-col :span="24" class="log_title">
                    <span class="bg_image">申请注意事项</span>
                    <img alt src="../../image/wxts_bt.png"/>
                </el-col>
                <el-col :span="24">
                    <p>1.【历史证书】为已持有的原深圳市安全生产监督管理局或人力资源与社会保障局颁发的证书信息，对于持有多张证书的，可以点击【证书类别】选择提供换领的证书名称。</p>
                    <p>2.按照一人只能担任一个岗位的原则，一人仅能选择换领一张对应现任岗位类别的考核合格证。</p>
                    <p>3.【申请信息】为本次提供申请领取考核合格证的个人信息，请如实填写，并上传个人头像照片，个人对所填写的信息和上传的照片真实性负责。</p>
                    <p>4.显示身份证号为15位的，可勾选【身份证号码】后的“修改”，并输入新的身份证号码。</p>
                    <p>5.在“头像”框中上传在照相馆拍摄的近期一寸免冠白底彩色电子证件照片，照片不符合规格要求的，退回申请。</p>
                    <p>6.所有信息一旦“提交”后不可修改，请认真核对后提交。如出现提交不成功，请注意查看提示，并按提示修改后再提交。</p>
                    <p>7.提交成功后，可在【结果查询】栏目查看所提交换证申请事项的办理进度。</p>
                    <p>
                        8.审核通过后，可在深圳市应急管理局官网（
                        <a>http://yjgl.sz.gov.cn/</a>）的“安全生产培训考核发证专题”或深圳应急管理微信公众号的“服务互动—考试服务”栏目，查询电子证书信息，并自行下载打印
                    </p>
                </el-col>
                <el-col :span="24" class="log_title">
                    <span class="bg_image">咨询服务</span>
                    <img alt src="../../image/wxts_bt.png"/>
                </el-col>
                <el-col :span="24">
                    <p>深圳市安全生产考核发证咨询电话：23481891。</p>

                </el-col>
            </el-row>
            <span class="dialog-footer" slot="footer">
        <el-button @click="centerDialogVisible = false" type="primary">知道了</el-button>
      </span>
        </el-dialog>
    </el-row>
</template>

<script>
import sensitiveWord from '@/json/sensitiveWord.json'; //曾飘洲添加密码校验
export default {
        data() {
            return {
                errMessage: '',
                captcha:
                    this.baseUrl + "/superviseEngId/captcha.shtml?CONSUMER_ID=system",
                version: 1,
                select: true,
                centerDialogVisible: false,
                show: true,
                count: "",
                loginMode: [
                    {text: "短信", seleLog: true},
                    {text: "密码", seleLog: false}
                ],
                loginIndex: 0,
                bac: require("@/image/dl_bg.png"),
                loginData: {
                    realName: "",
                    password: "",
                    realPhone: "",
                    realId: "",
                    captcha: null
                },
                realnameImage: require("@/image/dl_icon1.png"),
                passwordImage: require("@/image/dl_icon2.png"),
                systemNameImage: require("@/image/zxhz_icon2.png"),
                systemIdImage: require("@/image/zxhz_icon1.png"),
                systemPhoneImage: require("@/image/zxhz_icon3.png"),
                verifierImage: require("@/image/dl_icon3.png"),


                examinerRealnameImage: require("@/image/dl_icon1.png"),
                examinerVerifierImage: require("@/image/dl_icon3.png"),
                examinerPasswordImage: require("@/image/dl_icon2.png"),
                rules: {},
                code:"",
                validRandom:Math.ceil(Math.random()*10000000000),
                captchaType:Math.ceil(Math.random()*10000000000),
            };
        },
        methods: {
            allSame(value) {  
                let data = value.split('');  let chartCode = []; // 存放ASCII码
                let same = false;  let continuous = 1;  
                for (let i in data) {    
                    chartCode.push(value.charCodeAt(i));  
                }  
                for (let i = 0; i < chartCode.length; i++) {    
                    let oldNum = Number(chartCode[i - 1]) - Number(chartCode[i - 2]); 
                       let newNum = Number(chartCode[i]) - Number(chartCode[i - 1]);    
                    if (newNum != oldNum) {      
                        continuous = 1;    
                    }    
                    if (newNum == 0 || newNum == 1) {   
                        continuous++;    
                    } else {    
                        continuous = 1;    
                    }    
                    if (continuous >= 3) {   
                        same = true;      
                        break;    
                    }  
                }  
                return same; 
            },
            vaildpassWord() {
                debugger
                //曾飘洲添加密码校验
                let phnum = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=_])[a-zA-Z0-9@#$%^&+=_]{8,20}$/;
                let isR = false;
                sensitiveWord.map((i) => {
                if (i === this.loginData.password) {
                    isR = true;
                }
                });
                if (!phnum.exec(this.loginData.password)) {
                this.errMessage = '请输入8-20位密码包含字母、数字和特殊字符!';
                return false;
                } else if (this.allSame(this.loginData.password)) {
                this.errMessage = '请输入非连续字符密码!';
                return false;
                } else if (isR) {
                this.errMessage = '当前密码为弱口令!';
                return false;
                } else {
                this.errMessage = '';
                }
            },
             doRandom(){
                this.validRandom = Math.ceil(Math.random()*10000000000)
                this.version++;
            },
            refreshCaptcha() {
                this.version++;
            },
            read() {
                this.select = false;
            },
            reads() {
                this.select = true;
            },
            unifyAuth() {
                location.href =
                    this.casUrl +
                    "?service=initService&response_type=code&client_id=" +
                    this.clientId +
                    "&scope=all&redirect_uri=" +
                    this.redirectUrl;
            },
            sendCode() {
                try {
                    let mobilePattern = /^1[3-9]\d{9}$/;
                    if (
                        !this.loginData.realName ||
                        !mobilePattern.test(this.loginData.realName)
                    ) {
                        throw Error("请输入正确手机号码");
                    }
                    this.common
                        .ajax({
                            method: "post",
                              url: this.tzzyUrl + "DataBus/exchange",
                            headers: {
                                "content-type": "multipart/form-data"
                            },
                            data: {
                                CONSUMER_ID: "system-654ec1b21c39446f9cc64663f071c2c8",
                                SERVICE_CODE: "szsti.system.User.sendCode",
                                type: "2",
                                phone: this.loginData.realName
                            }
                        })
                        .then(res => {
                        console.log(res);
                            if (
                                res.data.BODY.status ==200
                            ) {
                                this.$message({
                                    type: "success",
                                    message: "验证码已发送"
                                });
                            } else {
                                this.$message({
                                    type: "error",
                                    message: "验证码发送失败"
                                });
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        });
                    const TIME_COUNT = 60;
                    if (!this.timer) {
                        this.count = TIME_COUNT;
                        this.show = false;
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                            } else {
                                this.show = true;
                                clearInterval(this.timer);
                                this.timer = null;
                            }
                        }, 1000);
                    }
                } catch (e) {
                    this.$message({
                        type: "warning",
                        message: e.message
                    });
                }
            },
            loginClick(item, index) {
                this.loginMode[index].seleLog = !this.loginMode[index].seleLog;
                if (this.loginIndex == index) {
                    if (!this.loginMode[index].seleLog) {
                        this.loginIndex = -1;
                    } else {
                        this.loginIndex = index;
                    }
                } else {
                    this.loginData.realName = null;
                    this.loginData.password = null;
                    this.loginIndex = index;
                }
            },
            examinerLogin() {
                this.vaildpassWord()
                //考评员登录
                try {
                    let formData = null;
                    let mobilePattern = /^1[3-9]\d{9}$/;

                    if (this.loginIndex == 0) {
                        let form  = {
                             CONSUMER_ID: "system",
                            SERVICE_CODE: "szsti.proxy.user.EvaluatorSmsLogin",
                            userId :this.loginData.realName,
                            password:this.loginData.password
                        }
                          this.common
                               .ajax({
                                    method: "post",
                                    url: this.tzzyUrl + "DataBus/exchange",
                                    headers: {
                                        "content-type": "multipart/form-data"
                                    },
                                    data: form
                                })
                                .then(res => {
                                        if (res.data.SYS_HEAD.RET_STATUS === "S") {
                                        let examiner_token = res.data.BODY.login_Proxy_Token;
                                        let examiner_userId = res.data.BODY.userId;
                                        let examiner_userName = res.data.BODY.userName;

                                        sessionStorage.setItem("examiner_token", examiner_token);
                                        sessionStorage.setItem("examiner_userId", examiner_userId);
                                        sessionStorage.setItem("examiner_userName", examiner_userName);

                                        this.common
                                            .examinerAjax({
                                                method: "post",
                                                url: this.tzzyUrl + "DataBus/exchange",
                                                headers: {
                                                    "content-type": "multipart/form-data"
                                                },
                                                data: {
                                                    SERVICE_CODE:
                                                        "szsti.informationManagement.SysEvaluatorProc.findEvaluator",
                                                    CONSUMER_ID: examiner_token,
                                                    evaluator: {
                                                        userId: examiner_userId
                                                    }
                                                }
                                            })
                                            .then(res => {
                                                if (res.data.BODY.status === 200) {
                                                    let data = res.data.BODY.data.data[0];
                                                    sessionStorage.setItem("examiner_trainPic", data.trainPic);
                                                }
                                                this.$message({
                                                    type: "success",
                                                    message: "登录成功"
                                                });
                                                let toRoute = localStorage.getItem("toRoute");
                                                this.$router.push({path:"/examinerSystem"})
                                                // if (!toRoute) {
                                                //     toRoute = "/examinerSystem";
                                                // }
                                                // setTimeout(() => {
                                                //     this.$router.push({path: toRoute});
                                                // }, 1000);
                                            })
                                            .catch(error => {
                                                console.error(error);
                                            });
                                    } else {
                                        this.$message({
                                            type: "error",
                                            message: res.data.SYS_HEAD.RET[0].RET_MSG
                                        });
                                    }
                                })
                        return;
                    } else {
                        if (!this.loginData.realName) {
                            throw Error("请输入账号");
                        } else if (!this.loginData.password) {
                            throw Error("请输入密码");
                        }

                        // 密码加密
                        formData = {
                            CONSUMER_ID: "system",
                            SERVICE_CODE: "szsti.proxy.user.LoginAdmin",
                            userId: this.loginData.realName,
                            username: this.loginData.realName,
                              captchaType:this.validRandom,
                                        "code": this.code,
                            password: this.aes
                                .encrypt(this.loginData.password, "SZ[SAFE]dqaq.123")
                                .replace(/%/g, "%25")
                                .replace(/[+]/g, "%2B")
                        };
                    }

                           this.common
                               .ajax({
                                    method: "post",
                                    url: this.tzzyUrl + "DataBus/exchange",
                                    headers: {
                                        "content-type": "multipart/form-data"
                                    },
                                    data: formData
                                })
                                .then(res => {
                                    this.doRandom()
                                    if (res.data.SYS_HEAD.RET_STATUS === "S") {
                                        let examiner_token = res.data.BODY.login_Proxy_Token;
                                        let examiner_userId = res.data.BODY.userId;
                                        let examiner_userName = res.data.BODY.userName;

                                        sessionStorage.setItem("examiner_token", examiner_token);
                                        sessionStorage.setItem("examiner_userId", examiner_userId);
                                        sessionStorage.setItem("examiner_userName", examiner_userName);

                                        this.common
                                            .examinerAjax({
                                                method: "post",
                                                url: this.tzzyUrl + "DataBus/exchange",
                                                headers: {
                                                    "content-type": "multipart/form-data"
                                                },
                                                data: {
                                                    SERVICE_CODE:
                                                        "szsti.informationManagement.SysEvaluatorProc.findEvaluator",
                                                    CONSUMER_ID: examiner_token,
                                                    evaluator: {
                                                        userId: examiner_userId
                                                    }
                                                }
                                            })
                                            .then(res => {
                                                if (res.data.BODY.status === 200) {
                                                    let data = res.data.BODY.data.data[0];
                                                    sessionStorage.setItem("examiner_trainPic", data.trainPic);
                                                }
                                                this.$message({
                                                    type: "success",
                                                    message: "登录成功"
                                                });
                                                let toRoute = localStorage.getItem("toRoute");
                                                this.$router.push({path:"/examinerSystem"})
                                                // if (!toRoute) {
                                                //     toRoute = "/examinerSystem";
                                                // }
                                                // setTimeout(() => {
                                                //     this.$router.push({path: toRoute});
                                                // }, 1000);
                                            })
                                            .catch(error => {
                                                console.error(error);
                                            });
                                    } else {
                                        this.$message({
                                            type: "error",
                                            message: res.data.SYS_HEAD.RET[0].RET_MSG
                                        });
                                    }
                                })
                                .catch(error => {
                                    console.error(error);
                                });

                    // this.axios({
                    //     url:this.tzzyUrl+'DataBus/exchange',
                    //     method:"post",
                    //     headers: {
                    //         "content-type": "multipart/form-data"
                    //     },
                    //     data:{
                    //         "CONSUMER_ID": "system-36044db8839d4331921b56f1bb0a4eb9",
                    //         "SERVICE_CODE": "szsti.system.User.validateCaptcha",
                    //         "code": this.code,
                    //         "type": this.validRandom
                    //     }
                    // }).then(res=>{
                    //     console.log(res)
                    //     this.version++
                    //     if(res.data.BODY.code==200){
                    //         // 这里填写登陆逻辑

                    //     }else{
                    //         this.$message({
                    //             type:"error",
                    //             message:res.data.BODY.message
                    //         })
                    //     }
                    // })
                    // 发送请求
                } catch (e) {
                    this.$message({
                        type: "warning",
                        message: e.message
                    });
                }
            },
            login() {


                try {
                    let formData = null;
                    let mobilePattern = /^1[3-9]\d{9}$/;
                    switch (this.loginIndex) {
                        case 0:
                            // 验证码登录

                            // 表单验证
                            if (
                                !this.loginData.realName ||
                                !mobilePattern.test(this.loginData.realName)
                            ) {
                                throw Error("请输入正确手机号码");
                            } else if (!this.loginData.password) {
                                throw Error("请输入验证码");
                            }
                            formData = {
                                CONSUMER_ID: "info",
                                SERVICE_CODE: "szsti.proxy.user.InfoNetworkCodeLogin",
                                userId: this.loginData.realName,
                                password: this.loginData.password
                            };
                            break;
                        case 1:
                            // 账号密码登录

                            // 表单验证
                            if (
                                !this.loginData.realName ||
                                !mobilePattern.test(this.loginData.realName)
                            ) {
                                throw Error("请输入正确手机号码");
                            } else if (!this.loginData.password) {
                                throw Error("请输入密码");
                            } else if (!this.loginData.captcha) {
                                throw Error("请输入验证码");
                            }

                            // 密码加密
                            formData = {
                                CONSUMER_ID: "info",
                                SERVICE_CODE: "szsti.proxy.user.InfoNetworkRegularLogin",
                                userId: this.loginData.realName,
                                password: this.aes
                                    .encrypt(this.loginData.password, "SZ[SAFE]dqaq.123")
                                    .replace(/%/g, "%25")
                                    .replace(/[+]/g, "%2B"),
                                captcha: this.loginData.captcha,
                                type: this.captchaType
                            };
                            break;
                        default:
                            throw Error("发生异常");
                    }
                    // 发送请求
                    this.common
                        .ajax({
                            method: "post",
                            url: this.url,
                            headers: {
                                "content-type": "multipart/form-data"
                            },
                            data: formData
                        })
                        .then(res => {
                            if (res.data.SYS_HEAD.RET_STATUS === "S") {
                                sessionStorage.setItem("Token", res.data.BODY.login_Proxy_Token);
                                sessionStorage.setItem("examineeId", res.data.BODY.user_id);
                                sessionStorage.setItem("isComplete", res.data.BODY.isComplete);
                                sessionStorage.setItem(
                                    "userInfo",
                                    JSON.stringify(res.data.BODY.userInfo)
                                );
                                this.$message({
                                    type: "success",
                                    message: "登录成功"
                                });
                                let toRoute = localStorage.getItem("toRoute");
                                if (!toRoute) {
                                    toRoute = "/xxfww/home";
                                }
                                setTimeout(() => {
                                    this.$router.push({path: toRoute});
                                }, 1000);
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.BODY.errormsg
                                });
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        });
                } catch (e) {
                    this.$message({
                        type: "warning",
                        message: e.message
                    });
                }
            },
            // 换证系统登录
            refreshValid(){
                this.version++
            },
            loginSystem() {
                try {
                    let mobilePattern = /^1[3-9]\d{9}$/;
                    // let cardIdTest = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                    let url = "szsti.publicInfo.CommonQuestion.getList";
                    let nameTest = /^((?![\u3000-\u303F])[\u2E80-\uFE4F]|\·)*(?![\u3000-\u303F])[\u2E80-\uFE4F](\·)*$/;
                    if (!this.loginData.realId ) {
                        throw Error("请输入正确的身份证号");
                    } else if (
                        !this.loginData.realName ||
                        !nameTest.test(this.loginData.realName)
                    ) {
                        throw Error("请输入正确的姓名");
                    } else if (
                        !this.loginData.realPhone ||
                        !mobilePattern.test(this.loginData.realPhone)
                    ) {
                        throw Error("请输入正确手机号码");
                    } else if (!(this.select == false)) {
                        throw Error("请您阅读温馨提示");
                    }

                    let param = {
                        CONSUMER_ID: "system",
                        SERVICE_CODE: "szsti.proxy.user.RenewAuditLogin",
                        phone: this.aes
                            .encrypt(this.loginData.realPhone, "SZ[SAFE]dqaq.123")
                            .replace(/%/g, "%25")
                            .replace(/[+]/g, "%2B"),
                        idCard: this.aes
                            .encrypt(this.loginData.realId, "SZ[SAFE]dqaq.123")
                            .replace(/%/g, "%25")
                            .replace(/[+]/g, "%2B"),
                        name: this.aes
                            .encrypt(this.loginData.realName, "SZ[SAFE]dqaq.123")
                            .replace(/%/g, "%25")
                            .replace(/[+]/g, "%2B")
                    };
                    //    发送请求
                    this.common
                        .ajax({
                            method: "post",
                            url: this.url,
                            headers: {
                                "content-type": "multipart/form-data"
                            },
                            data: param
                        })
                        .then(res => {
                            console.log(res)
                            if (res.status == 200 && res.data.BODY.data.msg == "验证通过！") {
                                const datas = res.data.BODY.data;
                                sessionStorage.setItem("code", datas.statusCode);
                                sessionStorage.setItem("datas", JSON.stringify(datas.data));
                                sessionStorage.setItem("Tokens", res.data.BODY.login_Proxy_Token);
                                sessionStorage.setItem("examineeId", res.data.BODY.USER_ID);
                                this.$message({
                                    type: "success",
                                    message: "登录成功"
                                });
                                let toRoute = localStorage.getItem("toRoute");
                                if (!toRoute) {
                                    toRoute = "/xxfww/home";
                                }
                                setTimeout(() => {
                                    this.$router.push({path: toRoute});
                                }, 1000);

                            }else{
                                this.$message({
                                    type:"error",
                                    message:res.data.BODY.data.msg
                                })
                            }
                        })
                        .catch(y => {
                            this.$message.error(y);
                        });
                } catch (error) {
                    this.$message({
                        type: "warning",
                        message: error.message
                    });
                }
            }
        },
        computed: {
            title() {
                let title = "";
                switch (this.$route.query.type) {
                    case "examinationregistration":
                        title = "考试报名登录";
                        break;
                    case "preRegisteredExamination":
                        title = "考试预约登录";
                        break;
                    case "replacementSystem":
                        title = "在线换证登录";
                        break;
                    case "examinerSystem":
                        title = "特种作业考评员登录";
                        break;
                }
                return title;
            }
        },
        mounted() {
            let whiteList = ["examinationregistration",
                            "preRegisteredExamination",
                            "replacementSystem",
                            "examinerSystem"]
            const type = this.$route.query.type
              if(!whiteList.includes(type)){
                    this.$router.push({path:"/"})
                    return
                }
            this.captchaType = Math.ceil(Math.random()*10000000000);
        }
    };
</script>
<style  scoped>
    .tips{
        color: red;
        position: absolute;
        top:10%;
        left:50%;
        font-size:40px;
        font-weight: 500;
        transform: translatex(-50%);
    }
    .login-err{
        position: absolute;
            top: -30px;
            height: 32px;
            line-height: 32px;
            width:calc(100% - 20px) ;
            background-color: rgba(246,89,46,0.2);
            color: #FFB4B4;
            border: 1px solid rgb(246,89,57);
            padding-left: 40px;
        }
        .imgs {
            width: 24px;
            height: 24px;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
        }
</style>
<style lang="stylus">
    #login-content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .login-image {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .login-box {
            z-index: 1;
            width: 64%;
            height: 70%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .left-login-box {
            margin-top: 61px;
            flex: 1;
            height: 100%;
            position: relative;

            .title {
                position: relative;
                margin: 0 auto;
                font-size: 0.41rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: rgba(255, 255, 255, 1);
            }
        }

        .footer-long {
            padding: 0.38rem 0 0rem;
            height: 0.8rem;
            font-size: 14px;
            cursor: pointer;

            img {
                width: 0.59rem;
                height: 0.58rem;
            }

            div {
                font-size: 0.2rem;
                color: #3E97DF;
                text-decoration: underline;
                padding-top: 0.2rem;
            }
        }
    }

    .right-login-box {
        flex: 1;
        display: flex;
        align-itens: center;
        justify-content: center;
        height: 108%;

        div {
            width: 4rem;

            .login-select {
                padding: 20px 0 0;

                .el-col-24 {
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }

                .login-select-content {
                    background-color: #fff;
                    display: flex;
                    height: 0.56rem;
                    box-shadow: -3px 0px 10px 0px rgba(0, 0, 0, 0.2);
                    border-radius: 0.28rem;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    width: 3rem;

                    span {
                        width: 50%;
                        display: inline-block;
                        font-size: 0.2rem;
                        text-align: center;
                        height: 0.56rem;
                        line-height: 0.56rem;
                        color: #3E97DF;
                        cursor: pointer;
                    }

                    .loginSpan {
                        color: #fff;
                        background-color: #3E97DF;
                        border-radius: 28px;
                    }
                }
            }

            .title {
                font-size: 0.4rem;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #3E97DF;
                margin-top: 0.62rem;
            }

            .login-input {
                margin-top: 0.4rem;

                .el-form-item {
                    margin-bottom: 0.22rem;
                }

                .el-input--prefix {
                    .el-input__inner {
                        padding-left: 40px;
                        padding-right: 0;
                        height: 0.56rem;
                        height: 0.56rem;
                    }
                }

                .password {
                    .el-input__inner {
                    }

                    .input-border {
                        display: flex;
                    }

                    .el-input-group {
                        display: flex;
                    }

                    .el-input-group__append {
                        display: flex;
                        border: 0;
                        padding: 0;
                        right: 80px;
                        background-color: #ffffff;
                        width: 0;

                        // span {
                        //     font-size: 0.18rem;
                        // }
                    }

                    .el-button {
                        color: #3E97DF;
                        margin: 0;
                        padding: 0;
                        margin-right: 40px;
                    }
                }

                .verification {
                    .el-form-item__content {
                    }

                    .el-input {
                        display: flex;
                    }
                }
            }

            .el-input__prefix {
                // display: inline-block;

                display: flex;
                align-items: center;

                // img {
                //     margin-top: 8px;
                // }
            }

            .login-botton {
                width: 100%;
            }

            .forget-password {
                text-align: left;
                margin-top: 10px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: rgba(102, 102, 102, 1);
            }

            .submit-log {
                .el-button {
                    display: flex;
                    justify-content: center;
                    font-size: 20px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    background-color: #3E97DF;
                    border-color: #3E97DF;
                    height: 0.56rem;
                }
            }

            .statement {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #3E97DF FF;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 40px;
                padding-bottom: 100px;

                span {
                    height: 30px;
                    line-height: 30px;
                    text-decoration: underline;
                    display: inline-block;
                    cursor: pointer;
                }

                img {
                    margin-right: 10px;
                }
            }
        }
    }

    .login_title {
        .el-dialog__body {
            padding: 20px 25px 10px 10px;

            .login_content {
                .log_title {
                    position: relative;
                    height: 40px;

                    span {
                        position: absolute;
                        left: 25px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FFFFFFFF;
                    }
                }

                p {
                    margin: 0;
                    padding: 0 40px;
                }

                .bg_image {
                    // background-image: url('../../image/wxts_bt.png');
                    background-size: 100% 100%;
                    width: 124px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .el-dialog {
            background-image: url('../../image/wxts_bg.png');
            background-size: 100% 100%;
            width: 11rem;

            .el-dialog__header {
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                .el-dialog__title {
                    font-size: 0.28rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #fff;
                    line-height: 0.9rem;
                }

                .el-dialog__headerbtn {
                    display: none;
                }
            }
        }
    }
</style>
