<template>
  <div id="app">
    <!-- <div id="nav">
          <router-link to="/xxfww"></router-link>
        </div> -->
    <router-view />
  </div>
</template>
<script>
import "@/scss/global.scss";

export default {
  data() {
    return {};
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  mounted() {
    if (this._isMobile() && this.$route.path == '/xxfww/home') {
         window.location = 'https://ksfw.yjgl.sz.gov.cn/infoMobile/#/'
        //window.location = 'https://szksfw.yjgl.sz.gov.cn:8081/infoMobile/#/'
    }
  },
};

// 缩放禁用
window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};
</script>

<style lang="stylus">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // font-size 18px
}

html, body, #app, section {
  margin: 0;
  height: 100%;
  overflow: auto;
}

.animated {
  animation-duration: 0.5s;
}
</style>
