<template>
  <div class="examinationregistration-question">
    <el-row class="sign-up">
      <el-col :span="24">
        <div
          class="grid_content"
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <div>
            <img src="../../../image/jbxx.png" style="vertical-align: middle" />
            <span class="grid-span">基本信息</span>
          </div>
          <div class="edit">
            <el-button size="small" @click="edit">编辑</el-button>
          </div>
        </div>
        <div class="personalInfo">
          <el-form
            :model="personalInfo"
            :rules="rules"
            ref="personalInfo"
            label-width="160px"
          >
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="姓名：" prop="realName">
                  <el-input
                    :disabled="!isEdit"
                    clearable
                    v-model="personalInfo.realName"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="性别：" prop="sex">
                  <el-select
                    autocomplete="off"
                    clearable
                    placeholder="请选择性别"
                    v-model="personalInfo.sex"
                    style="width: 100%"
                    :disabled="!isEdit"
                  >
                    <el-option
                      v-for="(item, index) in optionData.sex"
                      :key="index"
                      :label="item.dataValue"
                      :value="item.dataCode"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="身份证号：" prop="idCard">
                  <el-input
                    :disabled="!isEdit"
                    v-model="personalInfo.idCard"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="学历：" prop="qualificationsCondemned">
                  <el-select
                    :disabled="!isEdit"
                    autocomplete="off"
                    clearable
                    v-model="personalInfo.qualificationsCondemned"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) in optionData.education"
                      :key="index"
                      :label="item.dataValue"
                      :value="item.dataCode"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="现有技术等级或职称："
                  prop="technicalTitleLevel"
                >
                  <el-input
                    :disabled="!isEdit"
                    v-model="personalInfo.technicalTitleLevel"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="现工作单位："
                  prop="technicalTitleLicenceOrganiz"
                >
                  <el-input
                    :disabled="!isEdit"
                    v-model="personalInfo.technicalTitleLicenceOrganiz"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="100">
              <el-col :span="12">
                <el-form-item label="考评工种：" prop="examItem">
                  <el-select
                    :disabled="!isEdit"
                    clearable
                    multiple
                    v-model="personalInfo.examItem"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) in optionData.examItems"
                      :key="index"
                      :label="item.dataValue"
                      :value="item.dataCode"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="从业培训：" prop="isFlag">
                  <el-select
                    :disabled="!isEdit"
                    autocomplete="off"
                    clearable
                    v-model="personalInfo.isFlag"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) in optionData.employmentTraining"
                      :key="index"
                      :label="item.dataValue"
                      :value="item.dataCode"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="100">
              <el-col :span="12">
                <el-form-item label="培训单位：" prop="trainingId">
                  <el-select
                    :disabled="!isEdit"
                    autocomplete="off"
                    clearable
                    v-model="personalInfo.trainingId"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) in optionData.trainingData"
                      :key="index"
                      :label="item.trainName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所在辖区：" prop="jurisdiction">
                  <el-select
                    :disabled="!isEdit"
                    autocomplete="off"
                    clearable
                    v-model="personalInfo.jurisdiction"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) in optionData.areas"
                      :key="index"
                      :label="item.dataValue"
                      :value="item.dataCode"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="100">
              <el-col :span="12">
                <el-form-item label="住址：" prop="contactAddress">
                  <el-input
                    :disabled="!isEdit"
                    v-model="personalInfo.contactAddress"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机号码：" prop="mobile">
                  <el-input
                    :disabled="!isEdit"
                    v-model="personalInfo.mobile"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="100">
              <el-col :span="12">
                <el-form-item label="电子邮箱：" prop="mailBox">
                  <el-input
                    :disabled="!isEdit"
                    v-model="personalInfo.mailBox"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <div class="btn">
                  <el-button
                    size="small"
                    :disabled="!isEdit"
                    :type="isEdit ? 'primary' : 'info'"
                    @click="save"
                    style="width: 88px; height: 34px; font-size: 14px"
                    >保存</el-button
                  >
                  <el-button
                    size="small"
                    :disabled="!isEdit"
                    :type="isEdit ? 'primary' : 'info'"
                    @click="cancel"
                    style="
                      width: 88px;
                      height: 34px;
                      font-size: 14px;
                      margin-left: 20px;
                    "
                    >取消</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-col>
    </el-row>

    <el-row class="sign-up">
      <el-col :span="24">
        <div class="grid_content">
          <img src="../../../image/zhxx.png" style="width: 25px" />
          <span class="grid-span">账号信息</span>
        </div>
        <div class="accountInfo">
          <el-collapse @change="handleChange">
            <el-collapse-item class="head" name="0">
              <template slot="title">
                <span class="label">头像：</span>
                <img
                  class="userImg"
                  :src="trainPic"
                  @error="defaultImg"
                  style="
                    width: 68px;
                    height: 68px;
                    border-radius: 90px;
                    margin-left: -18px;
                  "
                />
                <span style="margin: 0 8px 0 auto">编辑</span>
                <img src="../../../image/bjxl1.png" style="width: 15px" />
              </template>
              <div class="collapseContent" style="align-items: flex-end">
                <div>
                  <el-upload
                    ref="upload"
                    class="avatar-uploader"
                    action="/examiner/DataBus/upload/uploadEvaluatorPic.shtml"
                    :http-request="uploadImage"
                    :show-file-list="false"
                    :on-change="onUploadChange"
                    :auto-upload="false"
                  >
                    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
                <el-button
                  size="small"
                  type="primary"
                  style="margin-left: 50px"
                  @click="uploadPic"
                  >保存</el-button
                >
              </div>
            </el-collapse-item>
            <el-collapse-item name="1">
              <template slot="title">
                <span class="label">用户名：</span>
                <span>{{ personalInfo.username }}</span>
                <span style="margin: 0 8px 0 auto">编辑</span>
                <img src="../../../image/bjxl1.png" style="width: 15px" />
              </template>
              <div class="collapseContent">
                <div>
                  <span>用户名：</span>
                  <el-input
                    placeholder="请输入用户名"
                    readonly
                    v-model="username"
                    style="width: 200px"
                  ></el-input>
                </div>
                <!-- <el-button size="small" type="primary" style="margin-left:50px;" @click="updateUserName">保存</el-button> -->
              </div>
            </el-collapse-item>
            <el-collapse-item name="2">
              <template slot="title">
                <span class="label">修改密码：</span>
                <span>******</span>
                <span style="margin: 0 8px 0 auto">编辑</span>
                <img src="../../../image/bjxl1.png" style="width: 15px" />
              </template>
              <div class="collapseContent">
                <div>
                  <span>当前密码：</span>
                  <el-input
                    placeholder="请输入当前密码"
                    v-model="currPassword"
                    style="width: 200px"
                    show-password
                  ></el-input>
                </div>
                <div style="margin-left: 20px">
                  <span>新密码：</span>
                  <el-input
                    placeholder="请输入新密码"
                    v-model="newPassword"
                    style="width: 200px"
                    show-password
                  ></el-input>
                </div>
                <div style="margin-left: 20px">
                  <span>确认新密码：</span>
                  <el-input
                    placeholder="确认新密码"
                    v-model="rePassword"
                    style="width: 200px"
                    show-password
                  ></el-input>
                </div>
                <el-button
                  size="small"
                  type="primary"
                  style="margin-left: 50px"
                  @click="updatePassword"
                  >保存</el-button
                >
              </div>
            </el-collapse-item>
            <el-collapse-item name="3">
              <template slot="title">
                <span class="label">绑定手机：</span>
                <span>{{ personalInfo.mobile }}</span>
                <span style="margin: 0 8px 0 auto">编辑</span>
                <img src="../../../image/bjxl1.png" style="width: 15px" />
              </template>
              <div class="collapseContent">
                <div>
                  <span>手机号：</span>
                  <el-input
                    placeholder="请输入手机号"
                    v-model="mobile"
                    style="width: 200px"
                  ></el-input>
                </div>
                <el-button
                  size="small"
                  type="primary"
                  style="margin-left: 50px"
                  @click="updateMobile"
                  >保存</el-button
                >
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: "question",
  data() {
    return {
      optionData: {
        sex: [
          {
            dataCode: 1,
            dataValue: "男",
          },
          {
            dataCode: 0,
            dataValue: "女",
          },
        ],
        education: [
          {
            dataCode: "小学",
            dataValue: "小学",
          },
          {
            dataCode: "初中",
            dataValue: "初中",
          },
          {
            dataCode: "中专/高中",
            dataValue: "中专/高中",
          },
          {
            dataCode: "专科",
            dataValue: "专科",
          },
          {
            dataCode: "本科",
            dataValue: "本科",
          },
          {
            dataCode: "硕士研究生",
            dataValue: "硕士研究生",
          },
          {
            dataCode: "博士研究生",
            dataValue: "博士研究生",
          },
        ],
        technicalTitleLevel: [
          {
            dataCode: "初级工程师",
            dataValue: "初级工程师",
          },
          {
            dataCode: "中级工程师",
            dataValue: "中级工程师",
          },
          {
            dataCode: "高级工程师",
            dataValue: "高级工程师",
          },
        ],
        employmentTraining: [
          {
            dataCode: 0,
            dataValue: "是",
          },
          {
            dataCode: 1,
            dataValue: "否",
          },
        ],
        areas: [
          {
            dataCode: "福田区",
            dataValue: "福田区",
          },
          {
            dataCode: "南山区",
            dataValue: "南山区",
          },
          {
            dataCode: "龙华区",
            dataValue: "龙华区",
          },
          {
            dataCode: "龙岗区",
            dataValue: "龙岗区",
          },
          {
            dataCode: "罗湖区",
            dataValue: "罗湖区",
          },
          {
            dataCode: "宝安区",
            dataValue: "宝安区",
          },
          {
            dataCode: "盐田区",
            dataValue: "盐田区",
          },
          {
            dataCode: "大鹏区",
            dataValue: "大鹏区",
          },
          {
            dataCode: "光明区",
            dataValue: "光明区",
          },
        ],
        examItems: [],
        trainingData: [],
      },
      personalInfo: {},
      personalInfoBack: {},
      trainPic: "",
      username: "",
      mobile: "",
      isEdit: false,
      rules: {},
      lastCollapse: [],
      imageUrl: "",
      currPassword: "",
      newPassword: "",
      rePassword: "",
    };
  },
  created() {
    const token = sessionStorage.getItem("examiner_token");
    if (token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      console.log(token);
    } else {
      this.$message.error("登录信息已失效,请重新登录!");
      sessionStorage.removeItem("examiner_token");
      setTimeout(function () {
        window.location.href =
          "https://ksfw.yjgl.sz.gov.cn/login?type=examinerSystem";
      }, 100);
    }
  },
  methods: {
    edit() {
      this.isEdit = true;
    },
    save() {
      let formData = new FormData();

      formData.append("CONSUMER_ID", sessionStorage.getItem("examiner_token"));
      formData.append(
        "SERVICE_CODE",
        "szsti.informationManagement.SysEvaluatorProc.updataEvaluator"
      );
      formData.append("type", "考评员信息");

      var bean = {
        evaluatorId: this.personalInfo.id, //考评员ID
        userId: this.personalInfo.userId, //用户ID
        realName: this.personalInfo.realName, //名称
        sex: this.personalInfo.sex, //性别
        idCard: this.personalInfo.idCard, //证件号
        qualificationsCondemned: this.personalInfo.qualificationsCondemned, //学历
        technicalTitleLevel: this.personalInfo.technicalTitleLevel, //技术职称等级
        technicalTitleLicenceOrganiz:
          this.personalInfo.technicalTitleLicenceOrganiz == null
            ? ""
            : this.personalInfo.technicalTitleLicenceOrganiz, //现工作单位
        examItem: this.personalInfo.examItem.toString(), //考评工种
        isFlag:
          this.personalInfo.isFlag == null ? "" : this.personalInfo.isFlag, //是否从业培训
        trainingId: this.personalInfo.trainingId, //培训单位
        jurisdiction:
          this.personalInfo.jurisdiction == null
            ? ""
            : this.personalInfo.jurisdiction, //所在辖区
        contactAddress:
          this.personalInfo.contactAddress == null
            ? ""
            : this.personalInfo.contactAddress, //住址
        telphoneNum: this.personalInfo.mobile, //手机号码
        mailBox:
          this.personalInfo.mailBox == null ? "" : this.personalInfo.mailBox, //电子邮箱
      };
      formData.append("bean", JSON.stringify(bean));

      this.axios({
        method: "post",
        url: "/examiner/DataBus/upload/uploadEvaluatorPic.shtml",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formData,
      })
        .then((res) => {
          if (res.data.status == "200") {
            this.$message({
              type: "success",
              message: "修改" + this.personalInfo.username + "考评员信息成功!",
            });
            this.personalInfoBack = Object.assign({}, this.personalInfo);
            this.isEdit = false;
          } else {
            this.$message({
              type: "error",
              message: "修改失败!",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateUserName() {
      let formData = new FormData();

      formData.append("CONSUMER_ID", sessionStorage.getItem("examiner_token"));
      formData.append(
        "SERVICE_CODE",
        "szsti.informationManagement.SysEvaluatorProc.updataEvaluator"
      );
      formData.append("type", "考评员信息");

      var bean = {
        userId: this.personalInfo.userId,
        evaluatorId: this.personalInfo.id,
        userName: this.username,
      };
      formData.append("bean", JSON.stringify(bean));

      this.axios({
        method: "post",
        url: "/examiner/DataBus/upload/uploadEvaluatorPic.shtml",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formData,
      })
        .then((res) => {
          if (res.data.status == "200") {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.personalInfo.username = this.username;
            this.personalInfoBack = Object.assign({}, this.personalInfo);
          } else {
            this.$message({
              type: "error",
              message: "修改失败!",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateMobile() {
      let formData = new FormData();

      formData.append("CONSUMER_ID", sessionStorage.getItem("examiner_token"));
      formData.append(
        "SERVICE_CODE",
        "szsti.informationManagement.SysEvaluatorProc.updataEvaluator"
      );
      formData.append("type", "考评员信息");

      var bean = {
        userId: this.personalInfo.userId,
        evaluatorId: this.personalInfo.id,
        telphoneNum: this.mobile,
      };
      formData.append("bean", JSON.stringify(bean));

      this.axios({
        method: "post",
        url: "/examiner/DataBus/upload/uploadEvaluatorPic.shtml",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formData,
      })
        .then((res) => {
          if (res.data.status == "200") {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.personalInfo.mobile = this.mobile;
            this.personalInfoBack = Object.assign({}, this.personalInfo);
          } else {
            this.$message({
              type: "error",
              message: "修改失败!",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updatePassword() {
      if (!this.currPassword) {
        this.$message("请输入当前密码");
        return;
      }
      if (!this.newPassword) {
        this.$message("请输入新密码");
        return;
      }
      if (!this.rePassword) {
        this.$message("请确认新密码");
        return;
      }

      if (this.newPassword != this.rePassword) {
        this.$message("两次输入密码不一致");
        return;
      }
      var reg =  /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,\/]).{8,32}$/;
         if (!(reg.test(this.newPassword))) {
        this.$message("密码应为英文字母+数字+字符组合（8位以上）");
        return;
      }

      this.axios({
        method: "post",
        url: this.tzzyUrl + "DataBus/exchange",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: {
          SERVICE_CODE: "szsti.system.User.findPassword",
          CONSUMER_ID: sessionStorage.getItem("examiner_token"),
          id: this.personalInfo.userId,
          password: this.currPassword,
        },
      })
        .then((res) => {
          if (res.data.BODY.data == "200") {
            this.updPass();
          } else {
            this.$message({
              type: "error",
              message: "当前密码错误!",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updPass() {
      let formData = new FormData();

      formData.append("CONSUMER_ID", sessionStorage.getItem("examiner_token"));
      formData.append(
        "SERVICE_CODE",
        "szsti.informationManagement.SysEvaluatorProc.updataEvaluator"
      );
      formData.append("type", "考评员信息");

      var bean = {
        userId: this.personalInfo.userId,
        evaluatorId: this.personalInfo.id,
        passWord: this.newPassword,
      };
      formData.append("bean", JSON.stringify(bean));

      this.axios({
        method: "post",
        url: "/examiner/DataBus/upload/uploadEvaluatorPic.shtml",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formData,
      })
        .then((res) => {
          if (res.data.status == "200") {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.currPassword = "";
            this.newPassword = "";
            this.rePassword = "";
          } else {
            this.$message({
              type: "error",
              message: "修改失败!",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    cancel() {
      this.isEdit = false;

      this.personalInfo = Object.assign({}, this.personalInfoBack);
    },
    getExamItems() {
      this.common
        .examinerAjax({
          method: "post",
          url: this.tzzyUrl + "DataBus/exchange",
          headers: {
            "content-type": "multipart/form-data",
          },
          data: {
            SERVICE_CODE: "szsti.system.DataCode.findDataCodeByParentCode",
            CONSUMER_ID: sessionStorage.getItem("examiner_token"),
            parentCode: "xmmc",
          },
        })
        .then((res) => {
          this.optionData.examItems = res.data.BODY.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getTrainMenu() {
      this.axios({
        method: "post",
        url: this.tzzyUrl + "DataBus/exchange",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: {
          SERVICE_CODE:
            "szsti.informationManagement.SysTrainingOrganizProc.findTrainMenu",
          CONSUMER_ID: sessionStorage.getItem("examiner_token"),
        },
      })
        .then((res) => {
          this.optionData.trainingData = res.data.BODY.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getPersonalInfo() {
      this.axios({
        method: "post",
        url: this.tzzyUrl + "DataBus/exchange",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: {
          SERVICE_CODE:
            "szsti.informationManagement.SysEvaluatorProc.findEvaluator",
          CONSUMER_ID: sessionStorage.getItem("examiner_token"),
          evaluator: {
            userId: sessionStorage.getItem("examiner_userId"),
          },
        },
      })
        .then((res) => {
          if (res.data.BODY.status === 200) {
            let data = res.data.BODY.data.data[0];
            data.examItem = data.examItem.split(",");

            this.personalInfo = data;
            this.personalInfoBack = Object.assign({}, data);
            this.trainPic = this.tzzyUrl + this.personalInfo.trainPic;
            // this.trainPic = "http://139.9.209.18:8082/" + this.personalInfo.trainPic;
            this.imageUrl = this.trainPic;

            this.username = this.personalInfo.username;
            this.mobile = this.personalInfo.mobile;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handleChange(val) {
      let elements = document.getElementsByClassName(
        "el-collapse-item__header"
      );

      let valSet = new Set(val);
      let lastCollapseSet = new Set(this.lastCollapse);

      let difference1 = val.filter((x) => !lastCollapseSet.has(x));
      let difference2 = this.lastCollapse.filter((x) => !valSet.has(x));

      if (difference1.length > 0) {
        let index = parseInt(difference1[0]);

        let element = document.getElementsByClassName(
          "el-collapse-item__header"
        )[index];
        element.children[2].innerText = "收起";
        element.children[2].setAttribute("class", "edit");
        element.children[3].src = require("@/image/bjxl2.png");
      }

      if (difference2.length > 0) {
        let index = parseInt(difference2[0]);

        let element = document.getElementsByClassName(
          "el-collapse-item__header"
        )[index];
        element.children[2].innerText = "编辑";
        element.children[2].setAttribute("class", "");
        element.children[3].src = require("@/image/bjxl1.png");
      }

      this.lastCollapse = val;
    },
    onUploadChange(file) {
      const isIMAGE =
        file.raw.type === "image/jpeg" || "image/jpg" || "image/png";
      const isPNG = file.raw.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (!isIMAGE) {
        this.$message.error("上传文件只能是图片格式!");
        return;
      }
      if (!isLt1M) {
        this.$message.error("上传文件大小不能超过 1MB!");
        return;
      }

      this.imageUrl = URL.createObjectURL(file.raw);
    },
    uploadImage(param) {
      let formData = new FormData();

      formData.append("CONSUMER_ID", sessionStorage.getItem("examiner_token"));
      formData.append(
        "SERVICE_CODE",
        "szsti.informationManagement.SysEvaluatorProc.updataEvaluator"
      );
      formData.append("type", "考评员信息");

      formData.append("fileName", param.file);

      var bean = {
        userId: this.personalInfo.userId,
        evaluatorId: this.personalInfo.id, //考评员ID
      };
      formData.append("bean", JSON.stringify(bean));

      this.axios({
        method: "post",
        url: "/examiner/DataBus/upload/uploadEvaluatorPic.shtml",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formData,
      })
        .then((res) => {
          if (res.data.status == "200") {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.getPersonalInfo();
          } else {
            this.$message({
              type: "error",
              message: "修改失败!",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    uploadPic() {
      this.$refs.upload.submit();
    },
    defaultImg() {
      this.trainPic = require("@/image/exit.png");
    },
  },
  mounted() {
    this.getExamItems();
    this.getTrainMenu();
    this.getPersonalInfo();
  },
};
</script>
<style lang="stylus" scoped>
.sign-up {
  margin-bottom: 20px;
  background-color: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);

  .personalInfo {
    padding: 20px 66px 0 0;
    font-size: 16px;

    .btn {
      text-align: left;
      margin-left: 80px;
    }
  }

  .accountInfo {
    padding: 30px 90px;

    >>> .el-collapse-item__header {
      font-size: 14px;
      color: #999999;

      .edit span {
        color: #3E97DF;
      }

      .el-icon-arrow-right {
        display: none;
      }
    }

    .head >>> .el-collapse-item__header {
      height: 80px;
    }
  }

  .label {
    width: 70px;
    text-align: right;
    margin-right: 18px;
  }

  .collapseContent {
    display: flex;
    margin-top: 10px;
    align-items: center;
    margin-left: 88px;
    text-left: left;
    color: #999999;
    font-size: 14px;

    span {
      width: 70px;
      text-align: left;
    }

    .el-button--primary {
      width: 86px;
      height: 37px;
    }
  }
}

.grid_content {
  background-color: #3E97DF;
  font-size: 20px;
  font-weight: bold;
  font-family: Source Han Sans CN;
  color: rgba(66, 71, 75, 1);
  height: 54px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  img {
    vertical-align: middle;
    width: 26px;
    margin-left: 14px;
  }

  .grid-span {
    color: #ffffff;
    font-size: 20px;
    margin-left: 6px;
  }

  .link_more span:link {
    color: #3E97DF;
  }

  .link_more span:hover {
    color: #3E97DF;
  }

  .link_more span:active {
    color: #3E97DF;
  }

  .edit {
    margin-right: 60px;

    .el-button--default {
      width: 88px;
      color: #3E97DF;
    }
  }
}

.grid_content_title {
  text-indent: 2em;
  font-size: 18px;
  word-break: break-all;
  text-align: left;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #878d99;
}

.el-button--primary {
  background-image: linear-gradient(#52B2FF, #3E97DF);
}

.el-button--info.is-disabled {
  background: #cccccc;
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 90px;
  cursor: pointer;
  overflow: hidden;
}
</style>