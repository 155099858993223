<template>
  <div>
    <div class="breadcrumb">
      当前位置: <div class="breadcrumb_home"
           @click="blackhome">网站首页</div>> 考试指引
    </div>
    <div class="ljxtitle">
      <div class="ljxtitle_ljxcontent">
        特种作业人员考试取证指引
      </div>
    </div>
    <div class="ljxcontent">
      <div class="ljxcontent_ljxtitle">
        <img src="../../image/kszyicon1.png"
             alt="">条件
      </div>
      <div class="ljxcontent_ljxtitle_ljxcontent">

        特种作业人员必须经专门的安全技术培训并考核合格，取得《中华人民共和国特种作业操作证》后，方可上岗作业。根据《特种作业人员安全技术培训考核管理规定》（国家安全监管总局令第30号），特种作业人员应当符合下列条件：
        <br>
        1. 年满18周岁，且不超过国家法定退休年龄；
        <br>
        2. 身体健康，无妨碍从事相应特种作业的器质性心脏病、癫痫病、美尼尔氏症、眩晕症、癔病、震颤麻痹症、精神病、痴呆症以及其他疾病和生理缺陷；
        <br>
        3. 具有初中及以上文化程度；
        <br>
        4. 具备必要的安全技术知识与技能；
        <br>
        5. 相应特种作业规定的其他条件。

      </div>
      <div class="ljxcontent_ljxtitle_guide">
        <div class="ljxcontent_ljxtitle_guide_box">
          <img src="../../image/sz.png"
               alt="">

          <router-link to="/xxfww/announcement?childId=21&detailId=28" class="ljxcontent_ljxtitle_guide_text">特种作业人员安全技术培训考核管理规定</router-link>
        </div>
      </div>
    </div>
    <div class="ljxcontent">
      <div class="ljxcontent_ljxtitle">
        <img src="../../image/kszyicon2.png"
             alt="">培训
      </div>
      <div class="ljxcontent_ljxtitle_ljxcontent">

        （一）培训机构报名
        <br>
        在深圳市应急管理局官网查询登记的培训机构，自行选取培训机构报名培训。
        <br>
        （二）报名提交资料
        <br>
        初训：特种作业操作证报考申请表、身份证、学历证书、个人健康书面承诺。
        <br>
        复审：特种作业操作证报考申请表、身份证、个人健康书面承诺。
        <br>
        （三）组织开展培训
        <br>
        培训机构按照原国家安全监管总局发布的安全技术培训大纲，足额学时组织开展理论培训和实操操作训练，提高受训人员知识技能。

      </div>
      <div class="ljxcontent_ljxtitle_guide">
        <div class="ljxcontent_ljxtitle_guide_box">
          <img src="../../image/sz.png"
               alt="">
          <router-link to="/xxfww/passRate" class="ljxcontent_ljxtitle_guide_text">培训机构</router-link>
        </div>
        <div class="ljxcontent_ljxtitle_guide_box">
          <img src="../../image/sz.png"
               alt="">
          <a href="https://ksfw.yjgl.sz.gov.cn/pic/fujian/特种作业操作证报考申请表+样板.docx" class="ljxcontent_ljxtitle_guide_text">报考申请表</a>
        </div>
        <div class="ljxcontent_ljxtitle_guide_box">
          <img src="../../image/sz.png"
               alt="">
          <a href="https://ksfw.yjgl.sz.gov.cn/pic/fujian/个人健康承诺书.doc" class="ljxcontent_ljxtitle_guide_text">个人健康书面承诺</a>
        </div>
        <div class="ljxcontent_ljxtitle_guide_box">
          <img src="../../image/sz.png"
               alt="">
          <router-link to="/xxfww/announcement?parentId=0&childId=22" class="ljxcontent_ljxtitle_guide_text">培训大纲</router-link>
        </div>
      </div>
    </div>
    <div class="ljxcontent">
      <div class="ljxcontent_ljxtitle">
        <img src="../../image/kszyicon3.png"
             alt="">考试
      </div>
      <div class="ljxcontent_ljxtitle_ljxcontent">

        培训结束后，由培训机构向深圳市应急管理局申请考试。初训考试包括理论考试与实操考试两个科目，复审考试只考理论考试一个科目。考试不合格允许补考一次，补考仍不合格重新培训考试。
        <br>
        （一）理论考试
        <br>
        考试前考生自行网上打印准考证，持准考证到考试点参加理论考试，考试结束提交试卷当场显示考试成绩。
        <br>
        （二）实操考试
        <br>
        考试前考生自行网上打印准考证，持准考证到考试点参加实操考试，考试结束后可以在网上查询考试成绩。

      </div>
      <div class="ljxcontent_ljxtitle_guide">
        <div class="ljxcontent_ljxtitle_guide_box">
          <img src="../../image/sz.png"
               alt="">
          <router-link to="/xxfww/printingOfAdmissionTicket?type=ticket" class="ljxcontent_ljxtitle_guide_text">理论考试准考证打印</router-link>
        </div>
        <div class="ljxcontent_ljxtitle_guide_box">
          <img src="../../image/sz.png"
               alt="">
          <router-link to="/xxfww/printingOfAdmissionTicket?type=tzzy2" class="ljxcontent_ljxtitle_guide_text">实操考试准考证打印 </router-link>
        </div>
        <div class="ljxcontent_ljxtitle_guide_box">
          <img src="../../image/sz.png"
               alt="">
          <router-link to="/xxfww/printingOfAdmissionTicket?type=tzzy1" class="ljxcontent_ljxtitle_guide_text">实操考试成绩查询</router-link>
        </div>
      </div>
    </div>
    <div class="ljxcontent">
      <div class="ljxcontent_ljxtitle">
        <img src="../../image/kszyicon4.png"
             alt="">取证
      </div>
      <div class="ljxcontent_ljxtitle_ljxcontent">

        （一）电子证书下载
        <br>
        本期次考试全部结束5个工作日后可以下载电子证，电子证书与实体证书一一对应，具有同等法律效力。手机下载电子证书步骤如下：
        <br>
        第一步：从微信搜索“国家安全生产考试”，进入并关注公众号；
        <br>
        第二步：选择“我的证书”然后进行注册；
        <br>
        第三步：注册并且按要求进行实名认证完成后，可以下载特种作业操作证。
        <br>
        为方便打印保管，可以通过电脑下载电子证书，电脑下载电子证书步骤如下：
        <br>
        第一步：进入应急管理部政府网站“特种作业操作证及安全生产知识和管理能力考核合格信息查询”系统(http://cx.mem.gov.cn/)。
        <br>
        第二步：点击“电子证书查询下载”，使用注册并认证过的手机号登录系统。
        <br>
        第三步：登录后显示、下载PDF电子证书即可打印。
        <br>
        （二）实体证书申领
        <br>
        根据国家应急管理部要求，实体证书根据取证人需要发放，由所报名的培训机构向深圳市应急管理局申请后领取。

      </div>
      <div class="ljxcontent_ljxtitle_guide">
        <div class="ljxcontent_ljxtitle_guide_box">
          <img src="../../image/sz.png"
               alt="">
            <el-tooltip placement="top-end" effect="light">
              <div slot="content">
                <img style="width:180px;" src="../../image/gongan.jpg" alt="">
              </div>
              <el-link class="ljxcontent_ljxtitle_guide_text">国家安全生产考试微信公众号</el-link>
            </el-tooltip>

        </div>
        <div class="ljxcontent_ljxtitle_guide_box">
          <img src="../../image/sz.png"
               alt="">
          <a href="http://cx.mem.gov.cn/" target="_blank" class="ljxcontent_ljxtitle_guide_text">全国特种作业操作证及考核合格证查询平台</a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {

    }

  },
  methods: {
    blackhome () {
      this.$router.push('/')
    }
  },
}
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  font-size: 14px;
  color: #3e97df;
  .breadcrumb_home {
    margin: 0 10px;
    cursor: pointer;
  }
}
.ljxtitle {
  margin-top: 47px;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  .ljxtitle_ljxcontent {
    width: 700px;
    font-weight: bold;
    color: rgba(62, 151, 223, 1);
    font-size: 22px;
  }
}
.ljxcontent {
  margin-top: 30px;
  width: 1200px;
  background-color: #f7f8fb;
  border-radius: 10px;
  padding: 25px 0 0 0;
  box-sizing: border-box;
  .ljxcontent_ljxtitle {
    background: url("../../image/kszybg.png") no-repeat;
    height: 50px;
    width: 240px;
    background-size: 100%;
    display: flex;
    font-size: 22px;
    color: #ffffff;
    align-items: center;
    font-weight: bold;
    img {
      width: 34px;
      height: 32px;
      margin-right: 29px;
      margin-left: 24px;
    }
  }
  .ljxcontent_ljxtitle_ljxcontent {
    margin-top: 20px;
    font-size: 14px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    text-align: left;
    line-height: 35px;
  }
  .ljxcontent_ljxtitle_guide {
    display: flex;
    margin-top: 40px;
    margin-left: 20px;
    flex-direction: column;
    .ljxcontent_ljxtitle_guide_box {
      margin-bottom: 41px;
      display: flex;
      align-items: center;
      height: 23px;
      img {
        width: 39px;
        height: 23px;
        margin-right: 23px;
        display: flex;
      }
      .ljxcontent_ljxtitle_guide_text {
        font-weight: bold;
        color: rgba(62, 151, 223, 1);
        font-size: 18px;
        &:hover{
          border-bottom: 1px solid rgba(62, 151, 223, 1);
        }
      }
    }
  }
}
</style>
