<template>
  <div>
    <el-row class="sign-up">
        <el-col :span="24">
            <div v-if="isList">
                <div class="grid-content">
                    <img src="../../../image/kpyy.png"/>
                    <span>预约列表</span>
                </div>
                <div class="exam-content">
                    <div>
                        <el-table :data="planList" border style="width: 100%" 
                            :header-cell-style="headerClass"
                            :cell-style="cellClass">
                            <el-table-column prop="planName" label="考评计划" min-width="400"></el-table-column>
                            <el-table-column prop="promiseStartTime" label="预约开始时间" :formatter="dateFormatter"></el-table-column>
                            <el-table-column prop="promiseEndTime" label="预约结束时间" :formatter="dateFormatter"></el-table-column>
                            <el-table-column label="状态">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.promiseStatus ==1">
                                        正在预约
                                    </div>
                                    <div v-else-if="scope.row.promiseStatus ==2">
                                        预约结束
                                    </div>
                                    <div v-else>
                                        未开始预约
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <div>
                                        <el-button :disabled='scope.row.promiseStatus==1?false:true'  size="mini"  :type="scope.row.promiseStatus==1?'primary':'info'" @click="openExamPlanParent(scope.row)">点击预约</el-button>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page.sync="start"
                            :page-sizes="[5, 10, 20, 30]"
                            :page-size.sync="count"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalPlanList"
                            style="float: right;margin-top:20px">
                        </el-pagination>
                    </div>
                    <div style="margin-top:100px">
                        <div class="notice">
                            <el-divider>预约须知</el-divider>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="grid-content">
                    <img src="../../../image/kpyy.png"/>
                    <span>考评预约</span>
                    <span style="font-size:14px;margin-left:60px;">温馨提示：请在{{endDate}}前完成预约，预约成功后不可撤销。</span>
                </div>
                <div class="exam-content">
                    <div class="search">
                        <span>考评工种项目：</span>
                        <el-select  placeholder="请选择项目" v-model="examItem" style="width:350px">
                            <el-option v-for="(item, index) in examItems" :key="index" :label="item.dataValue" :value="item.dataCode"></el-option>
                        </el-select>
                        <el-button @click="search" type="primary" size="mini" style="width:86px;height:37px;font-size:16px;">查询</el-button>
                    </div>
                    <div style="margin-top:30px; font-size:16px">
                        <table border>
                            <thead>
                                <tr>
                                    <th width="260px">考试日期</th>
                                    <th>考点</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(item,index) in timeList">
                                    <tr v-if="item.week" :key="index">
                                        <td>
                                            <div class="examTime">
                                                <div class="examTimeContent">
                                                    <div>{{item.examTime}}</div>
                                                    <div>{{item.week}}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="local">
                                                <div 
                                                    :class="{examLocaleAlready: (item.examTime == examTime && items.examPlaceId == examPlaceId) || items.reserveStatus==='true',
                                                    examLocaleFull:items.full==='true',
                                                    examLocale:items.full==='false'}"
                                                    v-for="(items, indexs) in item.examPlaceLs" :key="indexs"
                                                    @click="(items.full!='true')&&crrendPaClick(items,item)">
                                                    <div class="examLocaleContent">
                                                        <div>
                                                            <div v-if="items.reserveStatus==='true'" style="margin: 5px 0 10px 0">
                                                                <img :src="examLocaleAlreadyImg" style="vertical-align: middle;"/>
                                                                <span style="vertical-align: middle;margin-left:10px">已预约</span>
                                                            </div>
                                                            <div v-else>
                                                                <img :src="examLocaleFullImg" v-if="items.full === 'true'"/>
                                                                <img :src="examLocaleImg" v-else/>
                                                            </div>
                                                        </div>
                                                        <div>{{items.examPlaceName}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <!-- <tr height="60px">
                                    <td colspan="2" style="color:#909399">暂无数据</td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                    <div class="operation">
                        <el-button size="mini" @click="appointment" type="primary" style="width:123px;height:44px;font-size:18px;">提交</el-button>
                        <el-button size="mini" @click="cancel" type="primary" style="width:123px;height:44px;font-size:18px;">取消</el-button>
                    </div>
                </div>
            </div>
        </el-col>
    </el-row>
  </div>
</template>
<script>
import format from '@/lib/format.js'

export default {
    name: "question",
    data() {
        return {
            planList: [],
            totalPlanList: 0,
            start: 1,
            count: 10,
            isList: true,
            examItem: "",
            examItems: [],
            examPlanParentId: "",
            timeData: 1,
            currentIndex: 0,
            time: [
                { value: "一个月", id: 30 },
                { value: "一季度", id: 90 }
            ],
            endDate: '',
            value: '',
            timeList: [],
            examTime: "",
            examPlaceName:"",
            examPlaceId: "",
            examPlanId: "",
            examLocaleImg: require("@/image/zt1.png"),
            examLocaleFullImg: require("@/image/zt2.png"),
            examLocaleAlreadyImg: require("@/image/yyy.png"),
        };
    },
    created() {
    },
    methods: {
        dateFormatter(row, column) {
            let date = row[column.property];

            if (date == null || date == ""){
                return "";
            }

            return new Date(date).format("yyyy-MM-dd");
        },
        headerClass({ row, rowIndex}) {
            return 'background:#ffffff;color:#3E97DF;text-align: center;font-weight:bold;' 
        },
        cellClass({row, column, rowIndex, columnIndex}) {
            return 'text-align: center;'
        },
        handleSizeChange(val) {
            this.getPlanParentList();
        },
        handleCurrentChange(val) {
            this.getPlanParentList();
        },
        getPlanParentList(){
            if(!sessionStorage.getItem('examiner_token')){
                Message.error('登录信息已失效,请重新登录!');
                sessionStorage.removeItem('examiner_token');
                setTimeout(function() {
                     window.location.href ='https://ksfw.yjgl.sz.gov.cn/login?type=examinerSystem';
                   // window.location.href ='https://szksfw.yjgl.sz.gov.cn:8081/login?type=examinerSystem';
                }, 100)
            }
            this.common.examinerAjax({
                method: "post",
                url: this.tzzyUrl + "DataBus/exchange",
                headers: {
                    "content-type": "multipart/form-data"
                },
                data: {
                    SERVICE_CODE:"szsti.examManage.ExamPlanEntry.getExamPlanParentLs",
                    CONSUMER_ID: sessionStorage.getItem('examiner_token'),
                    start: this.start,
                    count: this.count,
                    type:0
                }
            })
            .then(res => {
                if (res.data.BODY.status == 200) {
                    this.planList =  res.data.BODY.data.data;
                    this.totalPlanList = res.data.BODY.data.total;
                }else{
                    this.planList=[];
                    this.totalPlanList=0;
                }
            })
            .catch(error => {
                console.error(error);
            });
        },
        openExamPlanParent(row){
            if (row.promiseEndTime) {
                let endTime = new Date(row.promiseEndTime); 
                this.endDate = endTime.format("yyyy年MM月dd日");
            }
            this.getExamItems(row);
            this.isList = false;
        },
        getExamItems(row) {
            this.common.examinerAjax({
                method: "post",
                url: this.tzzyUrl + "DataBus/exchange",
                headers: {
                    'content-type': "multipart/form-data"
                },
                data: {
                    "CONSUMER_ID"  : sessionStorage.getItem('examiner_token'),
                    "SERVICE_CODE": "szsti.examManage.ExamPlanEntry.findExamPlanByUserId",
                    "userId": sessionStorage.getItem('examiner_userId'),
                },
            }).then((res) => {
                if(res.data.BODY.data.paras!=null){
                    this.examItems = res.data.BODY.data.paras;
                    this.examItem = res.data.BODY.data.paras[0].dataCode;
                    this.examPlanParentId = row.id;
                    this.$nextTick(()=>{
                        this.getPlanList();
                    });
                } 
            }).catch((error) => {
                console.error(error);
            });
        },
        getPlanList(value,examItem) {
            if(value == undefined){
                value = '';
            } else {
                this.value = value;
            }
            if(examItem == undefined){
                examItem = '';
            } else {
                this.examItem = examItem;
            }
            let data={
                "CONSUMER_ID": sessionStorage.getItem('examiner_token'),
                "SERVICE_CODE": "szsti.examManage.ExamPlanEntry.getExamPlanByEvaluator",
                "Days": this.time[this.currentIndex].id,
                "userId": sessionStorage.getItem('examiner_userId'),
                "dataCode":this.value,
                "examItem":this.examItem,
                "examPlanParentId": this.examPlanParentId
            }
            this.common.examinerAjax({
                method: "post",
                url: this.tzzyUrl + "DataBus/exchange",
                headers: {
                    'content-type': "multipart/form-data"
                },
                data: data,
            }).then((res) => {
                if(res.data.BODY.status == 200){
                    this.timeList = res.data.BODY.data.examPlaceLs;
                }
            })
        },
        crrendPaClick(items, examPlanItem) {
            this.examTime =  examPlanItem.examTime;
            this.examPlaceId = items.examPlaceId;
            this.examPlanId = examPlanItem.examPlanId;
            this.examPlaceName = items.examPlaceName;
        },
        appointment() {
            if (!this.examPlaceId) {
                this.$message({
                    type: 'info',
                    message: '请选择预约考点'
                }); 
                return;
            }
            this.$confirm('您正在预约'+this.examPlaceName+'考点的考试, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data={
                    "CONSUMER_ID": sessionStorage.getItem('examiner_token'),
                    "SERVICE_CODE": "szsti.examManage.ExamPlanEntry.bindEvaluator",
                    "examPlanId": this.examPlanId,
                    "userId": sessionStorage.getItem('examiner_userId'),
                    "examPlaceId": this.examPlaceId,
                    "examItem": this.examItem,
                    "examTime":this.examTime,
                }

                this.common.examinerAjax({
                    method: "post",
                    url: this.tzzyUrl + "DataBus/exchange",
                    headers: {
                        'content-type': "multipart/form-data"
                    },
                    data: data,
                }).then((res) => {
                    if(res.data.BODY.status == 200){
                        this.$message({
                            type: 'success',
                            message: '预约成功'
                        });
                        this.getPlanList();
                    }
                    if(res.data.BODY.status == 500){
                        this.$message({
                            message: res.data.BODY.msg,
                            type:'error'
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        cancel() {
            this.isList = true;
            this.timeList = [];
            this.examPlanId = "";
            this.examPlaceId = "";
            this.examPlaceName = "";
            this.examTime = "";
        },
        search(){
            this.getPlanList('', this.examItem);
        }
    },
    mounted() {
        this.getPlanParentList();
    }
} ;
</script>
<style lang="stylus" scoped>
.sign-up {
    height: 100%;
    margin-bottom: 20px;
    background-color: #ffffff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);

    .exam-content {
        padding: 40px 45px;

        .notice {
            width:30%;
            margin: auto;

            .el-divider {
                background-color: #3E97DF;

                .el-divider__text {
                    color: #3E97DF;
                }
            }
        }

        .search {
            display:flex; 
            align-items: center;
            
            span {
                font-size:16px;
                width:120px;
            }

            button {
                height: 37px;
                width: 86px;
                margin-left: 19px;
            }   
        }

        table {
            width:100%;
            border-collapse:collapse;
            border:1px solid #D8E2F0;

            thead tr {
                height: 40px;
                color: #3E97DF;
            }

            .examTime {
                padding:16px;

                font-size: 16px;
                color: #fff;

                .examTimeContent {
                    width:100%;
                    height:135px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    // background: -webkit-linear-gradient(#92C9FF, #3497EC); /* Safari 5.1 - 6.0 */
                    // background: -o-linear-gradient(#92C9FF, #3497EC); /* Opera 11.1 - 12.0 */
                    // background: -moz-linear-gradient(#92C9FF, #3497EC); /* Firefox 3.6 - 15 */
                    background: linear-gradient(#92C9FF, #3497EC); /* 标准的语法 */
                }
            }

            .local {
                display:flex;
                flex-wrap: wrap; 
                padding:8px;

                .examLocale {
                    width: 250px;
                    height: 135px;
                    background: #EDF5FB;
                    margin:8px;
                    cursor:pointer;
                }

                .examLocaleFull {
                    width: 250px;
                    height: 135px;
                    background: linear-gradient(#FFA3A2, #FF5252);
                    margin:8px;
                }

                .examLocaleAlready {
                    width: 250px;
                    height: 135px;
                    background: linear-gradient(#92C9FF, #3497EC);
                    margin:8px;
                    color: #ffffff;
                }

                .examLocaleContent {
                    margin: auto;
                    padding: 15px 30px;
                }
            }

            .local:after {
                content: ""; 
                flex: auto;
            }
        }

        .operation {
            display:flex;
            margin-top:30px;
            justify-content: center;

            button {
                width:123px;
                height:44px;
            }
        }
    }
}

.grid-content {
  background-color: #3E97DF;
  font-size: 20px;
  font-weight: bold;
  font-family: Source Han Sans CN;
  color: rgba(66, 71, 75, 1);
//   padding: 10px;
  height:54px;
//   min-height: 26px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  img {
    vertical-align: middle; 
    width:26px; 
    margin-left:14px;
  }

  span {
    color: #ffffff;
    font-size:20px;
    margin-left:6px;
  }
}
.el-button--primary {
    background-image: linear-gradient(#52B2FF, #3E97DF);
}
.el-button--info.is-disabled {
    background:#cccccc;
}
</style>
<style lang="scss">
.el-table th.gutter{
    display: table-cell!important;
}
 
.el-table colgroup.gutter{
    display: table-cell!important;
}
</style>