<template>
  <div>
    <!-- -->
    <div class="ym-item-title">
      <div class="ym-line"></div>
      <div class="ym-name">师资力量</div>
      <div class="ym-line"></div>
      <div class="ym-bottom-line"></div>
    </div>
    <div class="ym-swiper">
      <div class="swiper-container">
        <div class="swiper-wrapper">

          <div class="swiper-slide" v-for="(item, index) in data" :key="index">
            <div class="swiper-slide-box">
              <img src="../../image/dl_bg.png" />
              <div class="swiper-slide-text">
                <p>{{item.name}}</p>
                <p style="color: #666666;font-size: 14px;">{{ item.qualification }}</p>
              </div>
              <div class="swiper-slide-article">主要负责人及安全管理人员：{{item.workcategory}}</div>
            </div>
          </div>
          <!-- <div class="swiper-slide">
            <div class="swiper-slide-box">
              <img src="../../image/dl_bg.png" />
              <div class="swiper-slide-text">
                <p>王守臣</p>
                <p style="color: #666666;font-size: 14px;">兼职</p>
              </div>
              <div class="swiper-slide-article">主要负责人及安全管理人员：粉尘涉爆单位，涉氨制冷单位</div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-slide-box">
              <img src="../../image/dl_bg.png" />
              <div class="swiper-slide-text">
                <p>王守臣</p>
                <p style="color: #666666;font-size: 14px;">兼职</p>
              </div>
              <div class="swiper-slide-article">主要负责人及安全管理人员：粉尘涉爆单位，涉氨制冷单位</div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-slide-box">
              <img src="../../image/dl_bg.png" />
              <div class="swiper-slide-text">
                <p>王守臣</p>
                <p style="color: #666666;font-size: 14px;">兼职</p>
              </div>
              <div class="swiper-slide-article">主要负责人及安全管理人员：粉尘涉爆单位，涉氨制冷单位</div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-slide-box">
              <img src="../../image/dl_bg.png" />
              <div class="swiper-slide-text">
                <p>王守臣</p>
                <p style="color: #666666;font-size: 14px;">兼职</p>
              </div>
              <div class="swiper-slide-article">主要负责人及安全管理人员：粉尘涉爆单位，涉氨制冷单位</div>
            </div>
          </div> -->
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
      </div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      swiper: null
    };
  },
  props: {
    dataList: {}
  },
  methods: {
    //开始轮播
    teacherRequest() {
      console.log(this.dataList);
      let id = this.dataList.id;
      let url = "szsti.AdminEnd.RegisterDynamic.getLecturer";
      let params = {
        CONSUMER_ID: 'system',
        instistutionId: "863511591752155136",
        currPage: "1",
        pageSize: "999"
      };
      this.$http(url, params, false)
        .then(res => {
          if (res.data.BODY.status == 200 && res.data.BODY.data.length >= 1) {
            this.data = res.data.BODY.data;
                this.$nextTick(()=>{
				this.carousel();
			})
          }
        })
        .catch(error => {});
    },
    carousel() {
      this.swiper = new Swiper(".swiper-container", {
        slidesPerView: 3,
        spaceBetween: 30,
        autoplay: {
          delay: 3000
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        centeredSlides: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      });
    }
  },
  destroyed() {
     this.swiper.destroy();
  },
  mounted() {
      this.teacherRequest();

  }
};
</script>

<style scoped="scoped" lang="scss">
.ym-title {
  width: 1200px;
  height: 50px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  margin-bottom: 5px;
  background: #3e97df;
  font-size: 22px;
  line-height: 50px;
}

.ym-phone {
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 30px 0;

  .ym-phone-item {
    flex: 1;
    display: flex;
    .ym-phone-item-title {
      width: 150px;
      height: 38px;
      background: rgba(246, 245, 245, 1);
      border: 1px solid rgba(198, 209, 222, 1);
      line-height: 38px;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      border-right: none;
    }

    .ym-phone-item-text {
      border: 1px solid rgba(198, 209, 222, 1);
      line-height: 38px;
      font-size: 18px;
      box-sizing: border-box;
      padding: 0 20px;
      flex: 1;
      // border-right: none;
    }
  }
}

.ym-item-title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 46px;
  margin-top: 12px;
  margin-bottom: 24px;

  .ym-line {
    width: 210px;
    height: 1px;
    background: #d9d9d9;
  }

  .ym-name {
    width: 140px;
    text-align: center;
    color: #333;
    font-size: 20px;
    font-weight: 700;
  }

  .ym-bottom-line {
    width: 50px;
    height: 3px;
    background: #3e97df;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -25px;
  }
}

.ym-swiper {
  padding: 0 224px;
  box-sizing: border-box;
  width: 1200px;
  height: 430px;
  position: relative;
  .swiper-container {
    height: 100%;
  }
  .swiper-button-next {
    right: 121px;
  }
  .swiper-button-prev {
    left: 121px;
  }
  .swiper-slide {
    height: 380px;
    box-sizing: border-box;
    img {
      display: block;
    }
    .swiper-slide-box {
      margin: 0 auto;
      width: 200px;
      height: 327px;
      margin-top: 27px;
      padding: 20px 20px;
      box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.15);
      box-sizing: border-box;
      img {
        width: 100%;
        height: 178px;
      }
      .swiper-slide-text {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-top: 24px;
        margin-bottom: 20px;
        p {
          margin: 0 7px;
          font-size: 24px;
          color: #333;
        }
      }
      .swiper-slide-article {
        line-height: 20px;
        font-size: 12px;
        color: #666;
        text-align: center;
        padding: 0 0px;
      }
    }
  }
  .swiper-slide-active {
    .swiper-slide-box {
      width: 232px;
      margin-top: 0;
      height: 100%;
      box-sizing: border-box;
      box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.15);
      padding: 20px;
      img {
        width: 100%;
        height: 205px;
      }
      .swiper-slide-text {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-top: 24px;
        margin-bottom: 20px;
        p {
          margin: 0 7px;
          font-size: 24px;
          color: #333;
        }
      }
      .swiper-slide-article {
        line-height: 20px;
        font-size: 14px;
        color: #666;
        text-align: center;
        padding: 0 0px;
      }
    }
  }
}
</style>
