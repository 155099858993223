<template>
    <div>
        <el-row class="sign-up">
            <el-col :span="24">
                <div class="grid-content">
                    <img
                        src="../../../image/kpyy.png"
                        style="vertical-align: middle;"
                    />
                    <span>预约记录</span>
                </div>
                <div class="exam-content-mine">
                    <el-form inline >
                                  <el-form-item label="时间">
                                    <el-date-picker
                                        v-model="time"
                                        type="daterange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        value-format="yyyy-MM-dd"	
                                    >
                                    </el-date-picker>
                                </el-form-item>
                               <el-form-item >
                                <el-form-item label="">
                                    <el-button @click="search" type="primary">搜索</el-button>
                                </el-form-item>
                             <el-form-item label="总费用">
                                 <span style="color:red;">{{totalCost}}</span>
                             </el-form-item>
                         </el-form-item>
                    </el-form>
                    <el-table :data="list" border>
                    <el-table-column prop="examTime" label="考试日期"></el-table-column>
                    <el-table-column prop="evaluatorTime" label="考评时长">
                        <template slot-scope="scoped">
                            <div>
                                {{scoped.row.evaluatorTime.toFixed(1)}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="examPlanName" label="所属考试计划"></el-table-column>
                    <el-table-column prop="examPlaceName" label="考点"></el-table-column>
                    <el-table-column prop="cost" label="费用"></el-table-column>
                </el-table>
                </div>
                <div style="text-align:center">
                    <el-pagination
                    background
                    :total="total"
                    :current-page="current"
                    @current-change="change"
                    layout="prev, pager, next"
                    >
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            time: [],
            current:1,
            total:1,
            list:[],
            totalCost:0
        };
    },
    mounted() {
        this.queryList();
    },
    methods: {
        queryList() {
            let data = {
                CONSUMER_ID: sessionStorage.getItem("examiner_token"),
                SERVICE_CODE:"szsti.examManage.DataStatistics.evaluateCostDetailByEvaluate",
                currPage:this.current
            };
            if(this.time&&this.time.length>0){
                data.startTime=this.time[0]
                data.endTime=this.time[1]
            }
            this.common.ajax({
                method: "post",
                url: this.tzzyUrl + "DataBus/exchange",
                headers: {
                    "content-type": "multipart/form-data"
                },
                data: data
            }).then(res=>{
                if(res.data.BODY.status ==200){
                    this.list =res.data.BODY.data
                    this.total =res.data.BODY.total
                    this.totalCost = res.data.BODY.totalCost
                }
                console.log(res)
            })
        },
        search(){
            this.current = 1
            this.queryList();
        },
        change(v){
            this.current = v
    this.queryList();
}
    }
};
</script>

<style lang="stylus" scoped>
.sign-up {
    margin-bottom: 20px;
    background-color: #ffffff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);
    .exam-content-mine {
        padding: 40px 42px;
        text-align:left;
        font-size :12px;
    }
}

.grid-content {
  background-color: #3E97DF;
  font-size: 20px;
  font-weight: bold;
  font-family: Source Han Sans CN;
  color: rgba(66, 71, 75, 1);
//   padding: 10px;
  height:54px;
//   min-height: 26px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  img {
    vertical-align: middle;
    width:26px;
    margin-left:14px;
  }

  span {
    color: #ffffff;
    font-size:20px;
    margin-left:6px;
  }

  .link_more span:link {
    color: #3E97DF;
  }

  .link_more span:hover {
    color: #3E97DF;
  }

  .link_more span:active {
    color: #3E97DF;
  }

  .edit {
       margin-right:60px;

       .el-button--default  {
            width: 88px;
            color: #3E97DF;
        }
  }
}


.el-button--primary {
    background-image: linear-gradient(#52B2FF, #3E97DF);
}

.grid-content_title {
  text-indent: 2em;
  font-size: 18px;
  word-break: break-all;
  text-align: left;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #878d99;
}
</style>
