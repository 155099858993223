<template>
    <el-container class="nav-page">
        <el-header height="80px">
            <div class="let_header_title">安全生产考试报名系统</div>
            <div class="right_header_login">
                <div @click="back_home" class="return-button">返回首页</div>
                <el-dropdown trigger="hover">

                    <div class="el-dropdown-link userinfo-inner">

                        <span>{{applicantData.realName}}</span>
                        <img class="userImg" :src="this.sysUserAvatar"/>
                        <span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="open2">用户资料</el-dropdown-item>
                        <el-dropdown-item divided @click.native="logout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </el-header>
        <user-page v-if="userShow!==''" :type=userShow @edit=edit></user-page>
        <el-container v-else>
            <el-aside width="260px">
                <el-menu
                    :default-active="defaultIndex"
                    class="el-menu-vertical-demo"
                    background-color="#3E97DF"
                    @select="handRouter"
                >
                    <el-menu-item
                        :index="String(index+1)"
                        v-for="(item,index) in menuData"
                        :key="item.name"
                        :class="(index+1)===Number(defaultIndex)?'bacTabs':''"
                    >
                        <div class="nav_tabs">
              <span>
                <img :src="item.icon"/>
                <span style="margin-left:11px">{{item.name}}</span>
              </span>
                            <span>
                <i class="el-icon-arrow-right"></i>
              </span>
                        </div>
                    </el-menu-item>
                </el-menu>
            </el-aside>
            <el-container>
                <el-main>
                    <router-view/>
                </el-main>
            </el-container>
        </el-container>
    </el-container>
</template>
<script>
    import userPage from "./userPage";

    export default {
        data() {
            return {
                applicantData: {
                    realName: null
                },
                sysUserAvatar: require("@/image/exit.png"),
                num: "1",
                menuData: [
                    {
                        name: "首页",
                        icon: require("../../image/zlb_icon1.png"),
                        iconChange: require("@/image/zlb_icon11.png"),
                        path: "/examinationregistration/homePage"
                    },
                    {
                        name: "我要报名",
                        icon: require("../../image/zlb_icon2.png"),
                        iconChange: require("@/image/zlb_icon12.png"),
                        path: "/examinationregistration/applicant"
                    },
                    {
                        name: "报名查询",
                        icon: require("../../image/zlb_icon3.png"),
                        iconChange: require("@/image/zlb_icon13.png"),
                        path: "/examinationregistration/applicantLook"
                    },
                    {
                        name: "常见问题",
                        icon: require("../../image/zlb_icon5.png"),
                        iconChange: require("@/image/zlb_icon15.png"),
                        path: "/examinationregistration/question"
                    }
                ],
                path: "/examinationregistration/homePage",
                userShow: ""
            };
        },
        methods: {
            handRouter(key, keyPath) {
                this.path = this.menuData[Number(key) - 1].path;
                this.num = String(key);
                let name = this.menuData[Number(key) - 1].name;
                if (name === "常见问题") {
                    this.$router.push({
                        path: this.path,
                        query: {
                            columnType: "ENLIST"
                        }
                    });
                } else {
                    this.$router.push(this.path);
                }
            },
            back_home() {
                this.$router.push("/xxfww/home");
            },
            init() {
                this.applicantData.realName = sessionStorage.getItem("name");
            },
            open2() {
                this.userShow = 1;
            },
            logout() {
                sessionStorage.clear();
                localStorage.clear();
                this.$router.push({path: "/Login"});
                this.$message({type: "success", message: "注销成功"});
            },
            edit(val) {
                this.userShow = val;
            }
        },
        mounted() {
            this.init();
        },
        computed: {
            defaultIndex: {
                get() {
                    if (this.$route.params.playId) {
                        return "2";
                    } else {
                        return this.num;
                    }
                },
                set(val) {
                    this.num = val;
                }
            }
        },
        components: {
            userPage
        }
    };
</script>
<style lang="stylus" scoped>
    * {
        font-family: Source Han Sans CN;
    }

    .nav-page {
        background-color: #EFF0F4;

        .el-header {
            background: linear-gradient(-50deg, rgba(74, 165, 239, 1), rgba(68, 168, 248, 1));
            color: #333;
            text-align: center;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            line-height: 80px;
        }

        .nav_tabs {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            span {
                span {
                    font-size: 20px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    line-height: 44px;
                }
            }
        };

    .let_header_title {
        color: #fff;
        font-weight: bold;
        font-size: 30px;
        font-family: Source Han Sans CN;
        height: 29px;
        line-height: 80px;
    }

    .right_header_login {
        display flex
        align-items center

        .el-dropdown {
            height: 80px;
            line-height: 80px;
            display: flex;
        }

        .return-button {
            margin-right: 10px;
            font-size 18px
            color #fff
            cursor pointer
        }

        .return-button:link {
            color: #fff;
        }

        .return-button:active {
            color: #fff;
        }

        .return-button {
            margin-right: 10px;
        }
    }

    .userinfo-inner {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;

        img {
            width: 40px;
            height: 40px;
            border-radius: 20px;
        }

        span {
            margin-right: 10px;

            i {
                color #fff
            }
        }


        .userImg:hover {
            cursor: pointer;
        }
    }

    .el-aside {
        background-color: #3E97DF;
        font-weight: bold;
        text-align: center;

        .el-menu-vertical-demo {
            border-right: 0;
        }

        .el-menu-item:hover {
            background url(../../image/xzds.png) no-repeat left top / 100% 100%
            background-color transparent !important
        }
    }

    .el-menu-item {
        color: #A5D7FE;
        opacity: 0.6;

        i {
            color: #fff;
        }
    }

    .bacTabs {
        background url(../../image/xzds.png) no-repeat left top / 100% 100%
        background-color transparent !important
        color: #FFF;
        opacity: 1;
    }

    .el-menu-item:hover {
        color: #FFF;
        opacity: 1;
    }

    .el-menu-item:visited {
        background-color: rgba(255, 255, 255, 1);
        color: #FFF;
        opacity: 1;
    }

    .el-main {
        color: #333;
        text-align: center;
        height: 100%;
        overflow-y: auto;
        padding: 20px 40px;
    }

    body > .el-container {
        margin-bottom: 40px;
    }

    .el-container:nth-child(5) .el-aside, .el-container:nth-child(6) .el-aside {
        line-height: 260px;
    }

    .el-container:nth-child(7) .el-aside {
        line-height: 320px;
    }

    }
</style>
