<template>
    <div>
        <div class="let-trainingInstitution-nav">
            <div>
                当前位置:
                <span @click="back_home"
                      class="hyperlink-blue"
                      title="去首页">网站首页</span>
                >{{$route.query.type|subTitleFilter}}
                <span></span>>输入信息
            </div>
        </div>
        <div class="loginContent"
             style="margin-bottom: 20px"
             v-if="!isTzzy" >
            <div class="content">
                <div class="title" :style="{marginRight:$route.query.type=='certificate'?'0':''}">
                    <span>{{title}}</span>
                    <div class="content_subheading" v-if="dd">(特种作业人员理论考试、危险化学品生产经营单位主要负责人和安全生产管理人员考试）</div>
                </div>
                <el-form :label-position="labelPosition"
                         :model="dataList"
                         :rules="rules"
                         label-width="120px"
                         ref="dataList"
                         size="mini"
                         hide-required-asterisk>
                    <!-- <el-form-item label="资格类型"
                                  prop="qualification">
                        <img alt
                             src="~@/image/xxsr_icon1.png"/>
                        <el-select class="select input"
                                   placeholder="请选择资格类型"
                                   v-model="dataList.region">
                            <el-option :key="index"
                                       :label="item.dataValue"
                                       :value="item.dataCode"
                                       v-for="(item, index) in industryType"></el-option>
                        </el-select>
                        <img alt=""
                             class="img_bot"
                             src="~@/image/dengl_xl.png">
                    </el-form-item> -->
                    <!-- 证件类型 -->
                    <!-- <el-form-item label="证件类型" prop="date1">
                                  <img alt src="~@/image/xxsr_icon2.png"/>
                                  <el-select class="select input" placeholder="请选择证件类型" v-model="dataList.date1">
                                      <el-option label="身份证" value="身份证"></el-option>
                                      <el-option label="准考证" value="准考证"></el-option>
                                  </el-select>
                                  <img alt="" class="img_bot" src="~@/image/dengl_xl.png">
                              </el-form-item> -->
          <el-form-item label="证件号码"
                        prop="idCard">
            <img alt
                 src="~@/image/xxsr_icon3.png" />
            <el-input class="input"
                      placeholder="请输入证件号码"
                      v-model="dataList.idCard"></el-input>
          </el-form-item>
          <el-form-item label="姓名"
                        prop="name">
                        <span slot="label">
                          <div v-html="'姓&emsp;&emsp;名'"></div>
                         </span>
            <img alt
                 src="~@/image/xxsr_icon4.png" />
            <el-input class="input"
                      placeholder="请输入姓名"
                      v-model="dataList.name"></el-input>
          </el-form-item>
              <!-- <el-form-item label="持证人姓名" v-if="$route.query.type=='history'||$route.query.type=='managerCertificate'||$route.query.type=='certificate'"
                        prop="name">
            <img alt
                 src="~@/image/xxsr_icon4.png" />
            <el-input class="input"
                      placeholder="请输入持证人姓名"
                      v-model="dataList.name"></el-input>
          </el-form-item> -->
          <el-form-item size="large">
            <el-button @click="onSubmit('dataList')"
                       type="primary">确定
            </el-button>
            <el-button @click="goBack">取消</el-button>
          </el-form-item>
        </el-form>
      </div>

        <div class="footer"  v-if="textshow==false&&$route.query.type=='managerCertificate'">
        <div class="footer_top">
          1.本系统仅提供2013-2018年深圳市核发的生产经营单位主要负责人和安全管理人员培训合格证信息查询。
        </div>
         <div class="footer_bottom" style="margin-left:0">
          2.持有本系统历史证书的请及时通过换证系统办理申请领取新版证书。
        </div>
      </div>
        <div class="footer"  v-if="textshow==false&&$route.query.type=='history'">
        <div class="footer_top">
          1.本系统仅提供2015年以前的深圳市核发的初级、中级、高级安全主任证，主要生产经营管理者证信息查询。
        </div>
         <div class="footer_bottom" style="margin-left:0">
         2.持有本系统历史证书的请及时通过换证系统办理申请领取新版证书
        </div>
      </div>
    <div class="footer"  v-if="textshow==true&&$route.query.type=='certificate'">
        <div class="footer_top">
         本系统提供深圳市锂电池生产加工和储存、粉尘涉爆、涉氨制冷、非高危生产经营单位的主要负责人、分管安全生产负责人、安全总监、安全生产管理人员安全生产知识和管理能力考核合格证书信息查询，如有疑问请与23481891联系
        </div>

      </div>
       <div class="footer" v-if="textshow == true&&$route.query.type!='certificate'">
        <!-- <div class="footer_top"><span>注 :</span>
          <span>1.请使用IE8.0及以上版本的浏览器；</span>
          <span>{{pxarkData.content}}</span></div>
        <div class="footer_bottom"><span>3.安全生产考试咨询电话23481891。</span></div> -->
        <div class="footer_top">注 : 1.请使用IE8.0及以上版本的浏览器；{{pxarkData.content}}</div>
        <div class="footer_bottom">3.安全生产考试咨询电话23481891。</div>
      </div>
      <div class="footer" v-if="textshow == false&&$route.query.type!='managerCertificate'&&$route.query.type!='history'">
        <!-- <div class="footer_top"><span>注 :</span>
          <span>1.请使用IE8.0及以上版本的浏览器；</span>
          <span>{{pxarkData.content}}</span></div>
        <div class="footer_bottom"><span>3.安全生产考试咨询电话23481891。</span></div> -->
        <div class="footer_top">注 : 1.请使用谷歌、360、IE8.0及以上版本的最新浏览器；<span>2.若无法查询或打印请咨询所属报名的培训机构领取准考证；</span></div>
        <div class="footer_bottom">3.安全生产考试咨询电话：<a style="color: #999999;" href="tel:0755-23481891">0755-23481891。</a></div>

      </div>
    </div>
    <div class="loginContent"
         style="margin-bottom: 20px"
         v-else>

      <div class="content">
        <div class="title">
          <span>{{title}}</span>
        </div>
        <div v-if="$route.query.type=='tzzy2'" class="del-tips">
          请以考试通知短信内容为准，或联系相关的培训机构查询
        </div>
        <el-form :label-position="labelPosition" v-if="$route.query.type!='tzzy2'"
                 :model="dataList"
                 :rules="rules"
                 label-width="100px"
                 ref="dataList"
                 size="mini"
                 hide-required-asterisk>
          <!-- <el-form-item label="作业类型" prop="region">
                        <img alt src="~@/image/xxsr_icon1.png"/>
                        <el-select class="select input" placeholder="请选择作业类型" v-model="dataList.region">
                            <el-option
                                :key="index"
                                :label="item.dataValue"
                                :value="item.dataCode"
                                v-for="(item, index) in workType"
                            ></el-option>
                        </el-select>
                        <img alt="" class="img_bot" src="~@/image/dengl_xl.png">
                    </el-form-item>
                    <el-form-item label="考试项目"
                                  prop="date1">
                        <img alt
                             src="~@/image/xxsr_icon2.png"/>
                        <el-select class="select input"
                                   placeholder="请选择考试项目"
                                   v-model="dataList.date1">
                            <el-option :key="index"
                                       :label="item.dataValue"
                                       :value="item.dataCode"
                                       v-for="(item, index) in examItem"></el-option>
                        </el-select>
                        <img alt=""
                             class="img_bot"
                             src="~@/image/dengl_xl.png">
                    </el-form-item> -->
          <el-form-item label="证件号码"
                        prop="idCard">
            <img alt
                 src="~@/image/xxsr_icon3.png" />
            <el-input class="input"
                      placeholder="请输入证件号码"
                      v-model="dataList.idCard"></el-input>
          </el-form-item>
          <el-form-item  prop="name">
                <span slot="label">
                  <div v-html="'姓&emsp;&emsp;名'"></div>
                </span>
            <img alt
                 src="~@/image/xxsr_icon4.png" />
            <el-input class="input"
                      placeholder="请输入姓名"
                      v-model="dataList.name"></el-input>
          </el-form-item>
          <el-form-item size="large">
            <el-button @click="onSubmit('dataList')"
                       type="primary">确定
            </el-button>
            <el-button @click="goBack">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- <div class="content" style="    font-size: 25px;
    height: 350px;
    line-height: 300px;
    width:800px;
    padding: 0;
    margin: 0;" >
        当前系统维护中，请耐心等候……
      </div> -->
      <div class="footer" v-if="textshow==true">
        <!-- <div class="footer_top"><span>注 :</span>
          <span>1.请使用IE8.0及以上版本的浏览器；</span>
          <span>{{pxarkData.content}}</span></div>
        <div class="footer_bottom"><span>3.安全生产考试咨询电话23481891。</span></div> -->
        <div class="footer_top">注 : 1.请使用谷歌、360、IE8.0及以上版本的最新浏览器；{{pxarkData.content}}</div>
        <div class="footer_bottom">3.若对实操成绩有异议，请先联系所属培训机构填表申请；4.安全生产考试咨询电话<a style="color: #999999;" href="tel:0755-23481891">0755-23481891</a>；</div>
      </div>

      <div class="footer" v-if="textshow==false">
        <!-- <div class="footer_top"><span>注 :</span>
          <span>1.请使用IE8.0及以上版本的浏览器；</span>
          <span>{{pxarkData.content}}</span></div>
        <div class="footer_bottom"><span>3.安全生产考试咨询电话23481891。</span></div> -->
        <div class="footer_top">注 : 1.请使用IE8.0及以上版本的浏览器；<span>2.安全生产考试咨询电话23481891。</span></div>

      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data () {
    return {


      textshow:false,
      dd: false,
      labelPosition: "left",
      commentType: this.$route.query.type,
      dataList: {
        name: "",
        region: "",
        date1: "",
        idCard: "",
        delivery: false,
        code: ""
      },
      rules: {
        region: [
          {
            message: "报考行业不能为空",
            trigger: ["blur", "change"]
          }
        ],
        date1: [
          {
            message: "证件类型不能为空",
            trigger: ["blur", "change"]
          }
        ],
        name: [
          { message: "考生姓名不能为空", trigger: "blur", required: true }
        ],
        idCard: [
          { message: "证件号码不能为空", trigger: "blur", required: true }
        ]
      },
      industryType: [],
      pxark: [
        {
          type: "score",
          content: "2.仅供查询最近6个月以内考试成绩；",
        },
        // {
        //     type: "ticket",
        //     content: "2.打印准考证前请确认已完成考试预约；",
        // },
        {
          type: "certificate",
          content: "2.仅供查询深圳市生产经营单位主要负责人等四类从业人员考核合格证书；",
        },
        {
          type: "tzzy1",
          content: "2.仅供查询最近6个月以内考试成绩；",
        },
        {
          type: "tzzy2",
          content: "2.仅供查询和打印特种作业实操考试准考证",
        },
        {
          type: "tzzy3",
          content: "2.打印准考证前请确认已完成考试预约；",
        }
      ],
      workType: [],
      examItem: []
    };
  },
  methods: {
    goBack () {
      this.$router.go(-1);
    },
    back_home () {
      this.$router.push("/xxfww/home");
    },
    init () {
      this.getIndustryType();
      this.getWorkType();
      this.getExamItem();
    },
    onSubmit (dataList) {
            debugger
      this.$refs[dataList].validate((valid) => {
        if (valid) {
          let formData = {
            SERVICE_CODE: "szsti.examManage.QueryManage.queryZkOrScoreInfo",
            CONSUMER_ID: "system-27708245f08b4262a84ee4c8a3eba97c",
            type: this.$route.query.type,
            name: this.dataList.name,
            cardType: this.dataList.date1,
            industryType: this.dataList.region,
            idCard: this.aes.encrypt(this.dataList.idCard, "SZ[SAFE]dqaq.123").replace(/%/g, "%25").replace(/[+]/g, "%2B")
            // idCard: this.dataList.idCard
          };
          let routePath,url,submitForm=null;
          switch (this.$route.query.type){
            case "score":
              console.log(1)
              routePath="/xxfww/schoolReport";
              url=this.url;
              submitForm = formData;
              submitForm.type="externalScore"
              break;
            case "ticket":
              console.log(2)

              routePath="/xxfww/details";
              url=this.url;
              submitForm = formData;
              break;
            case "certificate":
              routePath="/xxfww/certificateLook";
              url=this.url;
              submitForm = formData;
              submitForm.type = "externalCertificate"
              break;
            case "tzzy1":
              routePath="/xxfww/schoolReportOfTzzy";
              url= this.tzzyUrl + "DataBus/externalInterface/inquireGrade.shtml";
              submitForm = new FormData();
              submitForm.append("name", formData.name);
              // submitForm.append("operationType", formData.cardType);
              submitForm.append("idCard", formData.idCard);
              // submitForm.append("examItem", formData.cardType);
              break;
            case "tzzy2":
              console.log(5)
              routePath= "/xxfww/detailsOfTzzy",
              url= this.tzzyUrl + "DataBus/externalInterface/admissionTicket.shtml";
              submitForm = new FormData();
              submitForm.append("name", formData.name);
              submitForm.append("operationType", "001");
              submitForm.append("idCard", formData.idCard);
              submitForm.append("examItem", formData.cardType);
              break;
             case "tzzy3":
               console.log(6)
              routePath= "/xxfww/certificateTz",
              url= this.url;
              submitForm = formData;
              break;
            case "examinationScore": //理论考试成绩查询
              console.log(this.url)
              routePath= "/xxfww/examinationScore",
              url= this.url;
              submitForm = formData;
               submitForm.type = "externalImportScore"
              break;

              case "history": //理论考试成绩查询
              routePath= "/xxfww/historyCertify",
              url= this.url;
              submitForm = formData;
               submitForm.type = "laoanjian"
               submitForm.idNo =  submitForm.idCard
               delete submitForm.idCard
              break;

             case "managerCertificate": //理论考试成绩查询
              routePath= "/xxfww/managerCertificate",
              url= this.url;
              submitForm = formData;
               submitForm.type = "query13to18"
               submitForm.idNo =  submitForm.idCard
               delete submitForm.idCard
              break;
          }
          axios({
              method: "post",
             url: url,
              headers: {
                'content-type': "multipart/form-data"
              },
              data: submitForm
            })
            .then(res => {
              console.log(res)
                if(this.$route.query.type=="tzzy1"||this.$route.query.type=="tzzy2"){
                if(res.data.status == 500){
                  this.$message.error("暂无数据")
                  return false;
                }
                sessionStorage.setItem("checkResult",JSON.stringify(res.data.data));
              }else{
                if(res.data.BODY.data.list==null ||res.data.BODY.data.list.length==0){
                  this.$message.error("暂无数据")
                  return false;
                }
                sessionStorage.setItem("checkResult",JSON.stringify(res.data.BODY.data.list));
              }
              this.$router.push({path:routePath});
            })
            return false
          // 成绩查询
          if (this.$route.query.type === "score") {
            this.$router.push({
              path: "/xxfww/schoolReport",
              query: formData
            });
            // 准考证打印
          } else if (this.$route.query.type === "ticket") {
            this.$router.push({
              path: "/xxfww/details",
              query: formData
            });
            // 证书打印
          } else if (this.$route.query.type === "certificate") {
            this.$router.push({
              path: "/xxfww/certificateLook",
              query: formData
            });
          } else if (this.$route.query.type === "tzzy1") {
            this.$router.push({
              path: "/xxfww/schoolReportOfTzzy",
              query: formData
            });
          } else if (this.$route.query.type === "tzzy2") {
            this.$router.push({
              path: "/xxfww/detailsOfTzzy",
              query: formData
            });
          } else if (this.$route.query.type === "tzzy3") {
            this.$router.push({
              path: "/xxfww/certificateTz",
              query: formData
            });
          }else if (this.$route.query.type == "examinationScore"){
            this.$router.push({
              path:"/xxfww/examinationScore",
              query: formData
            })
          }
        } else {
          return this.$message({
            type: "warning",
            message: "请填写所有必填项",
          });
        }
      });
    },
    getIndustryType () {
      this.common
        .ajax({
          method: "post",
          url: this.url,
          headers: {
            "content-type": "multipart/form-data"
          },
          data: {
            CONSUMER_ID: "system-27708245f08b4262a84ee4c8a3eba97c",
            SERVICE_CODE: "szsti.system.DataCode.findDataCodeByParentCode",
            parentCode: "zige_type"
          }
        })
        .then(res => {
          if (res.data.BODY.success === "true") {
            this.industryType = res.data.BODY.data;
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    getExamItem () {
      this.common
        .ajax({
          method: "post",
          url: this.tzzyUrl + "DataBus/exchange",
          headers: {
            "content-type": "multipart/form-data"
          },
          data: {
            CONSUMER_ID: "system-27708245f08b4262a84ee4c8a3eba97c",
            SERVICE_CODE: "szsti.system.DataCode.findDataCodeByParentCode",
            parentCode: "xmmc"
          }
        })
        .then(res => {
          this.examItem = res.data.BODY.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    getWorkType () {
      this.common
        .ajax({
          method: "post",
          url: this.tzzyUrl + "DataBus/exchange",
          headers: {
            "content-type": "multipart/form-data"
          },
          data: {
            CONSUMER_ID: "system-27708245f08b4262a84ee4c8a3eba97c",
            SERVICE_CODE: "szsti.system.DataCode.findDataCodeByParentCode",
            parentCode: "dgzy"
          }
        })
        .then(res => {
          this.workType = res.data.BODY.data;
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
  computed: {
    isTzzy () {
      return this.$route.query.type.startsWith("tzzy");
    },
    title () {
      let title = "";
      switch (this.$route.query.type) {
        case "ticket":
          title = "理论考试准考证打印";
          break;
        case "certificate":
          title = "深圳市安全生产知识和管理能力考核合格证信息查询系统";
          break;
        case "score":
          title = "深圳市生产经营单位主要负责人等四类从业人员考试成绩查询";
          break;
        case "tzzy1":
          title = "实操考试成绩查询";
          break;
        case "tzzy2":
          title = "实操考试准考证打印";
          break;
        case "tzzy3":
          title = "实操考试证书查询";
          break;
          case "examinationScore":
          title = "理论考试成绩查询"
          break;
        case "managerCertificate":
          title = "深圳市生产经营单位主要负责人和安全管理人员培训合格证信息查询系统"
          break;
          case "history":
          title = "深圳市历史证书信息查询系统"
          break;

      }
      return title;
    },
    pxarkData () {
      let content = {};
      console.log(this.pxark,'this.pxark');

      this.pxark.forEach(element => {
        if (this.$route.query.type === element.type) {
          content = element;
          console.log(content, "content");
          this.textshow = true
        }
      });
      return content;
    }
  },
  filters:{
    subTitleFilter(v){
      switch (v){
        case "score":
        return "成绩查询"
         case "tzzy1":
        return "成绩查询"
         case "ticket":
        return "准考证打印"
         case "tzzy2":
        return "准考证打印"
          case "certificate":
        return "证书查询"
          case "managerCertificate":
        return "证书查询"
          case "history":
        return "证书查询"
        break;
        case "examinationScore":
        return "成绩查询"
        break;
      }
    }
  },
  mounted () {
    this.init();
    if (this.$route.query.type == "ticket") {
      this.dd = true
    }
    let type = this.$route.query.type
    if(type =="history" ||type =="managerCertificate"||type =="certificate"){
      this.rules.name[0].message = "持证人姓名不能为空"
    }
    // this.pxarkData()

  }
};
</script>
<style lang="scss" scoped>
.del-tips{
  font-size: 20px;
  line-height: 200px;
}
.let-trainingInstitution-nav {
  color: #3e97df;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-align: left;
  padding-left: 20px;
}

.loginContent {
  width: 100%;
  height: 500px;
  margin: 20px auto 20px;
  background-image: url(~@/image/srxx_bg.png);
  background-size: 100%;
  font-family: Microsoft YaHei;
  font-weight: 400;

  .title {
    font-family: Microsoft YaHei;
    font-weight: bold;
    margin-right: 14%;
    margin-bottom: 5%;
    span {
      color: #3e97df;
      font-size: 26px;
      display: inline-block;
    }
  }

  .content {
    width: 546px;
    padding-left: 150px;

    .content_subheading {
      font-size: 18px;
      margin-bottom: 20px;
      color: #c0c4d5;
      font-weight: normal;
    }

    .el-form {
      .el-form-item,
      .el-form-item--mini {
        margin-bottom: 20px;
        line-height: 46px;

        /deep/ .el-form-item__label {
          line-height: 46px;
          color: #333333;
          font-size: 20px;
        }

        /deep/ .el-form-item__content {
          position: relative;
          line-height: 46px;

          img {
            position: absolute;
            margin-left: -165px;
            margin-top: 13px;
          }

          .img_bot {
            margin-left: -36px;
            margin-top: 20px;
          }

          .el-button {
            width: 192px;
            height: 46px;
            border-radius: 23px;
          }

          .el-input--mini,
          .el-input--suffix,
          .el-select--mini {
            width: 400px;

            /deep/ .el-input__inner {
              height: 46px;
              line-height: 46px;
              background-color: #f2f9ff;
              border-radius: 23px;
              border: none;
              font-size: 18px;
              color: #999999;
            }

            /deep/ .el-input__suffix {
              display: none;
            }
          }

          /deep/ .el-form-item__error {
            left: 17px;
          }
        }
      }

      .validateVcode {
        position: absolute;
        top: 0;
        padding-left: 60%;

        span {
          font-size: 12px;
          color: #666666;
          padding: 4px;
        }
      }
    }
  }
}

.footer {
  font-size: 16px;
  color: #999999;
  //   display: flex;
  margin-left: 9%;
  //   flex-direction: column;
  text-align: left;
  margin-top: 3.5%;
  padding-right: 40px;
  .footer_top {
    display: flex;
  }
  .footer_bottom {
    display: flex;
    margin-left: 2.7%;
    margin-bottom: 20px;
  }
}
</style>
