<template>
    <el-row class="sign-up" :gutter="20" v-if="type===2">
        <el-col :span="12" :offset="6" class="singContent">
            <div class="grid-content">
            <div class="tips_title">
                <img src="../../image/glhtdl_icon2.png"/>
            </div>
                <span>修改手机号码</span>
            </div>
            <div class="page">
                <el-form ref="newData" :model="newData" label-width="180px">
                    <span>更换手机后,下次登录可使用新手机登录</span>
                    <el-form-item label="旧手机号码：">
                        <span>{{mobile}}</span>
                    </el-form-item>
                    <el-form-item label="新手机号码：">
                        <el-input v-model="newData.mobile"></el-input>
                    </el-form-item>
                    <el-form-item label="验证码：">
                        <el-input v-model="newData.code"></el-input>
                        <el-button type="success" @click="sendCode">获取验证码</el-button>
                    </el-form-item>
                </el-form>
                <el-col :span="12" :offset="6">

                </el-col>
                <el-button type="primary" @click="submitMobile">确认</el-button>
            </div>
        </el-col>
        <el-button type="primary" class="return-page" @click="returnPage">返回</el-button>
    </el-row>
    <el-row class="sign-up" :gutter="20" v-else>
        <el-col :span="12" :offset="6" class="singContent">
            <div class="grid-content-user">
                <span class="tips_title">
                    <img src="../../image/glhtdl_icon2.png"/>
                </span>
                <span>用户资料</span>
                <el-col :span="13" :offset="4">
                    <div class="userinfo-inner">
                        <el-upload
                            :before-upload="beforeUpload"
                            :show-file-list="false"
                            action
                            class="avatar-uploader"
                            style="border-radius: 50%;"
                        >
                            <img :src="uploadImageUrl" class="avatar" v-if="uploadImageUrl"/>
                            <img :src="this.sysUserAvatar" v-else/>
                        </el-upload>
                    </div>
                </el-col>

            </div>
            <div class="page">
                <el-form ref="userData" :model="userData" :rules="userDataRules" label-width="280px">
                    <el-form-item label="手机号码：" required>
                        <el-input v-model="mobile"></el-input>
                        <span class="edit-btn" @click="edit">修改<i class="el-icon-edit"></i></span>
                    </el-form-item>
                    <el-form-item label="姓名：" prop="realName" required>
                        <el-input v-model="userData.realName"></el-input>
                    </el-form-item>
                    <el-form-item label="性别："  prop="sexName" required>
                        <el-radio-group v-model="userData.sexName">
                            <el-radio label="男"></el-radio>
                            <el-radio label="女"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="身份证号：" prop="idCard" required>
                        <el-input v-model="userData.idCard"></el-input>
                    </el-form-item>
                    <el-form-item label="密码：">
                        <el-input type="password" v-model="userData.password"></el-input>
                    </el-form-item>
                    <el-form-item label="联系地址：">
                        <el-input v-model="userData.address"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submit">提交</el-button>
                        <el-button type="info" @click="homepage">返回</el-button>
                    </el-form-item>
                </el-form>

            </div>

        </el-col>
<!--        <el-button type="primary" class="return-page" @click="retun_page">返回</el-button>-->
    </el-row>
</template>
<script>
    export default {
        data() {
            return {
                type: 1,
                mobile: null,
                newData: {
                    "CONSUMER_ID": sessionStorage.getItem("Token"),
                    "SERVICE_CODE": "szsti.system.InfoExamineeManage.changeMobile",
                    "mobile": null,
                    "code": null
                },
                userData: {
                    "CONSUMER_ID": sessionStorage.getItem("Token"),
                    "SERVICE_CODE": "szsti.system.InfoExamineeManage.changeUserInfo",
                    "realName": null,
                    "sex": null,
                    "sexName": null,
                    "idCard": null,
                    "password": null,
                    "address": null,
                    "userPic": null
                },
                sysUserAvatar: require('@/image/exit.png'),
                userDataRules: {
                    realName: [
                        { required: true, message: '请输入姓名', trigger: 'blur' }
                    ],
                    sexName: [
                        { required: true, message: '请选择性别', trigger: 'blur' }
                    ],
                    idCard: [
                        { required: true, message: '请输入身份证号码', trigger: 'blur' }
                    ],
                },
                uploadImageUrl: null
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            homepage() {
                this.$router.go(-1);
            },
            beforeUpload(file) {
                console.log(file, "上传文件");
                console.log(this.imageUrl, "上传图片");
                let formData = new FormData();
                formData.append("CONSUMER_ID", sessionStorage.getItem("Token"));
                formData.append("type", "考生信息");
                formData.append("file", file);
                this.axios({
                    method: "post",
                    url: this.imageUrl,
                    headers: {
                        "content-type": "multipart/form-data"
                    },
                    data: formData
                })
                    .then(res => {
                        console.log(res, "上传图片");
                        if (res.data.success) {
                            this.userData.userPic = res.data.data;
                            this.uploadImageUrl = this.resourceUrl + this.userData.userPic;
                        } else {
                            this.$message({
                                type: "error",
                                message: "上传失败"
                            });
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
                return false;
            },
            resetForm() {
                this.$refs["userData"].resetFields();
            },
            sendCode() {
                try {
                    let mobilePattern = /^1[3-9]\d{9}$/;
                    if (!this.newData.mobile
                        || !mobilePattern.test(this.newData.mobile)) {
                        throw Error("请输入正确手机号码");
                    }
                    this.common
                        .ajax({
                            method: "post",
                            url: this.url,
                            headers: {
                                "content-type": "multipart/form-data"
                            },
                            data: {
                                "CONSUMER_ID": "system-654ec1b21c39446f9cc64663f071c2c8",
                                "SERVICE_CODE": "szsti.system.SmsManage.sendCode",
                                "type": "3",
                                "mobile": this.newData.mobile
                            }
                        })
                        .then(res => {
                            console.log(res, "发送验证码");
                            if (res.data.SYS_HEAD.RET_STATUS === "S"
                                && res.data.BODY.success === 'true') {
                                this.$message({
                                    type: "success",
                                    message: "验证码已发送"
                                });
                            } else {
                                this.$message({
                                    type: "error",
                                    message: "验证码发送失败"
                                });
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        });
                } catch (e) {
                    this.$message({
                        type: "warning",
                        message: e.message
                    });
                }
            },
            submitMobile() {
                this.common
                    .ajax({
                        method: "post",
                        url: this.url,
                        headers: {
                            "content-type": "multipart/form-data"
                        },
                        data: this.newData
                    })
                    .then(res => {
                        console.log(res, "修改手机号码");
                        if (res.data.SYS_HEAD.RET_STATUS === "S"
                            && res.data.BODY.success === 'true') {
                            this.$message({
                                type: "success",
                                message: "修改成功"
                            });
                            let userInfoStr = sessionStorage.getItem("userInfo");
                            let userInfo = JSON.parse(userInfoStr);
                            userInfo.mobilePhone = this.mobile = this.newData.mobile;
                            sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
                            this.type = 1;
                        } else {
                            this.$message({
                                type: "error",
                                message: "修改失败"
                            });
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },
            submit() {
                this.$refs["userData"].validate((valid) => {
                    if (valid) {
                        if (this.userData.sexName === '男') {
                            this.userData.sex = "1";
                        } else {
                            this.userData.sex = "0";
                        }
                        if (this.userData.password) {
                            this.userData.password = this.aes.encrypt(this.userData.password, "SZ[SAFE]dqaq.123").replace(/%/g, "%25").replace(/[+]/g, "%2B");
                        }
                        this.common
                            .ajax({
                                method: "post",
                                url: this.url,
                                headers: {
                                    "content-type": "multipart/form-data"
                                },
                                data: this.userData
                            })
                            .then(res => {
                                console.log(res, "修改用户信息");
                                if (res.data.SYS_HEAD.RET_STATUS === "S"
                                    && res.data.BODY.success === 'true') {
                                    this.$message({
                                        type: "success",
                                        message: "修改成功"
                                    });
                                    // 标记用户信息为完整
                                    sessionStorage.setItem("isComplete", "true");
                                    let json = sessionStorage.getItem("userInfo");
                                    let userInfo = JSON.parse(json);
                                    userInfo.realName = this.userData.realName;
                                    userInfo.sex = this.userData.sex;
                                    userInfo.idCard = this.userData.idCard;
                                    userInfo.address = this.userData.address;
                                    userInfo.userPic = this.userData.userPic;
                                    sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
                                    // 跳转到历史记录的页面
                                    let toRoute = localStorage.getItem("toRoute");
                                    if (!toRoute) {
                                        toRoute = '/xxfww/home';
                                    }
                                    setTimeout(() => {
                                        this.$router.push({path: toRoute});
                                    }, 1000);
                                } else {
                                    let message = res.data.BODY.message ? res.data.BODY.message : "修改失败";
                                    this.$message({
                                        type: "error",
                                        message: message
                                    });
                                }
                                this.resetForm();
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            init() {
                let userInfoStr = sessionStorage.getItem("userInfo");
                let userInfo;
                if (userInfoStr) {
                    userInfo = JSON.parse(userInfoStr);
                    console.log(userInfo, 'userInfo');
                    this.mobile = userInfo.mobilePhone;
                    this.userData.realName = userInfo.realName;
                    this.userData.sex = userInfo.sex;
                    if (userInfo.sex === "1") {
                        this.userData.sexName = "男";
                    } else {
                        this.userData.sexName = "女";
                    }
                    this.userData.idCard = userInfo.idCard;
                    this.userData.address = userInfo.address;
                    this.userData.userPic = userInfo.userPic;
                    if (userInfo.userPic) {
                        this.uploadImageUrl = this.resourceUrl + userInfo.userPic;
                    }
                }
                console.log(this.userData, 'userData');
            },
            edit() {
                this.type = 2;
            },
            returnPage() {
                this.type = 1;
            }
        },
    }
</script>
<style lang="stylus" scoped>
    .userinfo-inner .avatar {
        width 150px;
        border-radius 50%;
        height 150px;
    }

    .sign-up {
        height: 100%;
        margin-bottom: 20px;
        background-color: #F1F1EB;

        .singContent {

            .page {
                position: relative
                padding: 20px 10px;
                background-color: #ffffff;
                box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);

                .el-table {
                    margin-bottom: 10px
                }

                /deep/ .el-form-item__content {
                    text-align: left
                }

                /deep/ .el-input {
                    width: 200px
                }

                .edit-btn {
                    margin-left: 10px;
                    color: #3E97DF
                }
            }
        }

        .return-page {
            position: absolute;
            right: 30px;
            bottom: 30px;
            width: 150px;
            height: 60px
        }
    }

    .grid-content {
        margin-top: 200px;
        background-color: #3E97DF;
        font-size: 18px;
        font-weight: bold;
        font-family: Microsoft YaHei;
        color: #ffffff;
        padding: 10px;
        min-height: 26px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        img {
            margin-left: 0;
            height: 45%;
        }

        span {
            margin: 0 10px;
        }

        .link_more span:link {
            color: #3E97DF;
        }

        .link_more span:hover {
            color: #3E97DF;
        }

        .link_more span:active {
            color: #3E97DF;
        }
    }

    .grid-content_title {
        padding: 10px 0;
        text-indent: 2em;
        font-size: 18px;
        word-break: break-all;
        text-align: left;
        border-radius: 4px;
        border: 1px solid #ccc;
        color: #878d99;

        .tips_title {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #44A8F8;
        }
    }

    .grid-content-user {
        margin-top: 200px;
        background-color: #ffffff;
        font-size: 18px;
        font-weight: bold;
        font-family: Microsoft YaHei;
        color: #3E97DF;
        padding: 10px;
        min-height: 26px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        position: relative

        .userinfo-inner {
            position: absolute;
            top: -111px
        }

    }
</style>
