<template>
    <div id="content" class="preRegisteredExamination-homePage">
        <el-row class="sign-up-title">
            <el-col :span="24">
                <div class="grid-content_title">
                    <span class="tips_title">
                        <img src="../../../image/wxts1.png" alt />
                        温馨提示：
                    </span>
                    <span>未在规定时间内完成考试预约，过期预约将关闭。每个用户一年限定三次撤销机会。预约成功后请及时打印准考证参加考试，未考试视失信，将限制下次报考机会。</span>
                </div>
            </el-col>
        </el-row>
        <el-row class="sign-up">
            <el-col :span="24" class="singContent">
                <div class="grid-content">
                    <img src="../../../image/ksbmsy_icon1.png" />
                    <span>可预约列表</span>
                </div>
                <div class="page">
                    <el-table
                        :data="tableData1.list"
                        class="inner"
                        style="width: 100%;"
                        tooltip-effect="dark">
                        <el-table-column align="center" label="序号" type="index" width= "50"></el-table-column>
                        <el-table-column align="center" label="考试计划" min-width="200" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span>{{scope.row.playName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="考试时间" prop="examInterval"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="预约时间" prop="apInterval"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="快速操作">
                            <template slot-scope="scope">
                                <template v-if="scope.row.status===2">
                                    <el-button @click="applicant(scope.row)" size="mini" type="primary">点击预约</el-button>
                                </template>
                                <template v-else-if="scope.row.status===1">
                                    预约已结束
                                </template>
                                <template v-else>
                                    未开始预约
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination :current-page.sync="searchData1.currPage"
                                   :page-size="searchData1.pageSize"
                                   :total="tableData1.totals"
                                   @current-change="getList1"
                                   class="page-class">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
        <el-row class="sign-up">
            <el-col :span="24" class="singContent">
                <div class="grid-content">
                    <img src="../../../image/ksbmsy_icon1.png" />
                    <span>预约结果</span>
                </div>
                <div class="page">
                    <el-table :data="tableData2.list" class="inner" style="width: 100%;" tooltip-effect="dark">
                        <el-table-column align="center" label="序号" type="index" width="50"></el-table-column>
                        <el-table-column align="center" label="预约事项" min-width="200" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span>{{ scope.row.playName }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="考试时间" prop="examInterval" show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="预约起止时间" prop="apInterval" show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="状态" prop="statusName" show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="快速操作">
                            <template slot-scope="scope">
                                <template v-if="scope.row.status===1">
                                    <el-button
                                        @click="revocation(scope.row)"
                                        size="mini"
                                        style="margin-left:10px"
                                        type="danger"
                                    >撤销
                                    </el-button>
                                </template>
                                <el-button
                                    @click="look(scope.row)"
                                    size="mini"
                                    style="margin-left:10px; margin-top: 10px;"
                                    type="primary"
                                >查看预约
                                </el-button>
                                <br />
                                <el-button
                                    @click="printCert(scope.row)"
                                    size="mini"
                                    style="margin-left:10px; margin-top: 10px;"
                                    type="info"
                                >打印准考证
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        :current-page.sync="searchData1.currPage"
                        :page-size="searchData1.pageSize"
                        :total="tableData1.totals"
                        @current-change="getList1"
                        class="page-class"
                    ></el-pagination>
                </div>
                <!--                查看详情-->
                <el-dialog
                    :close-on-click-modal="false"
                    title="查看详情"
                    :visible.sync="showDetail"
                    width="50%"
                    @closed="afterCloseDetail"
                >
                    <p>预约ID：{{detail.appointmentId}}</p>
                    <p>考试计划名称：{{detail.playName}}</p>
                    <p>考试时间：{{detail.examInterval}}</p>
                    <p>预约时间：{{detail.apInterval}}</p>
                    <p>预约考点：{{detail.area + '#' + detail.roomName}}</p>
                    <p>状态：{{detail.statusName}}</p>
                </el-dialog>
            </el-col>
        </el-row>
        <el-row class="sign-up">
            <el-col :span="24" class="singContent">
                <div class="grid-content">
                    <img src="../../../image/ksbmsy_icon3.png" />
                    <span>问题解答</span>
                </div>
                <div class="page">
                    <el-table :data="tableData3.list" class="inner" style="width: 100%;" tooltip-effect="dark">
                        <el-table-column align="center" label="序号" prop="id" show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="问题描述" prop="question" show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="发布时间" prop="pulishTime" show-overflow-tooltip></el-table-column>
                    </el-table>
                    <el-pagination
                        :current-page.sync="searchData3.currPage"
                        :page-size="searchData3.pageSize"
                        :total="tableData3.totals"
                        @current-change="getList3"
                        class="page-class"
                    ></el-pagination>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showDetail: false,
            tableData1: {
                list: [],
                totals: 0
            },
            tableData2: {
                list: [],
                totals: 0
            },
            tableData3: {
                list: [],
                totals: 0
            },
            searchData1: {
                CONSUMER_ID: sessionStorage.getItem('Token'),
                SERVICE_CODE: 'szsti.examManage.AppointmentManage.getRegisterList',
                examineeId: sessionStorage.getItem('examineeId'),
                currPage: 1,
                pageSize: 5
            },
            searchData2: {
                CONSUMER_ID: sessionStorage.getItem('Token'),
                SERVICE_CODE: 'szsti.examManage.AppointmentManage.getAppointmentList',
                examineeId: sessionStorage.getItem('examineeId'),
                status: null,
                currPage: 1,
                pageSize: 5
            },
            searchData3: {
                SERVICE_CODE: 'szsti.ExamineeEnd.SysQuestionAnswer.getSysQuestionAnswerList',
                CONSUMER_ID: sessionStorage.getItem('Token'),
                pageSize: 5,
                currPage: 1
            },
            detail: {}
        };
    },
    created() {
        this.init();
    },
    methods: {
        // 打印准考证
        printCert(row) {
            this.$router.push({
                path: "/xxfww/details",
                query: {
                    SERVICE_CODE: "szsti.examManage.QueryManage.queryZkOrScoreInfo",
                    CONSUMER_ID: "system-27708245f08b4262a84ee4c8a3eba97c",
                    type: "ticket",
                    apId: row.appointmentId
                }
            });
        },
        afterCloseDetail() {
            this.detail = {};
        },
        // 列表初始化
        init() {
            this.getList1();
            this.getList2();
            this.getList3();
        },
        getList1() {
            this.common
                .ajax({
                    method: 'post',
                    url: this.url,
                    headers: {
                        'content-type': 'multipart/form-data'
                    },
                    data: this.searchData1
                })
                .then(res => {
                    if (res.data.BODY.success === 'true') {
                        this.tableData1.totals = res.data.BODY.data.pageInfo.totalRow;
                        this.tableData1.list = res.data.BODY.data.list;
                        this.tableData1.list.forEach(item => {
                            item.examInterval = item.startPlanTime + ' ~ ' + item.endPlanTime;
                            item.apInterval = item.apStartTime + ' ~ ' + item.apEndTime;
                            let startAp = new Date(item.apStartTime);
                            let endAp = new Date(item.apEndTime);
                            let now = new Date();
                            if (now < startAp) {
                                item.status = 0;
                            } else if (now > endAp) {
                                item.status = 1;
                            } else {
                                item.status = 2;
                            }
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getList2() {
            this.common
                .ajax({
                    method: 'post',
                    url: this.url,
                    headers: {
                        'content-type': 'multipart/form-data'
                    },
                    data: this.searchData2
                })
                .then(res => {
                    if (res.data.BODY.success === 'true') {
                        this.tableData2.totals = res.data.BODY.data.totalRow;
                        this.tableData2.list = res.data.BODY.data.list;
                        this.tableData2.list.forEach(item => {
                            item.examInterval = item.startPlanTime + ' ~ ' + item.endPlanTime;
                            item.apInterval = item.orderDate + ' ' + item.orderTimeSlot;
                            if (item.status === 0) {
                                item.statusName = '已撤销';
                            } else if (item.status === 1) {
                                item.statusName = '预约成功';
                            } else {
                                item.statusName = '结束考试';
                            }
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getList3() {
            this.common
                .ajax({
                    method: 'post',
                    url: this.url,
                    headers: {
                        'content-type': 'multipart/form-data'
                    },
                    data: this.searchData3
                })
                .then(res => {
                    if (res.data.BODY.success === 'true') {
                        this.tableData3.totals = res.data.BODY.data.pageInfo.totalRow;
                        this.tableData3.list = res.data.BODY.data.list;
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        // 预约
        applicant(row) {
            console.log(row, 'row');
            this.$router.push({ name: '我要预约', query: row });
        },
        // 查看
        look(row) {
            this.detail = row;
            this.showDetail = true;
        },
        // 撤销
        revocation(row) {
            this.$confirm("确定撤销该预约记录?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.common
                        .ajax({
                            method: "post",
                            url: this.url,
                            headers: {
                                "content-type": "multipart/form-data"
                            },
                            data: {
                                CONSUMER_ID: sessionStorage.getItem("Token"),
                                SERVICE_CODE:
                                    "szsti.examManage.AppointmentManage.revokeAppointment",
                                appointmentId: row.appointmentId
                            }
                        })
                        .then(res => {
                            if (res.data.BODY.success === "true") {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!"
                                });
                                this.init();
                            } else {
                                this.$message({
                                    type: "error",
                                    message: "操作失败!"
                                });
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消操作"
                    });
                });
        },
    },
    filters: {
        filterStatus(val) {
            val = Number(val);
            switch (val) {
                case 1:
                    return '已审核';
                    break;
                case 2:
                    return '审核未通过';
                    break;
                case 3:
                    return '待审核';
                    break;
                default:
                    '无';
            }
        }
    }
};
</script>
<style lang="stylus" scoped>
.el-row
    margin-bottom 20px
    &:last-child
        margin-bottom 0
#content
    .sign-up
        margin-bottom 20px
        background-color #ffffff
        box-shadow 0px 2px 5px 0px rgba(0, 0, 0, 0.18)
        .singContent
            .page
                padding 20px 10px
                .el-table
                    margin-bottom 10px
    .sign-up-title
        margin-bottom 20px
        background rgba(255, 255, 255, 1)
        box-shadow 0px 0px 5px 0px rgba(218, 225, 229, 0.75)
        border-radius 4px
.el-col
    border-radius 4px
.bg-purple-dark
    background #99a9bf
.bg-purple
    background #d3dce6
.bg-purple-light
    background #e5e9f2
.bg-purple-dark
    background #99a9bf
.bg-purple
    background #d3dce6
.bg-purple-light
    background #e5e9f2
.grid-content
    background-color #3E97DF
    font-size 0.2rem
    font-weight bold
    font-family Source Han Sans CN
    color rgba(66, 71, 75, 1)
    padding 0.08rem 0.2rem
    min-height 26px
    display flex
    flex-flow row nowrap
    align-items center
    img
        margin-left 0
        height 45%
    span
        margin 0 10px
        color #ffffff
.grid-content_title
    padding 10px 14px
    text-indent 0.2rem
    font-size 18px
    word-break break-all
    text-align left
    border-radius 4px
    border 1px solid #ccc
    color #878d99
    .tips_title
        font-size 16px
        font-family Source Han Sans CN
        font-weight bold
        color #44A8F8
        line-height 24px
    span
        font-size 16px
.row-bg
    padding 10px 0
    background-color #f9fafc
</style>
<style lang="scss">
    .preRegisteredExamination-homePage {
        .el-table{
            tr  {
                background: rgba(62,151,223,.1);
            }
            th {
                .cell {
                    font-size: 0.18rem;
                    color: #333;
                    font-weight: bold;
                }
            }
            .el-table__row--striped {
                background: #fff;
                td {
                    background: #fff;
                }
            }
        }

    }
</style>
