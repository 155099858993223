import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import * as getters from './getters'

Vue.use(Vuex)

// 应用初始状态
const state = {
    count: 10,
    role:"",
    printerStatus:false, //默认打印状态为否
    examination: [],
    tabPage:'',
    option_data:{
        // 所有操作项目
        all_exam_item: [{dataCode: "", dataValue: "请选择操作项目"}],
        // 所有作业类型
        all_work_type: [{dataCode: "", dataValue: "请选择作业类型"}],
        // 培训方式
        px_type: [{ dataCode: "", dataValue: "请选择培训方式" }],
        // 培训类型
        train_type: [{ dataCode: "", dataValue: "请选择培训类型" }],
        // 资格类型
        zige_type: [{ dataCode: "", dataValue: "请选择资格类型" }],
        // 考试名称
        exam_type: [{ dataCode: "", dataValue: "请选择考试名称" }],
        // 年份
        year: [{dataCode: "", dataValue: "请选择年份" }],
        // 考核类别
        four_theory: [{ dataCode: "", dataValue: "请选择考核类别" }],
        // 行业类别
        industry_type: [{ dataCode: "", dataValue: "请选择行业类别" }],
        // 考试计划
        exam_plan: [{ dataCode: "", dataValue: "请选择行考试计划" }],
        //  政治面貌
        politics_status: [{ dataCode: "", dataValue: "请选择行政治面貌" },{ dataCode: "党员", dataValue: "党员" },{ dataCode: "群众", dataValue: "群众" }],
        // 最高学历
        academic:[{ dataCode: "", dataValue: "请选择行最高学历" },{ dataCode: "小学", dataValue: "小学" },{ dataCode: "初中", dataValue: "初中" }],
        // 培训类别
        training_classes:[{ dataCode: 0, dataValue: "企业自主培训" },{ dataCode: 1, dataValue: "培训机构培训" }],
        // 培训课程
        training_course:[{ dataCode: "", dataValue: "请选择培训课程" }],
        // 课程内容
        lesson_content:[{ dataCode: "", dataValue: "请选择培训课程" }],
        // 培训机构
        organization: [{ dataCode: "", dataValue: "请选择培训机构" }],
        // 民族
        nation: [{ dataCode: "", dataValue: "请选择民族" }],
        // 政治面貌
        political_type: [{ dataCode: "", dataValue: "请选择政治面貌" }],
        // 证件类型
        card_type: [{ dataCode: "", dataValue: "请选择证件类型" }],
        // 文化程度
        degree_culture: [{ dataCode: "", dataValue: "请选择最高学历" }],
        // 行政区域
        area: [{ dataCode: "", dataValue: "请选择行政区域" }],
        // 可报名考试计划
        available_plan: [{ dataCode: "", dataValue: "请选择考试计划" }],
         // 性别
        sex: [{ dataCode: "", dataValue: "请选择" },{ dataCode: "男", dataValue: "男" },{ dataCode: '女', dataValue: "女" }],
        question_category: []
    },
    systemData: {

    }
}

// 定义所需的 mutations
const mutations = {
    setLogin(state,val) {
        state.role = val
    },
    setExamination(state,val) {
      state.examination = val
    },
    DECREMENT(state) {

    },
    setOptionData(state,obj){
        if(state.option_data[obj.x].length-1!==obj.list){
            state.option_data[obj.x]=state.option_data[obj.x].concat(obj.list);
        }
    },
    setTabPage(state,val){

        state.tabPage = val
    },
    setPrinterStatus(state,val){
        state.printerStatus = val
    },
}

// 创建 store 实例
export default new Vuex.Store({
    actions,
    getters,
    state,
    mutations
})

