const recursive={
    search:function(obj, id, fuc,prop={children:'list'},namePath=[]) {
        for (let i = 0, len = obj.length; i < len; i++) {
            let item = obj[i]
            if (item.id === id) {
                namePath.push(item)
                item.expand = true
                return {
                    has: true,
                    namePath
                }
            } else if (item[prop.children] && item[prop.children].length) {
                namePath.push(item)
                let result = search(item[prop.children], id, prop={children:'list'})
                if (result.has) return {has: true, namePath}
                namePath.pop()
            }
        }
        return {
            has: false
        }
    
    },
    recursiveSet:function(datas,fuc,prop={list:'list'},pars={parent:{},child:[]}){ //遍历树 
        datas.forEach(row => {
            fuc(row,pars)
            if(row[prop.list].length>0){
                pars.parent={};
                pars.child=[]
                this.recursiveSet(row[prop.list],fuc,prop,pars={parent:row,child:row[prop.list]});
            }
        })
    }


}
export default recursive