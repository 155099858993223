<template>
  <div id="content" class="preRegisteredExamination-appointmentLook">
    <el-row class="sign-up-title">
      <el-col :span="24">
        <div class="grid-content_title">
          <span class="tips_title">
            <img src="../../../image/wxts1.png" alt /> 温馨提示：
          </span>
          <span>可以下方查看所提交换证申请事项的办理进度，显示“不通过”的可以点击“修改”后重新提交.</span>
        </div>
      </el-col>
    </el-row>
    <el-row class="sign-up">
      <el-col :span="24" class="singContent">
        <div class="grid-content">
          <img src="../../../image/jgcx.png" />
          <span>结果查询</span>
        </div>
        <div>
          <el-col :span="20" class="trainingInformation">
            <el-row>
              <el-col :span="12">
                <div class="content-con">
                  <img src="../../../image/jgcx1.png" alt />
                  <span class="con-title">提交时间：</span>
                  <span>{{information.applyTime}}</span>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="content-con">
                  <img src="../../../image/jgcx2.png" alt />
                  <span class="con-title">姓 名：</span>
                  <span>{{information.realName}}</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="content-con">
                  <img src="../../../image/jgcx3.png" alt />
                  <span class="con-title">证件号码：</span>
                  <span>{{information.oldIdCard}}</span>
                </div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <div class="content-con">
                  <img src="../../../image/jgcx4.png" alt />
                  <span class="con-title">手机号码：</span>
                  <span>{{information.mobile}}</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="content-con">
                  <img src="../../../image/jgcx5.png" alt />
                  <span class="con-title">单位全称：</span>
                  <span>{{information.workUnit}}</span>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="content-con">
                  <img src="../../../image/jgcx6.png" alt />
                  <span class="con-title">行业类别：</span>
                  <span>{{information.industryType}}</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="content-con">
                  <img src="../../../image/jgcx7.png" alt />
                  <span class="con-title">考核类别：</span>
                  <span>{{information.examItem}}</span>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="content-con">
                  <img src="../../../image/jgcx8.png" alt />
                  <span class="con-title">审核状态：</span>
                  <span>{{information.status|status}}</span>
                  <el-button size="mini" type="primary" @click="modify" v-show="information.status=='2'">修改</el-button>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="content-con">
                  <img src="../../../image/jgcx7.png" alt />
                  <span class="con-title">备注：</span>
                  <span>{{information.remark}}</span>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      dataTime: "",
      realName: "",
      trainType: "",

      information: {
        applyTime: "",
        realName: "",
        oldIdCard: "",
        mobile: "",
        workUnit: "",
        industryType: "",
        examItem: "",
        status: ""
      }
    };
  },
  filters:{
    status:function(target){
      switch (target){
        case 0:
          return '待审核';
          break;
         case 1:
          return '审核通过';
          break;
         case 2:
          return '审核不通过';
          break;
         case 3:
          return '已发证';
          break;
      }
    }
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    //当换证申请拒绝，点击修改
    modify() {
      this.$router.push({ path: "/replacementSystem/homePage",query:{information:JSON.stringify(this.information)}});
    },

    //结果回显
    init() {
      const params = {
        CONSUMER_ID: sessionStorage.getItem("Tokens"),
        SERVICE_CODE: "szsti.AdminEnd.RenewAuditManage.getByIdCard",
        idCard: sessionStorage.getItem("cardId")
      };
      this.common
        .ajax({
          method: "post",
          headers: {
            "content-type": "multipart/form-data"
          },
          url: this.url,
          data: params
        })
        .then(res => {
          const data = res.data.BODY.data;
          if (res.data.BODY.code == 200) {
            this.information = data;
            console.log(data)
            // if (data.deleteFlag == 0) {
            //   this.information.status = "待审核";
            // } else if (data.deleteFlag == 1) {
            //   this.information.status = "审核通过";
            // } else if (data.deleteFlag == 2) {
            //   this.information.status = "审核不通过";
            // } else if (data.deleteFlag == 3) {
            //   this.information.status = "发证";
            // }
          }
        })
        .catch(error => {
          console.log(error, "失败");
        });
    }
  }
};
</script>
<style lang="stylus" scoped>
.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

#content {
  .sign-up {
    margin-bottom: 20px;
    background-color: #ffffff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);

    .singContent {
      .page {
        padding: 20px 10px;

        .el-table {
          margin-bottom: 10px;
        }
      }
    }
  }

  .sign-up-title {
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 5px 0px rgba(218, 225, 229, 0.75);
    border-radius: 4px;
  }

  .trainingInformation {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    padding: 50px 0 100px;

    .content-con {
      height: 45px;
      display: flex;
      padding-left: 60px;

      span {
        line-height: 45px;
      }

      .con-title {
        color: #3E97DF;
        padding-left: 14px;
        padding-right: 20px;
        width: 80px;
        text-align-last: justify;
        text-align: justify;
      }

      .el-button--primary {
        margin-left: 20px;
        width: 100px;
        font-size: 18px;
      }
    }
  }
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  background-color: #3E97DF;
  font-size: 18px;
  font-weight: bold;
  font-family: Source Han Sans CN;
  color: rgba(66, 71, 75, 1);
  padding: 10px;
  min-height: 26px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .tips_content {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }

  img {
    margin-left: 0;
    height: 45%;
  }

  span {
    margin: 0 10px;
    color: #ffffff;
  }
}

.grid-content_title {
  padding: 10px 6px;
  text-indent: 2em;
  font-size: 18px;
  word-break: break-all;
  text-align: left;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #878d99;

  span {
    font-size: 14px;
  }

  .tips_title {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #44A8F8;
    line-height: 30px;
  }
}
</style>
