<template>
    <div class="ym-box">
        <div class="let-trainingInstitution-nav">
            <div>
                当前位置:
                <span @click="back_home" class="hyperlink-blue" title="去首页">网站首页</span>
                > 网站导航
            </div>
        </div>

        <div class="ym-nav-box">
            <div class="ym-main-name">网站导航</div>
            <div class="ym-list" v-for="(item,index) in optionlist" :key="index">
                <div class="ym-list-title">
                    <img v-if="item.img == 1" src="../../image/wzdh1.png" >
                    <img v-if="item.img == 2" src="../../image/wzdh2.png" >
                    <img v-if="item.img == 3" src="../../image/wzdh3.png" >
                    <img v-if="item.img == 4" src="../../image/wzdh4.png" >
                    <img v-if="item.img == 5" src="../../image/wzdh5.png" >
                    {{item.name}}
                </div>
                <div class="ym-item">
                    <p @click="go(itm,index)" :class="[itm.type ? 'p1' : '']" v-for="(itm,idx) in item.item" :key="idx">{{itm.name ? itm.name : itm.dataValue}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
    export default {
        data() {
            return {
                list : [
                    {
                        name : "资讯信息",
                        img : 1,
                        item : [
                            {
                                name : "通知公告",
                                url : "/xxfww/announcement?childId=6",
                                type : 0
                            },
                            {
                                name : "考试计划",
                                url : "/xxfww/announcement?parentId=0&childId=37",
                                type : 0
                            },
                            {
                                name : "工作指引",
                                url : "/xxfww/announcement?parentId=0&childId=20",
                                type : 0
                            },
                            {
                                name : "法规政策",
                                url : "/xxfww/announcement?parentId=0&childId=21",
                                type : 0
                            },
                            {
                                name : "考试大纲",
                                url : "/xxfww/announcement?parentId=0&childId=22",
                                type : 0
                            },
                            {
                                name : "资料下载",
                                url : "/xxfww/announcement?parentId=0&childId=24",
                                type : 0
                            },
                            {
                                name : "关于我们",
                                url : "/xxfww/announcement?parentId=0&childId=25",
                                type : 0
                            }
                        ]
                    },
                    {
                        name : "考试指引",
                        img : 2,
                        item : [
                            {
                                name : "特种作业人员考试取证",
                                url : "/xxfww/special",
                                type : 0
                            },
                             {
                                name : "危险化学品生产经营单位主要负责人和安全生产管理人员考试取证",
                                url : "/xxfww/chemicals",
                                type : 0
                            },
                             {
                                name : "生产经营单位主要负责人等四类从业人员考试取证",
                                url : "/xxfww/production",
                                type : 0
                            }
                        ]
                    },
                    {
                        name : "常见问答",
                        img : 2,
                        item : [

                        ]
                    },
                    {
                        name : "考试查询",
                        img : 3,
                        item : [
                            {
                                name : "安全生产理论考试准考证",
                                url : "/xxfww/printingOfAdmissionTicket?type=ticket",
                                type : 0
                            },
                            {
                                name : "特种作业实操考试准考证",
                                url : "/xxfww/printingOfAdmissionTicket?type=tzzy2",
                                type : 0
                            },
                            {
                                name : "生产经营单位主要负责人等四类从业人员考试成绩",
                                url : "/xxfww/printingOfAdmissionTicket?type=score",
                                type : 0
                            },
                            {
                                name : "特种作业人员实操考试成绩",
                                url : "/xxfww/printingOfAdmissionTicket?type=tzzy1",
                                type : 0
                            }
                        ]
                    },
                    {
                        name : "证书查询",
                        img : 4,
                        item : [
                            {
                                name : "全国特种作业操作证及危化安全合格证",
                                url : "http://cx.mem.gov.cn/",
                                type : 0
                            },
                            {
                                name : "生产经营单位主要负责人等四类从业人员考核合格证",
                                url : "/xxfww/printingOfAdmissionTicket?type=certificate",
                                type : 0
                            },
                            
                           
                        ]
                    },
                    {
                       name : "培训机构与考试点",
                        img : 5, 
                         item : [
                            {
                                name : "深圳市安全培训机构信息",
                                url : "/xxfww/passRate",
                                type : 0
                            },
                            {
                                name : "深圳市安全生产考试点信息",
                                url : "/xxfww/theTestPoint",
                                type : 0
                            },
                            ]
                    },
                    {
                        name : "在线系统",
                        img : 5,
                        item : [
                            {
                                name : "考试报名系统",
                                url : "/login?type=examinationregistration",
                                type : 0
                            },
                            {
                                name : "在线换证系统",
                                url : "/login?type=replacementSystem",
                                type : 0
                            },
                            {
                                name : "合格证书查询系统",
                                url : "/xxfww/printingOfAdmissionTicket?type=certificate",
                                type : 0
                            },
                            {
                                name : "培训机构管理信息系统",
                                url : "https://pxjg.yjgl.sz.gov.cn/index.html#/login",
                                type : 0
                            }
                        ]
                    },
                ]
            }
        },
        methods:{
            back_home() {
                this.$router.push('/xxfww/home');
            },
            go (itm,index){
                console.log(itm.url.indexOf('http'));
                if(itm.url || itm.parentCode === "question_category"){
                    // if(index == 5){
                    //     window.open(url)
                    // }else if(itm.dataCode === "question_category01"){
                    //     this.$router.push({path: '/xxfww/faqs',query:itm })
                    // }else{
                    //     this.$router.push({path: itm.url,query:itm })
                    // }
                    if(itm.parentCode === "question_category"){
                       this.$router.push({path: '/xxfww/faqs',query:itm })
                       return
                    }
                    if(itm.url.indexOf('http')>-1){
                        window.open(itm.url)
                    }else{
                        this.$router.push({path:itm.url})
                    }
                }
            }
        },
        computed : {
             ...mapState({
                optionData: state => state.option_data
            }),
            optionlist() {
                this.list[2].item = this.optionData.question_category
                return this.list
            }
        },
        mounted() {
            this.$store.dispatch("modifyOptionData", this.url)

        }
    }
</script>

<style scoped="scoped" lang="scss">
    .ym-box {
        width: 1200px;
        overflow: hidden;
        .let-trainingInstitution-nav {
            color: #3e97df;
            font-size: 14px;
            font-family: Microsoft YaHei;
            text-align: left;
        }
        .ym-nav-box {
            margin: 20px auto;
            width:1196px;
            background:rgba(255,255,255,1);
            border:1px solid rgba(216,226,240,1);
            box-shadow:0px 2px 5px 0px rgba(0, 0, 0, 0.18);
            margin-bottom: 50px;
            .ym-main-name {
                width: 1200px;
                height: 50px;
                color: #fff;
                text-align: center;
                font-weight: bold;
                margin-bottom: 5px;
                background: #3E97DF;
                font-size: 22px;
                line-height: 50px;
            }
            .ym-list {
                overflow: hidden;
                margin: 6px 30px;
                box-sizing: border-box;
                .ym-list-title {
                    line-height: 54px;
                    font-size: 18px;
                    color: #333;
                    display: flex;
                    align-items: center;
                    img {
                        width: 18px;
                        height: 18px;
                        margin-right: 8px;
                    }
                }
                .ym-item {
                    width: 100%;
                    overflow: hidden;
                    p {
                        width: 25%;
                        text-align: left;
                        color: #3E97DF;
                        font-size: 16px;
                        cursor: pointer;
                        line-height: 20px;
                        float: left;
                    }
                    .p1 {
                        width: 50%;
                    }
                }
            }
        }
    }
</style>
