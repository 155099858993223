<template>
    <el-row class="loadingPage">
        <div class="loading-text">{{content}}</div>
        <img :src="loadingImage" />
        <!-- <canvas id="canvas"></canvas> -->
    </el-row>

</template>
<script>


export default {
    data(){
        return{
            loadingText:'开始授权.....',
            loadingImage:require('@/image/loading.png'),
            content:'',
            grantCode: null
        }
    },
    mounted(){
        this.text(this.loadingText);
        // 1.接受grant_code
        this.grantCode = this.$route.query.code;
        // 2.登录获取login_token+用户信息，保存到sessionStorage
        // 3.从localStorage里面获取要访问的路由，没有默认跳转首页
        this.login();
    },
    methods:{
        login() {
            this.common.ajax({
                method: "post",
                url: this.url,
                headers: {
                    'content-type': "multipart/form-data"
                },
                data: {
                    "CONSUMER_ID": "info",
                    "SERVICE_CODE": "szsti.proxy.user.InfoNetworkUnifyLogin",
                    "password": "test",
                    "userId": this.grantCode
                },
            }).then((res) => {
                if (res.data.SYS_HEAD.RET_STATUS === "S") {
                    sessionStorage.setItem("Token", res.data.BODY.login_Proxy_Token);
                    sessionStorage.setItem("examineeId", res.data.BODY.user_id);
                    sessionStorage.setItem("isComplete", res.data.BODY.isComplete);
                    sessionStorage.setItem("userInfo", JSON.stringify(res.data.BODY.userInfo));
                    this.$message({
                        type: 'success',
                        message: '授权登录成功!'
                    });
                    let toRoute = localStorage.getItem("toRoute");
                    if (toRoute) {
                        this.$router.push({path: toRoute});
                    } else {
                        this.$router.push({path: '/xxfww/home'});
                    }
                } else {
                    this.$message({
                        type: "error",
                        message: "授权登录失败"
                    });
                    this.$router.push({path: '/xxfww/home'});
                }
            }).catch((error) => {
                console.error(error);
            });
        },
        text(str){
            let string='';
            let num=3;
            let timer=null;
             timer=setInterval(()=>{
                if(num>=str.length){
                    num=3
                }
                num++
                this.content=str.slice(0, num)
            },100)
        }
    }
}
</script>
<style lang="stylus" scoped>
.loadingPage{
    position:relative;
    width: 100%;
    height: 100%;
    background: #000;
    overflow: hidden;
    text-align :center;
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    .loading-text{
        position:absolute;
        margin :0 auto;
        z-index :20;
        color:#A5D7FE;
        font-size:45px;
        width:100%
        height :50px;
        line-height :50px;
        top: 50%;
    }
}
</style>
