<template>
    <el-row :gutter="20" id="trainingInstitution-box">
        <el-col :span="24" class="right-trainingInstitution-tabel">
                <div class="let-trainingInstitution-nav">
                        <div>当前位置:<span @click="back_home" class="hyperlink-blue" title="去首页">网站首页</span>>考试点</div>

                </div>
                <div class="grid-content font-t">
                    <div>
                        <span>深圳市安全生产考试点信息</span>
                    </div>
                    <!-- <div class="link_more">
                        <span>更多>>></span>
                    </div> -->
                </div>
                <el-row class="inner-t">
                    <el-button-group class="btn-serch">
                        <el-button :class="item.bac?'btn-s':''" :type="item.bac?'primary ':''" v-for="item in btnData" :key='item.bt' @click="tabs_btn(item)">{{item.bt}}</el-button>
                    </el-button-group>
                </el-row>
                <el-row :gutter="20" class="inner-t trainingInstitution-tabel">
                    <el-col style="width: 100%;margin: 0;margin-top: 20px;">
                        <el-table :highlight-current-row="true" :data="tableData.list" :row-class-name="tableRowClassName" header-row-class-name='table-class-name' >
                            <el-table-column align="center" width="80" label="序号">
                                <template slot-scope="scope">
                                    <span class="uid-class">{{scope.row.uid}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" width="150" label="所属区域" prop="area"></el-table-column>
                            <el-table-column align="center"  label="考点名称" prop="examName"></el-table-column>
                            <el-table-column align="center"    label="地址" prop="examAddress"></el-table-column>
                            <el-table-column align="center"  width="150"  label="咨询电话" prop="telephone"></el-table-column>
                            <el-table-column align="center"  width="150"  label="考试项目" prop="examProject"></el-table-column>
                        </el-table>
                        <!-- <div class="caozuo" style="margin-top: 20px;float:right;">
                            <el-pagination
                                @size-change="size_change"
                                @current-change="current_change"
                                :current-page.sync="tableData.currPage"
                                :page-sizes="[10, 20, 30, 40]"
                                :page-size="tableData.pageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="tableData.totals"
                            ></el-pagination>
                        </div>-->
                    </el-col>
                 </el-row>
        </el-col>
    </el-row>
</template>
<script>
    import {mapState} from 'vuex'
    import cTree from "@/components/cTree.vue";
    export default {
        data() {
            return {
                btnData:[
                    {bt:'理论考试',type:'theory',bac:true},
                    {bt:'实际操作考试',type:'operation',bac:false},
                ],
                searchData: {
                    area: "",
                    institutionName: "",
                    trainInstitution: "",
                    type:'theory'
                },
                tableData: {
                    list: [],
                    totals: 0,
                    pageSize: 1000,
                    currPage: 1
                },
                 tabsNav: [
                    {
                        name: '通知公告',
                        list: [{list: [], name: '工作通知', img: require('@/image/wzlby_btn3.png')}, {
                            list: [],
                            name: '工作台',
                            img: require('@/image/wzlby_btn3.png')
                        }],
                        img: require('@/image/wzlby_btn1.png'),
                        isVew: true
                    },
                    {
                        name: '考试计划',
                        list: [],
                        img: require('@/image/wzlby_btn2.png'),
                        isVew: true
                    },
                    {
                        name: '工作指引111',
                        list: [{list: [], name: '', img: require('@/image/wzlby_btn3.png')}],
                        img: require('@/image/wzlby_btn3.png'),
                        isVew: true
                    },
                    {
                        name: '法规政策',
                        list: [{list: [], name: '', img: require('@/image/wzlby_btn3.png')}],
                        img: require('@/image/wzlby_btn4.png'),
                        isVew: true
                    },
                    {
                        name: '考试大纲',
                        list: [{list: [], name: '', img: require('@/image/wzlby_btn3.png')}],
                        img: require('@/image/wzlby_btn5.png'),
                        isVew: true
                    },
                    {name: '常见问答', list: [], img: require('@/image/wzlby_btn6.png'), isVew: true},
                    {name: '服务窗口', list: [], img: require('@/image/wzlby_btn7.png'), isVew: true},
                    {name: '成绩查询', list: [], img: require('@/image/wzlby_btn9.png'), isVew: true},
                    {name: '资料下载', list: [], img: require('../../image/wzlby_btn9.png'), isVew: true},
                ],
            }
        },
        created(){
            this.getOption();
            this.getExamRoomPages();
        },
        methods: {
            // 查询
            search() {
                this.init();
            },
            clear(obj) {
                Object.keys(obj).forEach(x => {
                    obj[x] = "";
                });
            },
            size_change(size) {
                this.tableData.pageSize = size;
                this.getExamRoomPages();
            },
            // current_change(currPage) {
            //     this.tableData.currPage = currPage;
            //     this.getExamRoomPages();
            // },
            // 初始化列表

            getExamRoomPages(){
                let url = "szsti.AdminEnd.ExamRoom.selectPages";
                let param = {
                    CONSUMER_ID: "system",
                    totals: this.tableData.totals,
                    currPage: this.tableData.currPage,
                    pageSize: this.tableData.pageSize,
                    examType: this.searchData.type,
                }
                this.$http(url, param, false).then(res => {
                    if (res.data.BODY.code == 200 && res.data.BODY.success == 'true') {
                        this.tableData.list = res.data.BODY.data.pageList;
                        for (var i = 0; i < this.tableData.list.length; i++) {
                            this.tableData.list[i].uid = i + 1;
                        }
                        this.tableData.totals = res.data.BODY.data.totalRow;
                    } else {
                        this.$message({message: '分页查询失败，失败信息：' + res.data.BODY.message, type: 'warning'});
                    }
                }).catch(y => {
                    this.$message.error(y);
                });
            },
            handleCurrentChange(val,num) {
                if(num){
                    this.tableData.currPage+=num
                }else{
                    this.tableData.currPage=val
                }
                this.init()
            },
            jumpPage(val){
                switch(val){
                  case "1"://跳转到首页
                    this.handleCurrentChange(1);
                        break;
                  case "0"://跳转到末页
                    this.handleCurrentChange(this.tableData.totals/this.tableData.pageSize);
                        break;
                  case "-1"://上一页
                    this.handleCurrentChange('-1',-1);
                        break;
                  case "+1"://下一页
                    this.handleCurrentChange('+1',+1);
                        break;
                }
            },
            // 初始字典
            getOption(){
                this.$store.dispatch('modifyOptionData',this.url)
            },
            tableRowClassName({row, rowIndex}) {
                if (rowIndex%2===0) {
                    return 'warning-row';
                } else{
                    return '';
                }

            },
            back_home() {
                this.$router.push('/xxfww/home');
            },
            tabs_btn(row){
                this.btnData.forEach(x=>{
                    if(row===x){
                        x.bac=true
                    }else{
                        x.bac=false
                    }
                })
                this.tableData.currPage=1
                // 请求新数据
                this.searchData.type=row.type
                this.getExamRoomPages();
            },
            changeOption(){},
        },
        computed:{
            ...mapState({
                optionData:state=>state.option_data
            })
        },
        components:{cTree}
    }
</script>
<style lang="stylus" scoped>
    #trainingInstitution-box {
        display: flex;
        justify-content: space-between;
        min-height:800px;
        padding-bottom: 60px;
        .btn-serch{
            width:100%;
            display :flex;
            justify-content :center;
            align-items :center;
            .el-button{
                display :inline-block;
                width:21%;
                color:#3E97DF
            }
        }
        .el-button.el-button--primary.btn-s{
             color :#fff
        }
        .grid-content {
            position: relative;
            padding: 10px;
            padding-left: 21px;
            min-height: 26px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
        }

        .let-trainingInstitution-nav {
            color: #3E97DF;
            font-size: 14px;
            font-family: Microsoft YaHei;
             text-align: left;

        }
        .right-trainingInstitution-tabel {
            .trainingInstitution-seach{

                height :50px;
                .el-col{
                    height :100%
                    line-height :50px
                }
            }
            .trainingInstitution-tabel{
            }
            .el-table{
                width:100%
                height:100%;
                overflow :auto;
                border-radius:4px 4px 4px 4px;
            }
            .table-class-name{
                color:#3E97DF !important
            }
            .el-table .warning-row {
                background: #CCC
            }
            .uid-class{
                display:inline-block;
                width:22px;
                height:21px;
               background:rgba(62,151,223,1);
               border-radius:50%;
               color:#FFF
            }
        }

    }

</style>
