import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import "./lib/http.js"
import './lib/datef.js'
import './lib/clone.js'
import common from './lib/common.js'
import aes from './lib/aes.js'
import './lib/format.js'
// import 'lib-flexible/flexible.js'
// import print from './lib/print.js'
import print from 'vue-print-nb'

import { Message } from 'element-ui'
Vue.use(ElementUI)
Vue.use(print)
// 清除默认样式
// import "normalize.css";

// 兼容
// import "babel-polyfill";

// import 'lib-flexible/'
import VueLazyload from 'vue-lazyload';

// Vue懒加载
// Vue.use(VueLazyload, {
//     preLoad: 1.3,
//     error: '/static/error-picture-128.png',
//     // loading: '/static/loading.gif',
//     loading: '/static/loading(3).gif',
//     attempt: 1
// });

let instance = axios.create({
  headers: {
    'content-type': 'application/x-www-form-urlencoded'
  }
})
// http request 拦截器
instance.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('examiner_token')
    if (token ) { // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.authorization = token  //请求头加上token
    }else{
      Message.error('登录信息已失效,请重新登录!');
      sessionStorage.removeItem('examiner_token');
      setTimeout(function() {
         window.location.href ='https://ksfw.yjgl.sz.gov.cn/login?type=examinerSystem';
        //window.location.href ='https://ksfw.yjgl.sz.gov.cn:8081/login?type=examinerSystem';
      }, 100)
    }
    return config
  },
  err => {
    return Promise.reject(err)
})
Vue.config.productionTip = false
Vue.prototype.common = common;
Vue.prototype.axios = axios;
Vue.prototype.aes = aes;


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

