import { render, staticRenderFns } from "./indexx.vue?vue&type=template&id=6b1070ba&scoped=true&"
import script from "./indexx.vue?vue&type=script&lang=js&"
export * from "./indexx.vue?vue&type=script&lang=js&"
import style0 from "./indexx.vue?vue&type=style&index=0&lang=css&"
import style1 from "./indexx.vue?vue&type=style&index=1&id=6b1070ba&lang=scss&scoped=true&"
import style2 from "./indexx.vue?vue&type=style&index=2&id=6b1070ba&scoped=true&lang=scss&"
import style3 from "./indexx.vue?vue&type=style&index=3&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b1070ba",
  null
  
)

export default component.exports