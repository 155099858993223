<template>
    <div>
        <div class="let-trainingInstitution-nav">
            <div>
                当前位置:
                <span @click="back_home" class="hyperlink-blue" title="去首页">网站首页</span>
                >{{ titles }}
            </div>
        </div>
        <div class="ym-main">
            <div class="ym-main-name">考试查询</div>
            <div v-for="(item,index) in list" :key="index">
            <div v-if="item" class="ym-item" >
                <div class="ym-item-title">
                    <div class="ym-line"></div>
                    <div class="ym-name">{{item.name}}</div>
                    <div class="ym-line"></div>
                    <div class="ym-bottom-line"></div>
                </div>
                <div class="ym-list" v-for="(itm,idx) in item.item" :key="idx">
                    <div class="ym-list-item">
                        <img v-if="item.img == 1" src="../../image/kscx1.png">
                        <img v-if="item.img == 2" src="../../image/kscx2.png">
                        <img v-if="item.img == 3" src="../../image/kscx3.png">
                        <div class="ym-list-item-text">
                            <p class="ym-p1">{{itm.name}}</p>
                            <p class="ym-p2">{{itm.text}}</p>
                        </div>
                        <div class="ym-button" @click="queryClick(itm)">点击进入</div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <!-- <div class="loginContent" style="margin-bottom: 20px">
            <div class="content">
                <el-row class="content-row" v-for="(item, index) in special" :key="index">
                    <el-col class="content-col">
                        <div @click="queryClick(item)">
                            <span class="num">{{ index + 1 }}</span>
                            <div>{{ item.title }}{{ titles }}</div>
                            <span class="time">点击进入</span>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            // special: [
            //     {
            //         title: this.$route.query.type === 'certificate' ? '全国特种作业操作证及危化企业考核合格证查询' : '深圳市特种作业实操考试',
            //         path: '/xxfww/printingOfAdmissionTicket'
            //     },
            //     {
            //         title: this.$route.query.type === 'certificate' ? '深圳市生产经营单位主要负责人等四类从业人员考试证书查询' : '深圳市生产经营单位主要负责人等四类从业人员考试',
            //         path: '/xxfww/printingOfAdmissionTicket'
            //     }
            // ],
            commentType: this.$route.query.type,
            special : [
                {
                    name : "准考证打印",
                    img : 1,
                    item : [
                        {
                            name : "实操考试准考证打印",
                            text : "(电工作业、焊接与热切割作业、制冷与空调作业、高处作业)",
                            url : "/xxfww/printingOfAdmissionTicket?type=tzzy2"
                        },
                        {
                            name : "理论考试准考证打印",
                            text : "(特种作业人员理论考试、危险化学品生产经营单位主要负责人和安全生产管理人员考试)",
                            url : "/xxfww/printingOfAdmissionTicket?type=ticket"
                        }
                        //  {
                        //     name : "深圳市安全生产理论考试准考证打印",
                        //     text : "(特种作业人员理论考试、危险化学品生产经营单位主要负责人和安全生产管理人员考试)",
                        //     url : "/xxfww/printingOfAdmissionTicket?type=ticket"
                        // },
                    ]
                },
                {
                    name : "成绩查询",
                    img : 2,
                    item : [
                        {
                            name : "实操考试成绩查询",
                            text : "(电工作业、焊接与热切割作业、制冷与空调作业、高处作业)",
                            url : "/xxfww/printingOfAdmissionTicket?type=tzzy1"
                        },
                          {
                            name : "理论考试成绩查询",
                            text : "(特种作业人员理论考试、危险化学品生产经营单位主要负责人和安全生产管理人员考试）",
                            url : "/xxfww/printingOfAdmissionTicket?type=examinationScore"
                        },
                        // {
                        //     name : "深圳市生产经营单位主要负责人等四类从业人员考试成绩查询",
                        //     text : "(锂电池生产加工储存单位、粉尘涉爆单位、涉氨制冷单位、非高危生产经营单位)",
                        //     url : "/xxfww/printingOfAdmissionTicket?type=score"
                        // }
                    ]
                },
                {
                    name : "证书查询",
                    img : 3,
                    item : [
                        {
                            name : "全国特种作业操作证查询",
                            text : "（特种作业操作证、电工进网作业许可证)",
                            url : "http://cx.mem.gov.cn/"
                        },
                        {
                            name : "全国安全生产知识和管理能力考核合格证信息查询",
                            text : "（危险化学品生产经营单位）",
                            url : "http://cx.mem.gov.cn/"
                        },
                        {
                            name : "深圳市安全生产知识和管理能力考核合格证信息查询",
                            text : "（锂电池生产加工和储存单位、粉尘涉爆单位、涉氨制冷单位、非高危生产经营单位）",
                            url : "/xxfww/printingOfAdmissionTicket?type=certificate"
                        },
                        // {
                        //     name : "深圳市生产经营单位主要负责人和安全管理人员培训合格证信息查询",
                        //     text : "（2013-2018年深圳市核发的生产经营单位主要负责人和安全管理人员培训合格证信息）",
                        //     url : "/xxfww/printingOfAdmissionTicket?type=managerCertificate"
                        // },
                        {
                            name : "深圳市历史证书信息查询",
                            text : "（2010年以前的深圳市核发的初级、中级、高级安全主任证，主要生产经营管理者证信息）",
                            url : "/xxfww/printingOfAdmissionTicket?type=history"
                        }
                    ]
                },
            ],
            list : [],
        };
    },
    methods: {
        back_home() {
            this.$router.push('/xxfww/home');
        },
        queryClick(item) {
            this.$store.dispatch('modifySetTabPage', '不选择');
            if(item.url.indexOf('http')>-1){
                window.open(item.url);
            }else{
                this.$router.push(item.url);
            }



            // if (item.path) {
            //     switch (this.$route.query.type) {
            //         case 'score':
            //             switch (item.title) {
            //                 case '深圳市特种作业实操考试':
            //                     this.$store.dispatch('modifySetTabPage', '不选择');
            //                     this.$router.push({
            //                         path: item.url,
            //                         query: { type: 'tzzy1' }
            //                     });
            //                     break;
            //                 case '深圳市生产经营单位主要负责人等四类从业人员考试':
            //                     this.$store.dispatch('modifySetTabPage', '不选择');
            //                     this.$router.push({
            //                         path: item.url,
            //                         query: { type: 'score' }
            //                     });
            //                     break;
            //             }
            //             break;

            //         case 'certificate':
            //             switch (item.title) {
            //                 case '全国特种作业操作证及危化企业考核合格证查询':
            //                     //   this.$router.push({
            //                     //   path: item.path,
            //                     //   query: { type: "certificate" }
            //                     // });
            //                     window.open('http://cx.mem.gov.cn/');
            //                     break;
            //                 case '深圳市生产经营单位主要负责人等四类从业人员考试证书查询':
            //                     this.$store.dispatch('modifySetTabPage', '不选择');
            //                     this.$router.push({
            //                         path: item.url,
            //                         query: { type: 'certificate' }
            //                     });
            //                     break;
            //             }
            //             break;

            //         case 'ticket':
            //             switch (item.title) {
            //                 case '深圳市特种作业实操考试':
            //                     this.$store.dispatch('modifySetTabPage', '不选择');
            //                     this.$router.push({
            //                         path: item.url,
            //                         query: { type: 'tzzy2' }
            //                     });
            //                     break;
            //                 case '深圳市生产经营单位主要负责人等四类从业人员考试':
            //                     this.$store.dispatch('modifySetTabPage', '不选择');
            //                     this.$router.push({
            //                         path: item.url,
            //                         query: { type: 'ticket' }
            //                     });
            //                     break;
            //             }
            //             break;
            //     }
            // }
        }
    },
    computed: {
        title() {
            let title = '';
            switch (this.$route.query.type) {
                case 'score':
                    title = '成绩查询';
                    break;
                case 'certificate':
                    title = this.special[0].title === '全国特种作业操作证及危化企业考核合格证查询' ? '' : '';
                    //   title = "证书查询";
                    break;
                case 'ticket':
                    title = '准考证打印';
                    break;
            }
            return title;
        },
        titles() {
            let titles = '';
            switch (this.$route.query.type) {
                case 'score':
                    titles = '成绩查询';
                    break;
                case 'certificate':
                    titles = '证书查询';
                    break;
                case 'ticket':
                    titles = '准考证打印';
                    break;
            }
            return titles;
        },
    },
    watch:{
        $route () {
            let list0 = this.special[0];
            let list1 = this.special[1];
            let list2 = this.special[2];

            switch (this.$route.query.type) {
                case 'score':
                    this.list = [list1,list0,list2];
                    break;
                case 'certificate':
                    this.list = [list2,list1,list0];
                    break;
                case 'ticket':
                    this.list = [list0,list1,list2];
                    break;
            }
            this.$forceUpdate()
        }
    },
    mounted () {
        let list0 = this.special[0];
        let list1 = this.special[1];
        let list2 = this.special[2];

        switch (this.$route.query.type) {
            case 'score':
                this.list = [list1,list0,list2];
                break;
            case 'certificate':
                this.list = [list2,list1,list0];
                break;
            case 'ticket':
                this.list = [list0,list1,list2];
                break;
        }
        this.$forceUpdate()
    }
};
</script>
<style lang="scss" scoped>
.let-trainingInstitution-nav {
    color: #3e97df;
    font-size: 14px;
    font-family: Microsoft YaHei;
    text-align: left;
}
.loginContent {
    width: 100%;
    margin: 20px auto 20px;
    padding: 10px 0 10px;

    .content {
        width: 100%;

        text-align: left;
        .content-row {
            width: 100%;
            margin-bottom: 20px;
            .content-col {
                cursor: pointer;
                background-color: #ccc9;
                div {
                    font-size: 18px;
                    .num {
                        width: 40px;
                        height: 40px;
                        display: inline-block;
                        background-color: #3e97df;
                        color: #fff;
                        font-size: 28px;
                        text-align: center;
                        line-height: 40px;
                    }
                    div {
                        display: inline-block;
                        padding-left: 20px;
                    }
                    .time {
                        color: #fff;
                        float: right;
                        line-height: 40px;
                        margin-right: 20px;
                        background-color: #1592db;
                        padding: 0 10px;
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss" scoped="scoped">
    .ym-main {
        width: 1200px;
        margin: 20px auto;
        border:1px solid rgba(216,226,240,1);
        box-shadow:0px 2px 5px 0px rgba(0, 0, 0, 0.18);
        .ym-main-name {
            width: 1200px;
            height: 50px;
            color: #fff;
            text-align: center;
            font-weight: bold;
            margin-bottom: 5px;
            background: #3E97DF;
            font-size: 22px;
            line-height: 50px;
        }
        .ym-item {
            overflow: hidden;
            width: 100%;
            .ym-item-title {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                height: 46px;
                margin-top: 12px;
                margin-bottom: 24px;
                .ym-line {
                    width: 210px;
                    height: 1px;
                    background: #D9D9D9 ;
                }
                .ym-name {
                    width: 140px;
                    text-align: center;
                    color: #333;
                    font-size: 20px;
                    font-weight: 700;
                }
                .ym-bottom-line {
                    width: 50px;
                    height: 3px;
                    background: #3E97DF;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    margin-left: -25px;
                }
            }
            .ym-list {
                width: 100%;
                box-sizing: border-box;
                overflow: hidden;
                padding: 0 50px;
                .ym-list-item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 30px;
                    img {
                        width: 55px;
                        height: 55px;
                    }
                    .ym-list-item-text {
                        width: 905px;
                        padding:3px 20px;
                        text-align: left;
                        .ym-p1 {
                            color: #333;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 24px;
                            height: 24px;
                            margin: 0;
                        }
                        .ym-p2 {
                            color: #666666;
                            font-size: 14px;
                            line-height: 24px;
                            height: 24px;
                            margin: 0;
                        }
                    }
                    .ym-button {
                        width: 100px;
                        height: 34px;
                        background: #3E97DF;
                        border-radius:5px;
                        line-height: 34px;
                        text-align: center;
                        font-size: 18px;
                        color: #fff;

                        cursor: pointer;
                    }
                }
            }
        }
    }

</style>
