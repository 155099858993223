<template>
  <div>
    <div class="breadcrumb">
      当前位置: <div class="breadcrumb_home"
           @click="blackhome">网站首页</div>> 考试指引
    </div>
    <div class="ljxtitle">
      <div class="ljxtitle_ljxcontent">
        生产经营单位主要负责人等四类从业人员考试取证指引
      </div>
    </div>
    <div class="ljxcontent">
      <div class="ljxcontent_ljxtitle">
        <img src="../../image/kszyicon1.png"
             alt="">考核人员范围
      </div>
      <div class="ljxcontent_ljxtitle_ljxcontent">

        锂电池生产加工和储存、粉尘涉爆、涉氨制冷等高危生产经营单位，以及从业人员在50人以上的工业企业（冶金、有色、建材、机械、轻工、纺织、烟草）、从业人员在100人以上的商贸行业生产经营单位的以下四类从业人员：
        <br>
        （一）主要负责人；
        <br>
        （二）分管安全生产负责人；
        <br>
        （三）安全总监；
        <br>
        （四）安全生产管理人员。
        <br>
        除上述规定的人员外，其他生产经营单位的从业人员遵循自愿的原则参加考核。

      </div>
      <div class="ljxcontent_ljxtitle_guide">
        <div class="ljxcontent_ljxtitle_guide_box">
          <img src="../../image/sz.png"
               alt="">
          <router-link to="/xxfww/announcement?childId=21&detailId=109" class="ljxcontent_ljxtitle_guide_text">深圳市生产经营单位安全生产主体责任规定</router-link>
        </div>
      </div>
    </div>
    <div class="ljxcontent">
      <div class="ljxcontent_ljxtitle">
        <img src="../../image/kszyicon2.png"
             alt="">培训
      </div>
      <div class="ljxcontent_ljxtitle_ljxcontent">

        （一）培训形式
        <br>
        生产经营单位可以自行组织培训，也可以委托培训机构进行培训，并建立培训档案。
        <br>
        （二）培训大纲
        <br>
        生产经营单位主要负责人等四类从业人员的培训工作，统一使用原市安全监管局组织编制的培训大纲，详见《深圳市安全监管局关于发布安全生产培训和考核大纲的通知》（深安监管〔2019〕6号）。

      </div>
      <div class="ljxcontent_ljxtitle_guide">
        <div class="ljxcontent_ljxtitle_guide_box">
          <img src="../../image/sz.png"
               alt="">
          <router-link  to="/xxfww/passRate" class="ljxcontent_ljxtitle_guide_text">培训机构</router-link>
        </div>
        <div class="ljxcontent_ljxtitle_guide_box">
          <img src="../../image/sz.png"
               alt="">
          <router-link to="/xxfww/announcement?parentId=0&childId=22" class="ljxcontent_ljxtitle_guide_text">培训大纲</router-link>
        </div>
      </div>
    </div>
    <div class="ljxcontent">
      <div class="ljxcontent_ljxtitle">
        <img src="../../image/kszyicon3.png"
             alt="">考试
      </div>
      <div class="ljxcontent_ljxtitle_ljxcontent">
        （一）报名方式
        <br/>
       采取线上报名方式，报考人员在“学习强安”APP自主报名。
        <br>
        （二）考核方式
        <br/>
        采取线上考核方式，报考人员在“学习强安”APP参加线上考核，具体操作步骤详见《“学习强安”APP考试操作指引》。
        <br>
        （三）考核科目及题型
        <br/>
        设理论考试一个科目，满分100分，80分及以上为合格，考试时间为60分钟。采用标准化题型，包括单选题和判断题。

      </div>
      <div class="ljxcontent_ljxtitle_guide">
        <div class="ljxcontent_ljxtitle_guide_box">
          <img src="../../image/sz.png"
               alt="">
          <a href="https://ksfw.yjgl.sz.gov.cn/pic/examfile/“学习强安”APP考试操作指引.docx" class="ljxcontent_ljxtitle_guide_text">“学习强安”APP考试操作指引</a>
        </div>
      </div>
    </div>
    <div class="ljxcontent">
      <div class="ljxcontent_ljxtitle">
        <img src="../../image/kszyicon4.png"
             alt="">取证
      </div>
      <div class="ljxcontent_ljxtitle_ljxcontent">

        （一）证书形式
        <br>
        生产经营单位主要负责人等四类从业人员经考核合格后，统一核发《安全生产知识和管理能力考核合格证》，证书采用电子证书形式，不再发放实体证书。
        <br>
        （二）电子证下载
        <br>
        考试结束后5个工作日后，可在深圳市应急管理局官网（http://yjgl.sz.gov.cn/）的“安全生产培训考核发证专题”或深圳应急管理微信公众号的“服务互动—考试服务”栏目，查询下载电子证书。

      </div>
      <div class="ljxcontent_ljxtitle_guide">
        <div class="ljxcontent_ljxtitle_guide_box">
          <img src="../../image/sz.png"
               alt="">
          <router-link to="/xxfww/printingOfAdmissionTicket?type=certificate" class="ljxcontent_ljxtitle_guide_text">证书查询</router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {

    }

  },
  methods: {
    blackhome () {
      this.$router.push('/')
    }
  },
}
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  font-size: 14px;
  color: #3e97df;
  .breadcrumb_home {
    margin: 0 10px;
    cursor: pointer;
  }
}
.ljxtitle {
  margin-top: 47px;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  .ljxtitle_ljxcontent {
    width: 410px;
    font-weight: bold;
    color: rgba(62, 151, 223, 1);
    font-size: 22px;
  }
}
.ljxcontent {
  margin-top: 30px;
  width: 1200px;
  background-color: #f7f8fb;
  border-radius: 10px;
  padding: 25px 0 0 0;
  box-sizing: border-box;
  .ljxcontent_ljxtitle {
    background: url("../../image/kszybg.png") no-repeat;
    height: 50px;
    width: 240px;
    background-size: 100%;
    display: flex;
    font-size: 22px;
    color: #ffffff;
    align-items: center;
    font-weight: bold;
    img {
      width: 34px;
      height: 32px;
      margin-right: 29px;
      margin-left: 24px;
    }
  }
  .ljxcontent_ljxtitle_ljxcontent {
    margin-top: 20px;
    font-size: 14px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    text-align: left;
    line-height: 35px;
  }
  .ljxcontent_ljxtitle_guide {
    display: flex;
    margin-top: 40px;
    margin-left: 20px;
    flex-direction: column;
    .ljxcontent_ljxtitle_guide_box {
      margin-bottom: 41px;
      display: flex;
      align-items: center;
      height: 23px;
      img {
        width: 39px;
        height: 23px;
        margin-right: 23px;
        display: flex;
      }
      .ljxcontent_ljxtitle_guide_text {
        font-weight: bold;
        color: rgba(62, 151, 223, 1);
        font-size: 18px;
      }
    }
  }
}
</style>