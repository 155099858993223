<template>
  <el-row :gutter="20"
          id="trainingInstitution-box"
          class="ym-trainingInstitution">
    <el-col :span="24"
            class="right-trainingInstitution-tabel">
      <div class="let-trainingInstitution-nav">
        <div>
          当前位置:
          <span @click="back_home"
                class="hyperlink-blue"
                title="去首页">网站首页</span>>培训机构
        </div>
      </div>
      <!-- 标题 -->
      <div class="ljxgrid-content font-t">
        <div>
          <span class="ljxgrid-content-span">{{getData.name}}</span>
        </div>
      </div>
      <div class="centent_box">
        <el-row class="location_consult">
          <el-col :span="3"
                  class="location">
            <div>机构地址</div>
          </el-col>
          <el-col :span="9"
                  class="consult">
            <div>{{getData.districtValue}}</div>
          </el-col>
          <el-col :span="3"
                  class="location">
            <div>咨询电话</div>
          </el-col>
          <el-col :span="9"
                  class="consult">
            <div>{{getData.phone}}</div>
          </el-col>
        </el-row>
        <el-row class="training">
          <div class="training_title">
            <el-col :span="12"
                    :offset="6">
              <el-divider>培训项目</el-divider>
              <div class="underline"></div>
              <div>
              </div>
            </el-col>
          </div>
          <div class="training_box">
            <div class="training_box_left">
              <div class="training_box_left_title" v-if="!trainChargeInfobbb.length">特种作业初训</div>
              <!-- <div class="training_box_left_text">
                <div class="training_box_left_text_circle"></div>低压电工作业
              </div>
              <div class="training_box_left_text">
                <div class="training_box_left_text_circle"></div>制冷与空调设备安装修理作业
              </div> -->
            </div>
            <div class="training_box_right">
              <div class="training_box_right_title" v-if="trainChargeInfobbb.length">安全管理</div>
              <div class="training_box_right_content">
                <div class="training_box_right_content_left">
                  <div class="training_box_right_content_left_box"
                       v-for="item in trainChargeInfobbb"
                       :key="item.index">
                    <div class="training_box_right_text_circle"></div>{{item}}
                  </div>
                </div>
                <!-- <div class="training_box_right_content_right" >
                  <div class="training_box_right_content_right_box" v-for="item in trainChargeInfobbb[1]" :key="item.index">
                    <div class="training_box_right_text_circle"></div>{{item}}
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </el-row>
        <el-row class="place">
          <div class="place_title">
            <el-col :span="12"
                    :offset="6">
              <el-divider>培训场地</el-divider>
              <div class="underline"></div>
              <div>
              </div>
            </el-col>
          </div>
          <div class="place_box">
            <div class="place_box_title">理论课堂</div>
            <div class="place_box_content">
              <template>
                <el-table :data="tableData"
                          show-summary
                          stripe
                          style="width: 100%">
                  <el-table-column prop="name"
                                   label="教室名称"
                                   align="center">
                  </el-table-column>
                  <el-table-column prop="area"
                                   label="面积(㎡)"
                                   align="center">
                  </el-table-column>
                  <el-table-column prop="person"
                                   align="center"
                                   label="容纳人数">
                  </el-table-column>
                  <el-table-column prop="place"
                                   label="培训位(个)"
                                   align="center">
                  </el-table-column>
                </el-table>
              </template>
            </div>
          </div>
          <div class="place_box">
            <div class="place_box_title">实操课室</div>
            <div class="place_box_content">
              <template>
                <el-table :data="tableData2"
                          show-summary
                          stripe
                          style="width: 100%">
                  <el-table-column prop="name"
                                   label="教室名称"
                                   align="center"
                                   width="180">
                  </el-table-column>
                  <el-table-column prop="catalog"
                                   label="培训项目"
                                   align="center"
                                   width="350">
                  </el-table-column>
                  <el-table-column prop="area"
                                   label="面积(㎡)"
                                   align="center">
                  </el-table-column>
                  <el-table-column prop="person"
                                   align="center"
                                   label="容纳人数">
                  </el-table-column>
                  <el-table-column prop="place"
                                   label="培训位(个)"
                                   align="center">
                  </el-table-column>
                </el-table>
              </template>
            </div>
          </div>
          <div class="place_title">
            <el-col :span="12"
                    :offset="6">
              <el-divider>师资力量</el-divider>
              <div class="underline"></div>
              <div>
              </div>
            </el-col>
          </div>
          <div class="place_box">
            <div class="place_box_content">
              <template>
                <el-table :data="tableData3"
                          stripe
                          style="width: 100%">
                  <el-table-column prop="name"
                                   label="姓名"
                                   align="center">
                  </el-table-column>
                  <el-table-column prop="work_type"
                                   label="专兼职情况"
                                   align="center">
                  </el-table-column>
                  <el-table-column prop="category"
                                   label="工种"
                                   align="center"
                                   width="450">
                  </el-table-column>
                </el-table>
              </template>
            </div>
            <el-pagination @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="currentPage"
                           background
                           :page-size="5"
                           :pager-count="6"
                           layout="total, prev, pager, next"
                           :total=total>
            </el-pagination>
          </div>
          <div class="place_title">
            <el-col :span="12"
                    :offset="6">
              <el-divider>培训收费标准</el-divider>
              <div class="underline"></div>
              <div>
              </div>
            </el-col>
          </div>
          <div class="place_box">
            <div class="place_box_content">
              <template>
                <el-table :data="tableData4"
                          stripe
                          style="width: 100%">
                  <el-table-column prop="catalog"
                                   label="培训项目"
                                   align="center"
                                   width="680">
                  </el-table-column>
                  <el-table-column prop="charge"
                                   label="培训费用"
                                   align="center">
                  </el-table-column>
                </el-table>
              </template>
            </div>
          </div>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  data () {
    return {

      //培训机构信息
      getData: [],
      tableData: [],
      tableData2: [],
      tableData3: [],
      tableData4: [],
      //当前页数
      currentPage: 1,
      // 总数
      total: 0,
      // 特种作业初训
      trainChargeInfo: [],
      trainChargeInfobbb: []
    }
  },
  created () {

  },
  methods: {

    // 获取数据
    getdata (id) {
      let url = "szsti.publicInfo.ColumnNode.frontColumnNodeContent";
      let param = {
        "CONSUMER_ID": "system-a6de2ef5070b4e04a0c981bf07ef8cb3",
        "SERVICE_CODE": "szsti.AdminEnd.RegisterDynamic.instistutionInfo",
        id
      };
      this.$http(url, param, false).then(res => {
        this.getData = res.data.BODY.data
        this.tableData = this.getData.theoryClassroom
        this.tableData2 = this.getData.handsonClassroom
        this.tableData4 = this.getData.trainChargeInfo
        console.log(this.tableData4, 999923123123);
        console.log(this.getData, 999999);
      }).catch(y => {
        this.$message.error(y);
      });
    },
    //获取师资力量数据
    getteacherdata (id) {
      let url = "szsti.publicInfo.ColumnNode.frontColumnNodeContent";
      let param = {
        "CONSUMER_ID": "system-aa8bcc6adba04dc19093179716b6e891",
        "SERVICE_CODE": "szsti.AdminEnd.RegisterDynamic.getLecturer",
        instistutionId: id,
        "currPage": this.currentPage,
        "pageSize": "5"
      };
      this.$http(url, param, false).then(res => {
        console.log(res, 666);
        this.tableData3 = res.data.BODY.data
        this.total = res.data.BODY.total
      }).catch(y => {
        this.$message.error(y);
      });
    },
    //获取培训机构下的培训项目
    gettraindata (id) {
      let url = "szsti.publicInfo.ColumnNode.frontColumnNodeContent";
      let param = {
        "CONSUMER_ID": "system-fe8b4ac420314fcb94a73bd112579cee",
        "SERVICE_CODE": "szsti.AdminEnd.RegisterDynamic.getWorkcategoryByInstistution",
        instistutionId: id,
        "type": ""
      };
      this.$http(url, param, false).then(res => {
        console.log(res, "res");
        this.trainChargeInfo = res.data.BODY.data[0].workcategory
        // let resbb = this.trainChargeInfo.split(/[\n\s+,，；; 、]/g)
        let resbb = this.trainChargeInfo.split(/[\n\s+,]/g)
        for (var i = 0; i < resbb.length; i++) {
          if (resbb[i] == "") {
            // 删除数组中空值
            resbb.splice(i, 1);
            i--;
          }
        }
        console.log(resbb, "resbb");
        this.trainChargeInfobbb = resbb
        // var newArr = [];
        // for (var i = 0; i < this.trainChargeInfobbb.length; i += 8) {
        //   newArr.push(this.trainChargeInfobbb.slice(i, i + 8));
        // }
        // console.log(newArr,'newArr');
        // this.trainChargeInfobbb = newArr
        console.log(this.trainChargeInfobbb, "this.trainChargeInfobbb");

      }).catch(y => {
        this.$message.error(y);
      });
    },


    back_home () {
      this.$router.push({
        path: "/"
      })
    },
    getSummaries (param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总价';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += ' 元';
        } else {
          sums[index] = 'N/A';
        }
      });

      return sums;
    },
    //分页
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getteacherdata()
    }
  },
  mounted () {
    this.getdata(this.$route.query.id)
    this.getteacherdata(this.$route.query.id)
    this.gettraindata(this.$route.query.id)
  },
  computed: {
    ...mapState({
      optionData: state => state.option_data
    })
  },
};
</script>
<style>
body .el-table th.gutter {
  display: table-cell !important;
}
</style>
<style lang="scss" scoped>
#trainingInstitution-box {
  display: flex;
  justify-content: space-between;
  min-height: 800px;
  .ljxgrid-content {
    position: relative;
    padding: 10px;
    min-height: 30px;
    display: flex;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: #fff;
    // color: #fff;
    margin-top: 3px;
    .ljxgrid-content-span {
      color: #3e97df;
    }
  }

  /deep/.el-dialog__header {
    background: rgba(62, 151, 223, 1);
    border-radius: 10px 10px 0 0;
    padding: 0;
    line-height: 56px;
    display: flex;
    justify-content: center;
    .el-dialog__title {
      color: #fff;
      line-height: 56px;
    }
    .el-dialog__headerbtn {
      top: 0;
      line-height: 56px;
      i {
        border: 1px solid rgba(255, 255, 255, 1);
        background: rgba(255, 255, 255, 1);
        border-radius: 50%;
        width: 31px;
        height: 31px;
        line-height: 31px;
        color: #3e97df;
      }
    }
  }

  /deep/.el-dialog__body {
    padding: 0;
    .el-table {
      .el-table__header-wrapper {
        .el-table__header {
          .is-center {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #3e97df;
            line-height: 32px;
          }
        }
      }
    }
  }

  /deep/.el-dialog {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.26);
    border-radius: 1px;
  }

  .let-trainingInstitution-nav {
    color: #3e97df;
    font-size: 14px;
    font-family: Microsoft YaHei;
    text-align: left;
  }
  .grid-content.font-t {
    background-color: #fff;
    .grid-content-span {
      // color: #3e97df;
    }
  }
  .centent_box {
    padding: 0 20px;
    box-sizing: border-box;
    border: 1px solid #c6d1de;
    .location_consult {
      margin-top: 30px;
      font-size: 16px;
      display: flex;
      .location {
        display: flex;
        background-color: #3e97df;
        color: #ffffff;
        height: 38px;
        align-items: center;
        justify-content: center;
      }
      .consult {
        display: flex;
        border: 1px solid #c6d1de;
        height: 38px;
        align-items: center;
        div {
          margin-left: 20px;
        }
      }
    }
    .training {
      //标签分割线
      .training_title {
        margin-top: 59px;
        .el-divider--horizontal {
          .is-center {
            font-size: 20.15px;
            font-weight: bold;
          }
        }
        .underline {
          width: 52px;
          border-bottom: 3px solid #3e97df;
          margin: 0 auto;
        }
      }
      .training_box {
        // height: 210px;
        margin-top: 140px;
        display: flex;
        flex-direction: column;
        .training_box_left {
          height: 100%;
          background-color: #f7f8fb;
          padding: 15px 26px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          .training_box_left_title {
            font-size: 18px;
            font-weight: bold;
            color: #3e97df;
            display: flex;
          }
          .training_box_left_text_circle {
            width: 10px;
            height: 10px;
            background: rgba(62, 151, 223, 1);
            border-radius: 50%;
            display: flex;
            margin-right: 10px;
          }
          .training_box_left_text {
            font-size: 15.77px;
            display: flex;
            align-items: center;
            margin-top: 19px;
          }
        }
        .training_box_right {
          background-color: #f7f8fb;
          padding: 15px 26px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;

          .training_box_right_title {
            font-size: 19px;
            width: 100%;
            color: #3e97df;
            font-weight: bold;
            display: flex;
            text-align: left;
            margin-bottom: 20px;
          }
          .training_box_right_content {
            width: 100%;
            display: flex;
            .training_box_right_content_left {
              font-size: 16px;
              display: flex;
              flex-direction: column;
              .training_box_right_content_left_box {
                display: flex;
                align-items: center;
                margin-bottom: 18px;
                .training_box_right_text_circle {
                  width: 10px;
                  height: 10px;
                  background: rgba(62, 151, 223, 1);
                  border-radius: 50%;
                  display: flex;
                  margin-right: 10px;
                  display: flex;
                }
              }
            }
            .training_box_right_content_right {
              font-size: 16px;
              width: 50%;
              display: flex;

              flex-direction: column;
              .training_box_right_content_right_box {
                display: flex;
                align-items: center;
                margin-bottom: 18px;
                .training_box_right_text_circle {
                  width: 10px;
                  height: 10px;
                  background: rgba(62, 151, 223, 1);
                  border-radius: 50%;
                  display: flex;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
    .place {
      //标签分割线
      .place_title {
        margin-top: 47px;
        .el-divider--horizontal {
          .is-center {
            font-size: 20.15px;
            font-weight: bold;
          }
        }
        .underline {
          width: 52px;
          border-bottom: 3px solid #3e97df;
          margin: 0 auto;
        }
      }
      .place_box {
        margin-top: 127px;
        display: flex;
        flex-direction: column;

        .place_box_title {
          font-size: 18px;
          font-weight: bold;
          color: rgba(102, 102, 102, 1);
          display: flex;
          margin-bottom: 10px;
        }
        .place_box_content {
          border: 1px solid #d8e2f0;
          border-radius: 5px 5px 5px 5px;
          margin-bottom: 20px;
          .el-table--enable-row-transition {
            .el-table__footer-wrapper {
              .el-table__footer {
                .has-gutter {
                  tr {
                    td {
                      color: #3e97df;
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
#trainingInstitution-box .grid-content {
  margin-top: 20px;
}
</style>
// 修改这个组件下el-UI的样式
<style lang="scss">
.ym-trainingInstitution {
  .el-table th > .cell {
    color: #3e97df;
    font-weight: bold;
  }
}
</style>
