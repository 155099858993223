<template>
  <el-row :gutter="20" id="trainingInstitution-box" class="ym-trainingInstitution">
    <el-col :span="24" class="right-trainingInstitution-tabel">
      <div class="let-trainingInstitution-nav">
        <div>
          当前位置:
          <span @click="back_home" class="hyperlink-blue" title="去首页">网站首页</span>>培训机构
        </div>
      </div>

      <div class="grid-content font-t">
        <div>
          <span class="grid-content-span">深圳市安全培训机构登记信息</span>
        </div>
      </div>
      <el-row :gutter="20" class="inner-t" style="margin-left: 0;margin-right: 0;">
        <el-col>
          <el-form :model="searchData" label-width="100px">
            <el-row class="trainingInstitution-seach">
              <el-col :span="6" class="seach-input">
                <el-form-item label="所在区：">
                  <el-select
                    autocomplete="off"
                    v-model="searchData.area"
                    clearable
                    placeholder="请选择所在区名称"
                  >
                    <el-option
                      v-for="item in dictionaryData.area"
                      :key="item.dataCode"
                      :label="item.dataValue"
                      :value="item.dataCode"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="seach-input">
                <el-form-item label="机构名称：">
                  <el-input
                    clearable
                    placeholder="请输入机构名称"
                    v-model.trim="searchData.institutionName"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6" class="seach-input">
                <el-form-item label="培训项目：">
                  <el-select
                    autocomplete="off"
                    v-model="searchData.trainInstitution"
                    clearable
                    placeholder="请选择培训项目名称"
                  >
                    <el-option v-for="item in trainProject" :key="item.id" :value="item.dataValue" ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="search-click">
                <el-button @click="search" type="primary" size="mini">查询</el-button>
                <el-button
                  type="danger"
                  size="mini"
                  class="button-solr-color"
                  @click="clear(searchData)"
                >清空</el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        class="inner-t trainingInstitution-tabel"
        style="margin-left: 0;margin-right: 0;"
      >
        <el-col style="padding: 0">
          <el-table :data="tableData.list" style="width: 1200px" @sort-change="sortChange"	>
            <el-table-column align="center" width="50" label="序号" color="#3E97DF">
              <template slot-scope="scope">
                <span class="uid-class">{{scope.$index+1}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" width="80" label="所属区域" prop="area"></el-table-column>
            <el-table-column align="center" label="机构名称" min-width="100" prop="institutionName"></el-table-column>
            <el-table-column align="center" label="机构地址" min-width="120" prop="institutionAddress"></el-table-column>
            <el-table-column align="center" width="120" label="咨询电话" prop="telephone"></el-table-column>
            <el-table-column align="center" width="280" label="培训项目" prop="institutionProject">
              <template slot-scope="scope">
                <pre v-html='scope.row.institutionProject' style="text-align:left;word-wrap:break-word;white-space: pre-wrap;font-family: Avenir,Helvetica,Arial,sans-serif;">
                </pre>
              </template>
            </el-table-column>
            <!-- <el-table-column align="center" sortable :sort-method="numberSort" width="100" label="培训人数" prop="trainNumTotal"></el-table-column> -->
            <!-- <el-table-column align="center" width="50" label="合格人数" prop="passNumTotal"></el-table-column> -->
            <!-- <el-table-column align="center" width="80" sortable='custom' :sort-method="persentSort" label="2022年特种作业初次培训合格率111" prop="passRateTotal"></el-table-column> -->
            <el-table-column label="培训合格率" width="100">
              <template slot-scope="scope" align="center">
                <el-button type="text" size="mini" @click="showDetails(scope.row)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="caozuo" style="margin-top: 20px;float:right;">
            <el-pagination
              @size-change="size_change"
              @current-change="current_change"
              :current-page.sync="tableData.currPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="tableData.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableData.totals"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
import { mapState } from "vuex";
import cTree from "@/components/cTree.vue";
export default {
  data() {
    return {

      searchData: {
        area: "",
        institutionName: "",
        trainInstitution: ""
      },
      tableData: {
        list: [],
        totals: 0,
        pageSize: 10,
        currPage: 1
      },
      dictionaryData: {
        // 所在区
        area: [],
        trainProjectParent: []
      },
      trainProject: [],
      detailsData: [],
      detailsDialog: false,
      requestNums: 0,
      hasRequestNums: 0
    };
  },
  created() {
    this.getOption();
    this.init();
    this.getTrainInstitutionPage();
    this.dictionary_data();
  },
  mounted() {},
  methods: {
    sortChange({ column, prop, order }){
      // sort -- desc, asc
      if(order==="ascending"){
        this.sort = "asc"
      }else if(order === "descending"){
        this.sort = "desc"
      }else{
        this.sort = ""
      }
      this.getTrainInstitutionPage();
    },
      numberSort(a,b){
          return a.trainNumTotal-b.trainNumTotal
      },
      persentSort(a,b){
          return a.passRateTotal.replace('%','') - b.passRateTotal.replace('%','')
      },
    // 查询
    search() {
      this.init();
      this.getTrainInstitutionPage();
    },
    clear(obj) {
      Object.keys(obj).forEach(x => {
        obj[x] = "";
      });
      this.getTrainInstitutionPage();
    },
    size_change(size) {
      this.tableData.pageSize = size;
      this.getTrainInstitutionPage();
    },
    current_change(currPage) {
      this.tableData.currPage = currPage;
      this.getTrainInstitutionPage();
    },
    // 初始化列表
    init() {
      let urlA = "szsti.AdminEnd.ExamCertificate.showExamCertificate";
      let paramA = {
        totals: this.tableData.totals,
        pageSize: this.tableData.pageSize,
        currPage: this.tableData.currPage,
        ...this.searchData
      };
      this.$http(urlA, paramA, true).then(x => {
        console.log(x.list);
        this.tableData = x;
        !this.tableData.list.length &&
          (this.tableData.list = [

          ]);
      });
    },
    getTrainInstitutionPage() {
      let url = "szsti.AdminEnd.TrainInstitution.selectPages";
      let param = {
        CONSUMER_ID: "system",
        currPage: this.tableData.currPage,
        pageSize: this.tableData.pageSize,
        area: this.searchData.area,
        sort:this.sort,
        institutionName: this.searchData.institutionName,
        institutionProject: this.searchData.trainInstitution
      };
      this.$http(url, param, false)
        .then(res => {
          console.log(res)
          if (res.data.BODY.code == 200 && res.data.BODY.success == "true") {
            const datas = res.data.BODY.data.pageList;
            datas.map((item, index) => {
              item.passRateTotal = !!item.passRateTotal ? Number(item.passRateTotal * 100).toFixed(2) + "%" : '';

            });
            this.tableData.list = datas;
            for (var i = 0; i < this.tableData.list.length; i++) {
              this.tableData.list[i].uid = i + 1;
            }
            this.tableData.totals = res.data.BODY.data.totalRow;
          } else {
            this.$message({
              message: "分页查询失败，失败信息：" + res.data.BODY.message,
              type: "warning"
            });
          }
        })
        .catch(y => {
          this.$message.error(y);
        });
    },
    handleCurrentChange(val, num) {
      if (num) {
        this.tableData.currPage += num;
      } else {
        this.tableData.currPage = val;
      }
      this.init();
    },
    jumpPage(val) {
      switch (val) {
        case "1": //跳转到首页
          this.handleCurrentChange(1);
          break;
        case "0": //跳转到末页
          this.handleCurrentChange(
            this.tableData.totals / this.tableData.pageSize
          );
          break;
        case "-1": //上一页
          this.handleCurrentChange("-1", -1);
          break;
        case "+1": //下一页
          this.handleCurrentChange("+1", +1);
          break;
      }
    },
    // 初始字典
    getOption() {
      this.$store.dispatch("modifyOptionData", this.url);
    },
    dictionary_data() {
      let _this = this;
      let url = "szsti.system.DataCode.findDataCodeByParentCode";
      const dictionaryData = Object.keys(this.dictionaryData);
      this.requestNums = dictionaryData.length;
      dictionaryData.forEach(x => {
        this.hasRequestNums++;
        let param = {
          CONSUMER_ID: "system",
          parentCode: x
        };
        this.$http(url, param, false)
          .then(res => {
            _this.dictionaryData[x].push(...res.data.BODY.data);
            if (x === "trainProjectParent") {
              _this.getTrainProject();
            }
          })
          .catch(y => {
            this.$message.error(y);
          });
      });
    },
    getTrainProject() {
      let _this = this;
      let url = "szsti.system.DataCode.findDataCodeByParentCode";
      this.dictionaryData.trainProjectParent.forEach(t => {
        let param = {
          CONSUMER_ID: "system",
          parentCode: t.dataValue + 1
        };
        this.$http(url, param, false)
          .then(res => {
            _this.trainProject.push(...res.data.BODY.data);
            if (this.hasRequestNums == this.requestNums) {
              var obj = {};
              var tempArr = [];
              _this.trainProject.forEach(item => {
                if (!obj[item.dataValue]) {
                  tempArr.push(item);
                  obj[item.dataValue] = true;
                }
              });
              _this.trainProject = tempArr;
            }
            console.log("train",_this.trainProject)
          })
          .catch(y => {
            this.$message.error(y);
          });
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      } else {
        return "";
      }
    },
    back_home() {
      this.$router.push("/xxfww/home");
    },
    changeOption() {},
    passRateTotalFormatter(row) {
      return row.passRateTotal * 100.0 + "%";
    },
    passRateFormatter(row) {
      return row.passRate * 100.0 + "%";
    },
    showDetails(row) {
      console.log(row.institutionAddress);
      const datas = row.institutionProjects;
      datas &&
        datas.map((item, index) => {
          item.passRate = Number(item.passRate * 100).toFixed(2) + "%";
        });
      this.$router.push({
        path: "/xxfww/passDetail?id=" + row.id,
        query: {
          id: row.id,
          organizationName: row.institutionName,
          phone: row.telephone,
          addRess: row.institutionAddress
        }
      });
      this.detailsData = row.institutionProjects;

      // this.detailsDialog = true;
    }
  },
  computed: {
    ...mapState({
      optionData: state => state.option_data
    })
  },
  components: { cTree }
};
</script>
<style>
body .el-table th.gutter {
  display: table-cell !important;
}
</style>
<style lang="scss" scoped>
#trainingInstitution-box {
  display: flex;
  justify-content: space-between;
  min-height: 800px;
  .grid-content {
    position: relative;
    padding: 10px;
    min-height: 30px;
    display: flex;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: #3e97df;
    color: #fff;
    margin-top: 0.2rem;
    margin-bottom: 16px;
  }

  /deep/.el-dialog__header {
    background: rgba(62, 151, 223, 1);
    border-radius: 10px 10px 0 0;
    padding: 0;
    line-height: 56px;
    display: flex;
    justify-content: center;
    .el-dialog__title {
      color: #fff;
      line-height: 56px;
    }
    .el-dialog__headerbtn {
      top: 0;
      line-height: 56px;
      i {
        border: 1px solid rgba(255, 255, 255, 1);
        background: rgba(255, 255, 255, 1);
        border-radius: 50%;
        width: 31px;
        height: 31px;
        line-height: 31px;
        color: #3e97df;
      }
    }
  }

  /deep/.el-dialog__body {
    padding: 0;
    .el-table {
      .el-table__header-wrapper {
        .el-table__header {
          .is-center {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #3e97df;
            line-height: 32px;
          }
        }
      }
    }
  }

  /deep/.el-dialog {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.26);
    border-radius: 1px;
  }

  .let-trainingInstitution-nav {
    color: #3e97df;
    font-size: 14px;
    font-family: Microsoft YaHei;
    text-align: left;
  }

  .right-trainingInstitution-tabel {
    .trainingInstitution-seach {
      margin-bottom: 30px;

      .seach-input {
        height: 100%;
        height: 38px;
        line-height: 38px;

        .el-form-item {
          .el-form-item__label {
            height: 38px;
            line-height: 38px;
            font-size: 16px;
            color: #333;
            font-family: Microsoft YaHei;
            font-weight: 400;
          }

          .el-form-item__content {
            height: 38px;
            line-height: 38px;
            .el-input--suffix {
              /deep/.el-input__inner {
                height: 38px;
                line-height: 38px;
                font-size: 16px;
              }
              .el-input__icon {
                line-height: 38px;
              }
            }
          }
        }
      }

      .search-click {
        display: flex;
        justify-content: center;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        .el-button {
          height: 38px;
          width: 90px;
        }
      }
    }

    .trainingInstitution-tabel {
      width: 100%;
      height: 100%;
    }

    .explain {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #3e97df;
      line-height: 63px;
      height: 63px;
      text-align: left;
      text-indent: 100px;
      border-radius: 0 0 10px 10px;
    }

    .el-table {
      height: 80%;
      overflow: auto;
      border: 1px solid rgba(216, 226, 240, 1);
      border-radius: 4px 4px 4px 4px;
    }

    .table-class-name {
      color: #3e97df !important;
    }

    .el-table .warning-row {
      background: #ccc;
    }

    .uid-class {
      display: inline-block;
      width: 22px;
      height: 21px;
      background: rgba(62, 151, 223, 1);
      border-radius: 50%;
      color: #fff;
    }
  }
}
</style>
<style scoped="scoped" lang="scss">
#trainingInstitution-box .grid-content {
  margin-top: 0.2rem;
  margin-bottom: 16px;
}
</style>
//修改这个组件下el-UI的样式
<style lang="scss">
.ym-trainingInstitution {
  .el-table th > .cell {
    color: #3e97df;
    font-weight: 600;
  }
}
</style>
