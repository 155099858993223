export default [
    {
        //修改用户
        path: '/userPage', name: '修改用户信息', components: {
            default: function (resolve) { return resolve(require('../views/examinationregistration/userPage.vue').default) },
        }
    },
    {   //登录
        path: '/login', components: {
            default: function (resolve) { return resolve(require('../views/login/index.vue').default) },
        }
    },
    {   //加载页
        path: '/loading', components: {
            default: function (resolve) { return resolve(require('../views/loadingPage/index.vue').default) },
        }
    },
    {   //404
        path: '/404', name: '404页面', components: {
            default: function (resolve) { return resolve(require('../views/404.vue').default) },
        }
    },
    {
        path: '/xxfww', components: {
            default: function (resolve) { return resolve(require('../views/home/HelloWorld.vue').default) },
        },
        children: [
            {
                //首页
                path: 'home', name: '首页', components: {
                    default: function (resolve) { return resolve(require('../views/home/index.vue').default) },
                }
            },
            {
                //首页(修改)
                path: 'homeup', name: '首页', components: {
                    default: function (resolve) { return resolve(require('../views/home/indexx.vue').default) },
                }
            },
            {
                //考试指引(危险化学品)
                path: 'chemicals', name: '考试指引危险化学', components: {
                    default: function (resolve) { return resolve(require('../views/home/chemicals.vue').default) },
                }
            },
            {
                //考试指引(生产经营)
                path: 'production', name: '考试指引生产经营', components: {
                    default: function (resolve) { return resolve(require('../views/home/production.vue').default) },
                }
            },
            {
                //考试指引(特种作业)
                path: 'special', name: '考试指引特种作业', components: {
                    default: function (resolve) { return resolve(require('../views/home/special.vue').default) },
                }
            },
            {
                //考试成绩查询
                path: 'schoolReport', name: "查询结果", components: {
                    default: function (resolve) { return resolve(require('../views/home/schoolReport.vue').default) }
                }
            },
            {
                //考试成绩查询
                path: 'examinationScore', name: "查询结果", components: {
                    default: function (resolve) { return resolve(require('../views/home/examinationScore.vue').default) }
                }
            },
            {
                //特种作业考试成绩查询
                path: 'schoolReportOfTzzy', name: "查询结果", components: {
                    default: function (resolve) { return resolve(require('../views/home/schoolReport1.vue').default) }
                }
            },
            {
                //通知公告
                path: 'announcement', name: '通知公告', props: true, components: {
                    default: function (resolve) { return resolve(require('../views/announcement/index.vue').default) },
                }
            },
            {
                //通知公告
                path: 'testplan', name: '考试计划', props: true, components: {
                    default: function (resolve) { return resolve(require('../views/announcement/index.vue').default) },
                }
            },
            {
                //特种作业合格率
                path: 'passRate', name: '特种作业合格率', components: {
                    default: function (resolve) { return resolve(require('../views/passRate/index.vue').default) },
                }
            },
            {   //特种作业合格率详情
                path: 'passDetail', name: '详情', components: {
                    default: function (resolve) {
                        return resolve(require('../views/passRate/detail.vue').default)
                    },
                }
            },
            {
                //培训机构
                path: 'trainingInstitution', name: '培训机构', components: {
                    default: function (resolve) { return resolve(require('../views/trainingInstitution/index.vue').default) },
                }
            },
            {
                //培训机构(修改)
                path: 'trainingInstitutionup', name: '培训机构(修改)', components: {
                    default: function (resolve) { return resolve(require('../views/trainingInstitution/indexx.vue').default) },
                }
            },
            {
                //培训机构详情
                path: 'trainingInstitutiondetails', name: '培训机构详情', components: {
                    default: function (resolve) { return resolve(require('../views/trainingInstitution/details.vue').default) },
                }
            },
            {
                //考试点
                path: 'theTestPoint', name: '考试点', components: {
                    default: function (resolve) { return resolve(require('../views/theTestPoint/index.vue').default) },
                }
            },
            {
                //准考证打印
                path: 'printingOfAdmissionTicket', name: '准考证打印', components: {
                    default: function (resolve) { return resolve(require('../views/printingOfAdmissionTicket/index.vue').default) },
                }
            },
            {
                //历史证书老安检
                path: 'historyCertify', name: '深圳市历史证书信息查询系统', components: {
                    default: function (resolve) { return resolve(require('../views/home/historyCertify.vue').default) },
                }
            },
            {
                //2013-2018合格证信息查询
                path: 'managerCertificate', name: '深圳市生产经营单位主要负责人和安全管理人员培训合格证信息查询系统', components: {
                    default: function (resolve) { return resolve(require('../views/home/managerCertificate.vue').default) },
                }
            },
            {
                //文章
                path: 'article', name: '文章', components: {
                    default: function (resolve) { return resolve(require('../views/printingOfAdmissionTicket/article.vue').default) },
                }

            },
            {
                //搜索页
                path: 'searchPage', name: '搜索页', components: {
                    default: function (resolve) { return resolve(require('../views/searchPage/index.vue').default) },
                }

            },
            {
                //常见问答
                path: 'faqs', name: '常见问答', components: {
                    default: function (resolve) { return resolve(require('../views/faqs/index.vue').default) },
                }
            },
            {
                //问题详情
                path: 'problem', name: '问题详情', components: {
                    default: function (resolve) { return resolve(require('../views/faqs/detail.vue').default) },
                }
            },
            //查询结果
            {
                path: 'details', name: '查询结果', components: {
                    default: function (resolve) { return resolve(require('../views/printingOfAdmissionTicket/details.vue').default) },
                }
            },
            //信息网准考证打印
            {
                path: 'details', name: '查询结果', components: {
                    default: function (resolve) { return resolve(require('../views/printingOfAdmissionTicket/details.vue').default) },
                }
            },
            //特种作业准考证打印
            {
                path: 'detailsOfTzzy', name: '查询结果', components: {
                    default: function (resolve) { return resolve(require('../views/printingOfAdmissionTicket/details1.vue').default) },
                }
            },
            {
                //证书查询
                path: 'certificateLook', name: '证书查询', components: {
                    default: function (resolve) { return resolve(require('../views/certificateLook/index.vue').default) },
                }
            },

            {
                //特种作业证书查询
                path: 'certificateTz', name: '证书查询', components: {
                    default: function (resolve) { return resolve(require('../views/certificateLook/index.vue').default) },
                }
            },
            {
                //网站导航
                path: 'navigation', name: '网站导航', components: {
                    default: function (resolve) { return resolve(require('../views/foot/navigation.vue').default) },
                }
            },
        ]
    },
    {   //换证系统
        path: '/replacementSystem', components: {
            default: function (resolve) {
                return resolve(require('../views/replacementSystem/index.vue').default)
            },
        }, children: [
            {   //首页
                path: 'homePage', name: '申请换证', components: {
                    default: function (resolve) {
                        return resolve(require('../views/replacementSystem/homePage/index.vue').default)
                    },
                }
            },
            {   //结果查询
                path: 'appointmentLook', name: '结果查询', components: {
                    default: function (resolve) {
                        return resolve(require('../views/replacementSystem/appointmentLook/index.vue').default)
                    },
                }
            },
            {   //常见问题
                path: 'question', name: '常见问题', components: {
                    default: function (resolve) {
                        return resolve(require('../views/replacementSystem/question/index.vue').default)
                    },
                }
            },
        ]
    },
    {//考评员系统
        path: '/examinerSystem', components: {
            default: function (resolve) {
                return resolve(require('../views/examinerSystem/index.vue').default)
            }
        }, children: [
            {   //考评预约
                path: 'evaluationAppointment', name: '考评预约', components: {
                    default: function (resolve) {
                        return resolve(require('../views/examinerSystem/evaluationAppointment/index.vue').default)
                    }
                }
            },
            {   //查询记录
                path: 'queryRecord', name: '查询记录', components: {
                    default: function (resolve) {
                        return resolve(require('../views/examinerSystem/queryRecord/index.vue').default)
                    }
                }
            },
            {   //个人信息
                path: 'personalInfo', name: '个人信息', components: {
                    default: function (resolve) {
                        return resolve(require('../views/examinerSystem/personalInfo/index.vue').default)
                    }
                }
            },
            {   // 考评员费用
                path: 'cost', name: '费用统计', components: {
                    default: function (resolve) {
                        return resolve(require('../views/examinerSystem/cost/cost.vue').default)
                    }
                }
            }
        ]
    },
    {   //考试报名
        path: '/examinationregistration', components: {
            default: function (resolve) {
                return resolve(require('../views/examinationregistration/index.vue').default)
            },
        }, children: [
            {   //首页
                path: 'homePage', name: '考试报名首页', components: {
                    default: function (resolve) {
                        return resolve(require('../views/examinationregistration/homePage/index.vue').default)
                    },
                }
            },
            {   //我要报名
                path: 'applicant', name: '我要报名', components: {
                    default: function (resolve) {
                        return resolve(require('../views/examinationregistration/applicant/index.vue').default)
                    },
                }
            },
            {   //报名查询
                path: 'applicantLook', name: '报名查询', components: {
                    default: function (resolve) {
                        return resolve(require('../views/examinationregistration/applicantLook/index.vue').default)
                    },
                }
            },
            {   //常见问题
                path: 'question', name: '常见问题', components: {
                    default: function (resolve) {
                        return resolve(require('../views/examinationregistration/question/index.vue').default)
                    },
                }
            },
            {
                //问题详情
                path: 'details', name: '问题详情', components: {
                    default: function (resolve) {
                        return resolve(require('../views/examinationregistration/question/details.vue'))
                    }
                }
            }
        ]
    },
    {   //考试预约
        path: '/preRegisteredExamination', components: {
            default: function (resolve) {
                return resolve(require('../views/preRegisteredExamination/index.vue').default)
            }
        }, children: [
            {   //首页
                path: 'homePage', name: '考试预约首页', components: {
                    default: function (resolve) {
                        return resolve(require('../views/preRegisteredExamination/homePage/index.vue').default)
                    },
                }
            },
            {   //我要预约
                path: 'appointment', name: '我要预约', components: {
                    default: function (resolve) {
                        return resolve(require('../views/preRegisteredExamination/appointment/index.vue').default)
                    },
                }
            },
            {   //预约查询
                path: 'appointmentLook', name: '预约查询', components: {
                    default: function (resolve) {
                        return resolve(require('../views/preRegisteredExamination/appointmentLook/index.vue').default)
                    },
                }
            },
            {   //常见问题
                path: 'question', name: '常见问题', components: {
                    default: function (resolve) {
                        return resolve(require('../views/preRegisteredExamination/question/index.vue').default)
                    },
                }
            },
            {
                //问题详情
                path: 'details', name: '问题详情', components: {
                    default: function (resolve) {
                        return resolve(require('../views/examinationregistration/question/details.vue'))
                    }
                }
            }
        ]
    },
    {
        path: '*',
        hidden: true,
        redirect: {
            path: '/xxfww/home'
        }
    },

]
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
