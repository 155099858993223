<template>
  <div>
    <div class="ym-item-title">
      <div class="ym-line"></div>
      <div class="ym-name">培训场地</div>
      <div class="ym-line"></div>
      <div class="ym-bottom-line"></div>
    </div>
    <div class="ym-table">
      <div class="conten-table">
        <div class="table-title">理论课室</div>
        <el-table
          :data="tableData"
          align="center"
          :summary-method="getSummaries"
          show-summary
          :stripe="true"
          style="width: 100%; margin-top: 20px"
        >
          <el-table-column align="center" prop="name" label="教室名称"></el-table-column>
          <el-table-column align="center" prop="area" label="面积(㎡）"></el-table-column>
          <el-table-column align="center" prop="person" label="容纳人数"></el-table-column>
          <el-table-column align="center" prop="place" label="培训位(个）"></el-table-column>
        </el-table>
      </div>
      <div class="conten-table table-bottom">
        <div class="table-title next">实操课室</div>
        <el-table
          :data="practical"
          align="center"
          :summary-method="getSummaries"
          show-summary
          :stripe="true"
          style="width: 100%; margin-top: 10px"
        >
          <el-table-column align="center" prop="name" label="教室名称"></el-table-column>
          <el-table-column align="center" width="310px" prop="catalog" label="培训项目"></el-table-column>
          <el-table-column align="center" prop="area" label="面积(㎡）"></el-table-column>
          <el-table-column align="center" prop="person" label="容纳人数"></el-table-column>
          <el-table-column align="center" prop="place" label="培训位(个）"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      practical: []
    };
  },
  props: {
    dataList: {}
  },

  methods: {
    init() {
      this.getClassRoom();
    },
    getClassRoom() {
      let id = this.dataList.id;
      let url = "szsti.AdminEnd.RegisterDynamic.instistutionInfo";
      let params = {
        CONSUMER_ID: "system",
        id: id
      };
      this.$http(url, params, false)
        .then(res => {
          if (res.data.BODY.code == 200) {
            let datas = res.data.BODY.data;
            this.tableData = datas.theoryClassroom; //实操课室
            if (datas.handsonClassroom.length) {
              this.practical = datas.handsonClassroom; //理论课室
            }
          }
        })
        .catch(error => {});
    },

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
      });

      return sums;
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style scoped="scoped" lang="scss">
.ym-title {
  width: 1200px;
  height: 50px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  margin-bottom: 5px;
  background: #3e97df;
  font-size: 22px;
  line-height: 50px;
}

.ym-phone {
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 30px 0;

  .ym-phone-item {
    flex: 1;
    display: flex;

    .ym-phone-item-title {
      width: 150px;
      height: 38px;
      background: rgba(246, 245, 245, 1);
      border: 1px solid rgba(198, 209, 222, 1);
      line-height: 38px;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      border-right: none;
    }

    .ym-phone-item-text {
      border: 1px solid rgba(198, 209, 222, 1);
      line-height: 38px;
      font-size: 18px;
      box-sizing: border-box;
      padding: 0 20px;
      flex: 1;
      // border-right: none;
    }
  }
}

.ym-item-title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 46px;
  margin-top: 12px;
  margin-bottom: 24px;

  .ym-line {
    width: 210px;
    height: 1px;
    background: #d9d9d9;
  }

  .ym-name {
    width: 140px;
    text-align: center;
    color: #333;
    font-size: 20px;
    font-weight: 700;
  }

  .ym-bottom-line {
    width: 50px;
    height: 3px;
    background: #3e97df;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -25px;
  }
}
.ym-table {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  .table-title {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666;
    text-align: left;
  }
  .next {
    margin-top: 20px;
  }
  .conten-table {
      .el-table {
          border-radius: 5px;
          border: 1px solid #D8E2F0;
      }
  };
  .table-bottom {
      padding-bottom: 40px;
  }
}
</style>
<style lang="scss">
.ym-place {
  .el-table th > .cell {
    text-align: center;
    color: #3e97df;
  }
  /deep/.el-table {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(216, 226, 240, 1);
    border-radius: 5px 5px 5px 5px;
  }
  .el-table th.is-leaf,
  .el-table td {
    border-bottom: none;
  }
  .el-table__footer-wrapper td {
    color: #3e97df;
    font-weight: 600;
  }
  .el-table__header,
  .el-table__body,
  .el-table__footer {
    width: 100% !important;
  }
}
</style>
