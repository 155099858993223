<template>
  <div style="width: 100%">
    <div class="let-trainingInstitution-nav">
      <div>
        当前位置:
        <span @click="back_home" class="hyperlink-blue" title="去首页">网站首页</span>>证书查询
        <span></span>>查询结果
      </div>
    </div>

    

    <div class="content" v-for="(i,index) in certificateInfo" :key="index">
<!--      <div class="head">深圳市安全生产考核合格证书</div>-->
      <div :id="`printMe${index}`">
        <div class="con" >
          <el-row :gutter="20" class="certificate-content">
            <el-col :span="24" class="content-con" style=" padding: 10px 70px 0 90px;">
              <el-form :model="certificateInfo" class="certificate-form" ref="certificateInfo" label-width="70px">
                <el-row class="content-box">
                  <el-col :span="17">
                    <el-form-item label="证号" >
                      <!-- <label><span>证</span><span style="text-indent: 2em;">号 :</span></label> -->
                      ：<span class="value-span">{{i.cardNo}}</span>
                    </el-form-item>
                    <el-form-item label="姓名">
                      <!-- <label>姓&emsp;&emsp;名: </label> -->
                      ：<span class="value-span">{{i.name}}</span>
                    </el-form-item>
                    <el-form-item label="性别">
                      <!-- <label>性&emsp;&emsp;别：</label> -->
                      ：<span class="value-span">{{i.sex}}</span>
                    </el-form-item>
                    <el-form-item label="行业类别">
                      ：<span class="value-span">{{i.workType}}</span>
                    </el-form-item>
                    <el-form-item label="考核类别">
                      ：<span class="value-span">{{i.examItem}}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7">
                    <el-form-item class="image-box">
                      <!-- <img :src="resourceUrl+i.pic" alt="照片" class="nameImage"> -->
                      <!-- <img
                        src="https://ksfw.yjgl.sz.gov.cn/pic/%E8%80%83%E7%94%9F%E6%8A%A5%E5%90%8D/05.jpg"
                        alt="照片"
                        class="nameImage"
                        crossOrigin="anonymous"
                      /> -->

                      <!-- <span>{{certificateInfo.certificateNum}}</span> -->
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row class="time-box content-box">
                  <el-col :span="24">
                    <el-form-item label="初领日期">
                      ：<span class="value-span">{{timeFilter(i.firstDate) }}</span>
                    </el-form-item>
                    <el-form-item label="有效期限">
                      ：<span class="value-span">{{timeFilter(i.validFrom)}}至{{timeFilter(i.validTo)}}</span>
                    </el-form-item>
                    <el-form-item label="领证方式">
                      ：<span class="value-span">{{trainTypeFilter(i.trainType)}}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row class="footer-content">
                  <el-col :span="6">
                    <img :src="qRCodeImage" class="qr-code" title="二维码" />
                  </el-col>
                  <el-col :span="18" class="footer-right">
                    <el-form-item label="发证机关">
                      ：<span class="value-span">{{i.authorizeUnit}}</span>
                    </el-form-item>
                    <el-form-item label="发证时间">
                      ：<span class="value-span">{{timeFilter(i.authorizeDate)}}</span>
                    </el-form-item>
                    <el-form-item label="查询网址">
                      ：<span class="value-span">http://yjgl.sz.gov.cn</span>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </div>
       <div class="footer">
      <!-- <el-button type="primary" v-print="printobj">打印证书</el-button> -->
      <el-button type="primary" @click="downLoad(index)">下载证书</el-button>
    </div>
    </div>
   
  </div>
</template>
<script>
import html2canvas from "html2canvas";
export default {
  data() {
    return {
      qRCodeImage: require("@/image/qr_code.jpg"),
      formData: {},
      certificateInfo: {},
    };
  },
  methods: {
    back_home() {
      this.$router.push("/xxfww/home");
    },
    getCertificateInfo() {
      this.certificateInfo = JSON.parse(sessionStorage.getItem("checkResult"))
      // this.common
      //   .ajax({
      //     method: "post",
      //     url: this.url,
      //     headers: {
      //       "content-type": "multipart/form-data"
      //     },
      //     data: this.formData
      //   })
      //   .then(res => {
      //     if (res.data.BODY.success === "true") {
      //       this.certificateInfo = res.data.BODY.data;
      //       //let pattern = /(\d{4})(\d{2})(\d{2})/;
      //       //this.certificateInfo.certificationTime = this.certificateInfo.certificationTime.substring(0, 10).replace(/-/g, '').replace(pattern, '$1年$2月$3日');
      //     }else if(res.data.BODY.code === 500 ){
      //         this.$message.error(res.data.BODY.message)
      //     }
      //   })
      //   .catch(error => {
      //     console.error(error);
      //   });
    },
    printer_certificate() {
      this.$store.dispatch("addventPrinter", true);
      window.print();
      this.$store.dispatch("addventPrinter", false);
    },
    downLoad(index) {
      html2canvas(document.querySelector(`#printMe${index}`), {
        useCORS: true
      }).then(function(canvas) {
        //获取年月日作为文件名
        let timers = new Date();
        let fullYear = timers.getFullYear();
        let month = timers.getMonth() + 1;
        let date = timers.getDate();
        let randoms = Math.random() + "";
        //年月日加上随机数
        let numberFileName =
          fullYear + "" + month + date + randoms.slice(3, 10);
        let imgData = canvas.toDataURL();
        //保存图片
        let saveFile = function(data, filename) {
          let save_link = document.createElementNS(
            "http://www.w3.org/1999/xhtml",
            "a"
          );
          save_link.href = data;
          save_link.download = filename;

          let event = document.createEvent("MouseEvents");
          event.initMouseEvent(
            "click",
            true,
            false,
            window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
          );
          save_link.dispatchEvent(event);
        };
        //最终文件名+文件格式
        let filename = numberFileName + ".png";
        saveFile(imgData, filename);
        // document.body.appendChild(canvas);  把截的图显示在网页上
      });
    },
    timeFilter(v){
    let newTime = v
     newTime = newTime.slice(0,4)+'年'+newTime.slice(4)
     newTime = newTime.slice(0,7)+'月'+newTime.slice(7)
     newTime = newTime.slice(0,11)+'日'+newTime.slice(11)
     return newTime
    },
    trainTypeFilter(v){
      // TYPE_CP("初培","考核合格发证"),
      // TYPE_BZ("补证","考核合格发证"),
      // TYPE_GZ("改证","考核合格发证"),
      // TYPE_ZR("主任换证","直接申领（安全主任证）"),
      // TYPE_PX("培训换证","直接申领（安全培训合格证）"),
      // TYPE_HZ("换证","延期复审换证");
      switch (v){
        case "初培":
        return '考核合格发证';
         case "补证":
        return '考核合格发证'
         case "改证":
        return '考核合格发证'
         case "主任换证":
        return '直接申领（安全主任证）'
         case "培训换证":
        return '直接申领（安全培训合格证）'
         case "换证":
        return '延期复审换证'
      }
    }
  },
 
  mounted() {
    this.formData = this.$route.query;
    this.getCertificateInfo();
  } ,
  
};
</script>
<style lang="scss" scoped>
.let-trainingInstitution-nav {
    font-family:SimSun;
  color: #3e97df;
  font-size: 16px;
  font-family: Microsoft YaHei;
  text-align: left;
}
.content {
  width: 100%;
  border: 1px solid #ccc;
  margin: 10px auto;
  font-family: "STSong","宋体";
  .head {
    color: #3e97df;
    font-size: 30px;
    padding: 10px 0;
    border-top: 3px solid #3e97df;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
  }
  .con {
    background-image: url(~@/image/WechatIMG38.jpeg);
    background-repeat: no-repeat;
    background-size: 596px 842px;
    height: 880px;

    width: 600px;
    margin: 0 auto;
    position: relative;
    .certificate-content {
      position: absolute;
      margin-top: 241px;
      width: 100%;
      height: 69%;
      font-size: 13px;
      color: #999;
  
      .value-span{
        padding-left: 10px;
      }
      .image-box {
        width: 100%;
        height: auto;
        position: relative;
      }
      .nameImage {
        position: absolute;
        width: 114px;
        height: 142px;
        background: #333333;
        right: 0;
        margin-top: 2px;
      }
      .el-form-item {
        margin-bottom: 0px;
      };
       /deep/.el-form-item__label {
        line-height: 30px;
        font-size: 16px;
      };
      /deep/.el-form-item__content {
        text-align: left;
        line-height: 30px;
        font-size: 16px;
        span {
            font-weight: bold;
            color: #000;
        }
      };

      .time-box {
        position: absolute;
        top: 210px;
        left: 90px;
      }
      .footer-content {
        margin-top: 50px;
        img {
          width: 90px;
          height: 85px;
        }
        .footer-right {
        font-size: 16px;
        }
      }
    }
  }
}

.footer {
 margin: 20px 0 40px;
font-size: 0;
}
.footer-content{
  position: absolute;
  top: 340px;
  left: 90px;
  width:100%;
}
.qr-code{
     opacity: 0;
}
</style>
<style lang="scss">
    .certificate-form{
        label{
            padding: 0;
            text-align:justify;
            text-justify:distribute-all-lines;/*ie6-8*/
            text-align-last:justify;/* ie9*/
            -moz-text-align-last:justify;/*ff*/
            -webkit-text-align-last:justify;/*chrome 20+*/
        }

    }
</style>
