<template>
    <div class="box">
        <div class="let-trainingInstitution-nav">
          <div>
            当前位置:
            <span @click="back_home" class="hyperlink-blue" title="去首页">网站首页</span>>
            <span @click="back" class="hyperlink-blue">培训机构</span> > 培训机构详情

          </div>
        </div>
        <div class="ym-suspension">
            <div :class="['ym-suspension-item',key == 0 ? 'ym-suspension-item-s' : '']" @click="setKey(0,'project')">
                <span>培训项目</span>
            </div>
            <div :class="['ym-suspension-item',key == 1 ? 'ym-suspension-item-s' : '']" @click="setKey(1,'place')">
                <span>培训场地</span>
            </div>
            <div :class="['ym-suspension-item',key == 2 ? 'ym-suspension-item-s' : '']" @click="setKey(2,'strength')">
                <span>师资力量</span>
            </div>
            <div :class="['ym-suspension-item',key == 3 ? 'ym-suspension-item-s' : '']" @click="setKey(3,'standard')">
            培训 </br>收费标准
            </div>
        </div>
        <div class="ym-project">
        <div class="ym-title">{{titleData.name}}</div>
        <div class="ym-phone">
            <div class="ym-phone-item">
                <div class="ym-phone-item-title">机构地址</div>
                <div class="ym-phone-item-text" style="border-right: none;">{{ titleData.address}}</div>
            </div>
            <div class="ym-phone-item">
                <div class="ym-phone-item-title">咨询电话</div>
                <div class="ym-phone-item-text">{{ titleData.counsePhone }}</div>
            </div>
        </div>
        <div class="main">
			<transition   name="fade" mode="out-in"  enter-active-class = "animated fadeIn"  leave-active-class = "animated fadeOut">
			  <component :is="name" :dataList= "dataList"></component>
			</transition>
        </div>
        </div>
    </div>
</template>

<script>
    import project from '@/components/trainingInstitution/project'
    import place from '@/components/trainingInstitution/place'
    import strength from '@/components/trainingInstitution/strength'
    import standard from '@/components/trainingInstitution/standard'

    export default {
        data() {
            return {
                key: 0,
                name : "project",
                dataList: {},
                titleData: { },
                id: '',
            }
        },
        components:{
            project,
            place,
            strength,
            standard
        },
        methods:{
            back_home () {
                this.$router.push("/xxfww/home");
            },
            back () {
                this.$router.push("/xxfww/trainingInstitution");
            },
            //改变动态组件
            setKey (index,val) {
                this.key = index;
                this.name = val;
                this.$forceUpdate()
                this.dataList = JSON.parse(this.$route.query.data)
                console.log(this.dataList);
            }
        },
        mounted () {
          this.titleData = JSON.parse(this.$route.query.data)
          console.log(this.titleData,123123);
            this.dataList = JSON.parse(this.$route.query.data)
        }
    }
</script>

<style scoped="scoped" lang="scss">
    .box {
        width: 1200px;
        .let-trainingInstitution-nav {
          color: #3e97df;
          font-size: 14px;
          font-family: Microsoft YaHei;
          text-align: left;
        }
        .ym-suspension {
            width: 76px;
            overflow: hidden;
            position: fixed;
            top: 2rem;
            right: 1rem;
            z-index: 1200;
            .ym-suspension-item {
                box-sizing: border-box;
                width: 76px;
                height: 76px;
                color: #333;
                display: flex;
                justify-content: center;
                text-align: center;
                align-items: center;
                font-size: 16px;
                line-height: 24px;
                cursor: pointer;
                background:rgba(247,248,251,1);
                box-shadow:0px 2px 7px 0px rgba(62,151,223,0.29);
                span {
                    width: 40px;
                }
            }
            .ym-suspension-item-s {
                background:rgba(62,151,223,1);
                box-shadow:0px 2px 7px 0px rgba(14,112,224,0.29);
                color: #fff;
            }
        }
        .main {
            width: 1200px;
            overflow: hidden;
            position: relative;
        }
               .ym-project{background:rgba(255,255,255,1);
        box-shadow:0px 2px 5px 0px rgba(0, 0, 0, 0.18);
        width: 100%;
        margin-top: 20px;
        margin-bottom: 50px;
        overflow: hidden;
        .ym-title {
            width: 1200px;
            height: 50px;
            color: #fff;
            text-align: center;
            font-weight: 600;
            margin-bottom: 5px;
            background: #3E97DF;
            font-size: 22px;
            line-height: 50px;
        }
        .ym-phone {
            box-sizing: border-box;
            padding: 0 20px;
            width: 100%;
            display: flex;
            margin: 30px 0;
            .ym-phone-item {
                flex: 1;
                display: flex;
                .ym-phone-item-title {
                    width: 150px;
                    height: auto;
                    background:rgba(246,245,245,1);
                    border:1px solid rgba(198,209,222,1);
                    font-weight: 600;
                    font-size: 18px;
                    border-right: none;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    text-align: center;
                }
                .ym-phone-item-text {
                    border:1px solid rgba(198,209,222,1);
                    line-height: 30px;
                    font-size: 16px;
                    box-sizing: border-box;
                    padding: 0 20px;
                    flex: 1;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    text-align: center;
                    // border-right: none;
                }
            }
        }
        }
    }
</style>
