<template>
  <div>
      <div class="let-trainingInstitution-nav">
      <div>当前位置:<span @click="back_home"
              class="hyperlink-blue"
              title="去首页">网站首页</span>>成绩查询<span></span>>查询结果</div>
    </div>
    <div style="padding: 10px 0 30px" v-for="(i,index) in zkzInfo" :key="index">
      <div class="con"
           :id="`printMe${index}`">
        <table>
          <tr>
            <td colspan="5"
                class="head">理论考试成绩单</td>
          </tr>
          <tr class="tableSty">
            <td>
              <span>姓名</span>
            </td>
            <td>
              <span>{{i.realName}}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>身份证号</span>
            </td>
            <td>
              <span>{{i.cardNo}}</span>
            </td>
          </tr>
            <tr>
            <td>
              <span>考试时间</span>
            </td>
            <td>
              <span>{{i.beginTime?i.beginTime:'未参加考试'}}</span>
            </td>
          </tr>
            <tr>
            <td>
              <span>考试成绩</span>
            </td>
            <td>
              <span>{{i.theoryScore1?i.theoryScore1:i.theoryScore}}</span>
            </td>
          </tr>
            <tr>
            <td>
              <span>考试批次</span>
            </td>
            <td>
              <span>{{i.planName.replace('全部考生成绩单','')}}</span>
            </td>
          </tr>
        
        </table>

      </div>
      <div class="footer">
        <el-button type="primary"
                   v-print="Object.assign({},printobj,{id:`printMe${index}`}) ">打印成绩单</el-button>
        <el-button type="primary"
                   @click="save(index)">下载成绩单</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import html2canvas from 'html2canvas'

export default {
  data () {
    return {
      formData: {},
      zkzInfo: {},
      printobj: {
        popTitle: '安全生产考试服务网',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
      }
    };
  },
  methods: {
      back_home () {
      this.$router.push('/xxfww/home');
    },
    init () {
      this.getZkzInfo();
    },
    getZkzInfo () {
      this.zkzInfo  = JSON.parse(sessionStorage.getItem("checkResult"));
      // this.common.ajax({
      //   method: "post",
      //   url: this.url,
      //   headers: {
      //     'content-type': "multipart/form-data"
      //   },
      //   data: this.formData,
      // }).then((res) => {
      //   console.log(res, '查询结果');
      //   if (res.data.data == null) {
      //     console.log("没有数据");
      //     this.$alert('未找到该账号数据,请检查填写信息是否有误', '错误', {
      //       confirmButtonText: '确定',
      //       callback: () => {
      //         // this.$router.push('/xxfww/printingOfAdmissionTicket?type=score');
      //       }
      //     });
      //   }
      //   if (res.data.BODY.success === "true") {
      //     this.zkzInfo = res.data.BODY.data;
      //   }
      // }).catch((error) => {
      //   console.error(error);
      // });
    },
    // 打印  ====打印样式待调
    printer () {
      this.$store.dispatch("addventPrinter", true);
      window.print();
      this.$store.dispatch("addventPrinter", false);

    },
    // 另存为
    save (index) {
      //#proMain:要截图的DOM元素
      //useCORS:true:解决跨域问题
      html2canvas(document.querySelector(`#printMe${index}`), { useCORS: true }).then(function (canvas) {
        //获取年月日作为文件名
        let timers = new Date();
        let fullYear = timers.getFullYear();
        let month = timers.getMonth() + 1;
        let date = timers.getDate();
        let randoms = Math.random() + '';
        //年月日加上随机数
        let numberFileName = fullYear + '' + month + date + randoms.slice(3, 10);
        let imgData = canvas.toDataURL();
        //保存图片
        let saveFile = function (data, filename) {
          let save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
          save_link.href = data;
          save_link.download = filename;

          let event = document.createEvent('MouseEvents');
          event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
          save_link.dispatchEvent(event);
        };
        //最终文件名+文件格式
        let filename = numberFileName + '.png';
        saveFile(imgData, filename);
        //document.body.appendChild(canvas);  把截的图显示在网页上
      })
    }
  },
  mounted () {
    this.formData = this.$route.query;
    this.init();
  }
};
</script>
<style lang="scss" scoped>
.let-trainingInstitution-nav {
  color: #3e97df;
  font-size: 14px;
  font-family: Microsoft YaHei;
  text-align: left;
  padding-left: 20px;
}
.con {
  width: 92%;
  border: 1px solid #ccc;
  margin: 20px auto;

  table {
    border-collapse: collapse;
    width: 100%;

    .tableSty {
      td:nth-child(1) {
        width: 14%;
      }

      td:nth-child(2) {
        width: 30%;
      }

      td:nth-child(3) {
        width: 14%;
      }

      td:nth-child(4) {
        width: 26%;
      }
    }

    td {
      height: 40px;
      border: 1px solid #ccc;
      border-left: none;
      font-size: 16px;
      text-align: center;
      padding: 0 6px;
    }

    .text {
      padding: 6px;

      td {
        text-align: left;

        div {
          padding: 10px;

          h4 {
            padding-left: 45%;
            margin: 8px 0;
          }

          span {
            display: inline-block;
            height: 20px;
            font-size: 15px;
            font-family: SimSun;
            font-weight: 400;
            color: rgba(0, 0, 0, 1);
          }
        }
      }
    }
  }

  .head {
    color: #000000;
    font-size: 30px;
    font-family: SimSun;
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
    line-height: 60px;
  }

  .content {
    border-bottom: 1px solid #ccc;
    height: 40px;
    line-height: 40px;
  }

  .main {
    .first {
      border-top: 1px solid #ccc;
    }
  }
}
</style>
