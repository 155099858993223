<template>
  <div class="examinationregistration-question">
    <el-row class="sign-up">
      <el-col :span="24" class="singContent">
        <div class="grid-content">
          <img src="../../../image/ksbmsy_icon3.png" />
          <span>问题解答</span>
        </div>
        <div class="page">
          <el-table
            :data="tableData1.list"
            class="inner"
            style="width: 100%;"
            :stripe="true"
            tooltip-effect="dark"
          >
            <el-table-column align="center" label="序号" show-overflow-tooltip prop="id" width="200"></el-table-column>
            <el-table-column align="center" label="问题描述" show-overflow-tooltip>
              <template slot-scope="scope">
                <span
                  :class="scope.row.show?'active-tab':'active-tabs'"
                  @click="showDetails(scope.row)"
                >{{scope.row.question}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="发布时间" prop="pulishTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="快速操作" width="120">
              <template slot-scope="scope" align="center">
                <el-button type="primary" size="mini" @click="showDetails(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page.sync="searchData1.currPage"
            :page-size="searchData1.pageSize"
            :total="tableData1.totals"
            @current-change="getList1"
            class="page-class"
          ></el-pagination>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: "question",
  data() {
    return {
      searchData1: {
        SERVICE_CODE:
          "szsti.ExamineeEnd.SysQuestionAnswer.getSysQuestionAnswerList",
        CONSUMER_ID: sessionStorage.getItem("Token"),
        pageSize: 5,
        currPage: 1
      },
      tableData1: {
        list: [],
        totals: 0
      }
    };
  },
  created() {
    this.init();
  },
  computed: {},
  methods: {
    init() {
      this.getList1();
    },
    //问题详情
    showDetails(datas) {
          this.$router.push({path: '/examinationregistration/details', query: {data: JSON.stringify(datas)}})
      },
    // 列表初始化
    getList1() {
      this.common
        .ajax({
          method: "post",
          url: this.url,
          headers: {
            "content-type": "multipart/form-data"
          },
          data: this.searchData1
        })
        .then(res => {
          if (res.data.BODY.success === "true") {
            this.tableData1.totals = res.data.BODY.data.pageInfo.totalRow;
            this.tableData1.list = res.data.BODY.data.list;
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    // 表格行点击
    active_tab() {}
  }
};
</script>
<style lang="stylus" scoped>
.sign-up {
  margin-bottom: 20px;
  background-color: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);

  .singContent {
    .page {
      padding: 20px 40px 20px 16px;

      .el-table {
        margin-bottom: 10px;
      }
    }
  }
}

.el-table__body-wrapper {
  overflow: hidden;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.question-content-title-box {
  margin-bottom: 0;
  border: 1px solid grey;
}

.question-content-title-top {
  font-size: 24px;
  font-weight: bold;
  font-family: Source Han Sans CN;
  color: rgba(66, 71, 75, 1);
}

.question-content-title-box-top {
  border: 1px solid grey;
  border-top: none;
}

.active-tab {
  color: red;
  text-decoration: underline;
}

.active-tabs:hover {
  color: red;
  text-decoration: underline;
}

.grid-content {
  background-color: #3E97DF;
  font-size: 0.2rem;
  font-weight: bold;
  font-family: Source Han Sans CN;
  color: rgba(66, 71, 75, 1);
  padding: 0.08rem 0.2rem;
  min-height: 26px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  img {
    margin-left: 0;
    height: 45%;
  }

  span {
    margin: 0 10px;
    color: #ffffff;
  }

  .link_more span:link {
    color: #3E97DF;
  }

  .link_more span:hover {
    color: #3E97DF;
  }

  .link_more span:active {
    color: #3E97DF;
  }
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.page-class {
  margin-top: 1%;
}
</style>
<style lang="scss">
.examinationregistration-question {
  .el-table {
    tr {
      background: rgba(62, 151, 223, 0.1);
    }
    th {
      .cell {
        font-size: 0.18rem;
        color: #333;
        font-weight: bold;
      }
    }
    .el-table__row--striped {
      background: #fff;
      td {
        background: #fff;
      }
    }
  }
}
</style>
