<template>
  <div class="examinationregistration-question">
    <el-row class="sign-up">
      <el-col :span="24" class="singContent">
        <div class="grid-content">
          <img src="../../../image/wtxq1.png" />
          <span>问题详情</span>
        </div>
        <div class="page">
          <el-row>
            <el-col class="content-col">
              <div class="font-title">
                <span>{{ detailData.question }}</span>
              </div>
              <div class="title-det">
                <span>发布时间&nbsp;</span>
                &nbsp;
                <span class="title-det-time">&nbsp;{{ detailData.pulishTime }}</span>
              </div>
              <el-row class="content-con">
                <el-col>
                  <div class="question-content">{{ detailData.answer}}</div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-button type="primary" @click="goBack">返回</el-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detailData: {}
    };
  },
  mounted() {
    this.detailData = JSON.parse(this.$route.query.data);
  },
  methods: {
    goBack() {
        this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss" scoped>
.sign-up {
  margin-bottom: 20px;
  background-color: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);
  .grid-content {
    background-color: #3e97df;
    font-size: 18px;
    font-weight: bold;
    font-family: Source Han Sans CN;
    color: rgba(66, 71, 75, 1);
    padding: 10px;
    min-height: 26px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    img {
      margin-left: 0;
      height: 45%;
    }

    span {
      margin: 0 10px;
      color: #ffffff;
    }

    .link_more span:link {
      color: #3e97df;
    }

    .link_more span:hover {
      color: #3e97df;
    }

    .link_more span:active {
      color: #3e97df;
    }
  }

  .grid-content_title {
    text-indent: 0.2rem;
    font-size: 18px;
    word-break: break-all;
    text-align: left;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #878d99;
  }
  .singContent {
    .page {
      padding: 20px 30px 40px;

      .el-table {
        margin-bottom: 10px;
      }
      .content-col {
        .font-title {
          font-size: 30px;
          margin-top: 13px;
          margin-bottom: 37px;
          span {
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333;
          }
        }
        .title-det {
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666;
          margin-bottom: 45px;
          span {
            font-size: 18px;
          }
          .title-det-time {
            font-size: 16px;
          }
          font-size: 14px;
        }
        .content-con {
          padding: 0 20px;
          .question-content {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333;
          }
        }
      }
    }
  }
}
.el-button--primary {
  font-size: 20px;
  width: 140px;
  height: 50px;
  background: rgba(62, 151, 223, 1);
  border-radius: 4px;
}
</style>
