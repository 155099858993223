import axios from 'axios'
import { Message } from 'element-ui'
export default {
  ajax(param) {
    let res = new Promise(function(resolve, reject) {
      axios(param)
        .then(res => {
          if (res.data.SYS_HEAD.RET[0].RET_CODE == '000000') {
            //当请求成功时且code为000000时,直接将数据进行返回
            resolve(res)
          } else {
            if (res.data.SYS_HEAD.RET[0].RET_CODE == '008899') {
              //当请求失败且code为008899时,将Token清除,并跳转至登录界面
            //   let tokens = sessionStorage.getItem('Tokens');
            // let code = sessionStorage.getItem('code');
           
              Message.error('登录信息已失效,请重新登录!')
              sessionStorage.removeItem('Token')
              setTimeout(function() {
                window.location.href =
                  window.location.href.split('#')[0] + '#/Login'
              }, 100)
            } else {
              resolve(res)
            }
          }
        })
        .catch(error => {
          reject(error)
        })
    })
    return res
  },
  examinerAjax(param) {
    let res = new Promise(function(resolve, reject) {
      axios(param)
        .then(res => {
          if (res.data.SYS_HEAD.RET[0].RET_CODE == '000000') {
            //当请求成功时且code为000000时,直接将数据进行返回
            resolve(res)
          } else {
            if (res.data.SYS_HEAD.RET[0].RET_CODE == '008899') {
              //当请求失败且code为008899时,将Token清除,并跳转至登录界面
              Message.error('登录信息已失效,请重新登录!');
              sessionStorage.removeItem('examiner_token');
              setTimeout(function() {
                 window.location.href ='https://ksfw.yjgl.sz.gov.cn/login?type=examinerSystem';
               // window.location.href ='https://szksfw.yjgl.sz.gov.cn:8081/login?type=examinerSystem';
              }, 100)
            } else {
              resolve(res)
            }
          }
        })
        .catch(error => {
          reject(error)
        })
    })
    return res
  },
  //判断正确的手机格式
  validPhone(rule, value, callback) {
    console.log('common,' + value + rule)
    if (!value) {
      callback(new Error('请输入联系电话'))
    } else if (!isvalidPhone(value)) {
      callback(new Error('请输入正确的11位手机号码'))
    } else {
      callback()
    }
  }
}

// 手机号验证
export function isvalidPhone(str) {
  const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
  return reg.test(str)
}
