<template>
    <div>
        <div class="ym-item-title">
            <div class="ym-line"></div>
            <div class="ym-name">培训项目</div>
            <div class="ym-line"></div>
            <div class="ym-bottom-line"></div>
        </div>
        <!-- <div class="ym-item-project">
            <div class="ym-item-project-item">
                <div class="ym-item-project-name">特种作业初训</div>
                <div class="ym-item-project-text">高压电工作业，低压电工作业</div>
            </div>
            <div class="ym-item-project-item ym-item-project-item1">
                <div class="ym-item-project-name">特种作业复训</div>
                <div class="ym-item-project-text">电力电缆作业，继电保护作业</div>
            </div>
            <div class="ym-item-project-item ym-item-project-item2">
                <div class="ym-item-project-name">主要负责人及安全管理人员</div>
                <div class="ym-item-project-text">危险化学品生产经营单位，锂电池生产加工和储存单位，粉尘涉爆单位，涉氨制冷单位</div>
            </div>
        </div> -->
        <div class="ym-table">
            <el-table :data="datas" align="center" :stripe="true" style="width: 100%; margin-top: 20px">
                <el-table-column align="center" prop="value" label="培训类别">
                </el-table-column>
                <el-table-column align="center" prop="workcategory" label="培训名称">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
   export default {
        data() {
            return {
                datas: []
            };
        },
        props: {
            dataList: {}
        },
        mounted() {
            this.getProject()
        },
        methods: {
            getProject () {
                let id = this.dataList.id;
                console.log(this.dataList,id);
                let url = "szsti.AdminEnd.RegisterDynamic.getWorkcategoryByInstistution";
                let params = {
                    CONSUMER_ID: 'system',
                    instistutionId: id,
                    type: 'workcategory'
                };
                this.$http(url, params, false).then(res => {
                    console.log(res.data.BODY);
                    if(res.data.BODY.status == 200) {
                        this.datas = res.data.BODY.data
                    }
                })
            }
        }
    };
</script>

<style scoped="scoped" lang="scss">
        .ym-item-title {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            height: 46px;
            margin-top: 12px;
            margin-bottom: 24px;
            .ym-line {
                width: 210px;
                height: 1px;
                background: #D9D9D9 ;
            }
            .ym-name {
                width: 140px;
                text-align: center;
                color: #333;
                font-size: 20px;
                font-weight: 700;
            }
            .ym-bottom-line {
                width: 50px;
                height: 3px;
                background: #3E97DF;
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -25px;
            }
        };
        .ym-table {
            padding: 0 20px 40px;
        }
        // .ym-item-project {
        //     width: 100%;
        //     padding: 0 20px;
        //     display: flex;
        //     justify-content: space-between;
        //     box-sizing: border-box;
        //     .ym-item-project-item {
        //         width: 395px;
        //         height: 224px;
        //         background: url(../../image/pxxmbg.png) no-repeat left top/100%;
        //         .ym-item-project-name {
        //             margin-top: 100px;
        //             font-size: 18px;
        //             color: #fff;
        //             padding: 0 30px;
        //             text-align: center;
        //         }
        //         .ym-item-project-text {
        //             color: #fff;
        //             font-size: 14px;
        //             margin-top: 24px;
        //             padding: 0 30px;
        //         }
        //     }
        //     .ym-item-project-item1 {
        //         background: url(../../image/pxxmbg1.png) no-repeat left top/100%;
        //     }
        //     .ym-item-project-item2 {
        //         background: url(../../image/pxxmbg2.png) no-repeat left top/100%;
        //     }
        // }
</style>
