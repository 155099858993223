<template>
    <el-row id="search-page">
        <el-row class="search-nav-title">
            <div>
                当前位置:
                <span @click="back_home" class="hyperlink-blue" title="去首页"
                    >网站首页</span
                >
                <span>>搜索</span>
            </div>
        </el-row>
        <el-row>
            <el-col :span="24" class="search-nav">
                <el-tag
                    v-for="(tag, index) in tagsData"
                    :class="[index > 0 ? 'm-l' : '',tag.show === true ? 'hyperlink-blue' : '']"
                    type="info"
                    :key="tag.columnName"
                    v-on:click="columnSelect(tag.id)"
                    style="line-height:0.48rem"
                >
                    {{ tag.columnName }}({{ tag.num }})
                    <!-- ({{tag.columnContent?tag.columnContent.length:0}}) -->
                </el-tag>
            </el-col>
        </el-row>
        <div class="nav-string"></div>
        <el-row>
            <el-col :span="17">
                <div class="content-title">
                    为您找到
                    <span style="color:red">
                        {{ this.$route.query.searchData }}
                    </span>
                    相关结果约
                    <span style="color:red">{{ newContentData.totals }}</span>
                    个
                </div>
                <el-row>
                    <el-row class="search-nav hlva" v-for="item in newContentData.list" :key="item.id">
                        <el-col :span="22">
                            <div style="margin-bottom:20px" @click="seachDetail(item)">
                                <div class="font-white" style="font-weight: bold;">
                                    <div class="search-titles font-white m-b">
                                        <span style="color:#3E97DF; font-weight:bold" v-html="'[' + cloumnIdFilter(item.columnId) + ']'"></span>
                                        <span style="font-weight:bold" v-html="item.title"></span>
                                    </div>
                                </div>
                                <div class="font-white m-b">
                                    <span v-html="item.sketch"></span>
                                </div>
                                <div class="font-white">
                                    <span style="color:#3E97DF;" v-html="item.externalLink"></span>
                                </div>
                            </div>
                        </el-col>
                        <!-- v-if="item.externalLink!==''" -->
                        <!-- <el-col :span="8" v-if="!!item.externalLink">
                            <a :target="item.externalLink"
                                >搜索地址栏:{{ item.externalLink }}</a
                            >
                        </el-col> -->
                    </el-row>
                </el-row>
            </el-col>
            <el-col :span="7" class="recommend">
                <div class="title">
                    <img src="../../image/sy_bt.png" />
                    <span>为您推荐</span>
                </div>
                <div class="recommend-box">
                    <!-- <span v-for="tag in tags"  :key="tag.name">{{tag.name}}</span> -->
                    <el-tag class="need-point"
                    @click="searchIntroduce(tag)"
                        v-for="tag in tags"
                        :key="tag.name"
                        :type="tag.type"
                        >{{ tag.name }}</el-tag
                    >
                </div>
            </el-col>
        </el-row>
        <el-pagination
            class="pagClass"
            @size-change="size_change"
            @current-change="current_change"
            :current-page.sync="newContentData.currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="newContentData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="newContentData.totals"
        ></el-pagination>
    </el-row>
</template>
<script>
function color16() {
    //十六进制颜色随机
    var r = Math.floor(Math.random() * 256);
    var g = Math.floor(Math.random() * 256);
    var b = Math.floor(Math.random() * 256);
    var color = "#" + r.toString(16) + g.toString(16) + b.toString(16);
    return color;
}
export default {
    data() {
        return {
            loading: true,
            newContentData: {
                list: [],
                totals: 0,
                pageSize: 10,
                currentPage: 1
            },
            tags: [
                { name: "报考", type: "warning" },
                { name: "考试", type: "success" },
                { name: "发证", type: "info" },
                { name: "特种作业", type: "warning" },
                { name: "电工证", type: "danger" },
                { name: "微化品", type: "success" },
                { name: "主要负责人", type: "info" },
                { name: "安全管理人员", type: "warning" }
            ],
            tagsData: []
        };
    },
    mounted() {
        this.$store.dispatch("modifySetTabPage", "");
        this.getDataTags();
        this.getNewContentData();
    },
    methods: {
        cloumnIdFilter(v){
            return this.tagsData.filter(i=>i.id==v)[0].columnName
        },
        back_home() {
            this.$router.push("/xxfww/home");
        },
        size_change(size) {
            this.newContentData.pageSize = size;
            this.getNewContentData();
        },
        current_change(currPage) {
            this.newContentData.currentPage = currPage;
            this.getNewContentData();
        },
        seachDetail(item) {
            this.$router.push(
                `/xxfww/announcement?childId=${item.columnId}&detailId=${item.id}`
            );
        },
        // 获取新闻页的新闻栏目文章数据
        getNewContentData() {
            this.newContentData.list = [];
            this.loading = true;
            let url = "szsti.publicInfo.ColumnContent.selectPages";
            let param = {
                CONSUMER_ID: "system-70837df36900457cb799035cd8d59a88",
                currPage: this.newContentData.currentPage,
                pageSize: this.newContentData.pageSize,
                title: this.$route.query.searchData
            };
            this.$http(url, param, false)
                .then(res => {
                    this.newContentData.list = res.data.BODY.data.pageList;
                    console.log(res)
                    if (this.newContentData.list.length > 0) {
                        var searchText = this.$route.query.searchData;
                        let pattren = new RegExp(
                            this.$route.query.searchData,
                            "g"
                        );
                        let replaceString =
                            '<span style="color:red">' +
                            this.$route.query.searchData +
                            "</span>";
                        this.newContentData.list.forEach(row => {
                            if (
                                row.sketch == null ||
                                row.sketch == "" ||
                                row.sketch == "null"
                            ) {
                                row.sketch = row.title;
                            }
                            if (searchText && searchText.length > 0) {
                                row.title = row.title.replace(
                                    pattren,
                                    replaceString
                                );
                                row.sketch = row.sketch.replace(
                                    pattren,
                                    replaceString
                                );
                            }
                        });
                    }
                    this.newContentData.totals = res.data.BODY.data.totalRow;
                    this.loading = false;
                })
                .catch(y => {
                    this.$message.error(y);
                });
        },
        // 获取搜索标签动态数据
        getDataTags() {
            let url = "szsti.publicInfo.ColumnNode.frontColumnNodeChildren";
            let param = {
                CONSUMER_ID: "system-70837df36900457cb799035cd8d59a88",
                title: this.$route.query.searchData
            };
            var num = 0;
            this.$http(url, param, false)
                .then(res => {
                    /*
                    Object.assign(this.tagsData, {
                        columnName: '全部',
                        id: '',
                        num: '',
                        show: true
                    });
            */

                    if (res.data.BODY.data.length > 0) {
                        console.log(res.data.BODY.data)
                        res.data.BODY.data.forEach(c => {
                            num = num + c.num;
                            if (
                                c.columnName == "通知公告" ||
                                c.columnName == "工作指引" ||
                                c.columnName == "法规政策" ||
                                c.columnName == "常见问答" ||
                                c.columnName == "考试计划" ||
                                c.columnName == "考试大纲"
                            ) {
                                this.tagsData.push({
                                    id: c.id,
                                    columnName: c.columnName,
                                    num: c.num,
                                    show: false
                                });
                                console.log(this.tagsData)
                            }
                        });
                    }
                    this.tagsData.unshift({
                        id: "",
                        columnName: "全部",
                        num: num,
                        show: true
                    });
                })
                .catch(y => {
                    this.$message.error(y);
                });
        },
        searchIntroduce(tag){
            
            this.$emit('search',tag)
        },
        // 根据搜索标签分类筛选文章数据
        columnSelect(id) {
            this.tagsData.forEach(c => {
                if (c.id == id) {
                    c.show = true;
                } else {
                    c.show = false;
                }
            });
            this.newContentData.list = [];
            this.loading = true;
            let url = "szsti.publicInfo.ColumnContent.selectPages";
            let param = {
                CONSUMER_ID: "system-70837df36900457cb799035cd8d59a88",
                currPage: this.newContentData.currentPage,
                pageSize: this.newContentData.pageSize,
                title: this.$route.query.searchData,
                columnId: id
            };
            this.$http(url, param, false)
                .then(res => {
                    this.newContentData.list = res.data.BODY.data.pageList;
                    if (this.newContentData.list.length > 0) {
                        var searchText = this.$route.query.searchData;
                        let pattren = new RegExp(
                            this.$route.query.searchData,
                            "g"
                        );
                        let replaceString =
                            '<span style="color:red">' +
                            this.$route.query.searchData +
                            "</span>";
                        this.newContentData.list.forEach(row => {
                            if (
                                row.sketch == null ||
                                row.sketch == "" ||
                                row.sketch == "null"
                            ) {
                                row.sketch = row.title;
                            }
                            if (searchText && searchText.length > 0) {
                                row.title = row.title.replace(
                                    pattren,
                                    replaceString
                                );
                                row.sketch = row.sketch.replace(
                                    pattren,
                                    replaceString
                                );
                            }
                        });
                    }
                    this.newContentData.totals = res.data.BODY.data.totalRow;
                    this.loading = false;
                })
                .catch(y => {
                    this.$message.error(y);
                });
        }
    },
    computed: {
        dataList() {
            let list = [];
            this.newContentData.forEach(element => {
                if (element.show === true) {
                    list = element.columnContent;
                }
            });
            return list;
        }
    },
   
    watch: {
        $route(newValue, oldValue) {
            if (newValue.query.searchData !== oldValue.query.searchData) {
                this.getNewContentData();
            }
        }
    }
};
</script>
<style lang="stylus">
#search-page {
  width: 100%;
  min-height: 800px;

  .pagClass {
    position: initial;
    display: block;
    font-size: 18px;
    margin-bottom: 16px;
    text-align: left;
  }

  .search-nav-title {
    color: #3E97DF;
    font-size: 14px;
    font-family: Microsoft YaHei;
    text-align: left;
  }

  .search-nav {
    text-align: left;
    margin-top: 0.2rem;
    font-size: 0.16rem;

    span {
      dispaly: inline-block;
      width: 1.3rem;
      height: 0.48rem;
      text-align: center;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333;
    //   background-color: #EBF4FC;
      margin-top: 0.1rem;
    }

    span:nth-child(7) {
      margin-right: 0.2rem;
    }

    span:nth-child(8) {
      margin-left: 0;
    }

    .search-titles {
      font-size: 0.2rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 0, 8rem;
    }
  }

  .nav-string {
    margin: 0.1rem 0 0.19rem;
    width: 100%;
    border-top: 1px solid #ccc;
  }

  .recommend {
    text-align: left;
    border: 1px solid #ccc;
    min-height: 150px;
    padding: 10px;

    .title {
      font-weight: bold;
      color: #3E97DF;
      font-size: 0.16rem;
      display: flex;
      align-items: center;

      img {
        height: 17px;
      }

      span {
        margin-left: 5px;
      }

      margin-bottom: 10px;
    }

    .recommend-box {
      display: flex;
      flex-flow: row wrap;

      .el-tag {
        margin-right: 3px;
        margin-bottom: 3px;
        height: 0.35rem;
        line-height: 0.35rem;
      }
    }
  }

  .content-title {
    text-align: left;
    font-size: 0.16rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
}
</style>
