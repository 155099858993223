import axios from "axios"
import Vue from 'vue'

// 政务迁移环境
// {
// Vue.prototype.url = 'https://10.224.156.17:80/DataBus/exchange';
// // // Vue.prototype.url = 'http://172.16.0.91:8080/DataBus/exchange';

// Vue.prototype.imageUrl = 'https://10.224.156.17:80/DataBus/uploadIpad/ipadFile.shtml';
// Vue.prototype.resourceUrl = 'https://10.224.156.17:80/pic/';
// Vue.prototype.casUrl = 'http://tyrztest.gd.gov.cn/tif/sso/connect/page/oauth2/authorize';
// Vue.prototype.clientId = 'gdbs_test02';
// Vue.prototype.redirectUrl = encodeURIComponent('https://10.224.156.17:80/');

// Vue.prototype.tzzyUrl = 'https://10.224.156.17:80/examiner/';   // 特种作业系统
// Vue.prototype.baseUrl = "https://10.224.156.17:80/DataBus";
// }

{
 // 正式环境
Vue.prototype.url = 'https://ksfw.yjgl.sz.gov.cn/DataBus/exchange';
// // Vue.prototype.url = 'http://172.16.0.91:8080/DataBus/exchange';

Vue.prototype.imageUrl = 'https://ksfw.yjgl.sz.gov.cn/DataBus/uploadIpad/ipadFile.shtml';
Vue.prototype.resourceUrl = 'https://ksfw.yjgl.sz.gov.cn/pic/';
Vue.prototype.casUrl = 'http://tyrztest.gd.gov.cn/tif/sso/connect/page/oauth2/authorize';
Vue.prototype.clientId = 'gdbs_test02';
Vue.prototype.redirectUrl = encodeURIComponent('https://ksfw.yjgl.sz.gov.cn/');
// Vue.prototype.tzzyUrl = 'https://139.9.209.18:8082/';   // 特种作业系统
Vue.prototype.tzzyUrl = 'https://ksfw.yjgl.sz.gov.cn/examiner/';   // 特种作业系统
Vue.prototype.baseUrl = "https://ksfw.yjgl.sz.gov.cn/DataBus";
}

 // 政务云环境
// Vue.prototype.url = 'https://szksfw.yjgl.sz.gov.cn:8081/DataBus/exchange';
// // // Vue.prototype.url = 'http://172.16.0.91:8080/DataBus/exchange';

// Vue.prototype.imageUrl = 'https://szksfw.yjgl.sz.gov.cn:8081/DataBus/uploadIpad/ipadFile.shtml';
// Vue.prototype.resourceUrl = 'https://szksfw.yjgl.sz.gov.cn:8081/pic/';
// Vue.prototype.casUrl = 'http://tyrztest.gd.gov.cn/tif/sso/connect/page/oauth2/authorize';
// Vue.prototype.clientId = 'gdbs_test02';
// Vue.prototype.redirectUrl = encodeURIComponent('https://szksfw.yjgl.sz.gov.cn:8081/');
// // Vue.prototype.tzzyUrl = 'https://139.9.209.18:8082/';   // 特种作业系统
// Vue.prototype.tzzyUrl = 'https://szksfw.yjgl.sz.gov.cn:8081/examiner/';   // 特种作业系统
// Vue.prototype.baseUrl = "https://szksfw.yjgl.sz.gov.cn:8081/DataBus";
// }
//  演示环境
// Vue.prototype.url = 'http://121.37.5.118:8083/LiLun/exchange';
// // Vue.prototype.url = 'http://172.16.0.91:8080/DataBus/exchange';

// Vue.prototype.imageUrl = 'http://121.37.5.118:8083/LiLun/uploadIpad/ipadFile.shtml';
// Vue.prototype.resourceUrl = 'http://121.37.5.118:8083/pic/';
// Vue.prototype.casUrl = 'http://tyrztest.gd.gov.cn/tif/sso/connect/page/oauth2/authorize';
// Vue.prototype.clientId = 'gdbs_test02';
// Vue.prototype.redirectUrl = encodeURIComponent('http://121.37.5.118:8082/');
// Vue.prototype.tzzyUrl = 'http://121.37.5.118:8083/';   // 特种作业系统
// Vue.prototype.loginUrl = 'http://121.37.5.118:8083/';   // 特种作业系统

// Vue.prototype.baseUrl = "http://121.37.5.118:8083/LiLun";


// Vue.prototype.url = 'http://172.16.1.67:8080/LiLun/exchange';
// // Vue.prototype.url = 'http://172.16.0.91:8080/DataBus/exchange';

// Vue.prototype.imageUrl = 'http://172.16.1.67:8080/LiLun/uploadIpad/ipadFile.shtml';
// Vue.prototype.resourceUrl = 'http://172.16.1.67:8080/pic/';
// Vue.prototype.casUrl = 'http://tyrztest.gd.gov.cn/tif/sso/connect/page/oauth2/authorize';
// Vue.prototype.clientId = 'gdbs_test02';
// Vue.prototype.redirectUrl = encodeURIComponent('http://172.16.1.67:8080/');
// Vue.prototype.tzzyUrl = 'http://172.16.1.67:8080/';   // 特种作业系统
// Vue.prototype.baseUrl = "http://172.16.1.67:8080/LiLun";

// 本地环境1
/*Vue.prototype.url = 'http://localhost:8080/DataBus/exchange';
Vue.prototype.resourceUrl = 'https://ksfw.yjgl.sz.gov.cn/pic/';
Vue.prototype.imageUrl = 'http://localhost:8080/DataBus/uploadIpad/ipadFile.shtml';
Vue.prototype.casUrl = 'http://tyrztest.gd.gov.cn/tif/sso/connect/page/oauth2/authorize';
Vue.prototype.clientId = 'gdbs_test02';
Vue.prototype.redirectUrl = encodeURIComponent('https://ksfw.yjgl.sz.gov.cn/');
Vue.prototype.tzzyUrl = 'http://139.9.209.18:8082/';   // 特种作业系统*/
// Vue.prototype.baseUrl = "http://localhost:8080/DataBus";

// 本地环境2
// Vue.prototype.url = 'http://localhost/DataBus/exchange';
// Vue.prototype.imageUrl = 'http://localhost/DataBus/uploadIpad/ipadFile.shtml';
// Vue.prototype.resourceUrl = 'http://www.myimage.com/';
// Vue.prototype.casUrl = 'http://tyrztest.gd.gov.cn/tif/sso/connect/page/oauth2/authorize';
// Vue.prototype.clientId = 'gdbs_test02';
// Vue.prototype.redirectUrl = encodeURIComponent('https://ksfw.yjgl.sz.gov.cn/');
// Vue.prototype.tzzyUrl = 'http://139.9.209.18:8082/';   // 特种作业开放接口
// Vue.prototype.tzzyUrl = 'http://172.16.0.97:8080/';   // 特种作业开放接口

// Vue.prototype.baseUrl = "http://localhost/DataBus";

function http(url, data, isPage) {
    return new Promise((succ, fail) => {
        let page = {
            currPage: 1,
            pageSize: 10
        };
        if (data.currPage) {
            page.currPage = data.currPage
        }
        if (data.pageSize) {
            page.pageSize = data.pageSize
        }
        let paramsData = {
            SERVICE_CODE: url,
            //  sessionStorage.getItem("Token")||
            CONSUMER_ID: ''
        };
        if (isPage ? true : isPage) {
            Object.assign(paramsData, page, data);
        } else {
            Object.assign(paramsData, data);
        }
        return axios({
            method: "post",
            url: this.url,
            headers: {
                "content-type": "multipart/form-data"
            },
            data: paramsData

        }).then(res => {
            if (isPage ? true : isPage) {
                let pageData = {
                    list: [],
                    totals: 0,
                    pageSize: 10,
                    currPage: 1
                };
                // debugger;
                if (!!res.data && !!res.data.BODY && !!res.data.BODY.data && res.data.BODY.data.list.length > 0) {
                    pageData.list = res.data.BODY.data.list;
                    for (var i = 0; i < pageData.list.length; i++) {
                        pageData.list[i].uid = i + 1;
                    }
                    pageData.totals = res.data.BODY.data.pageInfo.totalRow;
                    pageData.pageSize = res.data.BODY.data.pageInfo.pageSize;
                    pageData.currPage = res.data.BODY.data.pageInfo.currPage;
                }
                if (200 === res.status) {
                    //  debugger;
                    succ(pageData);
                } else {
                    fail(res);
                }
            } else {
                succ(res)
            }
        }).catch(function () {
            fail(...arguments)
        });
    })
}

Vue.prototype.$http = http
window.http = http
export default http;
