<template>
    <div>
        <div class="ym-item-title">
            <div class="ym-line"></div>
            <div class="ym-name">培训收费标准</div>
            <div class="ym-line"></div>
            <div class="ym-bottom-line"></div>
        </div>
        <div class="ym-table">
            <el-table :data="tableData" align="center" :stripe="true" style="width: 100%; margin-top: 20px">
                <el-table-column align="center" prop="catalog" label="培训项目" min-width="600">
                </el-table-column>
                <el-table-column align="center" prop="charge" label="培训费用" min-width="200">
                </el-table-column>
            </el-table>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableData: []
            };
        },
        props: {
            dataList: {}
        },
        mounted() {
            this.getPay();
        },
        methods: {
            getPay () {
                let id = this.dataList.id;
                console.log(this.dataList);
                let url = "szsti.AdminEnd.RegisterDynamic.instistutionInfo";
                let params = {
                    CONSUMER_ID: 'system',
                    id: id
                };
                this.$http(url, params, false).then(res => {
                     if (res.data.BODY.code == 200) {
                    let datas = res.data.BODY.data;
                    this.tableData = datas.trainChargeInfo
                    }
                })
            }
        }
    };
</script>

<style scoped="scoped" lang="scss">
        .ym-title {
            width: 1200px;
            height: 50px;
            color: #fff;
            text-align: center;
            font-weight: 600;
            margin-bottom: 5px;
            background: #3E97DF;
            font-size: 22px;
            line-height: 50px;
        }

        .ym-phone {
            box-sizing: border-box;
            padding: 0 20px;
            width: 100%;
            display: flex;
            align-items: center;
            margin: 30px 0;

            .ym-phone-item {
                flex: 1;
                display: flex;

                .ym-phone-item-title {
                    width: 150px;
                    height: 38px;
                    background: rgba(246, 245, 245, 1);
                    border: 1px solid rgba(198, 209, 222, 1);
                    line-height: 38px;
                    font-weight: 600;
                    font-size: 18px;
                    text-align: center;
                    border-right: none;
                }

                .ym-phone-item-text {
                    border: 1px solid rgba(198, 209, 222, 1);
                    line-height: 38px;
                    font-size: 18px;
                    box-sizing: border-box;
                    padding: 0 20px;
                    flex: 1;
                    // border-right: none;
                }
            }
        }

        .ym-item-title {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            height: 46px;
            margin-top: 12px;
            margin-bottom: 24px;

            .ym-line {
                width: 210px;
                height: 1px;
                background: #D9D9D9;
            }

            .ym-name {
                width: 140px;
                text-align: center;
                color: #333;
                font-size: 20px;
                font-weight: 700;
            }

            .ym-bottom-line {
                width: 50px;
                height: 3px;
                background: #3E97DF;
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -25px;
            }
        }
        .ym-table {
            width: 100%;
            padding: 0 20px 40px;
            box-sizing: border-box;
            .el-table {
                border: 1px solid #D8E2F0;
                border-radius: 5px;
            }
        }
</style>
<style lang="scss">
    .ym-table {
        .el-table th>.cell {
            text-align: center;
            color: #3E97DF;
        }
        .el-table {
            background:rgba(255,255,255,1);
            border:1px solid rgba(216,226,240,1);
            border-radius:5px 5px 5px 5px;
        }
        .el-table th.is-leaf,.el-table td {
            border-bottom: none;
        }
        .el-table__footer-wrapper td {
            color: #3E97DF;
            font-weight: 600;
        }
    }
</style>
