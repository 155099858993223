<template>
  <div class="home">
    <el-container>
      <el-header height="300px" :style="headerImage" class="header-imgage">
        <!-- <el-header height='300px' class="header-imgage"> -->
        <div class="layout_flex">
          <div class="flex_box box_search_title">
            <div class="name_title">{{ Name }}</div>
            <div class="serch_title_box">
              <div class="seach-box">
                <el-autocomplete class="search_input ym-search_input" v-model="inputText"
                  :fetch-suggestions="querySearch" placeholder="请输入关键字搜索" :trigger-on-focus="false"
                  @select="handleSelect">
                </el-autocomplete>
                <div class="search_button" @click="seach_detail">搜索</div>
              </div>
              <div style="margin-right: 20px">{{ sysName }}</div>
            </div>
          </div>
        </div>
      </el-header>
      <div class="nav_bar_content" :style="navStyle">
        <div class="nav_bar-nums">
          <nav-bar class="nav_bar_box"></nav-bar>
        </div>
      </div>
      <el-main class="nageshabixiedebuju">
        <div class="layout_flex">
          <!-- <div class="flex_box">
                        <div></div>
                    </div> -->
          <div class="box_search_title">
            <router-view @search="childSearch" :key="$route.fullPath" class="content-list" />
          </div>
          <!-- <div class="flex_box"></div> -->
        </div>
      </el-main>
      <el-footer height="330px" :style="footerImage" class="foot">
        <div class="container">
          <div class="first">
            <p class="foot_text_link">
              <a href="http://yjgl.sz.gov.cn/fzgn/lxfs/index.html">联系我们</a>
              -
              <a href="http://yjgl.sz.gov.cn/fzgn/bqbh/content/post_8865778.html">版权保护</a>
              -
              <a href="http://yjgl.sz.gov.cn/fzgn/yssm/content/post_8865771.html">隐私声明</a>
              -
              <a href="http://yjgl.sz.gov.cn/fzgn/wzdh/index.html">网站导航</a>
              <!-- -
              <a href="http://yjgl.sz.gov.cn/fzgn/nrjc/index.html">内容纠错</a> -->
            </p>
            <p>深圳市应急管理局　版权所有</p>
            <p>主办单位：深圳市应急管理局</p>
            <p>标识码：4403000081</p>
            <p>
              <a
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402003002"></a>
              <img src="../../image/footer/2019_index_41.png" />粤公网安备
              44030402003002号
            </p>
            <p>
              <a href="http://beian.miit.gov.cn/">粤ICP备19041158号-1</a>
            </p>
            <p class="p_last">
              <a @click="goJG('4403000081')" target="_blank"><img
                  src="../../image/footer/red.png" /></a>
              <span id="_span_jiucuo"><img @click="Link('4403000081')"
                  style="margin: 0; border: 0; cursor: pointer"
                  src="https://zfwzgl.www.gov.cn/exposure/images/jiucuo.png" /></span>
            </p>
            <p class="visible-xs" style="display: none">
              <span style="
                  background: rgb(105, 100, 100);
                  width: 120px;
                  height: 35px;
                  text-align: center;
                  color: rgb(255, 255, 255);
                  line-height: 35px;
                  opacity: 0.4;
                  font-size: 16px;
                  padding: 0px 10px;
                  display: inline-block;
                ">网站支持IPv6</span>
            </p>
          </div>
          <div class="secont">
            <h1>联系方式：</h1>
            <div>
              <img src="../../image/footer/2019_index_42.png" />举报投诉热线(邮箱):
              <a>12350;12350yjjb@sz.gov.cn</a>
            </div>
            <div>
               <img src="../../image/footer/2019_index_42.png" />机关纪委监督举报电话(邮箱)：
               <a>0755-88100363;yjjrsc@sz.gov.cn</a>
            </div>
            <div>
              <img src="../../image/footer/2019_index_42.png" />咨询及事故接报应急热线：
              <a>0755-83071111</a>
            </div>
            <!-- <div>
              <img src="../../image/footer/2019_index_42.png" />咨询电话：
              <a>0755-88101175</a>
            </div> -->
            <div>
              <img src="../../image/footer/2019_index_42.png" />考核发证查询电话：
              <a>0755-23481891</a>
            </div>
            <div>
              <img src="../../image/footer/2019_index_43.png" />深圳市福田区福中路市民中心C区5楼
            </div>
            <div>
              <img src="../../image/footer/2019_index_44.png" />政务邮箱：
              <a>yjglj@sz.gov.cn</a>
            </div>
          </div>
          <div class="three">
            <h1>友情链接：</h1>
            <!-- 国家、省、市友情链-->
            <a @click="goOther('http://www.mem.gov.cn/')" target="_blank">
              中华人民共和国应急管理部
            </a>
            <a @click="goOther('http://www.gd.gov.cn/')" target="_blank"> 广东省人民政府 </a>
            <a href="http://yjgl.gd.gov.cn/" target="_blank">
              广东省应急管理厅
            </a>
            <a @click="goOther('http://www.sz.gov.cn/cn/')" target="_blank">
              深圳市人民政府
            </a>
            <a @click="goOther('http://yjgl.sz.gov.cn/zwgk/xxgkml/qt/ztzl/sawhcywzjz/')"
              target="_blank">
              市安委会成员网站矩阵
            </a>
            <!-- <a href="http://yjgl.sz.gov.cn/zwgk/xxgkml/qt/ztzl/sawhcywzjz/index.html" target="_blank">市安委会成员网站矩阵 </a> -->
            <a href="http://yjgl.sz.gov.cn/fzgn/yqlj/gqyqlj/index.html" target="_blank">各区友情链接</a>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import navBar from './NavBar';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      Name: '深圳市应急管理局',
      sysName: '安全生产培训考核发证',
      navStyle: {},
      headerImage: {
        backgroundImage: 'url(' + require('../../image/zybanner.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      },
      footerImage: {
        // backgroundImage: "url(" + require("@/image/dbl.png") + ")",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundColor: '#f2f3f7',
      },
      searchDataList: [],
      inputText: '',
      seachData: {},
      query: {
        parentId: '',
        childId: '',
        detailId: '',
      },
      matchedData: [],
    };
  },
  components: { navBar },
  props: {
    msg: String,
  },
  created() {
    this.getSearchDataList();
  },
  methods: {
    goJG(code) {
      var msg = '您访问的链接即将离开“深圳市应急管理局”门户网站，是否继续？';
      if (confirm(msg) == true) {
        window.open(
          'http://bszs.conac.cn/sitename?method=show&id=54192B9A93E94A9DE053012819AC729F'
        );
        return true;
      } else {
        return false;
      }
    },
    goOther(link) {
      var msg = '您访问的链接即将离开“深圳市应急管理局”门户网站，是否继续？';
      if (confirm(msg) == true) {
        window.open(link);
        return true;
      } else {
        return false;
      }
    },
    Link(code) {
      window.open(
        `https://zfwzgl.www.gov.cn/exposure/jiucuo.html?site_code=${code}&url=${encodeURIComponent(
          'http://yjgl.sz.gov.cn/'
        )}`
      );
    },
    //底部跳转
    go(url) {
      this.$router.push(url);
      document.getElementById('app').scrollTop = 0;
    },
    querySearch(queryString, cb) {
      let restaurants = this.searchDataList;
      let results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      results.forEach((row) => {
        this.$set(row, 'value', row.title);
      });
      // 调用 callback 返回建议列表的数据
      this.matchedData = results;
      cb(results);
    },
    childSearch(v) {
      this.inputText = v.name;
      this.seach_detail();
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.title.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    seach_detail() {
      let query = {
        searchData: this.inputText,
      };
      this.$router.push({ path: '/xxfww/searchPage', query: query });
      //是否是匹配数据搜索
      /*if(this.seachData.title===this.inputText){
          if(this.seachData.externalLink!==""){
              window.open(this.seachData.externalLink)
          }else{
              this.query={
                  parentId:this.seachData.columnParentId,
                  childId:this.seachData.columnId,
                  detailId:this.seachData.id
              }
              // 导航状态变更
              this.$store.dispatch("modifySetTabPage",'通知公告');
              this.$router.push({path:'/xxfww/announcement',query:this.query})
          }
      }else{
           // 提示找不到文章
          if(this.matchedData.lenth>0){
              let data={}
              this.matchedData.forEach(row=>{
                  if(row.title===this.inputText){
                      data=row
                  }
              })
              this.query={
                  parentId:data.columnParentId,
                  childId:data.columnId,
                  detailId:data.id
              }
              // 导航状态变更
              this.$store.dispatch("modifySetTabPage",'通知公告');
              this.$router.push({path:'/xxfww/announcement',query:this.query})
          }else{
              this.$router.push({path:'/404'})
          }
      }*/
    },
    handleSelect(item) {
      this.seachData = {};
      this.seachData = item;
    },
    // 初始获取新闻详情页的文章数据
    getSearchDataList() {
      let url = 'szsti.publicInfo.ColumnNode.frontArticleSearch';
      let param = {
        CONSUMER_ID: 'system-70837df36900457cb799035cd8d59a88',
      };
      this.$http(url, param, false)
        .then((res) => {
          if (res.data.BODY.code == 200 && res.data.BODY.success == 'true') {
            this.searchDataList = res.data.BODY.data;
          } else {
            this.$message({
              message: '获取搜索数据失败，失败信息：' + res.data.BODY.message,
              type: 'warning',
            });
          }
        })
        .catch((y) => {
          this.$message.error(y);
        });
    },
  },
  watch: {
    '$store.state.printerStatus': {
      // immediate: true,
      handler(val) {
        if (val) {
          this.$set(this.headerImage, 'display', 'none');
          this.$set(this.footerImage, 'display', 'none');
          this.$set(this.navStyle, 'display', 'none');
        } else {
          delete this.headerImage.display;
          delete this.footerImage.display;
          delete this.navStyle.display;
        }
      },
    },
  },
};
</script>

<style  lang="stylus" scoped>
.header-imgage {
  background: url('../../image/zybanner.png') no-repeat left top / 100% 100%;
}

.layout_flex {
  max-width: 1200px;
  // height: 300px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;

  .flex_box {
    width: 360px;
    height: 100%;
    position: relative;

    .name_title {
      margin-top: 51px;
      margin-left: 0;
      position: absolute;
      font-size: 37px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
    }

    .serch_title_box {
      display: flex;
      align-items: flex-end;
      flex-direction: column-reverse;
      margin-top: 113px;
      font-size: 62px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);

      .seach-box {
        position: relative;
        margin-top: 0.38rem;
        margin-right: 20px;

        .search_button {
          position: absolute;
          right: 2px;
          top: 2px;
          font-size: 18px;
          display: block;
          cursor: pointer;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          width: 78px;
          overflow: hidden;
          height: 42px;
          padding: 0;
          line-height: 42px;
          background: rgba(21, 146, 219, 1);
          border-radius: 21px;
          // margin-left: 4.76rem;
        }
      }

      .search_input {
        width: 556px;
        height: 46px;
        padding: 0;
        display: block;
      }
    }
  }

  .box_search_title {
    flex-grow: 1;
  }

  .content-lay {
    background: rgba(21, 146, 219, 1);
  }
}

.content-lay {
  width: 1200px;
  margin: 0 auto;
}

.content-list {
  margin: 0;
  width: 1200px;
  margin-top: 20px;
}

.footer_box_size {
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: rgba(255, 255, 255, 1);

  div {
    height: 28px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
  }

  .foot-first {
    height: 40px;
    font-size: 18px;
    color: #333333;

    span {
      cursor: pointer;
    }
  }

  .foot-con {
    color: #666666;
  }
}

.box_search_title >>> .nav_bar_box {
  margin: 0;
  padding: 0;
  height: 44px;
}

.search_input >>> .el-input__inner {
  background: rgba(255, 255, 255, 1);
  border-radius: 23px;
  height: 46px;
  display: block;
  color: #333;
  font-size: 16px;

  &::placeholder {
    color: #999999;
    font-size: 16px;
  }
}

.search_input >>> .el-input {
  display: block;
}

.el-header {
  margin-top: 0;
  color: #333;
  text-align: center;
  padding: 0;
}

.nav_bar_content {
  height: 44px;
  width: 100%;
  background: rgba(21, 146, 219, 1);

  .nav_bar-nums {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
}

.nav_bar_content >>> .el-tabs__item {
  color: #FFF;
  flex: 1;
}

.nav_bar_content >>> .el-tabs {
  border: 0;
  box-shadow: none;
}

.nav_bar_content >>> .el-tabs__nav {
  background: #3E97DF;
}

.el-footer {
  background-color: #f2f3f7;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-main {
  background-color: #fff;
  height: 100%;
  width: 100%;
  padding: 0;
  overflow: hidden;
  text-align: center;
  display: inline-table;
}
</style>
<style lang="scss">
.ym-search_input {
  .el-input {
    display: block;
  }
}
.nageshabixiedebuju {
  display: inline !important;
}
.foot {
  width: 100%;
  background-color: #f2f2f2;
  overflow: hidden;
  transition: all 0.5s;
  padding-bottom: 15px;
  .container {
    font-family: '寰蒋闆呴粦';
    width: 1200px;
    height: inherit;
    margin: auto;
    display: flex;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    > div {
      font-size: 16px;
      text-align: left;
      p {
        margin-bottom: 10px;
        color: #666;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        a {
          color: #666;
          font-size: 16px;
        }
        img {
          vertical-align: middle;
          margin-bottom: 2px;
          border: 0;
        }
      }
      h1 {
        color: #333;
        font-size: 16px;
        font-weight: bold;
        margin: 25px auto 15px auto;
        height: 22px;
        line-height: 22px;
      }
      .foot_text_link {
        font-weight: bold;
        color: #333;
        margin: 25px auto 15px auto;
        height: 22px;
        line-height: 22px;
        > a {
          color: #333;
          &:hover {
            color: #2ea1e4;
          }
        }
      }
    }
    .first {
      flex: 2;
      margin-right: 25px;
    }
    .secont {
      // flex: 2;
      margin-right: 20px;
      > div {
        height: 30px;
        line-height: 30px;
        background-color: #e3e4e8;
        color: #666;
        margin-bottom: 10px;
        border-radius: 15px;
        padding: 0 10px 0 18px;
        width: 96%;
        font-size: 14px;
        > img {
          vertical-align: middle;
          margin: 0 10px 3px 0;
        }
      }
    }
    .three {
      flex: 1.5;
      padding: 0 25px 0 40px;
      h1 {
        color: #333;
        font-size: 16px;
        font-weight: bold;
        margin: 25px auto 15px auto;
        height: 22px;
        line-height: 22px;
      }
      > a {
        font-size: 14px;
        color: #333;
        height: 30px;
        line-height: 30px;
        background-color: #e3e4e8;
        color: #666;
        margin-bottom: 10px;
        border-radius: 15px;
        padding: 0 10px 0 18px;
        width: 92%;
        text-align: center;
        display: block;
        &:hover {
          color: #fff;
          background-color: #a9c7df;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
</style>
