<template>
  <div class="home_content">
    <!-- 主页标题图片 -->
    <div class="home_content_img">
      <img width="100%"
           height="112px"
           src="../../image/gonggao.png">
    </div>
    <!-- 中间内容 -->
    <div class="home_content_box_ljx">
      <!-- 中间左侧内容 -->
      <div class="home_content_left">
        <!-- tab栏 -->
        <div class="home_content_left_tab">
          <template>
            <el-tabs v-model="activeName"
                     @tab-click="handleClick"
                     class="home_content_left_tab_content">
              <el-tab-pane label="通知公告"
                           name="first">
                <el-card class="box-card">
                  <li v-for="o in inform"
                      :key="o.id"
                      class="text item"
                      @click="select(o.id,o.columnId,o.externalLink)">
                    <div class="xiaoyuandian"></div>
                    <div class="text_content">{{o.title}}</div>
                    <div class="text_time">{{o.publishTime}}</div>
                  </li>
                </el-card>
              </el-tab-pane>
              <el-tab-pane label="考试计划"
                           name="second">
                <el-card class="box-card">
                  <li v-for="o in inform2"
                      :key="o.id"
                      class="text item"
                      @click="select(o.id,o.columnId,o.externalLink)">
                    <div class="xiaoyuandian"></div>
                    <div class="text_content">{{o.title}}</div>
                    <div class="text_time">{{o.publishTime}}</div>
                  </li>
                </el-card>
              </el-tab-pane>
              <el-tab-pane label="工作指引"
                           name="third">
                <el-card class="box-card">
                  <li v-for="o in inform3"
                      :key="o.id"
                      class="text item"
                      @click="select(o.id,o.columnId,o.externalLink)">
                    <div class="xiaoyuandian"></div>
                    <div class="text_content">{{o.title}}</div>
                    <div class="text_time">{{o.publishTime}}</div>
                  </li>
                </el-card>
              </el-tab-pane>
              <el-tab-pane label="法规政策"
                           name="fourth">
                <el-card class="box-card">
                  <li v-for="o in inform4"
                      :key="o.id"
                      class="text item"
                      @click="select(o.id,o.columnId,o.externalLink)">
                    <div class="xiaoyuandian"></div>
                    <div class="text_content">{{o.title}}</div>
                    <div class="text_time">{{o.publishTime}}</div>
                  </li>
                </el-card>
              </el-tab-pane>
              <el-tab-pane label="更多>>"
                           name="five">更多</el-tab-pane>
            </el-tabs>
          </template>
        </div>
        <!-- 考试指引 -->
        <div class="home_content_left_guide">
          <div class="home_content_left_guide_title">
            考试指引
          </div>
          <div class="home_content_left_guide_box">
            <div class="home_content_left_guide_box_content_top">
              <div class="home_content_left_guide_box_content_top_box"
                   @click="special">
                <img src="../../image/ksfwicon9.png"
                     alt="">
                <div class="home_content_left_guide_box_content_top_box_text">
                  特种作业人员考试取证
                </div>
              </div>
              <div class="home_content_left_guide_box_content_top_box"
                   @click="chemicals">
                <img src="../../image/ksfwicon10.png"
                     alt="">
                <div class="home_content_left_guide_box_content_top_box_text">
                  危险化学品生产经营单位主要负责人和安管人员考试取证
                </div>
              </div>
            </div>
            <div class="home_content_left_guide_box_content_bottom">
              <div class="home_content_left_guide_box_content_top_box"
                   @click="production">
                <img src="../../image/ksfwicon11.png"
                     alt="">
                <div class="home_content_left_guide_box_content_top_box_text">
                  生产经营单位主要负责人等四类从业人员考试取证
                </div>
              </div>
              <div class="home_content_left_guide_box_content_top_box"
                   @click="to_quetion">
                <img src="../../image/ksfwicon12.png"
                     alt="">
                <div class="home_content_left_guide_box_content_top_box_text">
                  安全生产培训考试常见问题解答
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 中间右侧内容 -->
      <div class="home_content_right">
        <!-- 考试查询 -->
        <div class="home_content_right_refer">
          <div class="home_content_right_refer_title">考试查询</div>
          <div class="home_content_right_refer_box">
            <div class="home_content_right_refer_box_content">
              <img src="../../image/wzyicon1.png"
                   alt="">
              <div class="home_content_right_refer_box_content_text"
                   @click="examination">理论考试准考证</div>
            </div>
            <div class="home_content_right_refer_box_content">
              <img src="../../image/wzyicon2.png"
                   alt="">
              <div class="home_content_right_refer_box_content_text"
                   @click="tzexamination">实操考试准考证</div>
            </div>
            <!-- <div class="home_content_right_refer_box_content">
              <img src="../../image/wzyicon3.png"
                   alt="">
              <div class="home_content_right_refer_box_content_text"
                   @click="scexamination">生产经营单位主要负责人等四类从业人员考试成绩</div>
            </div> -->
             <div class="home_content_right_refer_box_content">
              <img src="../../image/wzyicon3.png"
                   alt="">
              <div class="home_content_right_refer_box_content_text"
                   @click="examinationScore">理论考试成绩</div>
            </div>
            <div class="home_content_right_refer_box_content">
              <img src="../../image/wzyicon4.png"
                   alt="">
              <div class="home_content_right_refer_box_content_text"
                   @click="tzcjexamination">实操考试成绩</div>
            </div>

          </div>
        </div>
        <!-- 证书查询 -->
        <div class="home_content_right_refer">
          <div class="home_content_right_refer_title">证书查询</div>
          <div class="home_content_right_refer_box">
            <div class="home_content_right_refer_box_content">
              <img src="../../image/wzyicon5.png"
                   alt="">
              <div class="home_content_right_refer_box_content_text"
                   @click="certificate">全国特种作业操作证</div>
            </div>
              <div class="home_content_right_refer_box_content">
              <img src="../../image/wzyicon8.png"
                   alt="">
              <div class="home_content_right_refer_box_content_text"
                   @click="certificate">全国安全生产知识和管理能力考核合格证</div>
            </div>
            <div class="home_content_right_refer_box_content">
              <img src="../../image/wzyicon6.png"
                   alt="">
              <div class="home_content_right_refer_box_content_text"
                   @click="sccertificate">深圳市安全生产知识和管理能力考核合格证</div>
            </div>
          </div>
        </div>
        <!-- 培训信息 -->
        <div class="home_content_right_refer">
          <div class="home_content_right_refer_title">培训信息</div>
          <div class="home_content_right_refer_box">
            <div class="home_content_right_refer_box_content">
              <img src="../../image/wzyicon7.png"
                   alt="">
              <div class="home_content_right_refer_box_content_text"
                   @click="train">深圳市安全培训机构登记信息</div>
            </div>
            <!-- <div class="home_content_right_refer_box_content">
              <img src="../../image/wzyicon8.png"
                   alt="">
              <div class="home_content_right_refer_box_content_text"
                   @click="tztrain">特种作业培训机构考试合格率</div>
            </div> -->
            <div class="home_content_right_refer_box_content">
              <img src="../../image/wzyicon9.png"
                   alt="">
              <div class="home_content_right_refer_box_content_text"
                   @click="sctrain">安全生产培训大纲及考核标准</div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- 底部内容 -->
    <div class="home_content_buttom_ljx">
      <!-- <div class="home_content_buttom_box"
           @mouseenter="changeImageSrc($event,1)"
           @mouseleave="changeImage($event,2)"
           @click="apply">
        <img :src="changimg ?  require('../../image/syicon1.png') : require('../../image/syicon2.png')"
             :class="{changimg}"
             alt="">
        <div class="home_content_buttom_box_content">报名系统</div>
      </div> -->
       <a href="http://yjgl.sz.gov.cn/" target="_blank" class="home_content_buttom_box" style="color:#000"
            @mouseenter="changeImageSrc($event,1)"
           @mouseleave="changeImage($event,2)"
         >
        <img :src="changimg ?  require('../../image/syicon1.png') : require('../../image/syicon2.png')"
             :class="{changimg}"
             alt="">
        <div class="home_content_buttom_box_content">深圳市应急管理局官网</div>
      </a>
      <div class="home_content_buttom_box"
           @mouseenter="changeImageSrc($event,3)"
           @mouseleave="changeImage($event,4)"
           @click="exchange">
        <img :src="changimg2 ?  require('../../image/syicon3.png') : require('../../image/syicon4.png')"
             :class="{changimg2}"
             alt="">
        <div class="home_content_buttom_box_content">四类人员</div>
      </div>
      <div class="home_content_buttom_box"
           @mouseenter="changeImageSrc($event,5)"
           @mouseleave="changeImage($event,6)"
           @click="cxcertificate">
        <img :src="changimg3 ?  require('../../image/syicon5.png') : require('../../image/syicon6.png')"
             :class="{changimg3}"
             alt="">
        <div class="home_content_buttom_box_content">证书查询系统</div>
      </div>
      <div class="home_content_buttom_box"
           @mouseenter="changeImageSrc($event,7)"
           @mouseleave="changeImage($event,8)"
           @click="pxtrain">
        <img :src="changimg4 ?  require('../../image/syicon7.png') : require('../../image/syicon8.png')"
             :class="{changimg4}"
             alt="">
        <div class="home_content_buttom_box_content">培训机构管理信息系统</div>
      </div>
    </div>
    <div class="home_content_consult_robotics">
      <div class="home_content_consult_robotics_robot"  @click="gorobot">
        <img src="../../image/ycan2.png"
             alt="">
        <div class="home_content_consult_robotics_robot_text">自助咨询回复机器人</div>
      </div>
      <div class="home_content_consult_robotlong"
           v-if="isrobot == false"
           @mouseleave="norobot"
          >
        <img src="../../image/ycan2.png"
             alt="">
        <div class="home_content_consult_robotlong_text">
          自助咨询回复机器人
        </div>
      </div>
      <div class="home_content_consult_phone"

           v-if="isphone">
        <img src="../../image/ycan4.png"
             alt="">
        <div class="home_content_consult_phone_text">
          电话 0755-23481891
        </div>
      </div>
      <div class="home_content_consult_phonelong"
           v-if="isphone == false"
           @mouseleave="nophone">
        <img src="../../image/ycan4.png"
             alt="">
        <div class="home_content_consult_phonelong_text">
          电话 0755-23481891
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: 'first',
      //通知公告
      inform: [

      ],
      inform2: [

      ],
      inform3: [

      ],
      inform4: [

      ],
      changimg: true,
      changimg2: true,
      changimg3: true,
      changimg4: true,
      isrobot: true,
      isphone: true
    };
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event);
      if (tab.name == "five") {
        console.log(tab.name == "five");
        let query = {
          childId: 6,
        }
        this.$store.dispatch("modifySetTabPage", '通知公告');
        this.$router.push({ path: '/xxfww/announcement', query: query })
      }
    },
    // 底部鼠标移入
    changeImageSrc ($event, index) {
      if (index == 1) {
        this.changimg = false
        $event.currentTarget.className = 'home_content_buttom_box active';
      } else if (index == 3) {
        this.changimg2 = false
        $event.currentTarget.className = 'home_content_buttom_box active';
      } else if (index == 5) {
        this.changimg3 = false
        $event.currentTarget.className = 'home_content_buttom_box active';
      } else if (index == 7) {
        this.changimg4 = false
        $event.currentTarget.className = 'home_content_buttom_box active';
      }
    },
    // 底部鼠标移出
    changeImage ($event, index) {
      if (index == 2) {
        this.changimg = true
        $event.currentTarget.className = 'home_content_buttom_box';
      } else if (index == 4) {
        this.changimg2 = true
        $event.currentTarget.className = 'home_content_buttom_box';
      } else if (index == 6) {
        this.changimg3 = true
        $event.currentTarget.className = 'home_content_buttom_box';
      } else if (index == 8) {
        this.changimg4 = true
        $event.currentTarget.className = 'home_content_buttom_box';
      }
    },
    //自助咨询回复机器人
    robot () {
      this.isrobot = false
    },
    norobot () {
      this.isrobot = true
    },
    //机器人跳转
    gorobot () {
      window.open('http://yjgl.sz.gov.cn/znjqr')
    },
    //电话
    phone () {
      this.isphone = false
    },
    nophone () {
      this.isphone = true
    },
    //全国特种作业操作证及危化安全合格证
    certificate () {
      window.open('http://cx.mem.gov.cn/')
    },
    //生产经营单位主要负责人等四类从业人员考核合格证
    sccertificate () {
      this.$store.dispatch("modifySetTabPage", '证书查询');
      this.$router.push({ path: `/xxfww/printingOfAdmissionTicket`, query: { type: 'certificate' } });
    },
    //安全生产理论考试准考证
    examination () {
      this.$store.dispatch("modifySetTabPage", '不选择');
      this.$router.push({ path: `/xxfww/printingOfAdmissionTicket`, query: { type: 'ticket' } });
    },
    //特种作业实操考试准考证
    tzexamination () {
      this.$store.dispatch("modifySetTabPage", '不选择');
      this.$router.push({ path: `/xxfww/printingOfAdmissionTicket`, query: { type: 'tzzy2' } });
    },
    //生产经营单位主要负责人等四类从业人员考试成绩
    scexamination () {
      this.$store.dispatch("modifySetTabPage", '不选择');
      this.$router.push({ path: `/xxfww/printingOfAdmissionTicket`, query: { type: 'score' } });
    },
    //特种作业人员实操考试成绩
    tzcjexamination () {
      this.$store.dispatch("modifySetTabPage", '不选择');
      this.$router.push({ path: `/xxfww/printingOfAdmissionTicket`, query: { type: 'tzzy1' } });
    },
      //理论考试成绩
    examinationScore(){
       this.$store.dispatch("modifySetTabPage", '不选择');
      this.$router.push({
        path: `/xxfww/printingOfAdmissionTicket`, query: { type: 'examinationScore' }
      })
    },
    //深圳市安全培训机构信息
    train () {
      this.$store.dispatch("modifySetTabPage", '培训机构');
      this.$router.push({ path: '/xxfww/passRate' });
    },

    //特种作业培训机构考试合格率
    tztrain () {
      this.$store.dispatch("modifySetTabPage", '培训机构');
      this.$router.push({ path: '/xxfww/passRate' });
    },
    //安全生产培训大纲及考核标准
    sctrain () {
      this.$store.dispatch("modifySetTabPage", '培训机构');
      this.$router.push({        path: '/xxfww/announcement', query: {          parentId: 0,
          childId: 22,        }      });
    },
    //考试报名
    apply () {
      this.$store.dispatch("modifySetTabPage", '考试报名');
      this.$router.push({ path: '/examinationregistration/homePage', query: { type: 'examinationregistration' } });
    },
    //四类人员
    exchange () {
        window.open("http://yjgl.sz.gov.cn/zwgk/xxgkml/qt/tzgg/content/post_9952063.html")
      // this.$router.push({ path: `/replacementSystem/homePage`, query: { type: 'replacementSystem' } });
    },
    //证书查询系统
    cxcertificate () {
      this.$router.push({ path: `/xxfww/printingOfAdmissionTicket`, query: { type: 'certificate' } });
    },
    //培训机构管理信息系统
    pxtrain () {
      alert('暂未开放')
      return false
      // window.open('https://pxjg.yjgl.sz.gov.cn')
    },
    //考试指引 危险化学品
    chemicals () {
      this.$router.push(
        { path: 'chemicals' }
      )
    },
    //考试指引 特种作业人员
    special () {
      this.$router.push({
        path: "special"
      })
    },
    //考试指引 生产经营单位
    production () {
      this.$router.push({
        path: "production"
      })
    },
    //安全生产解答
    to_quetion () {
      let query = {
        parentId: 0,
        childId: 23,
      }
      this.$store.dispatch("modifySetTabPage", '常见问题');
      this.$router.push({ path: '/xxfww/faqs', query: query })
    },
    istext (id) {
      console.log(id);
    },
    getColumnsByType () {
      let url = "szsti.publicInfo.ColumnNode.frontColumnNodeContent";
      let param = {
        CONSUMER_ID: "system-70837df36900457cb799035cd8d59a88",
        columnNode: {
          columnType: 'PC',
        },
      };
      this.$http(url, param, false).then(res => {
        this.inform = res.data.BODY.data[0].columnContent
        var newArr = [];
        for (var i = 0; i < this.inform.length; i += 8) {
          newArr.push(this.inform.slice(i, i + 8));
        }
        this.inform = newArr[0]
        this.inform2 = res.data.BODY.data[1].columnContent
        var newArr2 = [];
        for (var i = 0; i < this.inform2.length; i += 8) {
          newArr2.push(this.inform2.slice(i, i + 8));
        }
        this.inform2 = newArr2[0]
        this.inform3 = res.data.BODY.data[2].columnContent
        var newArr3 = [];
        for (var i = 0; i < this.inform3.length; i += 8) {
          newArr3.push(this.inform3.slice(i, i + 8));
        }
        this.inform3 = newArr3[0]
        this.inform4 = res.data.BODY.data[3].columnContent
        var newArr4 = [];
        for (var i = 0; i < this.inform4.length; i += 8) {
          newArr4.push(this.inform4.slice(i, i + 8));
        }
        this.inform4 = newArr4[0]
      }).catch(y => {
        this.$message.error(y);
      });
    },
    select (id, columnId,externalLink) {
      if(externalLink){
         window.open(externalLink,'_blank')
      }else{
      let query = {
        parentId: columnId,
        childId: columnId,
        detailId: id
      }
      this.$store.dispatch("modifySetTabPage", '通知公告');
      this.$router.push({ path: '/xxfww/announcement', query: query })
      }
    },
  },
  created () {
    console.log('rr',this.$route)

    if(this.$route.query.path){
      // if(this.$route.query.path.indexOf('login')>-1){
      //  this.$router.push({
      //     path:"/"+this.$route.query.path
      //   })
      // }else{
        let query =Object.assign({},this.$route.query)
        delete query.path
        this.$router.push({
          path:this.$route.query.path,
          query:query
        })
      // }

    }
    this.getColumnsByType();

  },
}
</script>

<style lang="less" scope>
.el-main {
  // background-color: #f2f3f7;
  .content-lay {
    background-color: #fff;
  }
}
.home_content {
  max-width: 100vw  !important;
  font-size: 0px;
  position: relative;
  .home_content_box_ljx {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    // background-color: rosybrown;
    margin-top: 20px;
    padding: 0px;
    .home_content_left {
      // width: 710px;
      flex:1;
      margin-right: 20px;
      height: 695px;
      .home_content_left_tab {
        .text {
          font-size: 14px;
        }

        .item {
          padding: 18px 0;
        }
        .box-card {
          width: 480px;
        }
        .home_content_left_tab_content {
          width: 710px;
          border: 1px solid #eeeeee;
          .el-tabs__nav-wrap {
            background-color: #f2f3f7;
          }
          .el-tabs__header {
            margin: 0px;
          }
          .el-tabs__nav-scroll {
            margin-left: 20px;
          }
          .el-tabs__item {
            font-size: 15px;
            &:last-child {
              margin-left: 46%;
            }
          }
          .is-always-shadow {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            border: none;
            .el-card__body {
              padding: 0px;
              height: 323px;
              .text {
                padding: 17px 15px 0px;
                display: flex;
                align-items: center;
                // width: 580px;
                font-size: 16px;
                justify-content: center;
                cursor: pointer;

                &:hover {
                  color: #3e97df;
                }
                .xiaoyuandian {
                  width: 4px;
                  height: 4px;
                  border-radius: 50%;
                  background-color: #3e97df;
                  display: flex;
                  justify-content: center;
                  margin-right: 10px;
                }
                .text_content {
                  width: 522px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  word-break: break-all;
                  white-space: nowrap;
                  text-align: left;
                }
                .text_time {
                  width: 83px;
                  margin-left: 43px;
                  white-space: nowrap;
                  // text-overflow: ellipsis;
                  overflow: hidden;
                  // word-break: break-all;
                  // white-space: nowrap;
                }
              }
            }
          }
        }
      }
      .home_content_left_guide {
        margin-top: 12px;
        width: 100%;
        height: 100px;
        .home_content_left_guide_title {
          font-size: 22px;
          display: flex;
          margin-bottom: 15px;
        }
        .home_content_left_guide_box {
          display: flex;
          height: 282px;
          width: 100%;
          flex-direction: column;
          justify-content: space-between;
          width: 710px;
          .home_content_left_guide_box_content_top {
            display: flex;
            width: 100%;
            height: 131px;
            justify-content: space-between;

            .home_content_left_guide_box_content_top_box {
              height: 100%;
              width: 340px;
              background: #f2f3f7;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              &:hover {
                color: #3e97df;
              }
              img {
                width: 57px;
                height: 57px;
                margin-right: 20px;
              }
              .home_content_left_guide_box_content_top_box_text {
                font-size: 16px;
                width: 183px;
                text-align: left;
              }
            }
          }
          .home_content_left_guide_box_content_bottom {
            display: flex;
            width: 100%;
            height: 131px;
            justify-content: space-between;
            .home_content_left_guide_box_content_top_box {
              height: 100%;
              width: 340px;
              background: #f2f3f7;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              &:hover {
                color: #3e97df;
              }
              img {
                width: 57px;
                height: 57px;
                margin-right: 20px;
              }
              .home_content_left_guide_box_content_top_box_text {
                font-size: 16px;
                width: 183px;
                text-align: left;
              }
            }
          }
        }
      }
    }
    .home_content_right {
      width: 430px;
      // height: 695px;
      .home_content_right_refer {
        width: 100%;
        background-color: #fff;
        margin-bottom: 12px;
        .home_content_right_refer_title {
          font-size: 16px;
          height: 40px;
          background-color: #f2f3f7;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
        }
        .home_content_right_refer_box {
          display: flex;
          flex-direction: column;
          border: 1px solid #eeeeee;
          .home_content_right_refer_box_content {
            display: flex;
            align-items: center;
            margin-left: 30px;
            margin-top: 15px;

            cursor: pointer;
            &:last-child {
              margin-bottom: 16px;
            }
            &:hover {
              color: #3e97df;
            }
            img {
              width: 41px;
              height: 41px;
              margin-right: 20px;
            }
            .home_content_right_refer_box_content_text {
              font-size: 16px;
              &:link{
                color: #000;
              }
              &:visited{
                color: #000;
              }
              &:hover{
                color: #3e97df;
              }
              &:active{
                color: #000;
              }
            }
          }
        }
      }
    }
  }
  .home_content_buttom_ljx {
    height: 176px;
    width: 100%;
    background: url("../../image/wzybg.png") no-repeat;
    box-sizing: 100%;
    margin-top: 20px;
    display: flex;
    padding: 0 60px;
    align-items: center;
    box-sizing: border-box;
    background-size: 100%;
    .home_content_buttom_box {
      height: 120px;
      width: 260px;
      margin-right: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      opacity: 0.6;
      background-color: #fff;
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 12px;
      }
      &:last-child {
        margin-right: 0px;
      }
      .home_content_buttom_box_content {
        font-size: 18px;
      }
    }
    .home_content_buttom_box.active {
      height: 120px;
      width: 260px;
      margin-right: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      opacity: 0.6;
      background-color: #3e97df;
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 12px;
      }
      &:last-child {
        margin-right: 0px;
      }
      .home_content_buttom_box_content {
        font-size: 18px;
        color: #fff;
      }
    }
  }
  .home_content_consult_robotics {
    position: fixed;
    .home_content_consult_robotics_robot {
      width: 300px;
      background-color: #3e97df;
      position: fixed;
      top: 40%;
      right: -13%;
      cursor: pointer;
      transition: all 0.7s;
      display: flex;
      align-items: center;
      border-radius: 30px;
      .home_content_consult_robotics_robot_text {
        color: #ffffff;
        font-size: 14px;
        display: flex;
      }
      img {
        display: flex;
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
      &:hover {
        transform: translateX(-150px);
      }
    }

    .home_content_consult_robotlong {
      width: 230px;
      height: 60px;
      background-color: #3e97df;
      position: fixed;
      top: 40%;
      right: 3%;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
      img {
        width: 60px;
        height: 60px;
      }
      .home_content_consult_robotlong_text {
        color: #ffffff;
        font-size: 16px;
      }
    }
    .home_content_consult_phone {
      width: 300px;
      height: 60px;
      background-color: #3e97df;
      position: fixed;
      top: 50%;
      right: -13%;
      border-radius: 30px;
      display: flex;
      align-items: center;
       transition: all 0.7s;
      cursor: pointer;
      &:hover {
        transform: translateX(-150px);
      }
      .home_content_consult_phone_text {
        font-size: 14px;
        color: #ffffff;
      }
      img {
        width: 60px;
        height: 60px;
        display: flex;
      }
    }
    .home_content_consult_phonelong {
      width: 230px;
      height: 60px;
      background-color: #3e97df;
      position: fixed;
      top: 50%;
      right: 3%;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .home_content_consult_phonelong_text {
        font-size: 16px;
        color: #ffffff;
      }
    }
  }
}
</style>
