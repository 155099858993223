import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from ''

Vue.use(VueRouter)

import routes from './routes.js';


let router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
});
let targe = true
router.beforeEach((to, from, next) => {
    // console.log(to,'listlistlistlistlist')
    let router = ['examinationregistration', 'preRegisteredExamination', 'replacementSystem', 'examinerSystem']
    let list = to.path.split('/');
    let tokens = sessionStorage.getItem('Tokens');
    let code = sessionStorage.getItem('code');
    let user = sessionStorage.getItem('Token');
    let examineeId = sessionStorage.getItem('examineeId');
    router.forEach(x => {

        if (x === list[1]) {
            localStorage.setItem("toRoute", to.path);
            if (list[1] == 'replacementSystem') {
                if (code == 200 && tokens) {
                    next()
                } else {
                    next({path: '/login', query: {type: list[1]}})
                }
            } else if (list[1] == 'examinationregistration' || list[1] == 'preRegisteredExamination'){
                if (user && examineeId && to.path != '/login') {
                    let isComplete = sessionStorage.getItem("isComplete");
                    if (isComplete === 'false') {
                        // 信息不完整，强制跳转到修改用户信息页面
                        console.log(isComplete, '用户信息完整性');
                        next({path: '/userPage'});
                    } else {
                        next();
                    }
                } else {
                    next({path: '/login', query: {type: list[1]}})
                }
            }else if(list[1] === 'examinerSystem'){
              // examiner_token
              const token = sessionStorage.getItem('examiner_token');
              if(token){
                next()
              }else{
                next({path: '/login', query: {type: list[1]}})
              }
            }
            // else {
            //     let user = sessionStorage.getItem('Token');
            //     let examineeId = sessionStorage.getItem('examineeId');
            //     if ((!user || !examineeId) && to.path != '/login') {
            //         // 动作：保存要访问的页面在localStorage里面
            //         localStorage.setItem("toRoute", to.path);
            //         // location.href = Vue.prototype.casUrl + "?service=initService&response_type=code&client_id="
            //         //     + Vue.prototype.clientId + "&scope=all&redirect_uri=" + Vue.prototype.redirectUrl;
            //
            //         next({path: '/login', query: {type: list[1]}})
            //     } else {
            //         let isComplete = sessionStorage.getItem("isComplete");
            //         if (isComplete === 'false') {
            //             // 信息不完整，强制跳转到修改用户信息页面
            //             console.log(isComplete, '用户信息完整性');
            //             next({path: '/userPage'});
            //         } else {
            //             next();
            //         }
            //     }
            // }

            // if ((!user || !examineeId) && to.path != '/login') {
            //     // 动作：保存要访问的页面在localStorage里面
            //     localStorage.setItem("toRoute", to.path);
            //     // location.href = Vue.prototype.casUrl + "?service=initService&response_type=code&client_id="
            //     //     + Vue.prototype.clientId + "&scope=all&redirect_uri=" + Vue.prototype.redirectUrl;

            //     next({path: '/login', query: {type: list[1]}})
            // } else {
            //     let isComplete = sessionStorage.getItem("isComplete");
            //     if (isComplete === 'false') {
            //         // 信息不完整，强制跳转到修改用户信息页面
            //         console.log(isComplete, '用户信息完整性');
            //         next({path: '/userPage'});
            //     } else {
            //         next();
            //     }
            // }
        }
    })



    // if (to.path === '/xxfww/home' && window.location.search !== '') {
    //     console.log('locations',window.location.search)
    //     var newUrl = "/";
    //     var stateObject = 0;
    //     var title = "0"
    //     let param = window.location.search.split('=');
    //     history.replaceState(stateObject, title, newUrl);
    //     console.log(param)
    //     next({path: '/loading', query: {code: param[param.length - 1]}})
    // }
    next()
    // 开放子集路由跳转
    // if(to.path==='/xxfww/announcement'&&targe){
    //   if(to.fullPath!==''){
    //     next()
    //     let timer=null
    //     timer=setTimeout(() => {
    //       next(to.fullPath)
    //       targe=false
    //       clearTimeout(timer)
    //     },1);
    //   }
    // }else{
    //   next()
    // }
})

window.$router = router
export default router;
