<template>
  <el-row :gutter="20" id="trainingInstitution-box" class="ym-faqs" style="margin-left: 0;margin-right:0;">
    <el-col :span="24" class="right-trainingInstitution-tabel" style="padding: 0;padding-left: 15px;">
      <div class="let-trainingInstitution-nav">
        <div>
          当前位置:
          <span @click="back_home" class="hyperlink-blue" title="去首页">网站首页</span>>常见问答
        </div>
      </div>

      <el-row :gutter="20" class="inner-t trainingInstitution-tabel" style="margin-left: 0;margin-right:0;">
        <el-col :span="7" class="let-announcement-nav">
          <el-row class="announcement-nav-box">
            <el-col :span="24">
              <div class="faqs-nav">
                <el-row class="apply-list">
                  <ul>
                    <li :key="index" v-for="(item,index) in this.optionData.question_category" @click="open_detail(item)">
                      <div class="span_detil inner-t hlva">{{item.dataDesc}}</div>
                    </li>
                  </ul>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="16" style="padding: 0;width: 839px;">
          <div class="grid-content font-t">
            <div>
              <span class="grid-content-span">{{titles}}</span>
            </div>
          </div>
          <el-row :gutter="20" class="inner-t">
            <el-col :span="14" class="search-keyword" :offset="10">
              <span class="searchTil">关键字：</span>
              <el-input class="search-inp" v-model="searchData.keyword"></el-input>
              <el-button @click="search">查询</el-button>
            </el-col>
          </el-row>
          <el-table
            class="inner-t table-list"
            :data="tableData.list"
            :row-class-name="tableRowClassName"
            header-row-class-name="table-class-name"
            @row-click="clickRow"
          >
            <el-table-column label="序号" type="index" align="center" width="80"></el-table-column>
            <el-table-column align="center" label="咨询内容" prop="question">
              <!-- <template slot-scope="scope">
                <span class="uid-class">{{scope.row.examName}}</span>
              </template>-->
            </el-table-column>
            <!-- <el-table-column align="center" label="所属类别" prop="area"></el-table-column> -->
            <el-table-column align="center" label="发布日期" prop="publishTime" width="200"></el-table-column>
          </el-table>

          <div class="caozuo" style="margin-top: 20px;float:right;">
            <el-pagination
              @size-change="size_change"
              @current-change="current_change"
              :current-page.sync="tableData.currPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="tableData.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableData.totalNum"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
import { mapState } from "vuex";
import cTree from "@/components/cTree.vue";
export default {
  data() {
    return {
      titles:  this.$route.query.dataValue || "培训考试发证常见问题解答",
      tabelList: [],
      category:  this.$route.query.dataCode || "",
      searchData: {
        keyword: "",
        category: ""
      },
      id: "",
      tableData: {
        list: [],
        totalNum: 0,
        pageSize: 10,
        currPage: 1
      }
    };
  },
  created() {
    this.getOption();
    this.getExamRoomPages(this.searchData.keyword.trim());
  },
  methods: {
    clickRow(row, column, event) {
      this.$router.push({ path: "problem", query: { id: row.id } });
    },
    // 查询
    search(datas) {
        this.getExamRoomPages(datas)
      if (this.searchData.keyword.trim() !== "" ) {
        const keys = this.searchData.keyword.trim();
        this.getExamRoomPages(keys);
      }else{
          this.getExamRoomPages("")
      }
    },
    clear(obj) {
      Object.keys(obj).forEach(x => {
        obj[x] = "";
      });
    },
    size_change(size) {
      this.tableData.pageSize = size;
      this.getExamRoomPages(this.searchData.keyword.trim());
    },
    current_change(currPage) {
      this.tableData.currPage = currPage;
      this.getExamRoomPages(this.searchData.keyword.trim());
    },
    open_detail(item, index) {
      this.searchData.keyword = "";
      this.titles = item.dataDesc;
      this.category = item.dataCode;
      this.id = item.id;
      this.getExamRoomPages(this.searchData.keyword.trim());
    },


    init() {
    },
    // 初始化列表
    getExamRoomPages(keys) {
      let url = "szsti.publicInfo.CommonQuestion.getList";
      let param = {
        CONSUMER_ID: "system",
        category: this.category,
        currPage: this.tableData.currPage,
        pageSize: this.tableData.pageSize,
        keyword: keys
      };
      this.$http(url, param, false)
        .then(res => {
          if (res.data.BODY.status == 200 && res.data.BODY.msg == "OK") {
            const datas = res.data.BODY.data;
            datas.map((item,index) => {
                 item.publishTime =  item.publishTime.slice(0,10)
            })
            this.tableData.list = datas;
            this.tableData.totalNum = res.data.BODY.totalNum;
            for (var i = 0; i < this.tableData.list.length; i++) {
              this.tableData.list[i].uid = i + 1;
            }

            this.tableData.totals = res.data.BODY.totalNum;
          } else {
            this.$message({
              message: "分页查询失败，失败信息：" + res.data.BODY.message,
              type: "warning"
            });
          }
        })
        .catch(y => {
          this.$message.error(y);
        });
    },
    // 初始字典
    getOption() {
      this.$store.dispatch("modifyOptionData", this.url);
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      } else {
        return "";
      }
    },
    back_home() {
      this.$router.push("/xxfww/home");
    },
    tabs_btn(row) {
      this.btnData.forEach(x => {
        if (row === x) {
          x.bac = true;
        } else {
          x.bac = false;
        }
      });
      // 请求新数据
      this.searchData.type = row.type;
      this.getExamRoomPages();
    },
    changeOption() {}
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState({
      optionData: state => state.option_data
    })
  },
};
</script>
<style lang="scss" scoped>
#trainingInstitution-box {
  display: flex;
  justify-content: space-between;
  min-height: 600px;

  .faqs-nav {
    font-size: 14px;
    background-image: url(~@/image/wtfl.png);
    background-size: 100%;
    font-family: Microsoft YaHei;
    font-weight: bold;
    height: 121px;
    width: 300px;
    color: rgba(255, 255, 255, 1);
    .apply-list {
      min-height: 94%;
      width: 100%;
      height: 620px;
      border: 1px solid #d8e2f0;
      ul {
        padding: 0;
        margin-top: 145px;
        list-style: none;
        padding: 0;
        border-top: none;
        li {
          color: #999999;
          font-family: \5 FAE \8 F6F \9 6c 5 \9 ED1;
          text-align: left;
          font-size: 14px;
          line-height: 30px;
          background: url("../../image/dot.jpg") no-repeat 15px 8px;
          background-size: 20px;
          padding: 6px 0;
          .span_detil {
            width: 80%;
            padding-left: 40px;
            line-height: 20px;
            word-wrap: break-word;
          }
        }
      }
    }
  }

  .btn-serch {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .el-button {
      display: inline-block;
      width: 21%;
      color: #3e97df;
    }
  }

  .el-button.el-button--primary.btn-s {
    color: #fff;
  }

  .grid-content {
    position: relative;
    padding: 10px;
    padding-left: 21px;
    min-height: 26px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: #3e97df;
    color: #fff;
    font-size: 22px;
    margin-bottom: 16px;
  }

  .search-keyword {
    margin-bottom: 10px;
    display: flex;

    .searchTil {
      color: #3e97df;
      display: inline-block;
      width: 130px;
      margin: auto;
      font-size: 17px;
      font-weight: 600;
    }

    /deep/.search-inp {
      input {
        height: 32px;
        background-color: #d8eaf9;
        border-radius: 13px;
        border: 1px solid #d8eaf9;
      }
    }

    /deep/.el-button--default {
      padding: 0;
      background-color: #3e97df;
      width: 100px;
      color: #fff;
      font-size: 14px;
      border-radius: 6px;
      margin-left: 10px;
      font-weight: bold;
    }
  }

  .let-trainingInstitution-nav {
    color: #3e97df;
    font-size: 14px;
    font-family: Microsoft YaHei;
    text-align: left;
    padding-left: 15px;
  }

  .right-trainingInstitution-tabel {
    /deep/ .table-class-name {
      color: #3e97df !important;
    }

    .trainingInstitution-seach {
      height: 50px;

      .el-col {
        height: 100%;
        line-height: 50px;
      }
    }

    .trainingInstitution-tabel {
      margin-top: 10px;
    }

    .el-table {
      width: 100%;
      height: 100%;
      overflow: auto;
      border: 1px solid #ccc;
      border-radius: 4px 4px 4px 4px;
    }
   /deep/ .el-table tbody tr:hover>td {
    background-color:#3E97DF !important;
    color: #fff;
}
    .table-list {
       /deep/ .el-table__body-wrapper {

        }
    };
    .uid-class {
      display: inline-block;
      width: 22px;
      height: 21px;
      background: rgba(62, 151, 223, 1);
      border-radius: 50%;
      color: #fff;
    }
  }

  .el-input__inner {
    height: 32px;
  }
}
</style>
//修改el-UI样式
<style lang="scss">
    .ym-faqs {
        .el-table th>.cell {
            font-weight: 600;
        }
        .el-table tr {
            cursor: pointer;
        }
    }
</style>
