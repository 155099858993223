<template>
  <el-row :gutter="20" id="trainingInstitution-box" class="ym-trainingInstitution">
    <el-col :span="24" class="right-trainingInstitution-tabel">
      <div class="let-trainingInstitution-nav">
        <div>
          当前位置:
          <span @click="back_home" class="hyperlink-blue" title="去首页">网站首页</span>>培训机构
        </div>
      </div>

      <div class="grid-content font-t">
        <div>
          <span class="grid-content-span">深圳市安全培训机构信息</span>
        </div>
      </div>
      <el-row :gutter="20" class="inner-t" style="margin-left: 0;margin-right: 0;">
        <el-col>
          <el-form :model="searchData" label-width="100px">
            <el-row class="trainingInstitution-seach">
              <el-col :span="6" class="seach-input">
                <el-form-item label="所在区市：">
                  <el-select
                    autocomplete="off"
                    v-model="searchData.area"
                    clearable
                    placeholder="请选择所在区市名称"
                  >
                    <el-option
                      v-for="item in area"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="seach-input">
                <el-form-item label="机构名称：">
                  <el-input clearable placeholder="请输入机构名称" v-model.trim="searchData.name" />
                </el-form-item>
              </el-col>
              <el-col :span="6" class="seach-input">
                <el-form-item label="培训项目：">
                  <el-select
                    autocomplete="off"
                    v-model="searchData.workcategory"
                    clearable
                    placeholder="请选择培训项目名称"
                  >
                    <el-option
                      v-for="item in trainProject"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="search-click">
                <el-button @click="search" type="primary" size="mini">查询</el-button>
                <el-button
                  type="danger"
                  size="mini"
                  class="button-solr-color"
                  @click="clear(searchData)"
                >清空</el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        class="inner-t trainingInstitution-tabel"
        style="margin-left: 0;margin-right: 0;"
      >
        <el-col style="padding: 0">
          <el-table :data="tableData.list" style="width: 1200px">
            <el-table-column align="center" width="50" label="序号" color="#3E97DF">
              <template slot-scope="scope">
                <span class="uid-class">{{scope.row.uid}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" width="100" label="所属区域" prop="value"></el-table-column>
            <el-table-column align="center" label="机构名称" min-width="130" prop="name"></el-table-column>
            <el-table-column align="center" label="地址" min-width="120" prop="address"></el-table-column>
            <el-table-column align="center" width="120" label="咨询电话" prop="counsePhone"></el-table-column>
            <el-table-column align="center" width="280" label="培训项目" prop="workcategory"></el-table-column>
            <el-table-column label="操作" width="120" align="center" class="operation">
              <template slot-scope="scope" align="center">
                  <img src="../../image/WechatIMG378.png" alt="">
                <el-button type="text" size="mini" @click="showDetails(scope.row)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="caozuo" style="margin-top: 20px;float:right;">
            <el-pagination
              @size-change="size_change"
              @current-change="current_change"
              :current-page.sync="tableData.currPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="tableData.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableData.totals"
            ></el-pagination>
          </div>
          <!-- <el-dialog title="安全培训开展情况" :visible.sync="detailsDialog" width="782px">
            <el-table :data="detailsData" style="width: 100%" :row-class-name="tableRowClassName">
              <el-table-column prop="project" align="center" label="培训项目" min-width="100" />
              <el-table-column prop="trainNum" align="center" label="培训人数" min-width="80" />
              <el-table-column prop="passNum" align="center" label="合格人数" min-width="80" />
              <el-table-column
                prop="passRate"
                :formatter="passRateFormatter"
                align="center"
                label="合格率"
                min-width="80"
              />
            </el-table>
            <div class="explain">说明：统计数据从2019年1月1日起</div>
          </el-dialog>-->
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
import { mapState } from "vuex";
import cTree from "@/components/cTree.vue";
export default {
  data() {
    return {
      searchData: {
        area: "",
        name: "",
        workcategory: ""
      },
      tableData: {
        list: [],
        totals: 0,
        pageSize: 10,
        currPage: 1
      },
      area: [],
      trainProject: [],
      detailsData: [],
      detailsDialog: false
    };
  },
  created() {
    this.getOption();
    this.getTrainInstitutionPage();
    this.dictionary_data();
    this.getTrainProject();
  },
  methods: {
    // 查询
    search() {
      console.log(this.searchData.area);
      this.getTrainInstitutionPage();
    },
    clear(obj) {
      Object.keys(obj).forEach(x => {
        obj[x] = "";
      });
      this.getTrainInstitutionPage();
    },
    size_change(size) {
      this.tableData.pageSize = size;
      this.getTrainInstitutionPage();
    },
    current_change(currPage) {
      this.tableData.currPage = currPage;
      this.getTrainInstitutionPage();
    },

    // 初始化列表
    getTrainInstitutionPage() {
      let url = "szsti.AdminEnd.RegisterDynamic.registerDynamicList";
      let param = {
        CONSUMER_ID: "system",
        currPage: this.tableData.currPage,
        pageSize: this.tableData.pageSize,
        area: this.searchData.area,
        name: this.searchData.name,
        workcategory: this.searchData.workcategory
      };
      this.$http(url, param, false)
        .then(res => {
          if (res.data.BODY.msg == "OK") {
            this.tableData.list = res.data.BODY.data;
            for (var i = 0; i < this.tableData.list.length; i++) {
              this.tableData.list[i].uid = i + 1;
            }
            console.log(res.data.BODY.data[1]);
            this.tableData.totals = res.data.BODY.total;
          } else {
            this.$message({
              message: "分页查询失败，失败信息：" + res.data.BODY.message,
              type: "warning"
            });
          }
        })
        .catch(y => {
          this.$message.error(y);
        });
    },

    // 初始字典
    getOption() {
      this.$store.dispatch("modifyOptionData", this.url);
    },

    //获取机构所在区域
    dictionary_data() {
      let _this = this;
      let url = "szsti.AdminEnd.RegisterDynamic.getDictionaryByType";
      let param = {
        CONSUMER_ID: "system",
        type: "district"
      };
      this.$http(url, param, false)
        .then(res => {
          if (res.data.BODY.code == 200) {
            this.area = res.data.BODY.data;
          }
        })
        .catch(y => {
          this.$message.error(y);
        });
    },

    //获取培训项目
    getTrainProject() {
      let _this = this;
      let url = "szsti.AdminEnd.RegisterDynamic.getDictionary";
      let param = {
        CONSUMER_ID: "system",
        type: "workcategory"
      };
      this.$http(url, param, false)
        .then(res => {
          if (res.data.BODY.code == 200) {
            this.trainProject = res.data.BODY.data;
          }
        })
        .catch(y => {
          this.$message.error(y);
        });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      } else {
        return "";
      }
    },
    back_home() {
      this.$router.push("/xxfww/home");
    },
    showDetails(row) {
      // this.$router.push({
      //   path: "/xxfww/trainingInstitutiondetails?id=" + row.id,
      //   query: { data: JSON.stringify(row) }
      // });
      this.$router.push ( {
        path: "/xxfww/trainingInstitutionup?id=" + row.id,
      })
    }
  },
  computed: {
    ...mapState({
      optionData: state => state.option_data
    })
  },
  components: { cTree }
};
</script>
<style>
body .el-table th.gutter {
  display: table-cell !important;
}
</style>
<style lang="scss" scoped>
#trainingInstitution-box {
  display: flex;
  justify-content: space-between;
  min-height: 800px;
  .grid-content {
    position: relative;
    padding: 10px;
    min-height: 30px;
    display: flex;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: #3e97df;
    color: #fff;
    margin-top: 3px;
  }

  /deep/.el-dialog__header {
    background: rgba(62, 151, 223, 1);
    border-radius: 10px 10px 0 0;
    padding: 0;
    line-height: 56px;
    display: flex;
    justify-content: center;
    .el-dialog__title {
      color: #fff;
      line-height: 56px;
    }
    .el-dialog__headerbtn {
      top: 0;
      line-height: 56px;
      i {
        border: 1px solid rgba(255, 255, 255, 1);
        background: rgba(255, 255, 255, 1);
        border-radius: 50%;
        width: 31px;
        height: 31px;
        line-height: 31px;
        color: #3e97df;
      }
    }
  }

  /deep/.el-dialog__body {
    padding: 0;
    .el-table {
      .el-table__header-wrapper {
        .el-table__header {
          .is-center {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #3e97df;
            line-height: 32px;
          }
        }
      }
    }
  }

  /deep/.el-dialog {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.26);
    border-radius: 1px;
  }

  .let-trainingInstitution-nav {
    color: #3e97df;
    font-size: 14px;
    font-family: Microsoft YaHei;
    text-align: left;
  }

  .right-trainingInstitution-tabel {
    .trainingInstitution-seach {
        height: 75px;
        display: flex;
        align-items: center;
      .seach-input {
        height: 100%;
        height: 38px;
        line-height: 38px;
        padding-right: 20px;
        .el-form-item {
          .el-form-item__label {
            height: 38px;
            line-height: 38px;
            font-size: 16px;
            color: #333;
            font-family: Microsoft YaHei;
            font-weight: 400;
            padding-right: 10px;
          }

          .el-form-item__content {
            height: 38px;
            line-height: 38px;
            .el-input--suffix {
              /deep/.el-input__inner {
                height: 38px;
                line-height: 38px;
                font-size: 16px;
              }
              .el-input__icon {
                line-height: 38px;
              }
            }
          }
        }
      }

      .search-click {
        display: flex;
        justify-content: center;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        .el-button--primary {
            background-color: #3E97DF;
        }
        .el-button {
          height: 38px;
          width: 90px;
        }
      }
    }

    .trainingInstitution-tabel {
      width: 100%;
      height: 100%;
      .el-table__row {
          td {
              padding: 10px 0;
          }
          td:last-child{
              .cell {
                  height: auto;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  img{
                      padding-right: 10px;
                  };
                  .el-button--text {
                      font-size: 14px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #3E97DF;
                      cursor: pointer;
                  }
              }
          }
      }
    }

    .explain {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #3e97df;
      line-height: 63px;
      height: 63px;
      text-align: left;
      text-indent: 100px;
      border-radius: 0 0 10px 10px;
    }

    .el-table {
      height: 80%;
      overflow: auto;
      border: 1px solid rgba(216, 226, 240, 1);
      border-radius: 4px 4px 4px 4px;
    }

    .table-class-name {
      color: #3e97df !important;
    }

    .el-table .warning-row {
      background: #ccc;
    }

    .uid-class {
      display: inline-block;
      width: 22px;
      height: 21px;
      background: rgba(62, 151, 223, 1);
      border-radius: 50%;
      color: #fff;
    }
  }
}
</style>
<style scoped="scoped" lang="scss">
#trainingInstitution-box .grid-content {
  margin-top: 20px;
}
</style>
//修改这个组件下el-UI的样式
<style lang="scss">
.ym-trainingInstitution {
  .el-table th > .cell {
    color: #3e97df;
    font-weight: bold;
  }
}
</style>
