<template>
    <div class="examinationregistration-homePage" id="content">
        <el-row class="sign-up-title">
            <el-col :span="24">
                <div class="grid-content_title">
              <span class="tips_title">
                <img alt src="../../../image/wxts1.png"/> 温馨提示：
              </span>
                    <span>生产经营单位主要负责人等四类从业人员考试计划已发布在如下，考在规定时间内完成报考、预约考试、打印准考证，并按准考证上时间到指定点考试，有疑问详询23481891。</span>
                </div>
            </el-col>
        </el-row>
        <el-row class="sign-up">
            <el-col :span="24" class="singContent">
                <div class="grid-content">
                    <img src="../../../image/ksbmsy_icon1.png"/>
                    <span>报名列表</span>
                </div>
                <div class="page">
                    <el-table
                        :data="tableData1.list"
                        :stripe="true"
                        class="inner"
                        style="width: 100%;"
                        tooltip-effect="dark"
                    >
                        <el-table-column align="center" label="序号" prop="id" width="50"></el-table-column>
                        <el-table-column align="center" label="考试计划" min-width="240" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span>{{scope.row.playName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            label="计划考试时间"
                            prop="planInterval"
                            show-overflow-tooltip
                        ></el-table-column>
                        <el-table-column align="center" label="报名时间" prop="orderInterval"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="快速操作">
                            <template slot-scope="scope">
                                <template v-if="scope.row.examieePlayStatus==='报名中'">
                                    <el-button
                                        @click="applicant(scope.row)"
                                        class="operation"
                                        size="mini"
                                        type="primary"
                                    >点击报名
                                    </el-button>
                                </template>
                                <template v-else>{{scope.row.examieePlayStatus}}</template>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        :current-page.sync="searchData1.currPage"
                        :page-size="searchData1.pageSize"
                        :total="tableData1.totals"
                        @current-change="getList1"
                        class="page-class"
                    ></el-pagination>
                </div>
            </el-col>
        </el-row>
        <el-row class="sign-up">
            <el-col :span="24" class="singContent">
                <div class="grid-content">
                    <img src="../../../image/ksbmsy_icon2.png"/>
                    <span>报名结果</span>
                </div>

                <div class="page">
                    <el-table
                        :data="tableData2.list"
                        :stripe="true"
                        class="inner"
                        style="width: 100%;"
                        tooltip-effect="dark"
                    >
                        <el-table-column align="center" label="序号" min-width="50" prop="id"></el-table-column>
                        <el-table-column align="center" label="考试计划" min-width="220" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span>{{scope.row.playName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="计划考试时间" prop="planTime"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="报名时间" prop="orderTime"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="操作时间" prop="examTime"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="审核结果">
                            <template slot-scope="scope">
                                <template v-if="scope.row.finish === 1">
                                    <span>考试结束</span>
                                </template>
                                <template v-else>
                                    <span>{{scope.row.examStatus|filterStatus}}</span>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="快速操作">
                            <template slot-scope="scope">
                                <el-button
                                    @click="look(scope.row)"
                                    size="mini"
                                    style="margin-left:10px; margin-bottom: 10px;"
                                    type="info"
                                >查看
                                </el-button>
                                <div v-if="scope.row.finish === 0">
                                    <template v-if="scope.row.examStatus===1 || scope.row.examStatus===3">
                                        <el-button
                                            @click="revocation(scope.row)"
                                            size="mini"
                                            style="margin-left:10px; margin-bottom: 10px;"
                                            type="warning"
                                        >撤销
                                        </el-button>
                                    </template>
                                    <template v-if="scope.row.examStatus===2 || scope.row.examStatus===4">
                                        <el-button
                                            @click="edit(scope.row)"
                                            size="mini"
                                            style="margin-left:10px"
                                            type="primary"
                                        >编辑
                                        </el-button>
                                    </template>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        :current-page.sync="searchData2.currPage"
                        :page-size="searchData2.pageSize"
                        :total="tableData2.totals"
                        @current-change="getList2"
                        class="page-class"
                    ></el-pagination>
                    <!--                查看详情-->
                    <el-dialog
                        :close-on-click-modal="false"
                        :visible.sync="showDetail"
                        @closed="afterCloseDetail"
                        class="log-content"
                        title="报名详情"
                        width="13.6rem"
                    >
                        <div class="log-min-con">
                            <div class="user-info contents">
                                <div>个人信息</div>
                                <table border="0" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <td>姓名：</td>
                                        <td>{{detail.realName}}</td>
                                        <td>性别：</td>
                                        <td>{{detail.sex}}</td>
                                        <td>民族：</td>
                                        <td>{{detail.nation}}</td>
                                    </tr>
                                    <tr>
                                        <td>政治面貌：</td>
                                        <td>{{detail.politicsStatus}}</td>
                                        <td>最高学历：</td>
                                        <td>{{detail.degreeCulture}}</td>
                                        <td>手机号码：</td>
                                        <td>{{detail.mobile}}</td>
                                    </tr>
                                    <tr>
                                        <td>证件类型：</td>
                                        <td>{{detail.cardType}}</td>
                                        <td>证件号码:</td>
                                        <td>{{detail.idCard}}</td>
                                        <td>工作单位：</td>
                                        <td>{{detail.workUnit}}</td>
                                    </tr>
                                    <tr>
                                        <td>通讯地址：</td>
                                        <td colspan="5">{{detail.address}}</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="training-info contents">
                                <div>培训信息</div>
                                <table border="0" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <td>培训类型：</td>
                                        <td>{{detail.type == 1 ? '培训机构培训' : '企业自主培训'}}</td>
                                        <td>企业(机构)名称：</td>
                                        <td>{{detail.entpName}}</td>
                                        <td>培训时间：</td>
                                        <td>{{detail.pxStartTime + "~" + detail.pxEndTime}}</td>
                                    </tr>
                                    <tr>
                                        <td>授课教师：</td>
                                        <td>{{detail.entpTeacher}}</td>
                                        <td>授课地点：</td>
                                        <td>{{detail.entpLocation}}</td>
                                        <td>授课内容：</td>
                                        <td>{{detail.entpContentName}}</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="exa-info contents">
                                <div>报考信息</div>
                                <table border="0" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <td>考试名称：</td>
                                        <td colspan="3">{{detail.playName}}</td>
                                        <td>作业类型：</td>
                                        <td>{{detail.workType}}</td>
                                    </tr>
                                    <tr>
                                        <td>操作项目：</td>
                                        <td>{{detail.examItem}}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <!-- <p>考生报名考试id：{{detail.id}}</p>
                        <p>考试计划名称：{{detail.playName}}</p>
                        <p>考试计划年份：{{detail.particularYear}}</p>
                        <p>考试计划批次：{{detail.batchCount}}</p>
                        <p>审核状态：{{detail.examStatus|filterStatus}}</p>
                        <p>计划时间：{{detail.planTime}}</p>
                        <p>报名时间：{{detail.orderTime}}</p>
                        <p>考试时间：{{detail.examTime}}</p>-->
                    </el-dialog>
                </div>
            </el-col>
        </el-row>
        <el-row class="sign-up">
            <el-col :span="24" class="singContent">
                <div class="grid-content">
                    <img src="../../../image/ksbmsy_icon3.png"/>
                    <span>问题解答</span>
                </div>
                <div class="page">
                    <el-table
                        :data="tableData3.list"
                        :stripe="true"
                        class="inner"
                        style="width: 100%;"
                        tooltip-effect="dark"
                    >
                        <el-table-column align="center" label="序号" prop="id" show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="问题描述" prop="question"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="发布时间" prop="pulishTime"
                                         show-overflow-tooltip></el-table-column>
                    </el-table>
                    <el-pagination
                        :current-page.sync="searchData3.currPage"
                        :page-size="searchData3.pageSize"
                        :total="tableData3.totals"
                        @current-change="getList3"
                        class="page-class"
                    ></el-pagination>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                showDetail: false,
                tableData1: {
                    list: [],
                    totals: 0
                },
                tableData2: {
                    list: [],
                    totals: 0
                },
                tableData3: {
                    list: [],
                    totals: 0
                },
                searchData1: {
                    CONSUMER_ID: sessionStorage.getItem("Token"),
                    SERVICE_CODE: "szsti.examManage.ExamOrderPlay.findExamSchedule",
                    pageSize: 5,
                    currPage: 1,
                    examItem: null,
                    particularYear: new Date().getFullYear(),
                    status: 1
                },
                searchData2: {
                    CONSUMER_ID: sessionStorage.getItem("Token"),
                    SERVICE_CODE: "szsti.AdminEnd.ExamExamineePlay.showResultList",
                    examineeId: sessionStorage.getItem("examineeId"),
                    flag: 1,
                    currPage: 1,
                    pageSize: 5
                },
                searchData3: {
                    SERVICE_CODE:
                        "szsti.ExamineeEnd.SysQuestionAnswer.getSysQuestionAnswerList",
                    CONSUMER_ID: sessionStorage.getItem("Token"),
                    pageSize: 5,
                    currPage: 1
                },
                detail: {}
            };
        },
        created() {
            this.init();
        },
        methods: {
            afterCloseDetail() {
                this.detail = {};
            },
            // 列表初始化
            init() {
                this.getList1();
                this.getList2();
                this.getList3();
            },
            getList1() {
                this.common
                    .ajax({
                        method: "post",
                        url: this.url,
                        headers: {
                            "content-type": "multipart/form-data"
                        },
                        data: this.searchData1
                    })
                    .then(res => {
                        console.log(res, "今年的考试计划");
                        if (res.data.BODY.ok === "true") {
                            this.tableData1.totals = res.data.BODY.data.pageInfo.totalRow;
                            this.tableData1.list = res.data.BODY.data.list;
                            this.tableData1.list.forEach(item => {
                                item.planInterval = item.startPlanTime + "~" + item.endPlanTime;
                                item.orderInterval =
                                    item.startOrderTime + "~" + item.endOrderTime;
                            });
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },
            getList2() {
                this.common
                    .ajax({
                        method: "post",
                        url: this.url,
                        headers: {
                            "content-type": "multipart/form-data"
                        },
                        data: this.searchData2
                    })
                    .then(res => {
                        console.log(res, "报名结果列表");
                        if (res.data.BODY.success === "true") {
                            this.tableData2.totals = res.data.BODY.data.totalRow;
                            this.tableData2.list = res.data.BODY.data.list;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },
            getList3() {
                this.common
                    .ajax({
                        method: "post",
                        url: this.url,
                        headers: {
                            "content-type": "multipart/form-data"
                        },
                        data: this.searchData3
                    })
                    .then(res => {
                        console.log(res, "问题列表");
                        if (res.data.BODY.success === "true") {
                            this.tableData3.totals = res.data.BODY.data.pageInfo.totalRow;
                            this.tableData3.list = res.data.BODY.data.list;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },
            // 报名
            applicant(row) {
                this.$router.push({name: "我要报名", params: {playId: row.id}});
            },
            // 查看
            look(row) {
                this.detail = row;
                this.showDetail = true;
            },
            // 修改
            edit(row) {
                this.$router.push({
                    path: "/examinationregistration/applicant",
                    query: {orderId: row.id}
                });
            },
            // 撤销
            revocation(row) {
                this.$confirm("确定撤销该报名记录?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        this.common
                            .ajax({
                                method: "post",
                                url: this.url,
                                headers: {
                                    "content-type": "multipart/form-data"
                                },
                                data: {
                                    CONSUMER_ID: sessionStorage.getItem("Token"),
                                    SERVICE_CODE:
                                        "szsti.AdminEnd.ExamExamineePlay.updateAuditStatus",
                                    id: row.id,
                                    auditStatus: "4",
                                    auditReason: "考生自主撤销"
                                }
                            })
                            .then(res => {
                                console.log(res, "撤销报名");
                                if (res.data.BODY.success === "true") {
                                    this.$message({
                                        type: "success",
                                        message: "操作成功!"
                                    });
                                    this.init();
                                } else {
                                    this.$message({
                                        type: "error",
                                        message: "操作失败!"
                                    });
                                }
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消操作"
                        });
                    });
            }
        },
        filters: {
            filterStatus(val) {
                val = Number(val);
                switch (val) {
                    case 1:
                        return "审核通过";
                        break;
                    case 2:
                        return "审核未通过";
                        break;
                    case 3:
                        return "待审核";
                        break;
                    case 4:
                        return '已撤销';
                        break;
                    case 5:
                        return '考试结束';
                        break;
                    default:
                        return "无";
                }
            }
        }
    };
</script>
<style lang="stylus" scoped>
    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    #content {
        .sign-up {
            margin-bottom: 20px;
            background-color: #ffffff;
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);

            .singContent {
                .page {
                    padding: 20px 10px;

                    .el-table {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .sign-up-title {
            margin-bottom: 20px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 0px 5px 0px rgba(218, 225, 229, 0.75);
            border-radius: 4px;
        }
    }

    .bg-purple-dark {
        background: #99a9bf;
    }

    .bg-purple {
        background: #d3dce6;
    }

    .bg-purple-light {
        background: #e5e9f2;
    }

    .grid-content {
        background-color: #3E97DF;
        font-size: 0.2rem;
        font-weight: bold;
        font-family: Source Han Sans CN;
        color: rgba(66, 71, 75, 1);
        padding: 0.08rem 0.2rem;
        min-height: 26px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        img {
            margin-left: 0;
            height: 45%;
        }

        span {
            margin: 0 10px;
            color: #ffffff;
        }

        .link_more span:link {
            color: #3E97DF;
        }

        .link_more span:hover {
            color: #3E97DF;
        }

        .link_more span:active {
            color: #3E97DF;
        }
    }

    .grid-content_title {
        padding: 10px 14px;
        text-indent: 0.2rem;
        font-size: 16px;
        word-break: break-all;
        text-align: left;
        border-radius: 0.04rem;
        border: 1px solid #ccc;
        color: #878d99;

        .tips_title {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #44A8F8;
            line-height: 0.3rem;
        }
    }

    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }

    .operation {
        background: linear-gradient(-30deg, rgba(62, 151, 223, 1), rgba(82, 178, 255, 1))
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
    }

    .page-class {
        height 30px
        overflow hidden
    }

    .el-dialog__wrapper {
        /deep/ .el-dialog {
            height: 728px

            .el-dialog__header {
                font-size: 0.18rem;
                padding: 0;
                background-color: #3E97DF;
                text-align: left;
                padding-left: 0.22rem;

                .el-dialog__title {
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #fff;
                    line-height: 0.6rem;
                }

                .el-dialog__headerbtn {
                    font-size: 0.3rem;
                    top: 10px;

                    .el-dialog__close {
                        color: #fff;
                    }
                }
            }

            .el-dialog__body {
                .log-min-con {
                    .user-info {
                        div {
                            padding-bottom: 0.15rem;
                        }
                    }

                    .training-info {
                        div {
                            padding: 0.27rem 0 0.22rem;
                        }
                    }

                    .contents {
                        div {
                            font-size: 18px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #3E97DF;
                            text-align: left;
                        }

                        table {
                            width: 100%;
                            border-right: 1px solid #D9D9D9;
                            border-bottom: 1px solid #D9D9D9;

                            td {
                                border: 1px solid #D9D9D9;
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 40
                                padding: 0.2rem 0;
                                border-bottom: 0;
                                border-right: 0;
                                font-family: Microsoft YaHei;
                                font-weight: 400
                            }

                            td:nth-child(odd) {
                                width: 1.7rem;
                                background-color: #EBF4FC;
                                color: #3E97DF;
                            }

                            td:nth-child(even) {
                                width: 2.6rem;
                                text-align: left;
                                padding-left: 0.2rem;
                            }

                        }
                    }

                    .exa-info {
                        div {
                            padding: 0.45rem 0 0.15rem;
                        }

                        table {
                            tr:first-child {
                                td {
                                    border-bottom: 1px solid #D9D9D9;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
<style lang="scss">
    .examinationregistration-homePage {
        .el-table {
            tr {
                background: rgba(62, 151, 223, .1);
            }

            th {
                .cell {
                    font-size: 0.18rem;
                    color: #333;
                    font-weight: bold;
                }
            }

            .el-table__row--striped {
                background: #fff;

                td {
                    background: #fff;
                }
            }
        }

    }
</style>
