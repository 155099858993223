<template>
  <el-row :gutter="20" id="trainingInstitution-box" style="margin-left: 0;margin-right:0;">
    <el-col :span="24" class="right-trainingInstitution-tabel">
      <div class="let-trainingInstitution-nav">
        <div>
          当前位置:
          <span @click="back_home" class="hyperlink-blue" title="去首页">网站首页</span>
          >常见问答
        </div>
      </div>

      <el-row
        :gutter="20"
        class="inner-t trainingInstitution-tabel"
        style="margin-left: 0;margin-right:0;"
      >
        <el-col :span="7" class="let-announcement-nav">
          <el-row class="announcement-nav-box">
            <el-col :span="24">
              <div class="faqs-nav">
                <el-row class="apply-list">
                  <ul>
                    <li :key="index" v-for="(item, index) in tabelList" @click="open_detail(item)">
                      <div class="span_detil inner-t hlva">{{ item.dataDesc }}</div>
                    </li>
                  </ul>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="16" :offset="1" class="content-col" style="padding: 0 20px;width: 855px;">
          <el-row>
            <el-col>
              <div class="ljxgrid-content font-t">
                <span>{{ title }}</span>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <div class="title-det" style="white-space: pre-wrap;">
                <span>发布时间</span>
                <span class="title-det-time">{{ time }}</span>
              </div>
            </el-col>
          </el-row>
          <el-row class="content-til">
            <el-col>
              <el-row class="content-row">
                <el-col :span="2" class="content-row-spa">
                  <span class="content-row-detail">Q</span>
                </el-col>
                <el-col :span="19" class="content-row-con">
                  <span class="triangle"></span>
                  <span class="content-con-detail">{{ question }}</span>
                </el-col>
              </el-row>
              <el-row class="content-row">
                <el-col
                  :span="19"
                  class="content-row-con"
                  style="background: #F5F5F5;margin-left: 50px;"
                >
                  <span class="triangle bor"></span>
                  <span v-html="answer" class="content-row-answr"></span>
                  <!-- <span class="content-con-detail">{{ answer }}</span> -->
                </el-col>
                <el-col :span="2" class="content-row-spa">
                  <span>A</span>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-col class="content-footer">
            <div @click="last" v-show="lastTitle">
              <span>上一条：</span>
              <span>{{ lastTitle }}？</span>
            </div>
            <div @click="next" v-show="nextTitle">
              <span>下一条：</span>
              <span>{{ nextTitle }}？</span>
            </div>
          </el-col>
          <!-- <el-button type="primary" @click="goBack">返回</el-button> -->
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
import { mapState } from "vuex";
import cTree from "@/components/cTree.vue";
export default {
  data() {
    return {
      titles: "特种作业人员考试发证",
      tabelList: [],
      title: "",
      time: "",
      question: "",
      answer: "",
      nextTitle: "",
      lastTitle: "",
      type: "this",
      id: null,
      idLast: null
    };
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
    back_home() {
      this.$router.push("/xxfww/home");
    },
    open_detail(item) {
      this.$router.push({
        path: "/xxfww/faqs",
        query: { dataValue: item.dataValue, category: item.category }
      });
    },
    // 上一条
    last() {
      let url = "szsti.publicInfo.CommonQuestion.getById";
      let param = {
        CONSUMER_ID: "system",
        id: this.idLast
      };
      this.$http(url, param, false)
        .then(res => {
          if (res.data.BODY.status == 200 && res.data.BODY.msg == "OK") {
            const typeList = res.data.BODY.data;
            this.title = typeList.this.title;
            this.time = typeList.this.publishTime;
            this.question = typeList.this.question;
            this.answer = typeList.this.answer;
            this.nextTitle = typeList.next ? typeList.next.title : "";
            this.lastTitle = typeList.last ? typeList.last.title : "";
            this.id = typeList.next ? typeList.next.id : null;
            this.idLast = typeList.last ? typeList.last.id : null;
          } else {
            this.$message({});
          }
        })
        .catch(y => {});
    },
    // 下一条
    next() {
      let url = "szsti.publicInfo.CommonQuestion.getById";
      let param = {
        CONSUMER_ID: "system",
        id: this.id
      };
      this.$http(url, param, false)
        .then(res => {
          if (res.data.BODY.status == 200 && res.data.BODY.msg == "OK") {
            const typeList = res.data.BODY.data;
            this.title = typeList.this.title;
            this.time = typeList.this.publishTime;
            this.question = typeList.this.question;
            this.answer = typeList.this.answer;
            this.nextTitle = typeList.next ? typeList.next.title : "";
            this.lastTitle = typeList.last ? typeList.last.title : "";
            this.id = typeList.next ? typeList.next.id : null;
            this.idLast = typeList.last ? typeList.last.id : null;
          } else {
            this.$message(res.data.BODY.msg);
          }
        })
        .catch(y => {});
    },
    getWorkType() {
      let url = "szsti.system.DataCode.findDataCodeByParentCode";

      let param = {
        CONSUMER_ID: "system-be6d9c413c7a4be484d938a621f2a4f0s",
        parentCode: "question_category"
      };
      this.$http(url, param, false)
        .then(res => {
          this.tabelList = res.data.BODY.data;
          this.titles = this.tabelList[0].dataDesc;
        })
        .catch(y => {});
    },
    // 详情数据
    getExamRoomPages() {
      let url = "szsti.publicInfo.CommonQuestion.getById";
      let param = {
        CONSUMER_ID: "system",
        id: this.$route.query.id
      };
      this.$http(url, param, false)
        .then(res => {
          if (res.data.BODY.status == 200 && res.data.BODY.msg == "OK") {
            const typeList = res.data.BODY.data;
            this.title = typeList.this.title;
            this.time = typeList.this.publishTime;
            this.question = typeList.this.question;
            this.answer = typeList.this.answer;
            this.nextTitle = typeList.next ? typeList.next.title : "";
            this.lastTitle = typeList.last ? typeList.last.title : "";
            this.id = typeList.next ? typeList.next.id : null;
            this.idLast = typeList.last ? typeList.last.id : null;
          } else {
            this.$message({});
          }
        })
        .catch(y => {});
    },
    init() {
      this.getWorkType();
      this.getExamRoomPages();
    }
  },
  mounted() {
    this.init();
  },
  computed: {},
  components: { cTree }
};
</script>
<style lang="scss" scoped>
#trainingInstitution-box {
  padding: 0;
  margin: 0;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  min-height: 600px;
  .right-trainingInstitution-tabel {
    .let-trainingInstitution-nav {
      color: #3e97df;
      font-size: 14px;
      font-family: Microsoft YaHei;
      text-align: left;
    }

    .trainingInstitution-tabel {
      margin-bottom: 30px;
      .let-announcement-nav {
        width: auto;
        margin-right: 25px;
        padding: 0 !important;
        .announcement-nav-box {
          .faqs-nav {
            font-size: 14px;
            background-image: url(~@/image/wtfl.png);
            background-size: 100%;
            font-family: Microsoft YaHei;
            font-weight: bold;
            height: 121px;
            width: 300px;
            color: rgba(255, 255, 255, 1);
            .apply-list {
              min-height: 579px;
              width: 100%;
              border: 1px solid #d8e2f0;
              margin-top: 10px;
              ul {
                padding: 0;
                margin-top: 145px;
                list-style: none;
                padding: 0;
                border-top: none;
                li {
                  color: #999999;
                  font-family: \5 FAE \8 F6F \9 6c 5 \9 ED1;
                  text-align: left;
                  font-size: 14px;
                  line-height: 20px;
                  background: url("../../image/dot.jpg") no-repeat 15px 8px;
                  background-size: 20px;
                  padding: 6px 0;
                  .span_detil {
                    width: 80%;
                    padding-left: 40px;
                    line-height: 20px;
                    word-wrap: break-word;
                  }
                }
              }
            }
          }
        }
      }

      .content-col {
        border: 1px solid #d8e2f0;
        margin-top: 10px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin-left: 0;
        padding: 0;
        border-radius: 4px;
        position: relative;
        .title-det {
          font-size: 18px;
          color: #666666;
          margin-bottom: 50px;
          .title-det-time {
            font-size: 14px;
            line-height: 24px;
          }
        }
        .ljxgrid-content {
          position: relative;
          padding: 30px 1px 15px;
          min-height: 26px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          // color: #;
          background: transparent;
          span {
            color: #3e97df;
          }
        }
        .content-til {
          // padding-bottom: 40px;
          min-height: 386px;
          line-height: 20px;
          .content-row {
            margin-bottom: 26px;
            position: relative;
            display: flex;
            align-items: center;
          }
          .content-row-spa {
            font-weight: bold;
            color: #333333;
            font-size: 15px;
            display: flex;
            width: 38px;
            height: 38px;
            align-items: center;
            span {
              height: 38px;
              width: 38px;
              border-radius: 50%;
              display: block;
              line-height: 38px;
              background-color: #f5f5f5;
            }
            .content-row-detail {
              color: #ffffff;
              background: linear-gradient(
                0deg,
                rgba(62, 151, 223, 1) 0%,
                rgba(78, 193, 247, 1) 100%
              );
            }
          }
          .content-row-con {
            text-align: left;
            font-size: 14px;
            background: linear-gradient(
              90deg,
              rgba(62, 151, 223, 1) 0%,
              rgba(83, 178, 255, 1) 100%
            );
            margin-left: 12px;
            margin-right: 12px;
            padding: 6px 20px;
            position: relative;
            width: 712px;
            span {
              color: #666666;
              line-height: 30px;
            }
            .content-con-detail {
              color: #fff;
            }
            .triangle {
              background: url(../../image/dhkj1.png) no-repeat left top/100%;
              width: 8px;
              height: 10px;
              position: absolute;
              top: 50%;
              margin-top: -5px;
              left: -7px;
            };
            .bor {
              left: auto;
              right: -8px;
              background: url(../../image/dhkj2.png) no-repeat left top/100%;
              // transform: rotate(-180deg);
            };
             .content-row-answr /deep/ p {
              margin: 0;
            }
          }
        }
      }
      .el-button--primary {
        width: 80px;
        font-size: 18px;
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
      }
      .content-footer {
        color: #3e97df;
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        div {
          cursor: pointer;
        }
      }
    }
  }

  .el-button.el-button--primary.btn-s {
    color: #fff;
  }
}
</style>
<style lang="scss">
.content-row-con {
  span >>> p {
    margin: 0;
  }
}
</style>
