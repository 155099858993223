<template>
    <el-row id="content">
        <el-row class="sign-up-title">
            <el-col :span="24">
                <div class="grid-content_title">
          <span class="tips_title">
            <img alt src="../../../image/wxts1.png"/> 温馨提示：
          </span>
                    <span>生产经营单位主要负责人等四类从业人员考试计划已发布在如下，考在规定时间内完成报考、预约考试、打印准考证，并按准考证上时间到指定点考试，有疑问详询23481891。</span>
                </div>
            </el-col>
        </el-row>
        <el-row class="sign-up">
            <el-col :span="24" class="singContent">
                <div class="grid-content">
                    <img src="../../../image/ksbmsy_icon1.png"/>
                    <span>可预约列表</span>
                </div>
                <div class="page">
                    <el-table
                        :data="tableData1.list"
                        border
                        class="inner"
                        style="width: 100%;"
                        tooltip-effect="dark"
                    >
                        <el-table-column align="center" label="序号" type="index"></el-table-column>
                        <el-table-column align="center" label="考试计划" min-width="200" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span>{{scope.row.playName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="考试时间" prop="examInterval"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="预约时间" prop="apInterval"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="快速操作">
                            <template slot-scope="scope">
                                <template v-if="scope.row.status===2">
                                    <el-button @click="applicant(scope.row)" size="mini" type="primary">点击预约</el-button>
                                </template>
                                <template v-else-if="scope.row.status===1">预约已结束</template>
                                <template v-else>未开始预约</template>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        :current-page.sync="searchData1.currPage"
                        :page-size="searchData1.pageSize"
                        :total="tableData1.totals"
                        @current-change="getList1"
                        class="page-class"
                    ></el-pagination>
                </div>
            </el-col>
        </el-row>
        <el-form :model="applicantData" label-width="100px" ref="applicantData">
            <el-row class="sign-up personal">
                <el-col :span="24">
                    <div class="grid-content">
                        <img src="../../../image/bticon_bkxx.png"/>
                        <span>报考信息</span>
                    </div>
                    <div class="make">
                        <el-row>
                            <el-col :span="18">
                                <el-col :span="8">
                                    <el-form-item label="姓名：" required>
                                        <el-input
                                            :disabled="true"
                                            clearable
                                            placeholder="请输入姓名"
                                            v-model="orderData.realName"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="性别：" required>
                                        <el-select
                                            :disabled="true"
                                            autocomplete="off"
                                            clearable
                                            placeholder="请选择考试名称"
                                            v-model="orderData.sex"
                                        >
                                            <el-option
                                                :key="item.dataCode"
                                                :label="item.dataValue"
                                                :value="item.dataCode"
                                                v-for="item in optionData.sex"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="证件类型：" required>
                                        <el-input
                                            :disabled="true"
                                            clearable
                                            placeholder="请输入证件类型"
                                            v-model="orderData.cardType"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="证件号码：" required>
                                        <el-input :disabled="true" placeholder="请输入证件号码"
                                                  v-model="orderData.idCard"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="工作单位：" required>
                                        <el-input
                                            :disabled="true"
                                            clearable
                                            placeholder="（请输入工作单位全称）"
                                            v-model="orderData.workUnit"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="考试名称：" required>
                                        <el-input
                                            :disabled="true"
                                            clearable
                                            placeholder="（请输入考试名称）"
                                            v-model="orderData.playName"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="作业类型：" required>
                                        <el-select
                                            :disabled="true"
                                            autocomplete="off"
                                            clearable
                                            placeholder="请选择作业类型"
                                            v-model="orderData.industryTypeCode"
                                        >
                                            <el-option
                                                :key="item.dataCode"
                                                :label="item.dataValue"
                                                :value="item.dataCode"
                                                v-for="item in optionData.all_work_type"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="操作项目：" required>
                                        <el-select
                                            :disabled="true"
                                            autocomplete="off"
                                            clearable
                                            placeholder="请选择操作项目"
                                            v-model="orderData.checkTypeCode"
                                        >
                                            <el-option
                                                :key="item.dataCode"
                                                :label="item.dataValue"
                                                :value="item.dataCode"
                                                v-for="item in optionData.all_exam_item"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-col>
                            <el-col :offset="1" :span="5">
                                <!--<span class="Photo">证件照片</span>-->
                                <img :src="uploadImageUrl" class="avatar" v-if="orderData.userPic"/>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <el-row class="sign-up">
                <el-col :span="24">
                    <div class="grid-content">
                        <img src="../../../image/bticon_yyxx.png"/>
                        <span>预约信息</span>
                    </div>
                    <div class="make">
                        <el-row class="mackCon">
                            <el-col :span="24">
                                <el-col :span="12">
                                    <el-form-item label="考点名称: " prop="orderPlayId">
                                        <el-select
                                            autocomplete="off"
                                            clearable
                                            placeholder="请选择考点"
                                            v-model="currRoomId"
                                        >
                                            <el-option
                                                :key="index"
                                                :label="index.split('#')[1]"
                                                :value="index"
                                                v-for="(item, index) in appointmentOptions"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <div class="content">
                                    <div class="tableList">
                                        <div class="title">
                                            <div class="time">预约日期</div>
                                            <div class="conRight">可预约时间段(剩余号数)</div>
                                        </div>
                                        <div :key="index" class="list" v-for="(item ,index) in timeList">
                                            <div class="timeRight">
                                                <div>{{item.date}}</div>
                                                <div>{{item.weekday}}</div>
                                            </div>
                                            <div class="place">
                                                <div
                                                    v-for="(items, indexs) in item.timeList"
                                                    :class="{palaceClick:items.isvew,fill:true}"
                                                    :key="indexs"
                                                    @click="(items.full === 'false')&&crrendPaClick(items, item.timeList, item)"
                                                >
                                                    <i v-if="items.full === 'true'">已排满</i>
                                                    <span>{{items.timeSlot}}</span>
                                                    <br/>
                                                    <span>({{`${items.count} / ${items.total}`}})</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </el-form>
        <el-row>
            <el-col :span="24" class="submitBon">
                <el-button @click="popupDetail" size="small" type="primary">提交</el-button>
                <el-button @click="pre" size="small" type="primary">返回</el-button>
            </el-col>
        </el-row>
        <el-dialog :visible.sync="showDetail" title="预约信息" width="50%">
            <p>考试计划名称：{{detail.playName}}</p>
            <p>考生姓名：{{detail.realName}}</p>
            <p>考生性别：{{detail.sex}}</p>
            <p>证件类型：{{detail.cardType}}</p>
            <p>证件号码：{{detail.idCard}}</p>
            <p>工作单位：{{detail.workUnit}}</p>
            <p>行业类别：{{detail.industryTypeName}}</p>
            <p>考核类别：{{detail.checkTypeName}}</p>
            <p>考点名称：{{detail.kaodianName}}</p>
            <p>预约时间：{{detail.orderDate + '(' + detail.week + ') ' + detail.orderTimeSlot}}</p>
            <span class="dialog-footer" slot="footer">
        <el-button @click="showDetail = false">取 消</el-button>
        <el-button @click="submit" type="primary">确 定</el-button>
      </span>
        </el-dialog>
    </el-row>
</template>
<script>
    import {mapState} from "vuex";

    export default {
        data() {
            return {
                showDetail: false,
                detail: {},
                tableData1: {
                    list: [],
                    totals: 0
                },
                searchData1: {
                    CONSUMER_ID: sessionStorage.getItem("Token"),
                    SERVICE_CODE: "szsti.examManage.AppointmentManage.getRegisterList",
                    examineeId: sessionStorage.getItem("examineeId"),
                    currPage: 1,
                    pageSize: 5
                },
                applicantData: {},
                timeList: {},
                orderData: {},
                // 报名记录ID
                orderId: null,
                appointmentOptions: [],
                apForm: {
                    SERVICE_CODE: "szsti.examManage.AppointmentManage.makeAppointment",
                    CONSUMER_ID: sessionStorage.getItem("Token"),
                    orderId: null,
                    examOrderId: null,
                    roomId: null,
                    orderDate: null,
                    orderTimeSlot: null
                },
                currRoomId: null,
                // 预约设置ID
                examOrderId: null,
            };
        },
        mounted() {
            if (this.$route.query.orderId) {
                this.orderId = this.$route.query.orderId;
                this.initForm();
            }
            this.init();
            this.getOption();
        },
        watch: {
            currRoomId: function () {
                for (let index in this.appointmentOptions) {
                    let item = this.appointmentOptions[index];
                    if (index === this.currRoomId) {
                        this.timeList = item;
                    }
                }
            }
        },
        computed: {
            ...mapState({
                optionData: state => state.option_data
            })
        },
        methods: {
            popupDetail() {
                try {
                    // 动作：表单验证
                    if (!this.apForm.roomId
                        || !this.apForm.orderDate
                        || !this.apForm.orderTimeSlot) {
                        throw Error("请选择考点+考试时间段");
                    }
                    this.showDetail = true;
                } catch (e) {
                    this.$message({
                        message: e.message,
                        type: "error"
                    });
                }
            },
            pre() {
                this.$router.push({name: "预约查询"});
            },
            applicant(row) {
                this.orderId = row.orderId;
                this.initForm();
            },
            initForm() {
                this.getExamineeInfo();
                this.getAppointmentOptions();
            },
            crrendPaClick(item, list, bigItem) {
                // 重置timeItem.isvew属性
                for (let kaodian in this.appointmentOptions) {
                    let dateList = this.appointmentOptions[kaodian];
                    dateList.forEach(dateItem => {
                        dateItem.timeList.forEach(timeItem => {
                            timeItem.isvew = false;
                        });
                    })
                }
                // 设置当前格子正在被选中
                item.isvew = true;
                // 修改预约表单
                this.apForm.orderId = this.orderId;
                this.apForm.examOrderId = this.examOrderId;
                this.apForm.roomId = this.currRoomId.split('#')[0] * 1;
                this.apForm.orderDate = item.orderDate;
                this.apForm.orderTimeSlot = item.timeSlot;
                // 修改详情
                this.detail = {
                    orderDate: item.orderDate,
                    orderTimeSlot: item.timeSlot,
                    week: bigItem.weekday,
                    playName: this.orderData.playName,
                    realName: this.orderData.realName,
                    sex: this.orderData.sex,
                    cardType: this.orderData.cardType,
                    idCard: this.orderData.idCard,
                    workUnit: this.orderData.workUnit,
                    industryTypeName: this.orderData.industryTypeName,
                    checkTypeName: this.orderData.checkTypeName,
                    kaodianName: this.currRoomId.split('#')[1]
                };
            },
            beforeUpload() {
                return false;
            },
            getOption() {
                this.$store.dispatch("modifyOptionData", this.url);
            },
            save() {
            },
            submit() {
                try {
                    // 动作：提交
                    this.common
                        .ajax({
                            method: "post",
                            url: this.url,
                            headers: {
                                "content-type": "multipart/form-data"
                            },
                            data: this.apForm
                        })
                        .then(res => {
                            if (res.data.BODY.success === "true") {
                                this.$message({
                                    message: "操作成功",
                                    type: "success"
                                });
                                this.$router.push({name: "预约查询"});
                            } else {
                                this.$message({
                                    message: res.data.BODY.message,
                                    type: "error"
                                });
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        });
                } catch (e) {
                    this.$message({
                        message: "操作失败",
                        type: "error"
                    });
                }
            },
            // 列表初始化
            init() {
                this.getList1();
                this.getOption();
            },
            getList1() {
                this.common
                    .ajax({
                        method: "post",
                        url: this.url,
                        headers: {
                            "content-type": "multipart/form-data"
                        },
                        data: this.searchData1
                    })
                    .then(res => {
                        if (res.data.BODY.success === "true") {
                            this.tableData1.totals = res.data.BODY.data.pageInfo.totalRow;
                            this.tableData1.list = res.data.BODY.data.list;
                            this.tableData1.list.forEach(item => {
                                item.examInterval = item.startPlanTime + " ~ " + item.endPlanTime;
                                item.apInterval = item.apStartTime + " ~ " + item.apEndTime;
                                let startAp = new Date(item.apStartTime);
                                let endAp = new Date(item.apEndTime);
                                let now = new Date();
                                if (now < startAp) {
                                    item.status = 0;
                                } else if (now > endAp) {
                                    item.status = 1;
                                } else {
                                    item.status = 2;
                                }
                            });
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },
            getExamineeInfo() {
                this.common
                    .ajax({
                        method: "post",
                        url: this.url,
                        headers: {
                            "content-type": "multipart/form-data"
                        },
                        data: {
                            SERVICE_CODE: "szsti.examManage.AppointmentManage.getExamineeInfo",
                            CONSUMER_ID: sessionStorage.getItem("Token"),
                            orderId: this.orderId
                        }
                    })
                    .then(res => {
                        if (res.data.BODY.success === "true") {
                            this.orderData = res.data.BODY.data;
                            this.uploadImageUrl = this.resourceUrl + this.orderData.userPic;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },
            getAppointmentOptions() {
                this.common
                    .ajax({
                        method: "post",
                        url: this.url,
                        headers: {
                            "content-type": "multipart/form-data"
                        },
                        data: {
                            SERVICE_CODE: "szsti.examManage.AppointmentManage.getAppointmentOptions",
                            CONSUMER_ID: sessionStorage.getItem("Token"),
                            orderId: this.orderId
                        }
                    })
                    .then(res => {
                        if (res.data.BODY.success === "true") {
                            this.examOrderId = res.data.BODY.data.examOrderId;
                            this.appointmentOptions = res.data.BODY.data.setting;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        }
    };
</script>
<style lang="stylus">
    // * {
    //     font-family: Microsoft YaHei;
    //     font-weight: 400;
    // }

    #content {
        .el-input__inner {
            background-color: #F9FAFC;
        }

        .submitBon {
            .el-button--primary {
                width: 100px;
                margin-right: 20px;
                font-size: 18px;
            }
        }

        .sign-up {
            margin-bottom: 20px;
            background-color: #ffffff;
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);

            .make {
                padding: 20px 10px;

                .mackCon {
                    // padding-top: 20px;

                    .el-form-item {
                        margin: 0;
                        width: 60%;

                        .el-form-item__label {
                            color: #333333;
                        }
                    }
                }
            }

            .trainingInformation {
                .el-input {
                    width: 70%;
                }

                margin-top: 14px;
                padding: 10px;

                .information {
                    .el-form-item__label {
                        font-weight: bold;
                        color: #44A8F8;
                    }
                }
            }
        }

        .personal {
            .el-row {
                padding: 10px;

                .avatar-uploader-icon {
                    font-size: 28px;
                    color: #8c939d;
                    width: 188px;
                    height: 188px;
                    line-height: 188px;
                    text-align: center;
                }

                .Photo {
                    font-size: 14px;
                    color: #333333;
                    display: inline-block;
                    padding-bottom: 10px;
                }
            }

            .el-form-item {
                .el-form-item__label {
                    color: #333333;
                }

                .el-form-item__content {
                    .el-row {
                        padding: 0;
                    }
                }
            }
        }

        .sign-up-title {
            margin-bottom: 20px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 0px 5px 0px rgba(218, 225, 229, 0.75);
            border-radius: 4px;
        }

    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
        border-radius: 5%;
    }

    .grid-content {
        background-color: #3E97DF;
        font-size: 0.2rem;
        font-weight: bold;
        font-family: Source Han Sans CN;
        color: rgba(66, 71, 75, 1);
        padding: 0.08rem 0.2rem;
        min-height: 26px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        img {
            margin-left: 0;
            height: 45%;
        }

        span {
            margin: 0 10px;
            color: #ffffff;
        }
    }

    .grid-content_title {
        padding: 10px 14px;
        text-indent: 0.2rem;
        font-size: 18px;
        word-break: break-all;
        text-align: left;
        border-radius: 4px;
        border: 1px solid #ccc;
        color: #878d99;

        span {
            font-size: 16px;
        }

        .tips_title {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #44A8F8;
            line-height: 24px;
        }
    }

    .el-form-item__content {
        text-align: left;
    }

    .el-select {
        width: 100%;
    }

    .applicant_title {
        font-size: 24px;
        font-weight: bold;
        font-family: Source Han Sans CN;
        color: rgba(66, 71, 75, 1);
        padding: 10px;
        min-height: 26px;
        margin: 0 10px;
        text-align: left;
    }

    .warning_remind {
        font-family: Source Han Sans CN;
        word-break: break-all;
        background: #f2f2f2;
        padding: 10px;
    }

    .content {
        margin-top: 20px;

        .tableList {
            width: 100%;
            display: table;
            text-align: center;

            .title {
                font-size: 16px;
                font-weight: blod;
                color: #333333;
                display: flex;

                .time {
                    width: 20%;
                    border: 1px solid #ccc;
                    padding: 6px;
                }

                .conRight {
                    padding: 6px;
                    width: 80%;
                    border: 1px solid #ccc;
                    border-left: none;
                }
            }

            .list {
                display: flex;

                &.active {
                    .place {
                        background-color: #fff;
                        cursor: pointer;
                        // pointer-events: all;
                    }
                }

                .timeRight {
                    width: 20%;
                    border: 1px solid #ccc;
                    border-top: none;
                    font-size: 18px;
                    color: #555555;
                    padding: 8px 0;
                }

                .date {
                    background-color: #12578a;
                    color: #fff;
                }

                .place {
                    width: 80%;
                    border-bottom: 1px solid #ccc;
                    border-right: 1px solid #ccc;
                    padding: 8px 0;
                    display: flex;
                    background-color: #b9b4c53d;

                    div {
                        font-size: 16px;
                        background-color: #d7d7d7;
                        position: relative;
                        margin: 8px 12px;
                        width: 129px;
                        height: 40px;
                        align-items: center;

                        &.fill {
                            height: 67px;
                            border-radius: 18px;
                            cursor: default;

                            i {
                                position: absolute;
                                top: -4px;
                                display: inline-block;
                                width: 42px;
                                height: 20px;
                                right: -14px;
                                padding: 2px;
                                border-radius: 2px;
                                background-color: #fff;
                                color: #f00;
                                font-size: 12px;
                                line-height: 20px;
                                text-align: center;
                                transform: rotate(45deg);
                                -ms-transform: rotate(45deg); /* IE 9 */
                                -moz-transform: rotate(45deg); /* Firefox */
                                -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
                                -o-transform: rotate(45deg);
                            }
                        }

                        span {
                            line-height: 36px;
                            color: #fff;
                            cursor: default;
                        }
                    }

                    .palaceClick {
                        color: #fff;
                        background-color: #169bd5;
                    }
                }
            }
        }
    }

    .submit {
        width: 90%;
        padding-top: 40px;
        display: flex;

        button {
            background-color: #1890ff;
            color: #fff;
            font-size: 20px;
            font-weight: 400;
            border-radius: 6px;
            width: 120px;
            margin: 0 auto;
        }
    }
</style>

