<template>
  <div class="examinationregistration-question">
    <el-row class="sign-up">
      <el-col :span="24" class="singContent">
        <div class="grid-content">
          <img src="../../../image/cjwtjjd.png" />
          <span>常见问题及解答</span>
        </div>
        <div class="page" v-for="(item, index) in dataList" :key="index">
          <div class="left">
            <span class="page-nav">{{index+1}}</span>
          </div>
          <div class="right">
            <div class="page_title">
              <span class="title_con">{{item.title}}</span>
            </div>
            <div class="page_con">
              <p>{{item.content}}</p>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: "question",
  data() {
    return {
      dataList: [
        {
          title:
            "你好！我的生产经营单位安全管理人员安全培训合格证到12份将到期。请问这要重新再考试吗？还是直接在应急网上换证？",
          content:
            "持有原深圳市安全监管局颁发的《生产经营单位安全管理人员安全培训合格证》的可以直接申请领取考核类别为安全生产管理人员的《安全生产》"
        },
        {
          title:
            "你好！我的生产经营单位安全管理人员安全培训合格证到12份将到期。请问这要重新再考试吗？还是直接在应急网上换证？",
          content:
            "持有原深圳市安全监管局颁发的《生产经营单位安全管理人员安全培训合格证》的可以直接申请领取考核类别为安全生产管理人员的《安全生产》"
        },
        {
          title:
            "你好！我的生产经营单位安全管理人员安全培训合格证到12份将到期。请问这要重新再考试吗？还是直接在应急网上换证？",
          content:
            "持有原深圳市安全监管局颁发的《生产经营单位安全管理人员安全培训合格证》的可以直接申请领取考核类别为安全生产管理人员的《安全生产》"
        },
        {
          title:
            "你好！我的生产经营单位安全管理人员安全培训合格证到12份将到期。请问这要重新再考试吗？还是直接在应急网上换证？",
          content:
            "持有原深圳市安全监管局颁发的《生产经营单位安全管理人员安全培训合格证》的可以直接申请领取考核类别为安全生产管理人员的《安全生产》"
        }
      ]
    };
  },
  created() {
    this.init();
  },
  computed: {},
  methods: {
    init() {
      this.getList1();
    },
    // 列表初始化
    getList1() {
      this.common
        .ajax({
          method: "post",
          url: this.url,
          headers: {
            "content-type": "multipart/form-data"
          },
          data: this.searchData1
        })
        .then(res => {
          if (res.data.BODY.success === "true") {
            this.tableData1.totals = res.data.BODY.data.pageInfo.totalRow;
            this.tableData1.list = res.data.BODY.data.list;
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    // 表格行点击
    active_tab() {}
  }
};
</script>
<style lang="stylus" scoped>
.sign-up {
  margin-bottom: 20px;
  background-color: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);

  .singContent {
    margin-bottom: 40px;

    .page {
      padding: 20px 10px;
      padding-left: 40px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      text-align: left;
      margin: 40px 50px 0;
      margin-top: 40px;
      border-radius: 30px;
      display: flex;
      border: 1px solid rgba(181, 217, 245, 1);
      background: linear-gradient(0deg, rgba(62, 151, 223, 0.1) 0%, rgba(62, 151, 223, 0) 34%);
        .left {
             .page-nav {
          background-image: url('../../../image/xhbg.png');
          background-size: 100%;
          width: 27px;
          height: 30px;
          display: inline-block;
          text-align: center;
          line-height: 30px;
          font-size: 29px;
          font-family: DIN;
          font-weight: 500;
          color: #fff;
          margin-right: 20px;
          position: relative
        }
        }
      .page_title {
        .title_con {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          background: linear-gradient(0deg, rgba(10, 130, 225, 1) 0.1220703125%, rgba(47, 150, 230, 1) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }


      }

      .page_con {
        color: #666666;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

.grid-content {
  background-color: #3E97DF;
  font-size: 18px;
  font-weight: bold;
  font-family: Source Han Sans CN;
  color: rgba(66, 71, 75, 1);
  padding: 10px;
  min-height: 26px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  img {
    margin-left: 0;
    height: 45%;
  }

  span {
    margin: 0 10px;
    color: #ffffff;
  }

  .link_more span:link {
    color: #3E97DF;
  }

  .link_more span:hover {
    color: #3E97DF;
  }

  .link_more span:active {
    color: #3E97DF;
  }
}

.grid-content_title {
  text-indent: 2em;
  font-size: 18px;
  word-break: break-all;
  text-align: left;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #878d99;
}
</style>
<style lang="scss">
.examinationregistration-question {
  .el-table {
    tr {
      background: rgba(62, 151, 223, 0.1);
    }
    th {
      .cell {
        font-size: 0.18rem;
        color: #333;
        font-weight: bold;
      }
    }
    .el-table__row--striped {
      background: #fff;
      td {
        background: #fff;
      }
    }
  }
}
</style>
