<template>
    <div id="c-try">
         <li v-for="(item, index) in data" :key="item.id">
            <div :class="['inner-t','nav-box-detail']" @mouseover="on_mouseover(item)" @mouseleave='on_mouseleave(item)' @click="judgment_open(item)">
                <img v-if="item.columnIcon" :src="resourceUrl + 'image/'+ item.columnIcon" :class="['nav-box-img', index == 0 ? 'nav-text-s' : '']">
                <div :class="['nav-text', index == 0 ? 'nav-text-s' : '']">
                    {{item[defalutProps.name]}}&nbsp;
                    <template v-if="!item.show">
                        <i class="el-icon-arrow-right c-bl" v-if="item[defalutProps.list].length>0"></i>
                    </template>
                    <template v-else>
                        <i class="el-icon-arrow-down c-bl" v-if="item[defalutProps.list].length>0"></i>
                    </template>
                </div>
                <div class="child-tree-list slideInDown" v-if="item.show&&item[defalutProps.list].length>0" @mouseleave='on_mouseleave(item)'>
                    <li v-for="row in item.children" :key="row.id" class="children-text child-tree"  @click="judgment_open(item,row)">
                        {{row[defalutProps.name]}}
                    </li>
                </div>
            </div>
         </li>
    </div>
</template>
<script>
import recursive from "@/lib/recursive-search.js";
export default {
    name: 'navTree',
    data(){
        return{

        }
    },
    mounted(){
        this.defaultSlect()
    },
    methods:{
        judgment_open(item,row){
            if(row){
                this.$emit('defaltOption',row);
            }else{
                this.$emit('defaltOption',item);
            }
        },
        on_mouseover(item){
            if(item[this.defalutProps.list].length>0){
                this.data.forEach(x => {
                    if(x.id===item.id){
                        this.$set(item,'show',true)
                    }else{
                        if(x[this.defalutProps.list].length>0){
                            this.$set(item,'show',false)
                        }
                    }
                });
            }

        },
        on_mouseleave(item){
            if(item[this.defalutProps.list].length>0){
                this.data.forEach(x => {
                    if(x.id===item.id){
                        this.$set(item,'show',false)
                    }else{
                        if(x[this.defalutProps.list].length>0){
                            this.$set(item,'show',false)
                        }
                    }
                });
            }
        },
        defaultSlect(){ //默认选中及展开效果
            let _this=this
            recursive.recursiveSet(this.data,(row)=>{
                if(_this.defaltOpen){
                    if(row[_this.defalutProps.list].length>0){
                        _this.$set(row,"show",true);
                    }
                }else{
                    if(row[_this.defalutProps.list].length>0){
                        _this.$set(row,"show",false);
                    }
                }
                if(_this.defaultIndex&&row[_this.defalutProps.list].length<=0){
                    // 设置默认选中
                    if(row.id===_this.defaultIndex){
                        _this.$set(row,"isVew",true);
                    }else{
                        _this.$set(row,"isVew",false);
                    }
                }else{
                    _this.$set(row,"isVew",false);
                }
            },this.defalutProps)
        },
    },
    props: {
        data: {
            default:()=>{return[]},
            type:Array
        },
        defaltOpen:{
            default:()=>{return false},
            type:Boolean
        },
        defalutProps:{
            default:()=>{
                return {name:'columnName',list:'children'}
            },
            type:Object
        },
        defaultIndex:{},
    }
}
</script>
<style lang="stylus">
#c-try{
    position:relative;
    overflow hidden
    li{
         list-style :none;
         width: 200px;
    }
     .nav-box-detail {
        height: 45px;
        position: relative;
        margin 0
        margin-bottom 3px
        .nav-box-img {
            left: 0;
            position: absolute;
            width: 100%
            height: 100%
            z-index: 0
        }
        .nav-text {
            position: absolute;
            width: 140px;
            height: 100%;
            padding-left:62px;
            left: 0;
            text-align: center;
            line-height: 45px
            font-size 18px
            .c-bl {
                position absolute
                right 10px
                top 50%
                transform translateY(-50%)
            }
        }
        .nav-text,.children-text:hover{
            cursor: pointer
        }
        .nav-text-s {
            z-index 1000

        }
        .child-tree-list{
            position :absolute;
            top:45px;
            width:99%;
            height :auto
            background :#fff;
            z-index :10;
            border :1px solid #ccc
            border-top:none
            animation-duration 0.5s
        }

        .children-text{
            height :45px;
            line-height :45px
        }
        .bac{
            background :rgba(172, 157, 157, 0.5);
        }
    }
}
</style>
