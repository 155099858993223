<template>
    <el-row id="content">
        <el-row class="sign-up-title">
            <el-col :span="24">
                <div class="grid-content_title">
          <span class="tips_title">
            <img src="../../../image/wxts1.png" alt/> 温馨提示：
          </span>
                    <span>生产经营单位主要负责人等四类从业人员考试计划已发布在如下，考在规定时间内完成报考、预约考试、打印准考证，并按准考证上时间到指定点考试，有疑问详询23481891。</span>
                </div>
            </el-col>
        </el-row>
        <el-form :model="applicantData" :rules="rules" label-width="100px" ref="applicantData">
            <el-row class="sign-up personal">
                <el-col :span="24">
                    <div class="grid-content">
           <img src="../../../image/bticon_grxx.png"/>
                        <span>个人信息</span>
                        <span class="tips_content">如实填写个人信息，证件照片统一为白底，彩色，大小在50-200k之间。请上传符合规格证件照片。</span>
                    </div>
                    <div style="margin-top:14px">
                        <el-row>
                            <el-col :span="18">
                                <el-col :span="8">
                                    <el-form-item label="姓名：" prop="realName">
                                        <el-input clearable placeholder="请输入姓名"
                                                  v-model="applicantData.realName"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="性别：" prop="sex">
                                        <el-select
                                            autocomplete="off"
                                            clearable
                                            placeholder="请选择性别"
                                            v-model="applicantData.sex"
                                        >
                                            <el-option label="男" :value="1"></el-option>
                                            <el-option label="女" :value="0"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="民族：" prop="nation">
                                        <el-select
                                            autocomplete="off"
                                            clearable
                                            filterable
                                            placeholder="请选择民族"
                                            v-model="applicantData.nation"
                                        >
                                            <el-option
                                                v-for="(item, index) in optionData.nation"
                                                :key="index"
                                                :label="item.dataValue"
                                                :value="item.dataCode"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="政治面貌：" prop="politicsStatus">
                                        <el-select
                                            autocomplete="off"
                                            clearable
                                            placeholder="请选择政治面貌"
                                            v-model="applicantData.politicsStatus"
                                        >
                                            <el-option
                                                v-for="(item, index) in optionData.political_type"
                                                :key="index"
                                                :label="item.dataValue"
                                                :value="item.dataCode"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="证件类型：" prop="idCard">
                                        <el-select
                                            autocomplete="off"
                                            clearable
                                            placeholder="证件类型"
                                            v-model="applicantData.credictTypeCode"
                                        >
                                            <el-option
                                                v-for="(item, index) in optionData.card_type"
                                                :key="index"
                                                :label="item.dataValue"
                                                :value="item.dataCode"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="证件号码：" prop="idCard">
                                        <el-input placeholder="请输入证件号码" v-model="applicantData.idCard"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="最高学历：" prop="degreeCultureCode">
                                        <el-select
                                            autocomplete="off"
                                            clearable
                                            placeholder="请选择最高学历"
                                            v-model="applicantData.degreeCultureCode"
                                        >
                                            <el-option
                                                v-for="(item, index) in optionData.degree_culture"
                                                :key="index"
                                                :label="item.dataValue"
                                                :value="item.dataCode"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="手机号码：" prop="mobilePhone">
                                        <el-input clearable placeholder="请输手机号码"
                                                  v-model="applicantData.mobilePhone"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="工作单位：" prop="workUnit">
                                        <el-input clearable placeholder="请输入工作单位全称"
                                                  v-model="applicantData.workUnit"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="通讯地址：" required>
                                        <el-row style="padding: 0">
                                            <el-col :span="6">
                                                <el-form-item prop="areaCode">
                                                    <el-select
                                                        autocomplete="off"
                                                        clearable
                                                        placeholder="请选择所在区"
                                                        v-model="applicantData.areaCode"
                                                    >
                                                        <el-option
                                                            v-for="(item, index) in optionData.area"
                                                            :key="index"
                                                            :label="item.dataValue"
                                                            :value="item.dataCode"
                                                        ></el-option>
                                                    </el-select>
                                                </el-form-item>

                                            </el-col>
                                            <el-col :span="18">
                                                <el-form-item prop="address">
                                                    <el-input placeholder="（请输入详细地址）"
                                                              v-model="applicantData.address"></el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-form-item>
                                </el-col>
                            </el-col>
                            <el-col :span="6">
                                <!--<span class="Photo">证件照片</span>-->
                                <el-upload
                                    :before-upload="beforeUpload"
                                    :show-file-list="false"
                                    action
                                    class="avatar-uploader"
                                >
                                    <img :src="uploadImageUrl" class="avatar" v-if="uploadImageUrl"/>
                                    <i class="el-icon-plus avatar-uploader-icon" v-else></i>
                                </el-upload>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <el-row class="sign-up">
                <el-col :span="24">
                    <div class="grid-content">
            <img src="../../../image/bticon_pxxx.png"/>
                        <span>培训信息</span>
                        <span class="tips_content">请选择类别,根据不同的类别填写相应栏目内容。</span>
                    </div>
                    <div class="trainingInformation">
                        <el-row class="information">
                            <el-col :span="8">
                                <el-form-item label="培训类别： " prop="trainType">
                                    <el-select
                                        autocomplete="off"
                                        placeholder="请选择培训类别"
                                        v-model="applicantData.trainType"
                                    >
                                        <el-option label="企业自主培训" :value="0"></el-option>
                                        <el-option label="培训机构培训" :value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <template v-if="applicantData.trainType ===0">
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="企业名称：" prop="entpName">
                                        <el-input clearable placeholder="（请输入企业全称）"
                                                  v-model="applicantData.entpName"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="企业代码：" prop="entpCode">
                                        <el-input
                                            clearable
                                            placeholder="（请输入企业社会信用代码或民办机构代码）"
                                            v-model="applicantData.entpCode"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="授课教师：" prop="entpTeacher">
                                        <el-input
                                            clearable
                                            placeholder="（请输入授课教师名称、可以多名）"
                                            v-model="applicantData.entpTeacher"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="授课地点：" prop="entpLocation">
                                        <el-input
                                            clearable
                                            placeholder="（请输入授课地点）"
                                            v-model="applicantData.entpLocation"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="培训方式：" prop="pxType">
                                        <el-select
                                            autocomplete="off"
                                            clearable
                                            placeholder="请选择培训方式"
                                            v-model="applicantData.pxType"
                                        >
                                            <el-option
                                                v-for="(item, index) in optionData.px_type"
                                                :key="index"
                                                :label="item.dataValue"
                                                :value="item.dataCode"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="授课内容：" prop="entpContent">
                                        <el-select
                                            autocomplete="off"
                                            clearable
                                            placeholder="请选择培训课程"
                                            v-model="applicantData.entpContent"
                                        >
                                            <el-option
                                                v-for="(item, index) in optionData.lesson_content"
                                                :key="index"
                                                :label="item.dataValue"
                                                :value="item.dataCode"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="培训时间：" prop="startTime">
                                        <el-date-picker
                                            start-placeholder="开始时间"
                                            type="datetime"
                                            format="yyyy-MM-dd HH:mm:ss"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            v-model="applicantData.startTime"
                                            placeholder="开始时间"
                                        ></el-date-picker>
                                    </el-form-item>
                                    <el-form-item prop="endTime">
                                        <el-date-picker
                                            start-placeholder="结束时间"
                                            type="datetime"
                                            format="yyyy-MM-dd HH:mm:ss"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            v-model="applicantData.endTime"
                                            placeholder="结束时间"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </template>
                        <template v-else>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="机构名称：" prop="institutionId">
                                        <el-select
                                            autocomplete="off"
                                            clearable
                                            filterable
                                            placeholder="请选择培训类别"
                                            v-model="applicantData.institutionId"
                                        >
                                            <el-option
                                                v-for="(item, index) in optionData.organization"
                                                :key="index"
                                                :label="item.dataValue"
                                                :value="item.dataCode"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="授课内容：" prop="entpContent">
                                        <el-select
                                            autocomplete="off"
                                            clearable
                                            placeholder="请选择培训课程"
                                            v-model="applicantData.entpContent"
                                        >
                                            <el-option
                                                v-for="(item, index) in optionData.lesson_content"
                                                :key="index"
                                                :label="item.dataValue"
                                                :value="item.dataCode"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="培训时间：" prop="startTime">
                                        <el-date-picker
                                            start-placeholder="开始时间"
                                            type="datetime"
                                            format="yyyy-MM-dd HH:mm:ss"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            v-model="applicantData.startTime"
                                            placeholder="开始时间"
                                        ></el-date-picker>
                                    </el-form-item>
                                    <el-form-item prop="endTime">
                                        <el-date-picker
                                            start-placeholder="结束时间"
                                            type="datetime"
                                            format="yyyy-MM-dd HH:mm:ss"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            v-model="applicantData.endTime"
                                            placeholder="结束时间"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="培训方式：" prop="pxType">
                                        <el-select
                                            autocomplete="off"
                                            clearable
                                            placeholder="请选择培训方式"
                                            v-model="applicantData.pxType"
                                        >
                                            <el-option
                                                v-for="(item, index) in optionData.px_type"
                                                :key="index"
                                                :label="item.dataValue"
                                                :value="item.dataCode"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </template>
                    </div>
                </el-col>
            </el-row>
            <el-row class="sign-up">
                <el-col :span="24">
                    <div class="grid-content">
            <img src="../../../image/bticon_bkxx.png"/>
                        <span>报考信息</span>
                    </div>
                    <div class="trainingInformation">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="考试名称：" prop="playId">
                                    <el-select
                                        autocomplete="off"
                                        clearable
                                        placeholder="请选择考试计划"
                                        v-model="applicantData.playId"
                                    >
                                        <el-option
                                            v-for="(item, index) in optionData.available_plan"
                                            :key="index"
                                            :label="item.dataValue"
                                            :value="item.dataCode"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="作业类型：" prop="industryTypeCode">
                                    <el-select
                                        autocomplete="off"
                                        clearable
                                        placeholder="请选择作业类型"
                                        v-model="applicantData.industryTypeCode"
                                    >
                                        <el-option
                                            v-for="(item, index) in optionData.all_work_type"
                                            :key="index"
                                            :label="item.dataValue"
                                            :value="item.dataCode"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="操作项目：" prop="checkTypeCode">
                                    <el-select
                                        autocomplete="off"
                                        clearable
                                        placeholder="请选择操作项目"
                                        v-model="applicantData.checkTypeCode"
                                    >
                                        <el-option
                                            v-for="(item, index) in optionData.all_exam_item"
                                            :key="index"
                                            :label="item.dataValue"
                                            :value="item.dataCode"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="资格类型：" prop="zigeType">
                                    <el-select
                                        autocomplete="off"
                                        clearable
                                        placeholder="请选择资格类型"
                                        v-model="applicantData.zigeType"
                                    >
                                        <el-option
                                            v-for="(item, index) in optionData.zige_type"
                                            :key="index"
                                            :label="item.dataValue"
                                            :value="item.dataCode"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="考试类型：" prop="ksType">
                                    <el-select
                                        autocomplete="off"
                                        clearable
                                        placeholder="请选择考试类型"
                                        v-model="applicantData.ksType"
                                    >
                                        <el-option
                                            v-for="(item, index) in optionData.train_type"
                                            :key="index"
                                            :label="item.dataValue"
                                            :value="item.dataCode"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </el-form>
        <el-row class="submitBon">
            <el-col :span="24">
                <el-button @click="submit" type="primary">提交</el-button>
                <el-button @click="pre" type="primary">返回</el-button>
            </el-col>
        </el-row>
    </el-row>
</template>
<script>
    import {mapState} from "vuex";

    export default {
        data() {
            return {
                examType: null,
                industryType: [],
                examItem: [],
                applicantData: {
                    CONSUMER_ID: sessionStorage.getItem("Token"),
                    SERVICE_CODE: "szsti.AdminEnd.ExamExamineePlay.addExamExamineePlay",
                    examineeId: sessionStorage.getItem("examineeId"),
                    playId: null,
                    industryTypeCode: null,
                    checkTypeCode: null,
                    trainType: null,
                    startTime: null,
                    endTime: null,
                    entpContent: null,
                    institutionId: null,
                    degreeCultureCode: null,
                    workUnit: null,
                    areaCode: null,
                    address: null,
                    userPic: null,
                    nation: null,
                    supplyFlag: "0",
                    supplyId: null,
                    entpName: null,
                    entpCode: null,
                    entpLocation: null,
                    entpTeacher: null,
                    realName: null,
                    sex: null,
                    politicsStatus: null,
                    dateRange: [],
                    idCard: null,
                    mobilePhone: null,
                    zigeType: null,
                    ksType: null,
                    pxType: null,
                    cardType: null
                },
                rules: {
                    pxType: [
                        {required: "true", message: "培训方式不能为空", trigger: "blur,change"}
                    ],
                    zigeType: [
                        {required: "true", message: "资格类型不能为空", trigger: "blur,change"}
                    ],
                    ksType: [
                        {required: "true", message: "考试类型不能为空", trigger: "blur,change"}
                    ],
                    realName: [
                        {required: "true", message: "姓名不能为空", trigger: "blur,change"}
                    ],
                    playId: [
                        {
                            required: "true",
                            message: "考试名称不能为空",
                            trigger: "blur,change"
                        }
                    ],
                    sex: [
                        {required: "true", message: "性别不能为空", trigger: "blur,change"}
                    ],
                    idCard: [
                        {required: "true", message: "证件号码不能为空", trigger: "blur"}
                    ],
                    nation: [
                        {required: "true", message: "民族不能为空", trigger: "blur,change"}
                    ],
                    politicsStatus: [
                        {required: "true", message: "政治面貌不能为空", trigger: "blur"}
                    ],
                    entpContent: [
                        {
                            required: "true",
                            message: "授课内容不能为空",
                            trigger: "blur,change"
                        }
                    ],
                    workUnit: [
                        {required: "true", message: "工作单位不能为空", trigger: "blur"}
                    ],
                    areaCode: [
                        {required: "true", message: "请选择区域", trigger: "blur"}
                    ],
                    address: [
                        {required: "true", message: "详细地址不能为空", trigger: "blur"}
                    ],
                    mobilePhone: [
                        {required: "true", message: "手机号码不能为空", trigger: "blur"}
                    ],
                    credictTypeCode: [
                        {required: "true", message: "证件类型不能为空", trigger: "blur"}
                    ],
                    entpLocation: [
                        {required: "true", message: "授课地点不能为空", trigger: "blur"}
                    ],
                    entpTeacher: [
                        {required: "true", message: "授课教师不能为空", trigger: "blur"}
                    ],
                    degreeCultureCode: [
                        {
                            required: "true",
                            message: "最高学历不能为空",
                            trigger: "blur,change"
                        }
                    ],
                    entpName: [
                        {required: "true", message: "企业名称不能为空", trigger: "blur"}
                    ],
                    entpCode: [
                        {required: "true", message: "企业代码不能为空", trigger: "blur"}
                    ],
                    trainType: [
                        {
                            required: "true",
                            message: "培训信息类型不能为空",
                            trigger: "blur,change",
                            type: "number"
                        }
                    ],
                    playId: [
                        {required: "true", message: "请选择考试计划", trigger: "blur"}
                    ],
                    checkTypeCode: [
                        {
                            required: "true",
                            message: "考核类别不能为空",
                            trigger: "blur,change"
                        }
                    ],
                    industryTypeCode: [
                        {
                            required: "true",
                            message: "行业类别不能为空",
                            trigger: "blur,change"
                        }
                    ],
                    institutionId: [
                        {
                            required: "true",
                            message: "机构名称不能为空",
                            trigger: "blur,change"
                        }
                    ],
                    startTime: [
                        {
                            required: true,
                            message: "培训开始时间不能为空",
                            trigger: "blur"
                        }
                    ],
                    endTime: [
                        {
                            required: true,
                            message: "培训结束时间不能为空",
                            trigger: "blur"
                        }
                    ]
                },
                uploadImageUrl: null,
                orderId: null
            };
        },
        computed: {
            ...mapState({
                optionData: state => state.option_data
            })
        },
        methods: {
            playChange(val) {
                let planList = this.optionData.available_plan;
                for (let i = 0; i < planList.length; i++) {
                    if (planList[i].dataCode === val) {
                        this.examType = planList[i].examType;
                        break;
                    }
                }
            },
            resetForm() {
                this.$refs["applicantData"].resetFields();
            },
            getOption() {
                this.$store.dispatch("modifyOptionData", this.url);
            },
            submit() {
                // 表单验证
                this.$refs["applicantData"].validate((valid) => {
                    if (valid) {
                        // 提交表单
                        this.common
                            .ajax({
                                method: "post",
                                url: this.url,
                                headers: {
                                    "content-type": "multipart/form-data"
                                },
                                data: this.applicantData
                            })
                            .then(res => {
                                console.log(res, "提交报名");
                                if (res.data.BODY.success === "true") {
                                    this.$message({
                                        type: "success",
                                        message: "操作成功!"
                                    });
                                    setTimeout(() => {
                                        this.$router.push({
                                            path: "/examinationregistration/applicantLook"
                                        });
                                    }, 1000);
                                } else {
                                    this.$message({
                                        type: "error",
                                        message: res.data.BODY.message
                                    });
                                }
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            pre() {
                this.$router.go(-1);
            },
            init() {
                let json = sessionStorage.getItem("userInfo");
                let userInfo = {};
                if (json) {
                    userInfo = JSON.parse(json);
                }
                this.applicantData.realName = userInfo.realName;
                this.applicantData.idCard = userInfo.idCard;
                this.applicantData.mobilePhone = userInfo.mobilePhone;
                this.applicantData.address = userInfo.address;
                this.applicantData.sex = userInfo.sex * 1;
                this.applicantData.userPic = userInfo.userPic;
                if (userInfo.userPic) {
                    this.uploadImageUrl = this.resourceUrl + userInfo.userPic;
                }
            },
            beforeUpload(file) {
                console.log(file, "上传文件");
                console.log(this.imageUrl, "上传图片");
                let formData = new FormData();
                formData.append("CONSUMER_ID", sessionStorage.getItem("Token"));
                formData.append("type", "考生报名");
                formData.append("file", file);
                this.axios({
                    method: "post",
                    url: this.imageUrl,
                    headers: {
                        "content-type": "multipart/form-data"
                    },
                    data: formData
                })
                    .then(res => {
                        console.log(res, "上传图片");
                        if (res.data.success) {
                            this.applicantData.userPic = res.data.data;
                            this.uploadImageUrl = this.resourceUrl + this.applicantData.userPic;
                        } else {
                            this.$message({
                                type: "error",
                                message: "上传失败"
                            });
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
                return false;
            },
            getOldData(orderId) {
                // 提交表单
                this.common
                    .ajax({
                        method: "post",
                        url: this.url,
                        headers: {
                            "content-type": "multipart/form-data"
                        },
                        data: {
                            CONSUMER_ID: sessionStorage.getItem("Token"),
                            SERVICE_CODE:
                                "szsti.AdminEnd.ExamExamineePlay.showExamExamineePlayDetail",
                            id: orderId
                        }
                    })
                    .then(res => {
                        console.log(res, "历史数据导入");
                        if (res.data.BODY.success === "true") {
                            let data = res.data.BODY.data;
                            this.applicantData.playId = data.playId;
                            this.applicantData.trainType = data.type;
                            this.applicantData.startTime = data.startTime;
                            this.applicantData.endTime = data.endTime;
                            this.applicantData.entpContent = data.entpContent;
                            this.applicantData.degreeCultureCode = data.degreeCultureCode;
                            this.applicantData.workUnit = data.workUnit;
                            this.applicantData.areaCode = data.areaCode;
                            this.applicantData.address = data.address;
                            this.applicantData.userPic = data.userPic;
                            this.uploadImageUrl = this.resourceUrl + data.userPic;
                            this.applicantData.nation = data.nation;
                            this.applicantData.realName = data.realName;
                            this.applicantData.idCard = data.idCard;
                            this.applicantData.mobilePhone = data.mobilePhone;
                            this.applicantData.sex = data.sex;
                            this.applicantData.politicsStatus = data.politicsStatus;
                            this.applicantData.institutionId = data.institutionId;
                            this.applicantData.entpName = data.entpName;
                            this.applicantData.entpCode = data.entpCode;
                            this.applicantData.entpContent = data.entpContent;
                            this.applicantData.entpLocation = data.entpLocation;
                            this.applicantData.entpTeacher = data.entpTeacher;
                            this.applicantData.zigeType = data.zigeType;
                            this.applicantData.ksType = data.ksType;
                            this.applicantData.pxType = data.pxType;
                            this.applicantData.credictTypeCode = data.cardType;
                            this.applicantData.industryTypeCode = data.industryTypeCode;
                            this.applicantData.checkTypeCode = data.checkTypeCode;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },
            getExamItem() {
                this.applicantData.checkTypeCode = null;
                this.common.ajax({
                    method: "post",
                    url: this.url,
                    headers: {
                        'content-type': "multipart/form-data"
                    },
                    data: {
                        "CONSUMER_ID": sessionStorage.getItem("Token"),
                        "SERVICE_CODE" : "szsti.system.DataCode.findDataCodeByParentCode",
                        "parentCode"   : this.applicantData.industryTypeCode
                    },
                }).then((res) => {
                    console.log(res, '操作项目');
                    if (res.data.BODY.success === "true") {
                        this.examItem = res.data.BODY.data;
                    }
                }).catch((error) => {
                    console.error(error);
                });
            },
        },
        mounted() {
            this.getOption();
            console.log(this.$route.params.playId, "playId");
            if (this.$route.params.playId) {
                // 带入考试计划ID
                this.applicantData.playId = this.$route.params.playId;
            }
            if (this.$route.query.orderId) {
                this.orderId = this.$route.query.orderId;
                this.getOldData(this.orderId);
            }
            this.init();
            setTimeout(() => {
                this.playChange(this.applicantData.playId);
            }, 500);
        }
    };
</script>
<style lang="stylus">
    // * {
    //     font-family: Microsoft YaHei;
    //     font-weight: 400;
    // }

    #content {
        .el-input__inner {
            background-color: #F9FAFC;
        }

        .submitBon {
            height 60px
            overflow hidden
            font-size 18px

            .el-button--primary {
                width: 100px;
                margin-right: 20px;
                font-size: 18px;
            }
        }

        .sign-up {
            margin-bottom: 20px;
            background-color: #ffffff;
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);
            .el-form-item {
                padding-right: 10px;
                .el-input--suffix {
                    .el-input__inner {
                        padding-right: 10px;
                    }
                }
            }
            .trainingInformation {
                .el-input {
                    width: 70%;
                }

                margin-top: 14px;
                padding: 10px;

                .trainingTime {
                    .el-form-item__content {
                        display: flex;
                    }
                }

                .information {
                    .el-form-item__label {
                        font-weight: bold;
                        color: #44A8F8;
                    }
                }
            }
        }

        .personal {
            .el-row {
                padding: 10px;

                .avatar-uploader-icon {
                    font-size: 28px;
                    color: #8c939d;
                    width: 188px;
                    height: 188px;
                    line-height: 188px;
                    text-align: center;
                }

                .Photo {
                    font-size: 14px;
                    color: #333333;
                    display: inline-block;
                    padding-bottom: 10px;
                }
            }

            .el-form-item {
                .el-form-item__label {
                    color: #333333;
                    padding: 0;
                }

                .el-form-item__content {
                    .el-row {
                        padding: 0;
                    }
                }
            }
        }

        .sign-up-title {
            margin-bottom: 20px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 0px 5px 0px rgba(218, 225, 229, 0.75);
            border-radius: 4px;
        }

    }

    .grid-content {
        background-color: #3E97DF;
        font-size: 0.20rem;
        font-weight: bold;
        font-family: Source Han Sans CN;
        color: rgba(66, 71, 75, 1);
        min-height: 26px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 0.08rem 0.2rem;
        .tips_content {
            font-size: 0.14rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
        }

        img {
            margin-left: 0;
            height: 45%;
        }

        // span {
        //     margin: 0 10px;
        //     color: #ffffff;
        // }
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    .grid-content_title {
        text-indent: 0.2rem;
        font-size: 18px;
        word-break: break-all;
        text-align: left;
        border-radius: 4px;
        border: 1px solid #ccc;
        color: #878d99;
        padding: 10px 14px;
        span {
            font-size: 16px;
        }

        .tips_title {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #44A8F8;
            line-height: 0.3rem;
        }
    }

    .el-form-item__content {
        text-align: left;
    }

    .el-select {
        width: 100%;
    }

    .warning_remind {
        font-family: Source Han Sans CN;
        word-break: break-all;
        background: #f2f2f2;
        padding: 10px;
    }
</style>
