<template>
  <div>
    <div class="let-trainingInstitution-nav">
      <div>当前位置:<span @click="back_home"
              class="hyperlink-blue"
              title="去首页">网站首页</span>>准考证打印<span></span>>查询结果</div>
    </div>
    <div style="padding: 10px 0 30px" v-for="(i,index) in zkzInfo" :key="index">
      <div class="con"
           :id="`printMe${index}`">
        <table>
          <tr>
            <td colspan="5"
                class="head">深圳市安全生产考试准考证</td>
          </tr>
          <tr class="tableSty">
            <td>
              <span>准考证号</span>
            </td>
            <td>
              <span>{{i.certificateNum}}</span>
            </td>
            <td>
              <span>考试时间</span>
            </td>
            <td>
              <span>{{i.examTime}}</span>
            </td>
            <td rowspan="4"
                style="border-right: none;">
              <img style="width: 128px;"
                   :src="resourceUrl + i.pic"
                   alt="">
            </td>
          </tr>
          <tr>
            <td>
              <span>姓名</span>
            </td>
            <td>
              <span>{{i.name}}</span>
            </td>
            <td>
              <span>资格类型</span>
            </td>
            <td>
              <span>{{i.zigeType}}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>性别</span>
            </td>
            <td>
              <span>{{i.sex}}</span>
            </td>
            <td>
              <span>考试项目</span>
            </td>
            <td>
              <span>{{i.examItemName}}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>考试机构</span>
            </td>
            <td>
              <span>城市安全发展科技研究院 (深圳)</span>
            </td>
            <td>
              <span>考核科目</span>
            </td>
            <td>
              <span>{{i.examSubject}}</span>
            </td>
          </tr>
          <!-- <tr>
            <td>
              <span>考点名称</span>
            </td>
            <td colspan="4">
              <span>{{zkzInfo.examName}}</span>
            </td>
          </tr> -->
          <tr>
            <td>
              <span>考试地址</span>
            </td>
            <td colspan="4">
              <span>{{i.examAddress}}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>证件类型</span>
            </td>
            <td>
              <span>{{i.certificateType}}</span>
            </td>
            <td>
              <span>证件号码</span>
            </td>
            <td colspan="2">
              <span>{{i.idCard}}</span>
            </td>
          </tr>
          <tr class="text">
            <td colspan="5">
              <div>
                <div style="width: 100%;">考生注意事项</div>
                <p>
                  1.请考生核对准考证个人信息，若信息错误请立即反馈给报考培训机构。
                  <br>
                  2.携带本人有效身份证件及准考证开考前30分钟到达考场候考室，迟到15分钟不得入场。
                  <br>
                  3.遵守考场纪律，凡作弊者取消考试资格，考试完毕点击“交卷”按钮显示成绩确认后方可离场。
                  <br>
                  4.考试期间，考生不得将贵重物品带进考场，否则由此造成的损失自负。
                  <br>
                  5.考点不提供停车位，请考生选择公共交通赴考。
                  <br>
                  6.可登录http://cx.mem.gov.cn/查询证书。
                  <br>
                  7.逾期未进行考试的视为弃考。
                </p>
              </div>
            </td>
          </tr>
          <!-- <tr class="text">
                        <td colspan="5">
                            <div>
                                <div>考场规则</div>
                                <p>
                                一、考生在参加考试前，应认真阅读本规则，并在考试期间严格遵守。<br>
                                二、考生应提前20分钟到达考试现场，在候考室等候入场。候考期间保持安静，不得喧哗。<br>
                                三、考生凭准考证和本人有效身份证明参加考试。经身份验证进入考场后，服从安排，对号入座。迟到15分钟以上的考生不得入场考试。非本场考生一律不得进入考场。<br>
                                四、考生严禁携带任何资料、纸张及各类具备储存及显示、扫描、拍摄、接发图像和文字功能的设备进入考场，应当关闭手机等通讯工具，将随身携带的物品存放于指定位置。<br>
                                五、考生应严格按照系统要求操作，不得从事与考试无关的操作。考生须在电脑上按以下流程开启考试：输入身份证号码—登录—确认考生信息无误—点击开始考试。<br>
                                六、考场已安装视频远程联网监控系统，对考试现场进行全程录音录像，考生应严格遵守考场纪律，保持安静，独立完成考试。严禁交头接耳、抄袭、替考、扰乱考场秩序等违纪行为，有关考试违纪行为经查属实的，根据有关规定处以警告、停止考试直至一年内不得报名参加考试等处理，并将记录在案。<br>
                                七、考生如遇电脑操作故障等问题，应当举手询问监考人员，但不得询问与试题内容相关的问题。<br>
                                八、考生应当严格遵守考试时间，考试开考后20分钟方可交卷离场。监考人员宣布考试时间结束，考生应当立即停止答题。考生确认考试完毕的，应当按下“提交试卷”，“提交试卷”后考生将无法继续答题。考生考试完毕后，必须确认成绩后方可离开考场。<br>
                                九、考生应自觉服从考务人员管理，不得妨碍考务人员正常工作。不得辱骂、威胁、报复考务人员。<br>
                                十、应当保持考场卫生，爱护考试设施设备，损坏者照价赔偿。考场内严禁吸烟。<br>
                                十一、考试结束后，考生不得逗留或围观喧哗。<br>
                                十二、考试如遇停电、系统设备故障等情况，无法继续考试的，考试机构另行通知考试。
                                </p>
                            </div>
                        </td>
                    </tr> -->
        </table>

      </div>
      <div class="footer">
        <el-button type="primary"
                   v-print="Object.assign({},printobj,{id:`printMe${index}`}) ">打印 / 下载准考证</el-button>
        <!-- <el-button @click="save">另存准考证</el-button> -->
      </div>
    </div>
  </div>
</template>
<script>
import html2canvas from 'html2canvas'
export default {
  data () {
    return {
      formData: {},
      zkzInfo: {},
      printobj: {
        // id: "printMe",
        popTitle: '安全生产考试服务网',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
      }
    };
  },
  methods: {
    back_home () {
      this.$router.push('/xxfww/home');
    },
    init () {
      this.getZkzInfo();
    },
    getZkzInfo () {
      this.zkzInfo = JSON.parse(sessionStorage.getItem('checkResult'))
      // this.common.ajax({
      //   method: "post",
      //   url: this.url,
      //   headers: {
      //     'content-type': "multipart/form-data"
      //   },
      //   data: this.formData,
      // }).then((res) => {
      //   console.log(res, 123123);
      //   console.log("this.url",this.url);
      //   if (res.data.BODY.data == null) {

      //     this.$alert('未找到该账号数据,请检查填写信息是否有误', '错误', {
      //       confirmButtonText: '确定',
      //       callback: () => {
      //          this.$router.push('/xxfww/printingOfAdmissionTicket?type=ticket');
      //       }
      //     });
      //   }

      //   if (res.data.BODY.success === "true") {
      //     this.zkzInfo = res.data.BODY.data.detail;
      //   }
      // }).catch((error) => {
      //   console.error(error);
      // });
    },

    // 另存为
    save () {
      //#proMain:要截图的DOM元素
      //useCORS:true:解决跨域问题
      html2canvas(document.querySelector('#proMain'), { useCORS: true }).then(function (canvas) {
        //获取年月日作为文件名
        let timers = new Date();
        let fullYear = timers.getFullYear();
        let month = timers.getMonth() + 1;
        let date = timers.getDate();
        let randoms = Math.random() + '';
        //年月日加上随机数
        let numberFileName = fullYear + '' + month + date + randoms.slice(3, 10);
        let imgData = canvas.toDataURL();
        //保存图片
        let saveFile = function (data, filename) {
          let save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
          save_link.href = data;
          save_link.download = filename;

          let event = document.createEvent('MouseEvents');
          event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
          save_link.dispatchEvent(event);
        };
        //最终文件名+文件格式
        let filename = numberFileName + '.png';
        saveFile(imgData, filename);
        //document.body.appendChild(canvas);  把截的图显示在网页上
      })
    }
  },
  mounted () {
    if (this.$route.query.apId) {
      this.formData = this.$route.query;


    } else {
      this.formData = this.$route.query;

    }
    this.init();
  }
};
</script>
<style lang="scss" scoped>
.let-trainingInstitution-nav {
  color: #3e97df;
  font-size: 14px;
  font-family: Microsoft YaHei;
  text-align: left;
}
.con {
  width: 92%;
  border: 1px solid #ccc;
  margin: 20px auto;

  table {
    border-collapse: collapse;
    width: 100%;

    .tableSty {
      td:nth-child(1) {
        width: 14%;
      }

      td:nth-child(2) {
        width: 30%;
      }

      td:nth-child(3) {
        width: 14%;
      }

      td:nth-child(4) {
        width: 26%;
      }
    }

    td {
      height: 40px;
      border: 1px solid #ccc;
      border-left: none;
      font-size: 16px;
      text-align: center;
      padding: 0 6px;
    }

    .text {
      padding: 6px;

      td {
        text-align: left;
        padding: 20px 20px 30px;
        div {
          div {
            text-align: center;
          }

          p {
            display: inline-block;
            height: 20px;
            font-size: 15px;
            font-family: SimSun;
            line-height: 24px;
            font-weight: 400;
            color: rgba(0, 0, 0, 1);
          }
        }
      }
    }
  }

  .head {
    color: #000000;
    font-size: 30px;
    font-family: SimSun;
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
    line-height: 60px;
  }

  .content {
    border-bottom: 1px solid #ccc;
    height: 40px;
    line-height: 40px;
  }

  .main {
    .first {
      border-top: 1px solid #ccc;
    }
  }
}
</style>
