<template>
  <div>
    <el-row class="sign-up">
        <el-col :span="24">
            <div class="grid-content">
                <img src="../../../image/kpyy.png" style="vertical-align: middle;"/>
                <span>预约记录</span>
            </div>
            <div class="exam-content">
                <el-table :data="appointmentRecord" border style="width: 100%"
                    :header-cell-style="headerClass"
                    :cell-style="cellClass">
                    <el-table-column prop="createdTime" label="操作时间" ></el-table-column>
                    <el-table-column prop="examItem" label="考评工种"></el-table-column>
                    <el-table-column prop="examTime" label="考评日期" :formatter="dateFormatter"></el-table-column>
                    <el-table-column prop="examPlaceName" label="预约考点"></el-table-column>
                    <el-table-column prop="examPlaceName" label="申请取消次数">
                          <template slot-scope="scope">
                            <div>
                                {{(scope.row.mapList&&scope.row.mapList.length)?scope.row.mapList.length:0}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="220">
                        <template slot-scope="scope">
                            <div>
                                <span v-if="new Date(scope.row.examTime).valueOf()>new Date().valueOf()">
                                     <el-button size="mini" :disabled="true" v-if="(scope.row.mapList&&scope.row.mapList.length>0&&scope.row.mapList[0].reviewStatus==0)">待审核</el-button>
                                    <el-button size="mini" v-else-if="scope.row.isDelete===1" disabled type="primary">已取消</el-button>
                                    <!-- <el-button v-else size="mini" type="primary" @click="cancelSignUp(scope.row)">
                                        取消报名
                                    </el-button> -->
                                </span>
                                <el-button size="mini" type="success" v-if="scope.row.mapList&&scope.row.mapList.length>0" @click="detailShow(scope.row)">
                                    审核明细
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    @size-change="appointmentChange"
                    @current-change="appointmentChange"
                    :current-page.sync="appointmentStart"
                    :page-sizes="[5, 10, 20, 30]"
                    :page-size.sync="appointmentCount"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="appointmentTitle"
                    style="float: right;margin-top:20px">
                </el-pagination>
            </div>
        </el-col>
    </el-row>

    <el-row class="sign-up">
        <el-col :span="24">
            <div class="grid-content">
                <img src="../../../image/kpjl.png" />
                <span>考评记录</span>
            </div>
            <div class="exam-content">
                <el-table :data="evaluationRecord" border style="width: 100%"
                    :header-cell-style="headerClass"
                    :cell-style="cellClass">
                    <el-table-column prop="dataValue" label="考评工种" ></el-table-column>
                    <el-table-column prop="examTime" label="考评日期" :formatter="dateFormatter"></el-table-column>
                    <el-table-column prop="kpsj" label="考评时间"></el-table-column>
                    <el-table-column prop="examPlaceName" label="出勤考点"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <div>
                                <el-button :disabled='scope.row.status==1?true:false' size="mini" :type="scope.row.status==1?'info':'primary'" @click="confirm(scope.row)">
                                    {{scope.row.status==1?'已确认':'点击确认'}}
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    @size-change="evaluationChange"
                    @current-change="evaluationChange"
                    :current-page.sync="evaluationStart"
                    :page-sizes="[5, 10, 20, 30]"
                    :page-size.sync="evaluationCount"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="evaluationTitle"
                    style="float: right;margin-top:20px">
                </el-pagination>
            </div>
        </el-col>
    </el-row>
    <el-dialog title="提示" style="font-size:12px" :visible.sync="cancelVisible" @close="causeClose">
        <div class="tips">
            考试计划已派发，取消考评预约须经过考试机构审核！<br/>
            若需要取消报名，请提交申请理由。
        </div>
        <el-input type="textarea" v-model.trim="cancelCause" placeholder="请输入申请理由。。。"></el-input>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelVisible = false">取 消</el-button>
            <el-button type="primary" @click="causeConfirm">确 定</el-button>
        </span>
    </el-dialog>

      <el-dialog title="审核明细" style="font-size:12px" :visible.sync="detailVisible">
        <el-table :data="saveDetail.mapList" border>
            <el-table-column label="申请时间" prop="createTime"></el-table-column>
            <el-table-column label="申请理由" prop="cancelCause"></el-table-column>
            <el-table-column label="审核状态" prop="reviewStatus">
                <template slot-scope="scoped">
                    <div>
                        <span v-if="scoped.row.reviewStatus==0">待审核</span>
                        <span v-if="scoped.row.reviewStatus==1">审核通过</span>
                        <span v-if="scoped.row.reviewStatus==2">审核不通过</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="审核备注" prop="remarks"></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
            <el-button @click="detailVisible = false">关 闭</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import format from '@/lib/format.js'

export default {
    name: "question",
    data() {
        return {
            cancelCause:"",
            cancelVisible:false,
            appointmentRecord: [],
            appointmentTitle: 0,
            appointmentStart: 1,
            appointmentCount: 10,
            evaluationRecord: [],
            evaluationTitle: 0,
            evaluationStart: 1,
            evaluationCount: 10,
            saveRow:{},
            saveDetail:{},
            detailVisible:false
        };
    },
    created() {
    },
    methods: {
        detailShow(row){
            this.saveDetail = row;
            this.detailVisible = true;
        },
        dateFormatter(row, column) {
            let date = row[column.property];

            if (date == null || date == ""){
                return "";
            }

            return new Date(date).format("yyyy-MM-dd");
        },
        headerClass({ row, rowIndex}) {
            return 'background:#ffffff;color:#3E97DF;text-align: center;'
        },
        cellClass({row, column, rowIndex, columnIndex}) {
            return 'text-align: center;'
        },
        getAppointmentRecord() {
               const userId = sessionStorage.getItem("examiner_userId")
               console.info(userId)
            this.common.examinerAjax({
                method: "post",
                url: this.tzzyUrl + "DataBus/exchange",
                headers: {
                    "content-type": "multipart/form-data"
                },
                data: {
                    SERVICE_CODE:"szsti.examManage.ExamPlanEntry.findExamPlanEvaluatorLs",
                    CONSUMER_ID: sessionStorage.getItem('examiner_token'),
                  "userId": this.aes.encrypt(userId, "SZ[SAFE]dqaq.123").replace(/%/g, "%25").replace(/[+]/g, "%2B"), 
                    start: this.appointmentStart,
	                count: this.appointmentCount
                }
            })
            .then(res => {
                console.log(res) // dispatchStatus 1 是已派发 0 未派发
                if (res.data.BODY.status == 200) {
                    this.appointmentRecord = res.data.BODY.data.data;
                    this.appointmentTitle = res.data.BODY.data.total;
                }else{
                    this.appointmentRecord = [];
                    this.appointmentTitle = 0;
                }
            })
            .catch(error => {
                console.error(error);
            });
        },
        appointmentChange() {
            this.getAppointmentRecord();
        },
        doCancel(row){
            console.log(row)
            let data = {
                        SERVICE_CODE:"szsti.examManage.ExamPlanEntry.cancelApply",
                        CONSUMER_ID: sessionStorage.getItem('examiner_token'),
                        planEvaluatorId: row.id,
                        dispatchStatus: row.dispatchStatus
                    }
                    if(this.cancelCause){
                        data.cancelCause = this.cancelCause
                    }
              this.common.examinerAjax({
                    method: "post",
                    url: this.tzzyUrl + "DataBus/exchange",
                    headers: {
                        "content-type": "multipart/form-data"
                    },
                    data: data
                })
                .then(res => {
                    if (res.data.BODY.status == 200) {
                        if(this.cancelCause){
                            this.$message({
                                type: 'success',
                                message: '申请成功'
                            });
                            this.getAppointmentRecord();
                            this.cancelVisible = false
                            return
                        }
                        this.$message({
                            type: 'success',
                            message: '取消报名成功'
                        });
                        this.getAppointmentRecord();
                    }else{
                        this.$message({
                            type: 'error',
                            message: '取消报名失败'
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        causeClose(){
            this.cancelCause = ""
        },
        causeConfirm(){
            if(!this.cancelCause){
                   this.$message({
                    type: 'error',
                    message: '请输入申请理由'
                });
                return
            }
            this.$confirm('是否取消报名?', '提示', {
                confirmButtonText: '是',
                cancelButtonText: '否',
                type: 'warning'
            }).then(() => {
                console.log(this)
              this.doCancel(this.saveRow)
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '已取消'
                // });
            });;
        },
        cancelSignUp(row) {
            if(row.dispatchStatus ==1) { //已派发需审核
            this.saveRow = row
                this.cancelVisible = true
            }else{
                 this.$confirm('是否取消报名?', '提示', {
                confirmButtonText: '是',
                cancelButtonText: '否',
                type: 'warning'
            }).then(() => {
              this.doCancel(row)
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '已取消'
                // });
            });;
            }

        },
        getEvaluationRecord() {
            this.axios({
                method: "post",
                url: this.tzzyUrl + "DataBus/exchange",
                headers: {
                    "content-type": "multipart/form-data"
                },
                data: {
                    SERVICE_CODE:"szsti.informationManagement.SysEvaluatorProc.findEvaluatorAttendanceRecordByUserId",
                    CONSUMER_ID: sessionStorage.getItem('examiner_token'),
                    userId: sessionStorage.getItem('examiner_userId'),
                    currPage: this.evaluationStart,
	                pageSize: this.evaluationCount
                }
            })
            .then(res => {
                if (res.data.BODY.status == 200) {
                    this.evaluationRecord = res.data.BODY.data.list;
                    this.evaluationTitle = res.data.BODY.data.totalRow;
                }else{
                    this.evaluationRecord = [];
                    this.evaluationTitle = 0;
                }
            })
            .catch(error => {
                console.error(error);
            });
        },
        evaluationChange() {
            this.getEvaluationRecord();
        },
        confirm(row) {
            this.$confirm('是否确认考评?', '提示', {
                confirmButtonText: '是',
                cancelButtonText: '否',
                type: 'warning'
            }).then(() => {
                this.common.examinerAjax({
                    method: "post",
                    url: this.tzzyUrl + "DataBus/exchange",
                    headers: {
                        "content-type": "multipart/form-data"
                    },
                    data: {
                        SERVICE_CODE:"szsti.informationManagement.SysEvaluatorProc.updateEvaluatorInfo",
                        CONSUMER_ID: sessionStorage.getItem('examiner_token'),
                        id: row.id,
                        status: 1
                    }
                })
                .then(res => {
                    if (res.data.BODY.status == 200) {
                        this.$message({
                            type: 'success',
                            message: '确认考评成功'
                        });
                        this.getEvaluationRecord();
                    }else{
                        this.$message({
                            type: 'error',
                            message: '确认考评失败'
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            }).catch(() => {
            });;
        }
    },
    mounted() {
        this.getAppointmentRecord();
        this.getEvaluationRecord();
    }
} ;
</script>
<style lang="stylus" scoped>
.tips{
    text-align :left;
    margin-bottom :20px;
    margin-top:-10px;
}
.sign-up {
    margin-bottom: 20px;
    background-color: #ffffff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);

    .exam-content {
        padding: 40px 42px;
    }
}

.grid-content {
  background-color: #3E97DF;
  font-size: 20px;
  font-weight: bold;
  font-family: Source Han Sans CN;
  color: rgba(66, 71, 75, 1);
//   padding: 10px;
  height:54px;
//   min-height: 26px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  img {
    vertical-align: middle;
    width:26px;
    margin-left:14px;
  }

  span {
    color: #ffffff;
    font-size:20px;
    margin-left:6px;
  }

  .link_more span:link {
    color: #3E97DF;
  }

  .link_more span:hover {
    color: #3E97DF;
  }

  .link_more span:active {
    color: #3E97DF;
  }

  .edit {
       margin-right:60px;

       .el-button--default  {
            width: 88px;
            color: #3E97DF;
        }
  }
}


.el-button--primary {
    background-image: linear-gradient(#52B2FF, #3E97DF);
}

.grid-content_title {
  text-indent: 2em;
  font-size: 18px;
  word-break: break-all;
  text-align: left;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #878d99;
}
</style>
