
<template>
  <el-row :gutter="20" id="trainingInstitution-box" class="ym-trainingInstitution">
    <el-col :span="24" class="right-trainingInstitution-tabel">
      <div class="let-trainingInstitution-nav">
        <div>
          当前位置:
          <span @click="back_home" class="hyperlink-blue" title="去首页">网站首页</span>>
          <span @click="back" class="hyperlink-blue">培训机构</span> > 合格率
        </div>
      </div>

      <div class="ljxgrid-content font-t">
        <div>
          <span class="ljxgrid-content-span">{{institutionName}}</span>
        </div>
      </div>
      <div class="ym-project ym-place">
        <div class="ym-phone">
          <div class="ym-phone-item" style="padding-left: 20px">
            <div class="ym-phone-item-title">机构地址</div>
            <div class="ym-phone-item-text" style="border-right: none;">{{addRess}}</div>
          </div>
          <div class="ym-phone-item" style="padding-right: 20px">
            <div class="ym-phone-item-title">咨询电话</div>
            <div class="ym-phone-item-text">{{phone}}</div>
          </div>
        </div>
        <div class="ym-table">
          <el-table :data="detailsData" style="width: 100%" :row-class-name="tableRowClassName">
            <el-table-column prop="projectType" align="center" label="培训类别" min-width="100" />
            <el-table-column prop="project" align="center" label="培训项目" min-width="100" />
            <el-table-column prop="trainNum" align="center" label="培训人数" min-width="80" />
            <el-table-column prop="passNum" align="center" label="合格人数" min-width="80" />
            <el-table-column prop="passRate" align="center" label="合格率" min-width="80" />
          </el-table>
          <div class="explain" style="text-align:center">说明：统计并公布2024年安全生产培训考试合格率。</div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import { mapState } from "vuex";
import cTree from "@/components/cTree.vue";
export default {
  data() {
    return {
      tableData: {
        list: [],
        totals: 0,
        pageSize: 10,
        currPage: 1
      },
      trainProject: [],
      detailsData: [],
      institutionName: this.$route.query.organizationName,
      phone: this.$route.query.phone,
      addRess: this.$route.query.addRess
    };
  },
  mounted() {
    this.getPass();
    console.log(this.$route.query.phone);
  },
  methods: {
    //   请求数据
    getPass() {
      let url =
        "szsti.AdminEnd.TrainInstitution.getTrainInstitutionProjectList";
      let id = this.$route.query.id;
      let params = {
        CONSUMER_ID: "system",
        trainInstitutionProject: { trainId: id }
      };
      this.$http(url, params, false)
        .then(res => {
          if (res.data.BODY.code == 200) {
            let datas = res.data.BODY.data;
            datas &&
              datas.map((item, index) => {
                item.passRate = Number(item.passRate * 100).toFixed(2) + "%";
              });
            this.detailsData = datas && datas.filter(item => item.trainNum > 0);
          }
        })
        .catch(error => {});
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      } else {
        return "";
      }
    },
    back() {
      this.$router.push("/xxfww/passRate");
    },
    // 初始字典
    getOption() {
      this.$store.dispatch("modifyOptionData", this.url);
    },

    back_home() {
      this.$router.push("/xxfww/home");
    }
  },
  computed: {
    ...mapState({
      optionData: state => state.option_data
    })
  }
};
</script>
<style>
body .el-table th.gutter {
  display: table-cell !important;
}
</style>
<style lang="scss" scoped>
#trainingInstitution-box {
  display: flex;
  justify-content: space-between;
  min-height: 800px;
  .ljxgrid-content {
    position: relative;
    padding: 10px;
    min-height: 30px;
    display: flex;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #fff;
    margin-top: 3px;
    .ljxgrid-content-span {
      color: #3e97df;
    }
  }
  .ym-project {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);
    width: 100%;
    margin-bottom: 50px;
    padding-bottom: 20px;
    .ym-phone {
      box-sizing: border-box;
      padding: 0 20px;
      width: 100%;
      display: flex;
      margin: 30px 0;
      .ym-phone-item {
        display: flex;
        flex: 1;
        .ym-phone-item-title {
          width: 150px;
          height: auto;
          background: rgba(246, 245, 245, 1);
          border: 1px solid rgba(198, 209, 222, 1);
          line-height: 30px;
          font-weight: 600;
          font-size: 18px;
          text-align: center;
          border-right: none;
          background-color: #3e97dfff;
          color: #ffffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .ym-phone-item-text {
          border: 1px solid rgba(198, 209, 222, 1);
          line-height: 30px;
          font-size: 18px;
          box-sizing: border-box;
          padding: 0 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
          // border-right: none;
        }
      }
    }
    .ym-table {
      width: 100%;
      box-sizing: border-box;
    }
  }
  /deep/.el-dialog__header {
    background: rgba(62, 151, 223, 1);
    border-radius: 10px 10px 0 0;
    padding: 0;
    line-height: 56px;
    display: flex;
    justify-content: center;
    .el-dialog__title {
      color: #fff;
      line-height: 56px;
    }
    .el-dialog__headerbtn {
      top: 0;
      line-height: 56px;
      i {
        border: 1px solid rgba(255, 255, 255, 1);
        background: rgba(255, 255, 255, 1);
        border-radius: 50%;
        width: 31px;
        height: 31px;
        line-height: 31px;
        color: #3e97df;
      }
    }
  }

  /deep/.el-dialog__body {
    padding: 0;
    .el-table {
      .el-table__header-wrapper {
        .el-table__header {
          .is-center {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #3e97df;
            line-height: 32px;
          }
        }
      }
    }
  }

  /deep/.el-dialog {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.26);
    border-radius: 1px;
  }

  .let-trainingInstitution-nav {
    color: #3e97df;
    font-size: 14px;
    font-family: Microsoft YaHei;
    text-align: left;
  }

  .right-trainingInstitution-tabel {
    .trainingInstitution-seach {
      margin-bottom: 30px;

      .seach-input {
        height: 100%;
        height: 38px;
        line-height: 38px;

        .el-form-item {
          .el-form-item__label {
            height: 38px;
            line-height: 38px;
            font-size: 16px;
            color: #333;
            font-family: Microsoft YaHei;
            font-weight: 400;
          }

          .el-form-item__content {
            height: 38px;
            line-height: 38px;
            .el-input--suffix {
              /deep/.el-input__inner {
                height: 38px;
                line-height: 38px;
                font-size: 16px;
              }
              .el-input__icon {
                line-height: 38px;
              }
            }
          }
        }
      }

      .search-click {
        display: flex;
        justify-content: center;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        .el-button {
          height: 38px;
          width: 90px;
        }
      }
    }

    .trainingInstitution-tabel {
      width: 100%;
      height: 100%;
    }

    .explain {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #3e97df;
      line-height: 63px;
      height: 63px;
      text-align: left;
      text-indent: 100px;
      border-radius: 0 0 10px 10px;
    }

    .el-table {
      height: 80%;
      overflow: auto;
      border: 1px solid rgba(216, 226, 240, 1);
      border-radius: 4px 4px 4px 4px;
    }

    .table-class-name {
      color: #3e97df !important;
    }

    .el-table .warning-row {
      background: #ccc;
    }

    .uid-class {
      display: inline-block;
      width: 22px;
      height: 21px;
      background: rgba(62, 151, 223, 1);
      border-radius: 50%;
      color: #fff;
    }
  }
}
</style>
<style scoped="scoped" lang="scss">
#trainingInstitution-box .grid-content {
  margin-top: 3px;
}
</style>
//修改这个组件下el-UI的样式
<style lang="scss">
.ym-trainingInstitution {
  .el-table th > .cell {
    color: #3e97df;
    font-weight: 600;
  }
}
</style>
