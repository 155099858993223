<template>
  <el-container class="nav-page">
    <el-header height="80px">
      <div class="let_header_title">考核合格证换证系统</div>
      <div class="right_header_login">
        <div class="user-left">
          <span>{{userInfo.name}}</span>
          <img class="userImg" :src="this.sysUserAvatar" />
        </div>
        <div @click="back_home" class="return-button user-right">
          <img src="../../image/WechatIMG309.png" alt="退出" />
          <span>退出</span>
        </div>
      </div>
    </el-header>
    <!-- <user-page v-if="userShow!==''" :type="userShow" @edit="edit"></user-page> -->
    <el-container>
      <el-aside width="260px">
        <el-menu
          :default-active="defaultIndex"
          class="el-menu-vertical-demo"
          background-color="#3E97DF"
          @select="handRouter"
        >
          <el-menu-item
            :index="String(index+1)"
            v-for="(item,index) in menuData"
            :key="item.name"
            :class="(index+1)===Number(defaultIndex)?'bacTabs':''"
          >
            <div class="nav_tabs">
              <span>
                <img :src="item.icon" />
                <span style="margin-left:11px">{{item.name}}</span>
              </span>
              <span>
                <i class="el-icon-arrow-right"></i>
              </span>
            </div>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>
<script>
// import userPage from "../examinationregistration/userPage";
export default {
  data() {
    return {
      applicantData: {
        realName: null
      },
      sysUserAvatar: require("@/image/exit.png"),
      menuData: [
        {
          name: "申请换证",
          icon: require("../../image/kshz_menu2.png"),
          iconChange: require("@/image/zlb_icon11.png"),
          path: "/replacementSystem/homePage"
        },
        {
          name: "结果查询",
          icon: require("../../image/zlb_icon3.png"),
          iconChange: require("@/image/zlb_icon13.png"),
          path: "/replacementSystem/appointmentLook"
        },
        // {
        //   name: "常见问题",
        //   icon: require("../../image/zlb_icon5.png"),
        //   iconChange: require("@/image/zlb_icon15.png"),
        //   path: "/replacementSystem/question"
        // }
      ],
      path: "/",
      userInfo: {},
      userShow: ""
    };
  },
  methods: {
    handRouter(key, keyPath) {
      this.path = this.menuData[Number(key) - 1].path;
      this.num = String(key);
      let name = this.menuData[Number(key) - 1].name;
      if (name === "常见问题") {
        this.$router.push({
          path: this.path,
          query: {
            columnType: "ENLIST"
          }
        });
      } else {
        this.$router.push(this.path);
      }
    },
    back_home() {
        sessionStorage.removeItem('Tokens')
      this.$router.push("/login?type=replacementSystem");
    },

    edit(val) {
      this.userShow = val;
    }
  },
  computed: {
    defaultIndex: {
      get() {
        if (this.$route.params.playId) {
          return "2";
        } else {
          return this.num;
        }
      },
      set(val) {
        this.num = val;
      }
    }
  },
  mounted() {
    let data = JSON.parse(sessionStorage.getItem("datas"));
    this.userInfo = data[0];
  }
};
</script>
<style lang="stylus" scoped>
.nav-page {
  background-color: #EFF0F4;

  .el-header {
    background: linear-gradient(-50deg, rgba(74, 165, 239, 1), rgba(68, 168, 248, 1));
    color: #333;
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    line-height: 80px;
  }

  .nav_tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      span {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 44px;
      }
    }
  }

  .let_header_title {
    color: #fff;
    font-weight: bold;
    font-size: 30px;
    font-family: Source Han Sans CN;
    height: 29px;
    line-height: 80px;
  }

  .right_header_login {
    display: flex;
    align-items: center;

    .user-left {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: center;
        margin-right: 30px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }

      span {
        margin-right: 10px;
        font-size: 18px;
        color: #fff;
        font-family:Source Han Sans CN;
        font-weight:400
      }

    }

    .user-right {
        height: 36px;
      margin-right: 10px;
      font-size: 18px;
      color: #fff;
      cursor: pointer;
      display: flex;
    align-items: center
      margin-right: 20px;
    }
    img {
        padding-right: 5px;
    }
    .user-right:link {
      color: #fff;
    }

    .user-right:active {
      color: #fff;
    }
    span{
        height: 36px
        line-height: 40px;
    }
  }

  .el-aside {
    background-color: #3E97DF;
    font-weight: bold;
    text-align: center;

    .el-menu-vertical-demo {
      border-right: 0;
    }

    .el-menu-item:hover {
      background: url('../../image/xzds.png') no-repeat left top / 100% 100%;
      background-color: transparent !important;
    }
  }

  .el-menu-item {
    color: #A5D7FE;
    opacity: 0.6;

    i {
      color: #fff;
    }
  }

  .bacTabs {
    background: url('../../image/xzds.png') no-repeat left top / 100% 100%;
    background-color: transparent !important;
    color: #FFF;
    opacity: 1;
  }

  .el-menu-item:hover {
    color: #FFF;
    opacity: 1;
  }

  .el-menu-item:visited {
    background-color: rgba(255, 255, 255, 1);
    color: #FFF;
    opacity: 1;
  }

  .el-main {
    color: #333;
    text-align: center;
    height: 100%;
    overflow-y: auto;
    padding: 20px 40px;
  }

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside, .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
}
</style>
