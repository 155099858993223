
<template>
  <div id="c-try">11
    <li v-for="item in data" :key="item[defalutProps.name]" :class="{'drop-down-box':item[defalutProps.list].length>0,'zIndex':item[defalutProps.list].length>0&&item.show,'child-tree':item[defalutProps.list].length<=0&&item.parentId!==0}">
        <el-row :class="['inner-t','nav-box-detail',item.isVew?'bacTree':'']" @click.native="judgment_open(item)">
            <div class="nav-text">
                {{item[defalutProps.name]}}&nbsp;
                <template v-if="item.show">
                    <i class="el-icon-arrow-right c-bl" v-if="item[defalutProps.list].length>0"></i>
                </template>
                <template v-else>
                    <i class="el-icon-arrow-down c-bl" v-if="item[defalutProps.list].length>0"></i>
                </template>
            </div>
            <img v-if="item.columnIcon" :src="resourceUrl + 'image/'+ item.columnIcon" class="nav-box-img">
        </el-row>
        <c-tree @defaltOption="onselect" :defalut-props=defalutProps :default-index='defaultIndex' :defalt-open='defaltOpen' v-if="item[defalutProps.list].length>0&& !item.show" :data="item[defalutProps.list]"></c-tree>
    </li>
  </div>
</template>
<script>
import recursive from "@/lib/recursive-search.js";
export default {
    name: 'cTree',
    data(){
        return{
            slelect:{}
        }
    },
    mounted(){
       this.defaultSlect()
    },
    methods: {
        defaultSlect(){ //默认选中及展开效果
            let _this=this
            recursive.recursiveSet(this.data,(row)=>{
                // debugger;
                if(_this.defaltOpen){
                    _this.$set(row,"show",true);
                }else{
                    _this.$set(row,"show",false);
                }
            },this.defalutProps)
        },
        judgment_open (item){
            this.$emit('defaltOption',item);
            this.$set(item,"show",!item.show);
        },
        onselect(item){
            this.$emit('defaltOption',item)
        },
        isEquative (item){
             return !(this.data.indexOf(item)>-1)
        }

    },
    props: {
        data: {
            default:()=>{return[]},
            type:Array
        },
        defaltOpen:{
            default:()=>{return false},
            type:Boolean
        },
        defalutProps:{
            default:()=>{
                return {name:'columnName',list:'children'}
            },
            type:Object
        },
        defaultIndex:{},
    }
}
</script>
<style lang="stylus" scoped>
#c-try{
    position:relative;
    .drop-down-box{
        border:1px solid #ccc;
        border-top:none
    }
    li{
        list-style :none
    }
    // .zIndex{
    //     width:100%
    //     z-index :20;
    //     position absolute
    //     background:#FFF
    // }
    .nav-box-detail {
        height: 45px;
        position: relative;
        .el-col {
            width: 100%;
            height: 100%;
        }
        .nav-box-img {
            left: 0;
            position: absolute;
            width: 100%
            height: 100%
            z-index: 0
        }
        .nav-text {
            position: absolute;
            width: 69%;
            height: 100%;
            padding-left: 60px;
            left: 0;
            text-align: center;
            line-height: 45px
        }
        .nav-text:hover{
            cursor: pointer
        }
        .bac{
            background :rgba(172, 157, 157, 0.5);
        }
    }
}
</style>
