//test

import $vue from 'vue'
import axios from "axios"

export const increment = ({ commit }) => {
    commit('INCREMENT')
}
export const decrement = ({ commit }) => {
    commit('DECREMENT')
}

export const modifyExamination = function ({ commit }, list) {
    commit('setExamination', list)
}
export const modifySetTabPage = function ({ commit }, list) {
    commit('setTabPage', list)
}
export const addventPrinter = function ({ commit }, list) {
    commit('setPrinterStatus', list)
}

export const modifyOptionData = function ({ commit }, url) {
    Object.keys(this.state.option_data).forEach(x => {
        if (this.state.option_data[x].length <= 1) {
            let data = {};
            switch (x) {
                case 'question_category':
                    data = {
                        SERVICE_CODE: "szsti.system.DataCode.findDataCodeByParentCode",
                        CONSUMER_ID: "system-be6d9c413c7a4be484d938a621f2a4f0s",
                        parentCode: "question_category"
                    };
                    break;
                case 'available_plan':
                    data = {
                        SERVICE_CODE: "szsti.AdminEnd.ExamExamineePlay.getAvailablePlanList",
                        CONSUMER_ID: sessionStorage.getItem("Token")||'system'
                    }
                    break;
                case 'organization':
                    data = {
                        SERVICE_CODE: "szsti.AdminEnd.TrainInstitution.getTrainInstitutionList",
                        CONSUMER_ID: sessionStorage.getItem("Token")||'system'
                    }
                    break;
                case 'exam_plan':
                    data = {
                        SERVICE_CODE: "szsti.examManage.ExamOrderPlay.findExamSchedule",
                        CONSUMER_ID: sessionStorage.getItem("Token")||'system',
                        pageSize: 999,
                        currPage: 1,
                    }
                    break;
                default:
                    data = {
                        SERVICE_CODE: "szsti.system.DataCode.findDataCodeByParentCode",
                        CONSUMER_ID: sessionStorage.getItem("Token")||'system',
                        parentCode: x
                    }
            }
            // debugger;
            axios({
                method: "post",
                url: url,
                headers: {
                    "content-type": "multipart/form-data"
                },
                data: data
            }).then(res => {
                let list = []

                switch (x) {     //培训机构 与考点 数据处
                    case 'available_plan':
                        if (res.data && res.data.BODY && res.data.BODY.data && res.data.BODY.data.length > 0) {
                            list = res.data.BODY.data.map(x => {
                                return {
                                    dataCode: x.id,
                                    dataValue: x.playName,
                                    examType: x.examItem
                                }
                            })
                        }
                        commit('setOptionData', { x: x, list: list })
                        break;
                    case 'organization':
                        if (res.data && res.data.BODY && res.data.BODY.data && res.data.BODY.data.length > 0) {
                            list = res.data.BODY.data.map(x => {
                                return {
                                    dataCode: x.id,
                                    dataValue: x.institutionName + '#' + x.institutionProjectType + '#' + x.institutionProjectType
                                }
                            })
                        }
                        commit('setOptionData', { x: x, list: list })
                        break;
                    case 'testing_centre':
                        if (res.data && res.data.BODY && res.data.BODY.data && res.data.BODY.data.length > 0) {
                            list = res.data.BODY.data.map(x => {
                                return { dataCode: x.id, dataValue: x.examName }
                            })
                        }
                        commit('setOptionData', { x: x, list: list })
                        break;
                    case '"question_category"':
                        if (res.data && res.data.BODY && res.data.BODY.data && res.data.BODY.data.length > 0) {
                            list = res.data.BODY.data
                        }
                        commit('setOptionData', { x: x, list: list })
                        break;
                    default:
                        commit('setOptionData', { x: x, list: res.data.BODY.data });
                }
            });
        }
    });
}
