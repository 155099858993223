<template>
  <el-row style="min-height: 596px">
    <el-row class="announcement-nav-title">
      <div>
        当前位置:
        <span @click="back_home" class="hyperlink-blue" title="去首页">网站首页</span>
        <span v-if="navTitle !== ''">>{{ navTitle }}</span>
        <span @click="back" :class="lookDetail ? 'hyperlink-blue' : ''">>{{ pageTitle }}</span>
        <span v-if="lookDetail">>文章详情</span>
      </div>
    </el-row>

    <el-row :gutter="20" id="announcement-box" style="margin: 0;">
      <el-col
        :span="4"
        class="let-announcement-nav"
        style="padding: 0;margin-right: 80px;width: 200px;"
      >
        <el-row class="announcement-nav-box">
          <el-col :span="24">
            <nav-tree
              :data="newContentData"
              :defalut-props="defalutProps"
              :defalt-index="defaltId"
              @defaltOption="changeOption"
            ></nav-tree>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="20" class="right-announcement-tabel">
        <template v-if="!lookDetail">
          <div class="grid-content font-t">
            <div>
              <span class="grid-content-span">{{ pageTitle }}</span>
            </div>
          </div>
          <el-row class="inner-t apply-list-con">
            <ul style="height: 456px;overflow: auto;">
              <li :key="item.id" v-for="item in tabelList.pageList" @click="open_detail(item)">
                <div class="span_detil inner-t hlva">
                  <span
                    style="overflow: hidden;text-align: left; text-overflow: ellipsis;white-space: nowrap; width:73%;"
                  >{{ item.title }}</span>
                  <span style="width: 30%;text-align:right">{{ item.publishTime.slice(0,11) }}</span>
                </div>
              </li>
            </ul>
            <div class="block">
              <el-pagination
                class="pg"
                @current-change="handleCurrentChange"
                :current-page="tabelData.currPage"
                :page-size="tabelData.pageSize"
                layout="slot,jumper"
                :total="tabelList.totalRow"
              >
                <div class="pg">
                  <div
                    class="pg-left"
                  >共{{ tabelList.totalRow }}条数据 &nbsp;&nbsp;每页{{ tabelData.pageSize }}条 &nbsp;&nbsp;第{{ tabelData.currPage }}页/共{{tabelList.totalPage}}页</div>
                  <el-button class="first" @click="jumpPage('1')">首页</el-button>
                  <el-button @click="jumpPage('-1')">上一页</el-button>
                  <el-button @click="jumpPage('+1')">下一页</el-button>
                  <el-button @click="jumpPage('0')">尾页</el-button>
                </div>
              </el-pagination>
            </div>
          </el-row>
        </template>
        <template v-else>
          <el-row class="article-detail">
            <el-col :span="24">
              <div class="font-t">{{ detailData.title }}</div>
            </el-col>
            <el-col :span="24" class="bac-t inner-t" style="margin-top:20px">
              <div>
                <span>来源：{{ detailData.source }}</span>
                <span class="m-l">信息提供日期: {{ detailData.publishTime }}</span>
                <!-- <span class="m-l">分享到: {{detailData.source}}</span> -->
              </div>
            </el-col>
            <el-col :span="24" class="inner-t content-box">
              <template v-if="detailData.externalLink&&detailData.externalLink.length>0">
                <a target="view_window" :href="detailData.externalLink" v-html="detailData.content"> </a>
              </template>
              
              <div v-else class="content-detail" v-html="detailData.content"></div>
            </el-col>
          </el-row>
          <div class="article-footer" v-if="detailData.annexAddr&&detailData.annexAddr.length > 0">
            <div class="footer-box">
              <span class="bac-blue">附件下载</span>
              <br />
              <div class="footer-box">
                <a
                  v-for="(item, index) in detailData.annexAddr"
                  :key="index"
                  target="view_window"
                  :href="resourceUrl + item"
                >{{ detailData.annexName[index] }}</a>
              </div>
            </div>
          </div>
        </template>
      </el-col>
    </el-row>
  </el-row>
</template>
<script>
import recursive from "@/lib/recursive-search.js";
import navTree from "@/components/navTree.vue";
export default {
  data() {
    return {
      lookDetail: false,
      navTitle: "",
      pageTitle: "",
      defaltId: 11,
      defalutProps: {
        name: "columnName",
        list: "children"
      },
      detailData: {},
      defaultIndex: "",
      newContentData: [],
      tabsNav: [
        {
          name: "通知公告",
          id: 6,
          list: [
            {
              list: [],
              name: "工作通知",
              id: 11,
              img: require("@/image/wzlby_btn3.png")
            },
            {
              list: [],
              name: "工作台",
              img: require("@/image/wzlby_btn3.png")
            }
          ],
          img: require("@/image/wzlby_btn1.png"),
          isVew: true
        },
        {
          name: "考试计划",
          id: 12,
          list: [],
          img: require("@/image/wzlby_btn2.png"),
          isVew: true
        },
        {
          id: 13,
          name: "工作指引",
          list: [
            { list: [], name: "", img: require("@/image/wzlby_btn3.png") }
          ],
          img: require("@/image/wzlby_btn3.png"),
          isVew: true
        },
        {
          id: 13,
          name: "法规政策",
          list: [
            { list: [], name: "", img: require("@/image/wzlby_btn3.png") }
          ],
          img: require("@/image/wzlby_btn4.png"),
          isVew: true
        },
        {
          id: 14,
          name: "考试大纲",
          list: [
            { list: [], name: "", img: require("@/image/wzlby_btn3.png") }
          ],
          img: require("@/image/wzlby_btn5.png"),
          isVew: true
        },
        {
          id: 15,
          name: "常见问答",
          list: [],
          img: require("@/image/wzlby_btn6.png"),
          isVew: true
        },
        {
          id: 16,
          name: "服务窗口",
          list: [],
          img: require("@/image/wzlby_btn7.png"),
          isVew: true
        },
        {
          id: 17,
          name: "成绩查询",
          list: [],
          img: require("@/image/wzlby_btn9.png"),
          isVew: true
        },
        {
          id: 18,
          name: "资料下载",
          list: [],
          img: require("../../image/wzlby_btn9.png"),
          isVew: true
        }
      ],

      routerData: false,
      loading: true,
      tabelList: {},
      tabelData: {
        totals: 0,
        pageSize: 10,
        currPage: 1
      },
      // 分页参数
      paginations: {
        currentPage: 1,
        total: 0,
        pageSize: 10
      },
      columnId: "",
      query: {
        parentId: "",
        childId: "",
        detailId: ""
      }
    };
  },
  created() {
    this.getNewContentData();
  },
  mounted() {
    // 路由带入默认选中数据
    console.log('refresh')
    if (this.$route.query.childId) {
      this.routerData = true;
    } else {
      this.routerData = false;
    };
    if(this.$route.query.detailId){
      this.lookDetail=true
    }
  },
  methods: {
    changeOption(item) {
      if (item.columnName === "常见问答") {
        this.$router.push({ path: "/xxfww/faqs" });
      } else if (item.columnName === "准考证打印") {
        this.$router.push({
          path: "/xxfww/article",
          query: { type: "ticket" }
        });
      } else if (item.columnName === "成绩查询") {
        this.$router.push({
          path: "/xxfww/article",
          query: { type: "score" }
        });
      } else if (item.columnName === "证书查询") {
        this.$router.push({
          path: "/xxfww/article",
          query: { type: "certificate" }
        });
      } else {
        let _this = this;
        recursive.recursiveSet(
          this.newContentData,
          (row, pars) => {
            if (row === item && row[_this.defalutProps.list] <= 0) {
              // 设置选中
              _this.pageTitle = row[_this.defalutProps.name];
              // 设置当前位置(判断同级不取值)
              // _this.navTitle=(this.tabsNav.indexOf(pars.parent))>-1&&(this.tabsNav.indexOf(row)>-1)?'':pars.parent[_this.defalutProps.name]

              _this.columnId = row.id;
              _this.getContentPages();
              _this.query.parentId = row.parentIds;
              _this.query.childId = row.id;
              let data = {};
              _this.query.parentId !== "" &&
                (data.parentId = _this.query.parentId);
              _this.query.childId !== "" &&
                (data.childId = _this.query.childId);

              _this.$router.push({ query: data });

              _this.lookDetail = false;
            } else {
              _this.$set(row, "isVew", false);
            }
          },
          this.defalutProps
        );
      }
    },
    // 获取新闻页的新闻栏目文章数据
    getNewContentData() {
      this.loading = true;
      let url = "szsti.publicInfo.ColumnNode.frontColumnNodeContent";
      let param = {
        CONSUMER_ID: "system-70837df36900457cb799035cd8d59a88",
        columnNode: {
          columnType: "NEW"
        }
      };
      this.$http(url, param, false)
        .then(res => {
          this.newContentData = res.data.BODY.data;
          this.loading = false;
        })
        .catch(y => {
          this.$message.error(y);
        });
    },
    // 获取相应文章列表分页数据
    getContentPages() {
      let url = "szsti.publicInfo.ColumnContent.selectPages";
      let param = {
        CONSUMER_ID: "system-70837df36900457cb799035cd8d59a88",
        columnId: this.columnId,
        currPage: this.paginations.currentPage,
        pageSize: this.paginations.pageSize,
      };
      this.$http(url, param, false)
        .then(res => {
            const datas = res.data.BODY.data
          this.tabelList = datas;
          this.tabelData.totals = datas.totalRow
        })
        .catch(y => {
          this.$message.error(y);
        });
    },
    // 初始化列表
    init() {
      let urlA = "szsti.AdminEnd.ExamCertificate.showExamCertificate";
      let paramA = {
        totals: this.tabelData.totals,
        pageSize: this.tabelData.pageSize,
        currPage: this.tabelData.currPage
      };
      this.$http(urlA, paramA, true).then(x => {
        console.log(22,x)
        this.tableData = x;
      });
    },

    back_home() {
      this.$router.push("/xxfww/home");
    },
    back() {
      if (this.lookDetail) {
        this.$router.push({
          path: "/xxfww/announcement",
          query: {
            parentId: this.$route.query.parentId,
            childId: this.$route.query.childId
          }
        });
        this.columnId = Number(this.$route.query.childId);
        this.getContentPages();
        this.lookDetail = false;
      }
    },
    judgment_open(row) {
      this.$set(row, "isVew", !row.isVew);
    },
    //分页触发事件
    handleCurrentChange(val, num) {
        // console.log(val,num);
      if (num) {
          this.tabelData.currPage += num;
        this.paginations.currentPage += num;
      } else {
          this.tabelData.currPage = val;
        this.paginations.currentPage = val;
      }
      this.getContentPages();
    },
    jumpPage(val) {
      switch (val) {
        case "1": //跳转到首页
          this.handleCurrentChange(1);
          break;
        case "0": //跳转到末页
        console.log(this.tabelList.totalRow,12);
          this.handleCurrentChange(
            this.tabelList.totalPage
          );
          break;
        case "-1": //上一页
        console.log(this.tabelData.currPage);
          if (this.tabelData.currPage > 1) {
              console.log(100)
            this.handleCurrentChange("-1", -1);
          }
          break;
        case "+1": //下一页
          if (
            this.tabelData.currPage <=
            this.tabelData.totals / this.tabelData.pageSize
          ) {
            this.handleCurrentChange("+1", +1);
          }
          break;
      }
    },
    // 打开详情
    open_detail(row) {
    //   debugger;
      if (row.externalLink != "" && row.externalLink != null) {
        //跳转外部链接
        window.open(row.externalLink);
      } else {
        this.query.detailId = row.id;
        let data = {};
       
        this.query.parentId !== "" && (data.parentId = this.query.parentId);
        this.query.childId !== "" && (data.childId = this.query.childId);
        this.query.detailId !== "" && (data.detailId = this.query.detailId);
        data.childId= this.$route.query.childId
        this.$router.push({ query: data });
        // this.$route.query={parentId:item.id,childId:row.columnId,detailId:row.id}}
        this.detailData = row;
        this.detailData.annexAddr &&
          (this.detailData.annexAddr = this.detailData.annexAddr.split(","));
        this.detailData.annexName &&
          (this.detailData.annexName = this.detailData.annexName.split(","));
        this.lookDetail = true;
      }
    },
    skip_methods() {
      let _this = this;
      this.navTitle = "";
      this.pageTitle = "";
      recursive.recursiveSet(
        this.newContentData,
        (row, pars) => {
          if (_this.$route.query.detailId) {
            //-------------------------------------去详情
            if (
              row.id === Number(_this.$route.query.parentId) &&
              Number(_this.$route.query.parentId)
            ) {
              // 设置选中
              _this.defaltId = _this.$route.query.childId;
              // 设置当前位置(判断同级不取值)
              _this.navTitle = row[_this.defalutProps.name];
            }
            if (
              row.id === Number(_this.$route.query.childId) &&
              row[_this.defalutProps.list] <= 0
            ) {
              _this.pageTitle = row[_this.defalutProps.name];
              row.columnContent.forEach(x => {
                if (x.id === Number(_this.$route.query.detailId)) {
                  _this.detailData = x;
                  _this.detailData.annexAddr &&
                    (_this.detailData.annexAddr = _this.detailData.annexAddr.split(
                      ","
                    ));
                  _this.detailData.annexName &&
                    (_this.detailData.annexName = _this.detailData.annexName.split(
                      ","
                    ));
                  _this.lookDetail = true;
                }
              });
            }
          } else {
            //-------------------------------------不详情
            if (row.id === Number(_this.$route.query.childId)) {
              _this.columnId = Number(_this.$route.query.childId);
              // _this.navTitle=row[_this.defalutProps.name];
              _this.pageTitle = row[_this.defalutProps.name];
                  _this.getContentPages();
              _this.lookDetail = false;
            }
            if (
              Number(_this.$route.query.parentId) &&
              row.id === Number(_this.$route.query.parentId)
            ) {
              _this.navTitle = row[_this.defalutProps.name];
            }
          }
        },
        this.defalutProps
      );
    }
  },
  computed: {
    routerChange() {
      return (
        this.$route.query.parentId ||
        this.$route.query.childId ||
        this.$route.query.detailId
      );
    }
  },
  watch: {
    loading(val) {
      if (val === false && this.routerData == true) {
        this.skip_methods();
      }
    },
    routerChange() {
      this.skip_methods();
    }
  },
  components: { navTree }
};
</script>
<style lang="stylus">
.announcement-nav-title {
  color: #3E97DF;
  font-size: 14px;
  font-family: Microsoft YaHei;
  text-align: left;
  padding-left: 14px;
}

#announcement-box {
  display: flex;
  padding-left: 14px;

  // justify-content space-between
  .grid-content {
    position: relative;
    padding: 0 10px;
    padding-left: 21px;
    min-height: 26px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: #3E97DF;
    color: #fff;
    line-height: 36px;

    img {
      position: absolute;
      margin-left: 0;
      height: 45%;
    }
  }

  .grid-content_title {
    text-indent: 2em;
    font-size: 18px;
    word-break: break-all;
    text-align: left;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #878d99;
  }

  .let-announcement-nav {
    color: #3E97DF;
    font-size: 14px;
    font-family: Microsoft YaHei;

    .announcement-nav-box {
      margin-top: 20px;
    }
  }

  .right-announcement-tabel {
    position: relative;
    margin-top: 20px;
    padding: 0 20px;
    overflow-y: hidden;
    overflow-x: hidden;
    width 906px
    .apply-list-con {
      width: 100%;
      font-family: Microsoft YaHei;
      font-weight: 400;
      position: static;

      ul {
        padding: 0;
      }

      ul li {
        font-family: \5 FAE \8 F6F \9 6C 5 \9 ED1;
        text-align: left;
        font-size: 16px;
        line-height: 32px;
        background: url('../../image/dot.jpg') no-repeat 8px center / 12px;
        // background-size 24px
        padding: 5px 0;

        // padding-right 24px
        .inner-t {
          margin: 0;
        }
      }

      .block {
        font-size: 13px;
        position: absolute;
        bottom: 0;

        .pg {
          .pg-left {
            color: #666666;
            line-height: 24px;
          }

          .first {
            margin-left: 20px;
          }

          .el-button {
            background-color: #3E97DF;
            color: #ffffff;
            height: 24px;
            text-align: center;
            border-radius: inherit;

            span {
              width: 53px;
              height: 24px;
              line-height: 24px;
              font-size: 13px;
            }
          }
        }
      }
    }

    .article-footer {
      // position absolute
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      text-align: left;
      min-height: 200px;
      width: 100%;
      bottom: 0;
      border-top: 1px solid #ccc;

      .footer-box {
        padding: 10px 0;
        .bac-blue {
            display: inline-block;
            width: 72px;
            height: 24px;
            line-height: 24px;
            text-align: center;
        };
        a {
            color: #3E97DF;
            display: block;
            line-height: 24px;
        }
      }
    }

    .article-title {
      line-height: 100px;
      height: 100px;
    }
  }

  .content-box {
    text-align: initial;

    .content-detail {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(102, 102, 102, 1);
    }
  }

  .span_detil {
    padding-left: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #2d2f33;
  }
}
</style>
<style scoped="scoped" lang="scss">
.bac-t {
  line-height: 26px;
  margin-bottom: 10px;
}
.font-t{
  font-size: 22px;
}
</style>
