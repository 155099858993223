<template>
  <el-row>
    <ul class="title-nav">
      <li v-for="(item,index) in tabs"
          :key='item.label'
          @click="tabs_click(item)">
        <div :class="[item.isView?'isView':'','nav-b','hlvaTree']"
             style="display: inline-block;">{{item.label}}</div>
        <span v-if="index!==tabs.length-1"></span>
      </li>
    </ul>
  </el-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  data () {
    return {
      tabs: [
        { id: 1, label: "首页", name: "home", path: '/xxfww/home', isView: true },
        {
          id: 2,
          label: "通知公告",
          name: "announcement",
          path: '/xxfww/announcement',
          isView: false
        },
        {
            id: 4,
            label: "考试计划",
            name: "theTestPoint",
            path: '/xxfww/testplan?parentId=0&childId=37',
            isView: false
        },
        {
          id: 3,
          label: "培训机构",
          name: "trainingInstitution",
          path: '/xxfww/passRate',
          isView: false
        },
       
        // {
        //   id: 5,
        //   label: "考试报名",
        //   name: "examinationregistration",
        //   path: '/examinationregistration/homePage',
        //   isView: false
        // },
        // {
        //   id: 6,
        //   label: "考试预约",
        //   name: "preRegisteredExamination",
        //   path: '/preRegisteredExamination/homePage',
        //   isView: false
        // },
        {
          id: 7,
          label: "准考证打印",
          type: 'ticket',
          name: "printingOfAdmissionTicket",
          path: '/xxfww/article',
          isView: false
        },
        {
          id: 8,
          label: "成绩查询",
          type: 'score',
          name: "printingOfAdmissionTicket",
          path: '/xxfww/article',
          isView: false
        },
        {
          id: 9,
          label: "证书查询",
          type: 'certificate',
          name: "certificateLook",
          path: '/xxfww/article',
          isView: false
        },
        {
          id: 10,
          label: "常见问答",
          name: "faqs",
          path: '/xxfww/faqs',
          isView: false
        },
      ]
    };
  },
  mounted () {
    this.tabs.forEach(row => {
      row.isView = false;
      if (row.label == this.$router.currentRoute.name) {
        console.log(row,12312312);
        console.log(row.label,3123123);
        row.isView = true;
      }
    })
  },
  watch: {
    $route (newValue, oldValue) {
      this.tabs.forEach(row => {
        row.isView = false;
        if (row.label == this.$router.currentRoute.name) {
          if (row.type) {    //处理相同路由的页面1
            row.isView = false;
          } else {
            row.isView = true;
          }
        }
        if (this.$router.currentRoute.query.type && row.type) {     //处理相同路由的页面2  增加type识别避免冲突影响导航栏背景样式
          if (row.type === this.$router.currentRoute.query.type) {
            row.isView = true;
          }
        }
      })
    },
    // 监听导航状态变更----其他页面触发的
    tabDate (row) {
      this.tabs.forEach(x => {
        if (x.label === row) {
          x.isView = true
        } else {
          x.isView = false
        }
      })
    }
  },
  methods: {
    tabs_click (tab) {
      this.tabs.forEach(row => {
        this.$set(row, 'isView', false)
        if (row.id === tab.id) {
          console.log(row.label, row.path);
          row.isView = !row.isView
          switch (row.label) {
            case '准考证打印':
              this.$router.push({ path: row.path, query: { type: 'ticket' } });
              break;
            case '成绩查询':
              this.$router.push({ path: row.path, query: { type: 'score' } });
              break;
            case '证书查询':
              this.$router.push({ path: row.path, query: { type: 'certificate' } });
              break;
            case '通知公告':
              let query = {
                childId: 6
              }
              this.$router.push({ path: row.path, query: query });
              break;
            case '考试报名':
              this.$router.push({ path: "/examinationregistration/homePage", query: query });
              // window.open('/#/examinationregistration/homePage');
              break;
            case '考试预约':
              this.$router.push({ path: "/preRegisteredExamination/homePage", query: query });
              // window.open('/#/preRegisteredExamination/homePage');
              break;
            default:
              this.$router.push({ path: row.path });
          }
        }
      })
    }
  },
  computed: {
    ...mapState({
      tabDate: state => state.tabPage
    })
  }
};
</script>

<style lang="stylus" scoped>
.title-nav {
  padding: 0;
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #FFFFFF;
  width: 100%;
  height: 44px;
  display: flex;
  line-height: 44px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-item: center;
  list-style: none;

  li {
    flex: 1;

    div {
      width: 100%;
      transition: all 0.3s;
      height: 100%;
    }

    span {
      width: 3%;
    }
  }
}

.isView {
  // border-bottom:1px solid #ccc;
  // box-shadow :-2px -1px 8px 4px;
  background: #ffff;
  color: #3E97DF;
  font-weight: 600;
}

.hlvaTree:hover {
  transform: none;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
}
</style>
